(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .controller('QuestionsController', QuestionsController);

    QuestionsController.$inject = ['$rootScope', '$scope', '$window', 'DTOptionsBuilder', 'DTColumnBuilder', 'WidgetsService', 'EmailsService', 'LanguagesService', 'NgTableParams', '$state', '$mdSidenav', '$mdDialog', '$timeout','SnackbarUtils','WidgetValidationService'];

    function QuestionsController($rootScope, $scope, $window, DTOptionsBuilder, DTColumnBuilder, WidgetsService, EmailsService, LanguagesService, NgTableParams, $state, $mdSidenav, $mdDialog, $timeout, SnackbarUtils,WidgetValidationService) {
        var vm = this;

        var defaultQuestion = {
            questionId : null,
            questionTextTranslateMap: {},
            questionType: null,
            questionDatetime: null,
            questionMedia: null,
            questionStartDate: null,
            questionEndDate: null,
            questionProposedBy: $rootScope.globals.userId,
            questionIsAccepted: 0,
            questionAnswerCount: 0,
            questionUsesChoiceImages: false,
            widgetTopicUI: {
                topicId : null
            },
            questionChoiceTypePayload: {
                choiceTypeId: null,
                choiceTypeName: "",
                choiceTypeDefault: true,
                choiceTypeTranslateMap: {},
                choices: [
                    {
                        choiceId: null,
                        choiceMedia: null,
                        choiceTranslateMap: {},
                        choiceValue: 1
                    },
                    {
                        choiceId: null,
                        choiceMedia: null,
                        choiceTranslateMap: {},
                        choiceValue: 2
                    }
                ]
            }
        };


        vm.scrollToElement = function (id) {
            $timeout( function(){
                var elemToScroll = document.getElementById(id);
                elemToScroll && elemToScroll.scrollIntoView({ behavior: "smooth"});
            }, 150);
        };

        vm.getPreviewUrl = function(id) {
            return "https://" + $rootScope.globals.panelHostname + "/app/cardPage/" + id;
        };

        vm.getStatsUrl = function(id) {
            return "https://" + $rootScope.globals.panelHostname + "/app/stats/" + id;
        };

        vm.getTopics = function () {
            WidgetsService.GetAllTopics(
                function success(response) {
                    vm.topics = response.data;
                    vm.topicsCopy = angular.copy(vm.topics);
                    vm.topicsMap = {};
                    vm.topics.map(element => vm.topicsMap[element.topicName] = element.topicId);
                    vm.topicsMap["TOTAL"] = 0;
                    vm.topicNames = vm.topics.map(element => {return element.topicName;});
                    vm.topicNames.unshift("TOTAL");
                },
                function error(response) {
                    console.log(response);
                })
        };

        vm.successCallbackForQuestionImageDirective = function () {
            vm.tableParams.reload();
        };

        vm.reloadQuestions = function () {
            WidgetsService.GetTopicsCounts(
                function success(response){
                    vm.countsByTopic = response.data;
                    vm.countsByTopic[0] = Object.values(vm.countsByTopic).reduce((a, b) => a + b, 0);
                },
                function error(response) {
                    console.log(response);
                });
            delete vm.tableParams;
            vm.tableParams = new NgTableParams(vm.initialParams, {
                filterDelay: 300,
                // page size buttons (right set of buttons in demo)
                counts: [],
                // determines the pager buttons (left set of buttons in demo)ss
                paginationMaxBlocks: 20,
                paginationMinBlocks: 2,
                //get data from server whenever the user changes the page/order/filter
                getData: function(params) {
                    var queryParams = params.url();
                    var orderColumn = Object.keys(queryParams).find(key => queryParams[key] === "asc" || queryParams[key] === "desc");
                    if (typeof orderColumn !== "undefined"){
                        const order = queryParams[orderColumn];
                        delete queryParams[orderColumn];
                        queryParams["order"] = order;
                        queryParams["orderColumn"] = orderColumn.charAt(8);
                    }
                    vm.tableLanguageId && (queryParams["lang"] = vm.tableLanguageId);
                    return WidgetsService.GetAllQuestions(queryParams,
                        function success(response) {
                            vm.questionsList = response.data.pageRows;
                            params.total(response.data.count);
                            return vm.questionsList;
                        },
                        function error(response) {
                            console.log(response);
                        });
                }
            });
        };

        vm.initNewQuestion = function() {
            vm.currentDirectiveLanguage = false;
            if(vm.areCustomChoicesUsed){
                vm.areCustomChoicesUsed = false;
            }
            if(vm.isLanguageSelected && vm.languagesList.length > 1){
                vm.isLanguageSelected = false;
            }
            if(vm.isLeftLanguageValid && vm.languagesList.length > 1){
                vm.isLeftLanguageValid = false;
            }
            if(vm.isStartDateChanged)
                vm.isStartDateChanged = false;
            if(vm.isEndDateChanged)
                vm.isEndDateChanged = false;
            delete vm.currentQuestion;
            setTimeout(function () {
                // The setTimeout is executed out of the angular context, use angular $scope.$apply() to modify and notice
                // angular that the variable has suffered changes and it should apply them.
                $scope.$apply(function () {
                    vm.currentQuestion = angular.copy(defaultQuestion);
                    vm.currentQuestionStartDate = null;
                    vm.currentQuestionEndDate = null;
                });
                vm.scrollToElement("targetScrollOnEditId");
            }, 250);

        };

        vm.saveCurrentQuestion = function () {
            if (!vm.currentQuestion) {
                return;
            }
            
            if(vm.currentQuestion.questionChoiceTypePayload.choiceTypeDefault){
                delete vm.currentQuestion.questionChoiceTypePayload.choiceTypeName;
                delete vm.currentQuestion.questionChoiceTypePayload.choiceTypeTranslateMap;
                delete vm.currentQuestion.questionChoiceTypePayload.choices;
            }

            // Send dates as strings - use current question copy to prevent datepicker errors due to the date->string type change
            var currentQuestionCopy = angular.copy(vm.currentQuestion);
            currentQuestionCopy.questionDatetime = (currentQuestionCopy.questionDatetime == null ? null : moment(currentQuestionCopy.questionDatetime).format("YYYY-MM-DD HH:mm:ss"));

            // If start/end date was not changed, use the original one (datepicker dates are followed by 00:00)
            if (currentQuestionCopy.questionStartDate != null) {
                if (!vm.isStartDateChanged) {
                    currentQuestionCopy.questionStartDate = vm.currentQuestionStartDate || moment(currentQuestionCopy.questionStartDate).format("YYYY-MM-DD HH:mm:ss");
                }
                else
                    currentQuestionCopy.questionStartDate = moment(currentQuestionCopy.questionStartDate).format("YYYY-MM-DD HH:mm:ss")
            }
            if (currentQuestionCopy.questionEndDate != null) {
                if (!vm.isEndDateChanged)
                    currentQuestionCopy.questionEndDate = vm.currentQuestionEndDate || moment(currentQuestionCopy.questionEndDate).format("YYYY-MM-DD HH:mm:ss");
                else
                    currentQuestionCopy.questionEndDate = moment(currentQuestionCopy.questionEndDate).format("YYYY-MM-DD HH:mm:ss")
            }

            var confirm = $mdDialog.confirm()
                .title("Question save")
                .textContent("Are you sure that you want to save your modifications on this record ?")
                .cancel("No")
                .ok("Yes");

            if(this.validateQuestion()) {
                $mdDialog.show(confirm).then(function () {
                    WidgetsService.SaveQuestion(
                        currentQuestionCopy,
                        function  success(response) {
                            var translatedLanguages = WidgetValidationService.getTranslatedLanguages(vm.currentQuestion, vm.languagesList);
                            SnackbarUtils.displaySnackbarWithLanguagesEnum("success", "Your translation has been saved into:", translatedLanguages);
                            WidgetValidationService.areAllQuestionTextsTranslated(vm.languagesList,vm.currentQuestion);
                            $timeout(function(){
                                vm.reloadQuestions();
                            },2000);
                            delete vm.currentQuestion;
                        },
                        function error(response) {
                            SnackbarUtils.displaySnackbar("error", "Your question has not been saved");
                        }
                    );
                }, function () {
                });
            }
        };

        vm.validateQuestion = function(){
            if(!vm.validateQuestionTexts()) return false;
            if(!vm.areCustomChoicesUsed) return true;
            if(!WidgetValidationService.validateIncompleteTranslations(vm.currentQuestion, vm.languagesList)) return false;
            if(!WidgetValidationService.validateCustomChoices(vm.currentQuestion, vm.languagesList)) return false;
            return true;
        };

        vm.areChoicesEmpty = function(key){
            var areAllChoicesEmpty = true;
            for(var choice of vm.currentQuestion.questionChoiceTypePayload.choices){
                if((typeof choice.choiceTranslateMap[key] !== 'undefined') &&
                 choice.choiceTranslateMap[key] !== ""){
                    areAllChoicesEmpty = false;
                }
            }
            if(areAllChoicesEmpty){
                for(var choiceElement of vm.currentQuestion.questionChoiceTypePayload.choices){
                    if(typeof choiceElement.choiceTranslateMap[key] !== 'undefined'){
                        delete choiceElement.choiceTranslateMap[key];
                    }
                }
                delete vm.currentQuestion.questionTextTranslateMap[key];
                return true;
            }
            return false;
        };

        vm.validateQuestionTexts = function(){

            if(Object.keys(vm.currentQuestion.questionTextTranslateMap).length === 0){
                SnackbarUtils
                    .displaySnackbar("error", "Please add Question Text Translation for at least one language");
                return false;
            }

            for(var key in vm.currentQuestion.questionTextTranslateMap){
                if(vm.currentQuestion.questionTextTranslateMap.hasOwnProperty(key)){
                    if(vm.currentQuestion.questionTextTranslateMap[key] === ''){
                        if(vm.areCustomChoicesUsed && vm.areChoicesEmpty(key)) continue;
                        var correspondingLanguage = vm.languagesList.find(lang => lang.lang_id.toString() === key);
                        SnackbarUtils
                            .displaySnackbar("error", "Please add Question Text Translation for language: " + correspondingLanguage.lang_ui_name);
                        return false;
                    }
                }
            }
            return true;
        };

        vm.refreshAfterDelete = function(questionId) {
            var questionToDelete = vm.questionsList.find(element => element.question_id === questionId);
            var questionTopicName = questionToDelete.topic_name;
            vm.countsByTopic[vm.topicsMap["TOTAL"]] --;
            vm.countsByTopic[vm.topicsMap[questionTopicName]] --;
            vm.tableParams.reload();
        };

        vm.viewImageOfQuestion = function (questionId) {
            WidgetsService.getQuestionImage(
                questionId,
                function success(response) {
                    var file = new Blob([ response.data ], {
                        type : 'image/jpeg'
                    });
                    var fileURL = URL.createObjectURL(file);
                    $mdDialog.show({
                        template:
                            '<md-dialog>' +
                            '   <md-dialog-content layout="row" layout-align="center center">' +
                            '       <img ng-src="{{questionImg}}"/>' +
                            '   </md-dialog-content>' +
                            '   <md-dialog-actions>' +
                            '       <md-button ng-click="cancel()" class="md-primary">' +
                            '           Close' +
                            '       </md-button>' +
                            '   </md-dialog-actions>' +
                            '</md-dialog>',
                        locals: {
                            questionImg: fileURL
                        },
                        controller: function ($scope, $mdDialog, questionImg) {
                            $scope.questionImg = questionImg;
                            $scope.cancel = function () {
                                $mdDialog.cancel();
                            };
                        }
                    }).then(
                        function () {
                        },
                        function () {
                        });

                },
                function error(response) {
                    alert("Failed to retrieve question Image!");
                }
            );
        };

        vm.deleteImgFromQuestion = function (questionId) {

            var confirm = $mdDialog.confirm()
                .title("Question edit")
                .textContent("Are you sure that you want to remove the image of this question ?")
                .cancel("No")
                .ok("Yes");

            $mdDialog.show(confirm).then(function() {
                WidgetsService.DeleteQuestionPicture(
                    questionId,
                    function success(response) {
                        $mdDialog.show(
                            $mdDialog.alert()
                                .title('Image deletion')
                                .textContent('The image was deleted')
                                .ok('Close')
                        );
                        vm.tableParams.reload();
                    },
                    function error(response) {
                        $mdDialog.show(
                            $mdDialog.alert()
                                .title('Question Alert')
                                .textContent('Failed to remove image of question!')
                                .ok('Close')
                        );
                    }
                );
            }, function() {
                //
            });


        };

        vm.removeQuestion = function (questionId) {
            if(!vm.isQuestionDeletionProcessStarted) {
                vm.isQuestionDeletionProcessStarted = true;

                var confirm = $mdDialog.confirm({
                    template:
                        '<md-dialog md-theme="default">' +
                        '   <md-dialog-content class="md-dialog-content" style="margin-left:20px;margin-right: 20px">' +
                        '   <div style="display:flex; justify-content: center"><img src="images/warning.PNG" alt=""/></div>'+
                        '       <h2 class="md-title">' +
                        '         ' +
                        '       </h2>' +
                        '       <div class="md-dialog-content-body" style="text-align: center;margin-top:3%">' +
                        '           <p>' +
                        '               Are you sure you want to delete this question and its answers?' +
                        '           </p>' +
                        '       </div>' +
                        '   </md-dialog-conent>' +
                        '   <md-dialog-actions style="justify-content: center;margin-top:6%;margin-bottom:5%" ng-disabled="vm.isQuestionDeletionProcessStarted === true">' +
                        '       <md-button ng-click="deleteQuestion()" class="md-raised md-warn md-hue-2">' +
                        '           Delete question' +
                        '       </md-button>' +
                        '       <md-button ng-click="goBack()" class="md-background md-hue-1">' +
                        '           Go back' +
                        '       </md-button>' +
                        '   </md-dialog-actions>' +
                        '</md-dialog>',
                    controller: function QuestionDismissDialogController($scope, $mdDialog) {
                        $scope.deleteQuestion = function () {
                            $mdDialog.hide();
                            WidgetsService.DeleteQuestion(
                                questionId,
                                function success(response) {
                                    vm.isQuestionDeletionProcessStarted = false;
                                    vm.refreshAfterDelete(questionId);
                                },
                                function error(response) {
                                    vm.isQuestionDeletionProcessStarted = false;
                                    $mdDialog.show(
                                        $mdDialog.alert()
                                            .title('Question Alert')
                                            .textContent('Failed to remove question!')
                                            .ok('Close')
                                    );
                                }
                            );
                        },
                            $scope.goBack = function () {
                                $mdDialog.hide();
                            }
                    }
                });
                $mdDialog.show(confirm).then(function () {
                    vm.isQuestionDeletionProcessStarted = false;
                });
            }
        };

        vm.editQuestion = function (question) {
            vm.currentDirectiveLanguage = vm.languagesList.find(elem => elem.lang_ui_name === question.lang_name[0]);
            delete vm.currentQuestion;
            WidgetsService.GetQuestionById(question.question_id,
                function(success){
                    $timeout( function(){
                        vm.currentQuestion = angular.copy(success.data);
                        vm.currentQuestion.questionChoiceTypePayload.choiceTypeName.startsWith('custom') ? vm.areCustomChoicesUsed = true : vm.areCustomChoicesUsed = false;

                        // Initial start and end dates
                        vm.currentQuestionStartDate = success.data.questionStartDate;
                        vm.currentQuestionEndDate = success.data.questionEndDate;

                        vm.currentQuestion.questionChoiceTypePayload = success.data.questionChoiceTypePayload;

                        vm.scrollToElement("targetScrollOnEditId");
                        $scope.$apply();
                    }, 250);

                },function(error){
                console.log(error);
                });

        };

        vm.cancelCurrentQuestion = function() {
            if (!vm.isQuestionDeletionProcessStarted) {
                vm.isQuestionDeletionProcessStarted = true;
                var confirm = $mdDialog.confirm({
                    template:
                        '<md-dialog md-theme="default">' +
                        '   <md-dialog-content class="md-dialog-content" style="margin-left:20px;margin-right: 20px">' +
                        '<div style="display:flex; justify-content: center"><img src="images/warning.PNG" alt=""/></div>'+
                        '       <h2 class="md-title">' +
                        '         ' +
                        '       </h2>' +
                        '       <div class="md-dialog-content-body" style="text-align: center;margin-top:3%">' +
                        '           <p>' +
                        '               All your unsaved changes will be lost. Are you sure you want to cancel your updates?' +
                        '           </p>' +
                        '       </div>' +
                        '   </md-dialog-conent>' +
                        '   <md-dialog-actions style="justify-content: center;margin-top:6%;margin-bottom:5%" ng-disabled="vm.isQuestionDeletionProcessStarted === true">' +
                        '       <md-button ng-click="deleteCurrentQuestion()" class="md-raised md-warn md-hue-2">' +
                        '           Yes' +
                        '       </md-button>' +
                        '       <md-button ng-click="goBack()" class="md-background md-hue-1">' +
                        '           Go back' +
                        '       </md-button>' +
                        '   </md-dialog-actions>' +
                        '</md-dialog>',
                    controller: function QuestionDismissDialogController($scope, $mdDialog) {
                        $scope.deleteCurrentQuestion = function () {
                            vm.isQuestionDeletionProcessStarted = false;
                            $mdDialog.hide();
                            delete vm.currentQuestion;
                        },
                        $scope.goBack = function () {
                            vm.isQuestionDeletionProcessStarted = true;
                            $mdDialog.hide();
                        }
                    }
                });
                $mdDialog.show(confirm).then(function() {
                    vm.isQuestionDeletionProcessStarted = false;
                });
            }
        };

        vm.getChoicesForChoiceTypeId = function (choiceTypeId) {
            WidgetsService.getChoiceTypeById(
                choiceTypeId,
                function (response) {
                    var choices = response.data.choices;
                    var choiceTranslationMap = {};

                    choices.forEach(function (choice) {
                        if(angular.equals(choice.choiceTranslateMap, {})) return;
                        for(var key in choice.choiceTranslateMap){
                            if(typeof key !== 'undefined' && choice.choiceTranslateMap.hasOwnProperty(key)) {
                                if (typeof choiceTranslationMap[key] === 'undefined') {
                                    choiceTranslationMap[key] = [];
                                    choiceTranslationMap[key].push(choice.choiceTranslateMap[key]);
                                } else {
                                    choiceTranslationMap[key].push(choice.choiceTranslateMap[key]);
                                }
                            }
                        }
                    });

                    if(Object.keys(choiceTranslationMap).length > 0) {
                        vm.choiceTypeIdToChoicesMap.set(choiceTypeId, choiceTranslationMap);
                    }else{
                        vm.choiceTypesList = vm.choiceTypesList.filter(elem => elem.choice_type_id !== choiceTypeId);
                    }
                }
            );
        };

        vm.getChoiceTypes = function () {
            WidgetsService.GetAllChoiceTypes(vm.selectedLanguageId,
                function (success) {
                    vm.choiceTypesList = success.data;
                    vm.choiceTypesListCopy = angular.copy(vm.choiceTypesList);
                    vm.choiceTypesList.forEach(function (choice) {
                            var choiceTypeId = choice.choice_type_id;
                            vm.getChoicesForChoiceTypeId(choiceTypeId);
                        }
                    );
                },
                function (error) {
                    console.log(error);
                }
            );
        };

        // Validation functions for datepickers
        vm.getMaxStartDate = function() {
            return vm.currentQuestion.questionEndDate ? vm.currentQuestion.questionEndDate : null;
        }

        vm.getMinEndDate = function() {
            return vm.currentQuestion.questionStartDate ? moment(vm.currentQuestion.questionStartDate).add(1, "days").format("YYYY-MM-DD")  : null;
        }

        vm.toggleStartDateChanged = function() {
            vm.isStartDateChanged = true;
        }

        vm.toggleEndDateChanged = function() {
            vm.isEndDateChanged = true;
        }

        vm.buildUserProposedByLink = function (question) {
            return (question.question_proposed_by === vm.addedByAdmin ? '' : '/users/user/' + question.question_proposed_by);
        };

        vm.sendQuestionAcceptStatusEmail = function (questionId, status) {
            EmailsService.sendQuestionStatusEmail(questionId, status,
                function success(response) {
                },
                function error(response) {
                }
            );
        };

        vm.updateQuestionAcceptStatus = function (questionId, accept_status) {
            var actions = (accept_status == vm.acceptStatus) ? ["approve", "approved", "approval"]: ["reject", "rejected", "rejection"];

            var confirm = $mdDialog.confirm()
                .title('Question ' + actions[2])
                .textContent('Are you sure that you want to ' + actions[0] + ' this question?')
                .cancel('No')
                .ok('Yes');

            $mdDialog.show(confirm).then(function() {
                WidgetsService.UpdateQuestionAcceptStatus(
                    questionId,
                    accept_status,
                    function success(response) {
                        $mdDialog.show(
                            $mdDialog.alert()
                                .title('Question Alert')
                                .textContent('Successfully ' + actions[1] + ' question!')
                                .ok('Close')
                        );
                        vm.tableParams.reload();
                        vm.sendQuestionAcceptStatusEmail(questionId, accept_status);
                    },
                    function error(response) {
                        $mdDialog.show(
                            $mdDialog.alert()
                                .title('Question Alert')
                                .textContent('Failed to ' + actions[0] + ' question!')
                                .ok('Close')
                        );
                    }
                );
            }, function() {
                //
            });
        };


        vm.getQuestionsAsExcel = function() {
            WidgetsService.GetQuestionsAsExcel($window.location.hostname,
                function success(response) {
                    var excelFile = new Blob([response.data], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    });

                    var excelFileName = "questions_" + $window.location.hostname + ".xlsx";

                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(excelFile, excelFileName);
                    }
                    else {
                        var downloadLink = document.createElement("a");
                        document.body.appendChild(downloadLink);
                        downloadLink.style = "display: none";
                        var fileURL = URL.createObjectURL(excelFile);
                        downloadLink.href = fileURL;
                        downloadLink.download = excelFileName;
                        downloadLink.click();
                        window.URL.revokeObjectURL(fileURL);
                        downloadLink.remove();
                    }
                },
                function error(response) {
                    $mdDialog.show(
                        $mdDialog.alert()
                            .title('Excel Alert')
                            .textContent('Failed to download the questions Excel!')
                            .ok('Close')
                    );
                }
            );
        };

        vm.getLanguages = function() {
            LanguagesService.GetPanelLanguages(
                function (success) {
                    vm.getTopics();
                    if(success.data.length === 1){
                        vm.isLanguageSelected = true;
                        vm.isLeftLanguageValid = true;
                        vm.languageObject = success.data[0];
                    }
                    vm.languagesList = success.data;
                },
                function (error) {
                    console.log(error)
                }
            );
        };

        vm.onLeftLanguageChange = function(language){
            if(typeof language !== 'undefined' && (typeof language.langId !== 'undefined')){
                vm.selectedLanguageId = language.langId;
                vm.isLanguageSelected = true;
                vm.languageObject = vm.languagesList.find(elem => elem.lang_id === language.langId);
                vm.choiceTypesListCopy = vm.choiceTypesList.filter(function (choiceType) {
                    if (choiceType.available_languages_ids) {
                        return (typeof choiceType.available_languages_ids.find(langId => langId === language.langId) !== "undefined");
                    }
                    return false;
                });
                vm.topicsCopy = vm.topics.filter(function (topic) {
                    return topic.topicTranslations.hasOwnProperty(vm.languageObject.lang_ui_name);
                });

                if(typeof vm.currentQuestion.questionChoiceTypePayload.choiceTypeId !== 'undefined' &&
                    vm.currentQuestion.questionChoiceTypePayload.choiceTypeId !== null &&
                    !vm.areCustomChoicesUsed){
                    if(!WidgetValidationService.isChoiceTypeTranslatedInLang(vm.currentQuestion.questionChoiceTypePayload.choiceTypeId, vm.choiceTypesList, language.langId)) {
                        delete vm.currentQuestion.questionChoiceTypePayload.choiceTypeId;
                    }
                }

                let choiceTypeTranslationsAvailable = vm.choiceTypesListCopy.length !== 0;
                let topicTranslationsAvailable = vm.topicsCopy.length !== 0;

                vm.isLeftLanguageValid = (vm.areCustomChoicesUsed && topicTranslationsAvailable) ||
                    (!vm.areCustomChoicesUsed && choiceTypeTranslationsAvailable && topicTranslationsAvailable);

                let choiceTypesAndTopicsTranslationsAvailable = choiceTypeTranslationsAvailable && topicTranslationsAvailable;
                if (!choiceTypesAndTopicsTranslationsAvailable) {
                    let oneTranslationAvailable = choiceTypeTranslationsAvailable || topicTranslationsAvailable;
                    let topicTranslationsUnavailableMessage = "No question topics were translated into " + vm.languageObject.lang_ui_name + " language.";
                    let choiceTypesTranslationsUnavailableMessage = "No question choices were translated into " + vm.languageObject.lang_ui_name + " language. You can either translate predefined choices or use custom choices.";

                    if (oneTranslationAvailable) {
                        if (!choiceTypeTranslationsAvailable)
                            SnackbarUtils.displaySnackbarWithLink("error", choiceTypesTranslationsUnavailableMessage, "widgetschoices", "TRANSLATE CHOICES");
                        else
                            SnackbarUtils.displaySnackbarWithLink("error", topicTranslationsUnavailableMessage, "widgetstopics", "TRANSLATE TOPICS");
                    }
                    else {
                        SnackbarUtils.displaySnackbarWithLink("error", choiceTypesTranslationsUnavailableMessage, "widgetschoices", "TRANSLATE CHOICES");
                        SnackbarUtils.displaySnackbarWithLink("error", topicTranslationsUnavailableMessage, "widgetstopics", "TRANSLATE TOPICS");
                    }
                }
            }
        };

        vm.onRightLanguageChange = function (language) {
            var isRightLanguageValid = true;

            if(vm.currentQuestion && vm.currentQuestion.questionChoiceTypePayload &&
                vm.currentQuestion.questionChoiceTypePayload.choiceTypeId && !vm.areCustomChoicesUsed) {

                var predefinedSelectedChoiceType = vm.choiceTypesList.find(function (elem) {
                    return elem.choice_type_id === vm.currentQuestion.questionChoiceTypePayload.choiceTypeId;
                });

                isRightLanguageValid = predefinedSelectedChoiceType.available_languages_ids.includes(language.langId);
            }

            if(!isRightLanguageValid) {
                vm.languageObject = vm.languagesList.find(elem => elem.lang_id === language.langId);
                var message = "Please translate the predefined choices into the " + vm.languageObject.lang_ui_name + " language before translating the question text.";
                SnackbarUtils.displaySnackbarWithLink("error", message, "widgetschoices", "TRANSLATE CHOICES");
            }

            return isRightLanguageValid;
        };

        vm.toggleRightLanguageCheck = function () {
            // Incrementing this variable will make the widget.choices.directive to check if left language is valid.
            // and execute the needed logic.
            vm.triggerVarRightLanguageCheck++;
        };

        vm.onCustomChoicesToggle = function() {
            // Check if language is translated
            vm.isLeftLanguageValid = (vm.areCustomChoicesUsed && (vm.topicsCopy.length !== 0)) ||
                (!vm.areCustomChoicesUsed && (vm.choiceTypesListCopy.length !== 0) && (vm.topicsCopy.length !== 0));
            
            delete vm.currentQuestion.questionChoiceTypePayload.choiceTypeName;

            if(vm.areCustomChoicesUsed) {
                vm.currentQuestion.questionChoiceTypePayload.choiceTypeDefault = false;
                vm.currentQuestion.questionChoiceTypePayload.choiceTypeId = null;
                vm.currentQuestion.questionChoiceTypePayload.choiceTypeTranslateMap = {};
                vm.currentQuestion.questionChoiceTypePayload.choices = angular.copy(defaultQuestion.questionChoiceTypePayload.choices);
            }
            else {
                vm.currentQuestion.questionChoiceTypePayload.choiceTypeDefault = true;
                delete vm.currentQuestion.questionChoiceTypePayload.choiceTypeTranslateMap;
                delete vm.currentQuestion.questionChoiceTypePayload.choices;
            }
        };

        (function initController() {
            vm.activeSubmenu = 'panel';
            vm.breadcrumbs = 'Panel: Wall Cards';

            vm.isLanguageSelected = false;
            vm.selectedLanguageId = undefined;
            vm.areCustomChoicesUsed = false;
            vm.isLeftLanguageValid = false;
            vm.triggerVarRightLanguageCheck = 0;

            vm.tableLanguageId = undefined;

            vm.currentDate = moment().format("YYYY-MM-DD");
            vm.isStartDateChanged = false;
            vm.isEndDateChanged = false;

            vm.topics = [];
            vm.topicsCopy = [];
            vm.selectedTopic = undefined;

            vm.choiceTypeIdToChoicesMap = new Map();

            vm.choiceTypesList = [];
            vm.choiceTypesListCopy = [];
            vm.getChoiceTypes();

            vm.questionTypesList = ["vertical_buttons", "single_classic", "multiple_classic"];

            vm.initialParams = {
                page: 1,
                count: 20
            };

            vm.excelUploadCheck = {};

            vm.acceptStatus = 1;
            vm.rejectStatus = 2;
            vm.addedByAdmin = "Added by admin";

            vm.questionsList = [];
            vm.reloadQuestions();

            vm.languagesList = [];
            vm.getLanguages();

            vm.uploadImgToQuestion = WidgetsService.SaveQuestionPicture;
            vm.importExcel = WidgetsService.ImportExcel;
        })();
    }
})();
