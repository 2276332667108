(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('RecurrentHistoryService', RecurrentHistoryService);

	RecurrentHistoryService.$inject = ['$http'];
	function RecurrentHistoryService($http) {
		return {
			GetStats: function (withUndecided, cumulated, okCallback, koCallback) {
				$http.get("/rest/vote/history" + "?withUndecided=" + withUndecided + "&cumulated=" + cumulated)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			},

			GetVotesByUser: function (okCallback, koCallback) {
				$http.get("/rest/vote/usershistory")
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			},

			GetPredefinedQuestionHistoryData: function (questionId, okCallback, koCallback) {
				$http.get("/rest/quests/predefined/question/history/" + questionId)
					.then(function successCallback(response) {
						okCallback && okCallback(response);
					}, function errorCallback(response) {
						koCallback && koCallback(response);
					});
			},
		}
	}
})();
