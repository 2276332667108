(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.controller('HomeController', HomeController);

	HomeController.$inject = ['$window', '$state', 'UsersService'];
	function HomeController($window, $state, UsersService) {

		var vm = this;

		(function initController() {
			vm.breadcrumbs = "Simple Dashboard";
			vm.hostname = $window.location.hostname;
			UsersService.GetStatistics(function (response) {
				vm.statistics = response.data;
				vm.dataLoaded = true;
			});
		})();

		vm.goto = function (state) {
			$state.go(state);
		};

	}
})();
