(function () {
    'use strict';
    angular
        .module('questiaPlatformApp').directive('widgetChoicesDirective', function () {
        return {
            restrict: 'E',
            scope: {
                question: '=',
                choiceType: '=',
                languages: '=',
                selectedLanguageIdLeft: '=',
                areChoicesRequired: '=',
                isLeftLanguageValid: '=',
                triggerVarRightLanguageCheck: '=',
                onLeftLanguageChange: '=',
                onRightLanguageChange: '='
            },
            templateUrl: 'js/ng-templates/subviews/directives/widget.choices.directive.subview.html',
            controllerAs: 'vm',
            controller: ['$scope','$mdDialog','WidgetsService', 'WidgetValidationService', function ($scope, $mdDialog, WidgetsService, WidgetValidationService) {
                var vm = this;
                vm.showTranslateSide = false;
                vm.leftLanguageIsSelected = false;
                vm.inputsDisableOpacityValue = 0.6;
                vm.languagesLeft = [];
                vm.languagesRight = [];
                vm.languages = $scope.languages;
                if(typeof $scope.selectedLanguageIdLeft !== "undefined") {
                    vm.selectedLanguageIdLeft = $scope.selectedLanguageIdLeft.lang_id + "";
                }
                if (typeof $scope.question !== "undefined") {
                    vm.question = $scope.question;
                    vm.choiceType = $scope.question.questionChoiceTypePayload;
                } else {
                    vm.choiceType = $scope.choiceType
                }

                if (vm.languages.length === 1) {
                    vm.selectedLanguageIdLeft = vm.languages[0].lang_id;
                    vm.leftLanguageIsSelected = true;
                    vm.singlePanelLanguage = true;
                } else if (typeof $scope.selectedLanguageId !== "undefined" && $scope.selectedLanguageId !== "none") {
                    vm.selectedLanguageIdLeft = $scope.selectedLanguageId;
                    vm.leftLanguageIsSelected = true;
                } else if(vm.choiceType.choiceTypeId) {
                    var choiceTypeLanguagesIds = Object.keys(vm.choiceType.choiceTypeTranslateMap);
                    if(typeof vm.selectedLanguageIdLeft === 'undefined'){
                        vm.selectedLanguageIdLeft = choiceTypeLanguagesIds[0];
                    }
                    vm.leftLanguageIsSelected = true;
                }

                if(vm.question && (Object.keys(vm.question.questionTextTranslateMap).length > 0)) {
                    for(var i in vm.languages) {
                        var langId = vm.languages[i].lang_id;
                        if(vm.question.questionTextTranslateMap[langId]){
                            vm.languagesLeft.push(vm.languages[i]);
                        } else {
                            vm.languagesRight.push(vm.languages[i]);
                        }
                    }
                }
                else if(!vm.question && vm.choiceType.choiceTypeId) {
                    for(var i in vm.languages) {
                        var langId = vm.languages[i].lang_id;
                        if(vm.choiceType.choiceTypeTranslateMap[langId]){
                            vm.languagesLeft.push(vm.languages[i]);
                        } else {
                            vm.languagesRight.push(vm.languages[i]);
                        }
                    }
                } else {
                    vm.languagesLeft = vm.languages;
                    vm.languagesRight = vm.languages;
                }

                vm.viewImageOfChoice = function (choiceId) {
                    var choice = vm.choiceType.choices.find(elem => elem.choiceId === choiceId);

                    $mdDialog.show({
                        template:
                            '<md-dialog>' +
                            '   <md-dialog-content layout="row" layout-align="center center">' +
                            '       <img ng-src="{{choiceImg}}"/>' +
                            '   </md-dialog-content>' +
                            '   <md-dialog-actions>' +
                            '       <md-button ng-click="cancel()" class="md-primary">' +
                            '           Close' +
                            '       </md-button>' +
                            '   </md-dialog-actions>' +
                            '</md-dialog>',
                        locals: {
                            choiceImg: 'data:image/png;base64,' + choice.choiceMedia
                        },
                        controller: function ($scope, $mdDialog, choiceImg) {
                            $scope.choiceImg = choiceImg;
                            $scope.cancel = function () {
                                $mdDialog.cancel();
                            };
                        }
                    }).then(
                        function () {
                        },
                        function () {
                        });
                };

                const blobToBase64 = blob => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    return new Promise(resolve => {
                        reader.onloadend = () => {
                            resolve(reader.result);
                        };
                    });
                };

                vm.uploadImgToChoice = function(id,data){
                    const choice = vm.choiceType.choices.find(function(element){
                        return element.choiceId === id;
                    });
                    choice.choiceMedia = data;

                    blobToBase64(choice.choiceMedia).then(res => {
                        choice.choiceMedia = res.split(',')[1];
                    });

                    WidgetsService.SaveChoicePicture(id,data,function(){
                            $mdDialog.show(
                                $mdDialog.alert()
                                    .title('Success')
                                    .textContent('The image of the choice was successfully updated')
                                    .ok('Close'));
                        },
                        function(){
                            $mdDialog.show(
                                $mdDialog.alert()
                                    .title('Success')
                                    .textContent('There was an error while trying to save the image into the database. Please contact the support team')
                                    .ok('Close'));
                        })
                };

                vm.choicesSplice = function (index) {
                    var newChoice = {
                        choiceId: undefined,
                        choiceMedia: undefined,
                        choiceValue: 1,
                        choiceTranslateMap: {}
                    };
                    vm.choiceType.choices.splice(index, 0, newChoice);

                    for (var i = index; i < vm.choiceType.choices.length; i++) {
                        vm.choiceType.choices[i].choiceValue = (i + 1);
                    }
                };

                vm.onLanguageChangeCallback = function(languageId){
                    $scope.onLeftLanguageChange({language : languageId});
                };

                vm.removeChoiceFromIndex = function (index) {
                    vm.choiceType.choices.splice(index, 1);
                };

                vm.toggleTranslateSide = function () {
                    vm.showTranslateSide = !vm.showTranslateSide;
                    if (!vm.showTranslateSide) {
                        vm.clearPartialTranslatedLanguages();

                        delete $scope.selectedLanguageIdRight;
                        vm.rightLanguageIsSelected = false;
                    }
                };

                vm.clearPartialTranslatedLanguages = function () {
                    var isScaleDefault = typeof vm.question === "undefined";
                    var translationCountMap = WidgetValidationService.getNoOfFieldsTranslatedByLangIdForChoiceType(vm.question, vm.choiceType, vm.languagesRight);
                    var maxTranslationCount = vm.choiceType.choices.length + 1;

                    for (var i in vm.languagesRight) {
                        var langId = vm.languagesRight[i].lang_id;
                        if (langId !== vm.selectedLanguageIdLeft &&
                            translationCountMap[langId] < maxTranslationCount) {

                            if (isScaleDefault) {
                                if (vm.choiceType.choiceTypeTranslateMap[langId]) {
                                    delete vm.choiceType.choiceTypeTranslateMap[langId]
                                }
                            } else {
                                if (vm.question.questionTextTranslateMap[langId]) {
                                    delete vm.question.questionTextTranslateMap[langId]
                                }
                            }

                            for (var m in vm.choiceType.choices) {
                                if (vm.choiceType.choices[m].choiceTranslateMap[langId]) {
                                    delete vm.choiceType.choices[m].choiceTranslateMap[langId];
                                }
                            }
                        }
                    }
                };

                $scope.onRightLanguageChangeInternal = function (langId) {
                    if(typeof langId !== "undefined" && typeof $scope.onRightLanguageChange !== "undefined") {
                        var isLangValid = $scope.onRightLanguageChange({langId: langId});
                        if(!isLangValid) {
                            delete $scope.selectedLanguageIdRight;
                            vm.leftLanguageIsSelected = false;
                        }
                    }
                };
            }],
            link: function(scope, element, attrs) {
                scope.$watch('areChoicesRequired', function(newValue, oldValue) {
                }, true);
                scope.$watch(scope.isLeftLanguageValid, function(newValue, oldValue) {
                }, true);
                scope.$watch("triggerVarRightLanguageCheck", function(newValue, oldValue) {
                    // Whenever an external action increments this watched variable, a check is triggered
                    // by calling the next function (scope.onRightLanguageChangeInternal).
                    scope.onRightLanguageChangeInternal(scope.selectedLanguageIdRight);
                }, true);
            }
        };
    })
})();
