(function () {
	'use strict';

	const WITHOUT_UNDECIDED_PREFIX = "_WITHOUT_UNDECIDED";

	angular
		.module('questiaPlatformApp')
		.controller('ChartsController', ChartsController);

	ChartsController.$inject = ['ChartsService', 'SurveysService', 'ChartsUtils', 'RegionsService', 'LanguagesService', 'QuestiaUtils', 'ConfigurationsService', 'DistributionsService', 'SurveyDistributionsUtils', '$window', '$rootScope', '$state', '$queue', '$stateParams', '$element', '$mdDialog', '$timeout'];
	function ChartsController(ChartsService, SurveysService, ChartsUtils, RegionsService, LanguagesService, QuestiaUtils, ConfigurationsService, DistributionsService, SurveyDistributionsUtils, $window, $rootScope, $state, $queue, $stateParams, $element, $mdDialog, $timeout) {

		var vm = this;

		vm.setUpColorPalettes = function(){
			vm.selectedPalette = {};
			vm.colorPalettes = vm.colorTemplates.templatePalettes;
			vm.parsePalettesForUI(vm.colorPalettes);
		};

		vm.parsePalettesForUI = function(data){
			vm.paletteUi = [];
			for(var elem of data){
				vm.paletteUi[elem.paletteName] = {
					primaryColors : elem.primaryColors.concat(elem.secondaryColors),
					secondaryColors : elem.secondaryColors,
					fonts : elem.fonts
				};
			}
		};


		vm.setUpTemplate = function(element){
			var selectedTemplate = JSON.parse(element);
			vm.localReportName = selectedTemplate.reportName;
			vm.colorPalettes = ChartsUtils.findColorPalettesByTemplateName(selectedTemplate.templateName,vm.colorTemplates);
			vm.isDefaultTemplate = false;
			vm.parsePalettesForUI(vm.colorPalettes);
		};

		vm.handleReportTemplateChange = function (element,oldElement){

			if(!vm.isDefaultTemplate) {
				var confirm = $mdDialog.confirm()
					.title('Are you sure you want to change the report template?')
					.textContent('Changing the report template will automatically refresh the charts and all the configurations you have done so far will be lost.')
					.ok('Yes')
					.cancel('No');

				$mdDialog.show(confirm).then(function () {
					vm.setUpTemplate(element);
					vm.refreshCharts();
				}, function () {
					vm.targetTemplate = oldElement;
				});
			}else{
				vm.setUpTemplate(element);
			}
		};

		(function initController() {

			initVars();

			RegionsService.getDivisionsList().then(function (response) {
				vm.divisions = response.data;
			});

			RegionsService.getDivisionsToRegionsMap().then(function (response) {
				vm.divisionsData = response.data;
			});

			LanguagesService.GetAllLanguages(
				function (success) {
					vm.languages = success.data.languagesList;
					vm.defaultLanguageId = success.data.defaultLanguageId;
					if (!vm.languages || vm.languages.length === 0) {
						vm.languages = [{lang_ui_name: "Default", lang_google_translate_name: null}];
					}
					else {
						let defaultLanguageIndex = vm.languages.findIndex(lang => lang.lang_id === vm.defaultLanguageId);
						vm.languages[defaultLanguageIndex].lang_ui_name += " (Default)";
						vm.languages[defaultLanguageIndex].lang_google_translate_name = null;
					}
				},
				function (error) {
					vm.languages = [{lang_ui_name: "Default", lang_google_translate_name: null}];
				}
			);
			getQuestions(null);


		})();

        var originatorEv;
		function initVars(){
            vm.breadcrumbs = "Surveys: Charts";
            vm.activeSubmenu = 'surveys';
            vm.surveyId = $stateParams.qid || 1;
            vm.surveyTitle = null;
            vm.surveyData = null;

            vm.completed = false;

			vm.defaultFilterElem = {
				inputQId: "",
				choices: [],
				selectedChoice: undefined,
				selectedChoices: [],
				qType: "",
				ageGroups: "",
				cityCountyFilterType: "",
				selectedDivisionId: ""
			};

            vm.distributionsData = undefined;
            vm.savedDistributionsData = undefined;
            vm.showDistributionsSection = false;
            vm.distributionsDataValidation = {
            	age: "",
				sex: "",
				location: ""
			};
            vm.distributionsSavedInDB = false;
			vm.lastMaxAgeInputIsValid = true;
			vm.weightsCalculated = false;

			vm.globalQFilters = [
				JSON.parse(JSON.stringify(vm.defaultFilterElem))
			];

            vm.date = null;
            vm.greyBackground = false;
            vm.ASL_QUESTION_ID = "2009";
            vm.voteQid = $rootScope.globals.voteQid;
            vm.isLocaleForThai = $window.location.hostname === "admin2.questia.co.th";
            vm.selectedLanguage = null;
            vm.withWeighting = null;
			vm.isDefaultTemplate = true;
			ChartsService.GetColorTemplates(function(success){
				vm.colorTemplates = success.data;
				vm.setUpColorPalettes();
			},function(error){
				console.log(error);
			});

			vm.loadingDistributions = true;
			DistributionsService.getSurveyDistributions(vm.surveyId,
				function (success) {
					vm.distributionsData = success.data;
					vm.savedDistributionsData = angular.copy(vm.distributionsData);
					vm.distributionsSavedInDB = vm.areDistributionsSavedInDB();
					vm.showDistributionsSection = !!vm.distributionsSavedInDB;
					vm.loadingDistributions = false;
				}, function (error) {
					console.log(error);
				}
			);

			RegionsService.getDivisionsList().then(function(response) {
				vm.availableDivisions = response.data;
			});
        }

        vm.areDistributionsSavedInDB = function() {
			return vm.distributionsData.ageDistributions.distTypeActive
				|| vm.distributionsData.sexDistributions.distTypeActive
				|| vm.distributionsData.locationDistributions.distTypeActive;
		};

        vm.toggleWeightSection = function() {
			vm.showDistributionsSection = !vm.showDistributionsSection;
		};

		vm.getDistRegionsForDivisionId = function (divisionId) {
			DistributionsService.getDistributionListForDivision(divisionId,
				function (success) {
					vm.distributionsData.locationDistributions.distributionsList = success.data.distributionsList;
				}, function (error) {
					console.log(error);
				}
			);
		};

		vm.toggleAgeDistributions = function() {
			if(vm.distributionsData.ageDistributions.distTypeActive) {
				vm.distributionsData.ageDistributions.distributionsList = [{
					minAge: 18,
					maxAge: undefined,
					percentage: undefined
				}];
			} else {
				vm.distributionsData.ageDistributions.distributionsList = [];
			}
		};

		vm.addNextAgeDistributionInterval = function(index){
			vm.lastMaxAgeInputIsValid = true;
			if(!vm.distributionsData.ageDistributions.distributionsList[index].maxAge) vm.lastMaxAgeInputIsValid = false;
			if(vm.distributionsData.ageDistributions.distributionsList[index].maxAge <= vm.distributionsData.ageDistributions.distributionsList[index].minAge) vm.lastMaxAgeInputIsValid = false;

			if(!vm.lastMaxAgeInputIsValid) return;

			var nextMinAge = vm.distributionsData.ageDistributions.distributionsList[index].maxAge + 1;

			vm.distributionsData.ageDistributions.distributionsList.push({
				minAge: nextMinAge,
				maxAge: undefined,
				percentage: undefined
			});
		};

		vm.editCurrentAgeDistributionInterval = function(index) {
			vm.distributionsData.ageDistributions.distributionsList.length = index + 1;
		};

		vm.calculateWeights = function() {
			vm.calculatingWeights = true;
			DistributionsService.calculateWeightsForQuestId(vm.surveyId,
				function (success) {
					if(success.data.hasError) {
						$mdDialog.show(
							$mdDialog.alert()
								.title('Survey Error Alert')
								.textContent(success.data.weightingMessage)
								.ok('Close')
						);
					} else {
						if(success.data.hasWarning) {
							$mdDialog.show(
								$mdDialog.alert()
									.title('Survey Warning Alert')
									.textContent(success.data.weightingMessage)
									.ok('Close')
							);
						}
						vm.weigthsCalculusResults = success.data;
						vm.weigthsCalculusResults.mode = vm.weigthsCalculusResults.mode.map((elem) => elem.toFixed(3)).toString().replaceAll(",",", ");
					}
					vm.weightsCalculated = true;
					vm.calculatingWeights = false;
					if (vm.withWeighting)
						vm.refreshCharts();
				}, function (error) {
					vm.calculatingWeights = false;
					$mdDialog.show(
						$mdDialog.alert()
							.title('Survey Alert')
							.textContent('Error, cannot calculate weights!')
							.ok('Close')
					);
				}
			);
		};

		vm.useQuotasPercentsForDistributions = function () {
			vm.loadingDistributionsFromQuotas = true;
			DistributionsService.getSurveyDistributionsFromQuotas(vm.surveyId,
				function (success) {
					var dummyDistributionsFromQuotas = success.data;
					if(!dummyDistributionsFromQuotas.ageDistributions.distTypeActive
						&& !dummyDistributionsFromQuotas.sexDistributions.distTypeActive
						&& !dummyDistributionsFromQuotas.locationDistributions.distTypeActive) {
						$mdDialog.show(
							$mdDialog.alert()
								.title('Survey Alert')
								.textContent('There are no quotas to be used!')
								.ok('Close')
						);
					} else {
						let distributionsDataEqual = SurveyDistributionsUtils.areDistributionsIdentical(success.data, vm.savedDistributionsData);
						vm.distributionsData = success.data;
						vm.savedDistributionsData = angular.copy(vm.distributionsData);
						vm.distributionsSavedInDB = false;

						if (!distributionsDataEqual) {
							$mdDialog.show(
								$mdDialog.alert()
									.title('Distributions Alert')
									.textContent('Distributions have been changed, please recalculate the weighting factors !')
									.ok('Close')
							);
						}
					}
					vm.loadingDistributionsFromQuotas = false;
				}, function (error) {
					vm.loadingDistributionsFromQuotas = false;
					$mdDialog.show(
						$mdDialog.alert()
							.title('Survey Alert')
							.textContent('Error, cannot get quotas percentages for distributions!')
							.ok('Close')
					);
				}
			)
		};

		vm.saveDistributions = function () {
			vm.distributionsDataValidation = SurveyDistributionsUtils.validateDistributions(vm.distributionsData);

			if(vm.distributionsDataValidation.age !== ""
			|| vm.distributionsDataValidation.sex !== ""
			|| vm.distributionsDataValidation.location !== "") {
				return;
			}

			var confirm = $mdDialog.confirm()
				.title('Distributions Save')
				.textContent('Are you sure you want to save current distributions?')
				.ok('Yes')
				.cancel('No');
			$mdDialog.show(confirm).then(function() {
				vm.savingDistributions = true;
				DistributionsService.saveSurveyDistributions(vm.surveyId, vm.distributionsData,
					function (success) {
						let distributionsDataEqual = SurveyDistributionsUtils.areDistributionsIdentical(success.data, vm.savedDistributionsData);
						vm.distributionsData = success.data;
						vm.savedDistributionsData = angular.copy(vm.distributionsData);
						vm.distributionsSavedInDB = vm.areDistributionsSavedInDB();
						vm.savingDistributions = false;

						let saveAlert = $mdDialog.alert()
							.title('Survey Alert')
							.textContent('Distributions saved successfully!')
							.ok('Close');

						$mdDialog.show(saveAlert).then(function() {
							if (vm.distributionsSavedInDB && !distributionsDataEqual) {
								$mdDialog.show(
									$mdDialog.alert()
										.title('Distributions Alert')
										.textContent('Distributions have been changed, please recalculate the weighting factors !')
										.ok('Close')
								);
							}
						});
					}, function (error) {
						vm.savingDistributions = false;
						$mdDialog.show(
							$mdDialog.alert()
								.title('Survey Alert')
								.textContent('Error, cannot save distributions!')
								.ok('Close')
						);
					}
				);
			}, function(){});
		};

        vm.openMenu = function ($mdMenu, ev) {
            originatorEv = ev;
            $mdMenu.open(ev);
        };

        vm.changeTranslationLanguage = function (languageToTranslate) {
            vm.selectedLanguage = languageToTranslate.lang_google_translate_name;
            getQuestions(languageToTranslate);
        };

        vm.changeSelectedPaletteGlobal = function () {
			const selectedColorPalette = vm.colorPalettes.find(colorPalette => colorPalette.paletteName === vm.selectedPaletteGlobal.paletteName);

			Object.keys(vm.selectedPalette).forEach(function (questionUniqueKey) {
        		vm.selectedPalette[questionUniqueKey] = JSON.parse(JSON.stringify(selectedColorPalette));
			});

			vm.refreshAllCharts();
		};

		function setFilters(question_unique_key) {
			vm.generalFilters = {
				filters: []
			};

            var globalFilters = vm.buildGlobalQFiltersForServer(vm.globalQFilters);
            vm.generalFilters.filters = [...vm.generalFilters.filters, ...globalFilters];

            if (vm.date) {
                vm.generalFilters.filters.push({
                    "type": "date_answer",
                    "values": [vm.date.toLocaleString()]
                });
            }
			if(question_unique_key) {
				const chartConfig = vm.chartConfigs[question_unique_key];

				//individual question filters
				if (vm.chartConfigs[question_unique_key].dknaOption && vm.chartConfigs[question_unique_key].dknaOption !== "0") {
					vm.generalFilters.filters.push({
						"type": "answers_nsnr",
						"values": [vm.chartConfigs[question_unique_key].dknaOption]
					});
				}
				if(question_unique_key.endsWith("VOTE5")) {
					vm.generalFilters.filters.push({
						"type": "ignore_answer",
						"values": [998]
					});
				}
				if(question_unique_key.includes(WITHOUT_UNDECIDED_PREFIX)) {
					vm.generalFilters.filters.push({
						"type": "ignore_answer",
						"values": [997, 998]
					});
				}
				if(vm.chartConfigs[question_unique_key].dynamicAgeGroups) {
					vm.chartConfigs[question_unique_key].dynamicAgeGroups.replace(" ", "");
					vm.generalFilters.dynamicAgeGroups = vm.chartConfigs[question_unique_key].dynamicAgeGroups.split(",");
					vm.redoRequest = true;
				}
				if (chartConfig.crossWithVote) {
				    let ignoredAnswers = []
					if(!chartConfig.crossWithVoteShowNotDecided) ignoredAnswers.push(997)
					if(!chartConfig.crossWithVoteShowNotVote) ignoredAnswers.push(998)
					if (ignoredAnswers.length > 0)
						 vm.generalFilters.filters.push({
							  "type": "ignore_answer",
							  "values": ignoredAnswers
						 });
				}
			}
		}

		function buildChart(question_unique_key) {
			vm.charts[question_unique_key] = AmCharts.makeChart(
				"chart" + question_unique_key,
				ChartsUtils.buildChartConfig(question_unique_key, angular.copy(vm.chartDataProviders[question_unique_key]), vm.chartConfigs[question_unique_key], vm.withWeighting, vm.weigthsCalculusResults)
			);
		}

		function cleanSelectDataKeys(selectData){

            for (var key in selectData) {
                if (selectData.hasOwnProperty(key)) {
                    if(key.startsWith("|")){
                    	var currentValue = selectData[key];
                    	var oldKey = key;
                    	key = key.split("|")[2];
                    	selectData[key] = currentValue;
                    	delete selectData[oldKey];
					}
                }
            }

            return selectData;
		}

		function cleanStatsRefreshedData(data){
			for(var i = 0; i < data.length ; i++){
				if(data[i]){
                    if(data[i].match(/^\d/) && data[i].length > 1){
                    	if(data[i].includes("#")){
                            data[i] = data[i].split("#")[1];
						}
                    }
				}
			}
			return data;
		}

		function makeQueuedRequest(question) {

			setFilters(question.question_unique_key);

			var questionStatisticsConfigs = {
                filtersWithAgeGroup: vm.generalFilters
            };
			if(question.type === "cityCounty"){
                questionStatisticsConfigs.cityCountyType = vm.chartConfigs[question.question_unique_key].cityCountyType;
                if(questionStatisticsConfigs.cityCountyType === 'division'){
                    questionStatisticsConfigs.divisionId = vm.chartConfigs[question.question_unique_key].divisionId;
                }
			}

			ChartsService.GetQuestionStats(vm.surveyId, question.question_id,vm.selectedLanguage, vm.withWeighting, questionStatisticsConfigs, function (response2) {
					//we need to cache these, as after the chart renders it mangles with data, and if we need to re-render we better have these untouched
                    response2.data = cleanSelectDataKeys(response2.data);
					vm.chartConfigsDecorator(question.question_unique_key);

					if (question.type === "number") {
						const questionIndex = vm.questions.findIndex(q => q.question_unique_key == question.question_unique_key);
						vm.chartDataProviders[question.question_unique_key] = ChartsUtils.cleanNumberQuestionStats(response2.data);
						vm.charts[question.question_unique_key] = { export: true };
						return;
					}

					var utilData = ChartsUtils.buildDataProvider(response2.data, vm.chartConfigs[question.question_unique_key]);
					vm.chartDataProviders[question.question_unique_key] = utilData.chartData;
					if(utilData.totalUsers) {
						question.total = utilData.totalUsers;
					}

					buildChart(question.question_unique_key);
				},
				function (response2) {
				});
		}

		function addVote5WithoutUndecidedInQuestionsList(questions) {
            var vote5questionIdx = questions.findIndex(function (element) {
                return element.id === "VOTE5";
            });

            if(typeof vote5questionIdx !== "undefined" && vote5questionIdx !== -1) {
                var vote5WithoutUndecided = JSON.parse(JSON.stringify(questions[vote5questionIdx]));
                vote5WithoutUndecided.id = vote5WithoutUndecided.id + WITHOUT_UNDECIDED_PREFIX;
                questions.push(vote5WithoutUndecided);
				const vote5Cumulated = JSON.parse(JSON.stringify(questions[vote5questionIdx]));
				vote5Cumulated.id = "VOTE";
				questions.push(vote5Cumulated);
			}

            return questions;
        }

        function addUniqueKeyToEachQuestion(questions) {
			for(var idx in questions) {
				questions[idx].question_unique_key = questions[idx].question_id + "_" + questions[idx].id;
			}
			return questions;
		}

		function fetchQuestions(languageToTranslate){
			languageToTranslate = languageToTranslate !== null ? languageToTranslate.lang_google_translate_name : null;
			setFilters();
			SurveysService.GetSurveyJson(vm.surveyId, true,languageToTranslate,  function (response) {

				if(response.status === 201){
					QuestiaUtils.showAlert("This survey is not translated in the chosen language" );
				}

				vm.surveyTitle = response.data.title;
				vm.surveyData = response.data;
				vm.weightsCalculated = response.data.weightsValidated;
				if(vm.weightsCalculated && !vm.weigthsCalculusResults) {
					vm.calculatingWeights = true;
					DistributionsService.getWeightsStatsForQuestId(vm.surveyId,
						function (success) {
							vm.weigthsCalculusResults = success.data;
							vm.weigthsCalculusResults.mode = vm.weigthsCalculusResults.mode.map((elem) => elem.toFixed(3)).toString().replaceAll(",",", ");
							vm.weightsCalculated = true;
							vm.calculatingWeights = false;
						}, function (error) {
							vm.calculatingWeights = false;
							$mdDialog.show(
								$mdDialog.alert()
									.title('Survey Alert')
									.textContent(error)
									.ok('Close')
							);
						}
					);
				}

				var questions = response.data.questions;
				questions = addVote5WithoutUndecidedInQuestionsList(questions);
				questions = addUniqueKeyToEachQuestion(questions);

				var queueOptions = {
					delay: 250, //delay 0.25 seconds between processing items
					paused: true, //start out paused
					complete: function () {
						console.log('complete!');
						vm.completed = true;
					}
				};

				// create an instance of a queue
				// note that the first argument - a callback to be used on each item - is required
				var chartsReqQueue = $queue.queue(makeQueuedRequest, queueOptions);

				for (var i = 0; i < questions.length; i++) {
					if (['quote', 'jumbotron', 'text', 'smileys'].indexOf(questions[i].type) === -1) {
						vm.questions.push(questions[i]);
						vm.chartConfigs[questions[i].question_unique_key] = {};

						//create a default config - shallow copy of the constant
						angular.extend(vm.chartConfigs[questions[i].question_unique_key], ChartsUtils.DEFAULT_CHART_CONFIG);
						vm.chartConfigs[questions[i].question_unique_key].type = "bar";
						if(questions[i].type === "selects" || questions[i].type === "ranking"){
							vm.chartConfigs[questions[i].question_unique_key].type = questions[i].options.multiple === "true" ? "cluster" : "stack";
						}
						//legend on by default for stacks
						if (questions[i].type === "selects" || questions[i].type === "ranking") {
							vm.chartConfigs[questions[i].question_unique_key].showLegend = true;
							(function (i) {
								ChartsService.GetSelectsForCross(questions[i].question_id,languageToTranslate,
									function (response) {
										if (response.data.length > 0) {
											vm.chartConfigs[questions[i].question_unique_key].selectsOptions = response.data;
										}
									},
									function (response) {
										console.log(response);
									});
							})(i);
						}
						if(questions[i].type === "cityCounty") {
							vm.chartConfigs[questions[i].question_unique_key].cityCountyType = "division";
							if(typeof vm.divisions !== 'undefined'){
								vm.chartConfigs[questions[i].question_unique_key].divisionId = vm.divisions[0].division_id;
							}
						}
						vm.chartConfigs[questions[i].question_unique_key].questionType = questions[i].type;
						if (!vm.greyBackground) {
							vm.chartConfigs[questions[i].question_unique_key].backgroundColor = "#ffffff";
						}
						(function (question) {
							chartsReqQueue.add(question);
						})(questions[i]);
					} else if(questions[i].type === "text"){
						vm.questions.push(questions[i]);
						vm.chartConfigs[questions[i].question_unique_key] = vm.configureWordCloud(questions[i]);

						(function (i) {
							ChartsService.GetQuestionStats(vm.surveyId, questions[i].question_id, vm.selectedLanguage, vm.withWeighting, {},
								function (response) {
									vm.chartConfigs[questions[i].question_unique_key].words =
										ChartsUtils.setUpWordsForWordCloud(response.data);
								},
								function (response) {
									console.log(response);
								});
						})(i);
						if (!vm.greyBackground) {
							vm.chartConfigs[questions[i].question_unique_key].backgroundColor = "#ffffff";
						}
					}
				}
				chartsReqQueue.start(); //must call start() if queue starts paused
			});
		}

		function getQuestions(languageToTranslate) {
			vm.questions = [];
			vm.charts = {};
            vm.numberOfChunks = 1;
			vm.chartConfigs = {};
			vm.chartDataProviders = {};
			var splitAuth = $rootScope.globals.authorization.split(":");
			vm.auth = "?user_id=" + splitAuth[0] + "&token=" + splitAuth[1];

			var selectedLanguageId = languageToTranslate !== null ? languageToTranslate.lang_id : -1;
			ConfigurationsService.GetConfigurationByLangId("DK_NA", selectedLanguageId,
				function (response) {
				if(response.data.value !== null) {
					$rootScope.dknaTranslatedLabel = response.data.value;
				}
					fetchQuestions(languageToTranslate);
				},
				function (response) {
					fetchQuestions(languageToTranslate);
				});
		}

		vm.refreshCharts = function () {
			if (!vm.surveyId) return;
			getQuestions(null);
		};

		vm.switchWeightingCharts = function () {
			vm.withWeighting = !vm.withWeighting;
			vm.refreshCharts();
		};

		vm.crossChangeReq = function (question) {
			var chartConfig = vm.chartConfigs[question.question_unique_key];
			var crossQuestion = "";
			if(chartConfig.crossWithId) {
				crossQuestion = vm.questions.find(x=> x.question_id === chartConfig.crossWithId);
				if(crossQuestion) {
					ChartsUtils.crossChange(question, chartConfig, {
						question_id: crossQuestion.question_id,
						question_quest_qid: crossQuestion.id,
						question_type: crossQuestion.type,
						question_text: crossQuestion.question
					});
				}
				else {
					ChartsService.GetQuestion(chartConfig.crossWithId,
						function(response){
							const crossQuestion = response.data;
							ChartsUtils.crossChange(question, chartConfig, crossQuestion);
						},
						function(response){
							ChartsUtils.crossChange(question, chartConfig);
						}
					);
				}
			}
			else ChartsUtils.crossChange(question, chartConfig);
			chartConfig.redoRequest = true;
		};

		vm.buildUtilDataAndMakeChart = function (crossData, question) {
			var chartConfig = vm.chartConfigs[question.question_unique_key];

			if (chartConfig.crossWithMatrix) {
				vm.buildUtilDataAndMakeCrossMatrixCharts(crossData, question);
				return;
			}

			const crossDataClone = JSON.parse(JSON.stringify(crossData));

			var utilData = ChartsUtils.buildDataProvider(crossDataClone, chartConfig);
			vm.chartDataProviders[question.question_unique_key] = utilData.chartData;
			if(utilData.totalUsers) {
				question.total = utilData.totalUsers;
			}

			//modified config, same data provider
			buildChart(question.question_unique_key)
		};

		vm.buildUtilDataAndMakeCrossMatrixCharts = function (crossData, question) {
			const chartConfig = vm.chartConfigs[question.question_unique_key];

			chartConfig.showCrossWithMatrix = true;

			const stats = ChartsUtils.preprocessDataCrossWithMatrix(crossData, question, chartConfig);
			for (const stat of stats) {
				const utilData = ChartsUtils.buildDataProvider(stat, chartConfig);
				chartConfig.showLegend = true;
				vm.chartConfigs[stat.chartUniqueKey] = chartConfig;

				vm.chartDataProviders[stat.chartUniqueKey] = utilData.chartData;
				if(utilData.totalUsers) {
					question.total = utilData.totalUsers;
				}

				buildChart(stat.chartUniqueKey);
			}
		};

		function doesQuestionDataLabelsContainDKNA(labels) {
		    if(!labels) return false;
			for(let i = 0; i < labels.length; i++) {
				let currentLabel = labels[i];
				if(currentLabel.includes("#") && currentLabel.split("#")[0] === "999") {
					return true
				}
			}
			return false;
		}

		function doesQuestionDataLabelsContainOtherChoice(labels) {
			if(!labels) return undefined;
			for(let i = 0; i < labels.length; i++) {
				let currentLabel = labels[i];
				if(currentLabel.includes("#") && currentLabel.split("#")[1].endsWith("...")) {
					return i;
				}
			}
			return undefined;
		}

		vm.getDataAndBuildChart = function (question) {
			$timeout(function () {
				if (!question || !vm.charts[question.question_unique_key]) return;
				var chartConfig = vm.chartConfigs[question.question_unique_key];
				var chartDomEl = document.getElementById('chart' + question.question_unique_key);
				if (chartDomEl) {
					chartDomEl.style.height = chartConfig.height + 'px';
					chartDomEl.style.width = chartConfig.width + 'px';
				}
				if (chartConfig.crossWithMatrix){
					for (const chart of question.matrixCrossStats) {
						const chartDomEl = document.getElementById('chart' + chart.chartUniqueKey);
						chartDomEl.style.height = chartConfig.height + 'px';
						chartDomEl.style.width = chartConfig.width + 'px';
					}
				} else chartConfig.showCrossWithMatrix && (chartConfig.showCrossWithMatrix = false);

				//data needs refresh?
				if (chartConfig.redoRequest) {
					chartConfig.redoRequest = false;

					//redo req
					setFilters();

					// filters configs
					var questionStatisticsConfigs = {
						filtersWithAgeGroup: vm.generalFilters
					};

					// CityCounty configs
					if (question.type === "cityCounty") {
						questionStatisticsConfigs.cityCountyType = vm.chartConfigs[question.question_unique_key].cityCountyType;
						if (questionStatisticsConfigs.cityCountyType === 'division') {
							questionStatisticsConfigs.divisionId = vm.chartConfigs[question.question_unique_key].divisionId;
						}
					}

					// If cross with other id is location question, by default cross with first region
					if(chartConfig.crossWithId === $rootScope.globals.asl.locationID) {
						chartConfig.crossWithDivisionId = vm.divisions[0].division_id;
					}

					var req_id = ChartsUtils.determineChartRequestUrl(question, chartConfig, questionStatisticsConfigs);
					setFilters(question.question_unique_key);
					questionStatisticsConfigs.filtersWithAgeGroup = vm.generalFilters;

					ChartsService.GetQuestionStats(vm.surveyId, req_id, vm.selectedLanguage, vm.withWeighting, questionStatisticsConfigs,
						function (response2) {
							if (response2.data.labels1 && response2.data.labels2) {
								response2.data.firstQuestionHasDKNA = doesQuestionDataLabelsContainDKNA(response2.data.labels1);
								response2.data.firstQuestionOtherChoiceIndex = doesQuestionDataLabelsContainOtherChoice(response2.data.labels1);
								response2.data.labels1 = cleanStatsRefreshedData(response2.data.labels1);
								if (chartConfig.crossWithId !== $rootScope.globals.asl.ageID || (chartConfig.questionType !== "selects" && question.type !== "ranking")) {
									response2.data.labels2 = cleanStatsRefreshedData(response2.data.labels2);
								}
								if (chartConfig.crossWithId) {
									var questionType = vm.questions.find(x => x.question_id === chartConfig.crossWithId);
									if (questionType) {
										questionType = questionType.type;
										if (chartConfig.questionType !== "selects" && questionType !== 'combodate' && question.type !== "ranking") {
											response2.data.labels2 = cleanStatsRefreshedData(response2.data.labels2);
										}
									}
									ChartsUtils.extractTotalUsersCountArray(response2.data, chartConfig);
									// remove multipleChoice undefined label row in chart for cross with any other question
									if (question.type === "multipleChoice") {
										if (response2.data.crossStats && (response2.data.crossStats.length > question.choices.length)) response2.data.crossStats.pop();
									}
									if (chartConfig.crossWithMatrix) {
										response2.data.labels3 = cleanStatsRefreshedData(response2.data.labels3);
									}
								}

								if (chartConfig.crossWithDivisionId) {
									ChartsUtils.extractTotalUsersCountArray(response2.data, chartConfig);
								}
							}
							if (response2.data && (response2.data.labels1 === undefined && response2.data.labels2 === undefined)) {
								response2.data = cleanSelectDataKeys(response2.data);
							}
							vm.buildUtilDataAndMakeChart(response2.data, question);
						},
						function (response2) {
						}
					);
				} else {
					vm.chartConfigsDecorator(question.question_unique_key);
					if (chartConfig.crossWithMatrix) {
						for(const element of question.matrixCrossStats){
							buildChart(element.chartUniqueKey);
						}
					}
					else buildChart(question.question_unique_key);
				}
			}, 0)
		}

		function createDivsForCrossWithMatrix(question, chartConfig) {
			// html calls vm.getDataAndBuildChart(question) after divs are rendered
			if (["singleChoice", "multipleChoice"].includes(question.type)) {
				question.matrixCrossStats = question.choices
					.sort((a, b) => a.value - b.value)
					.map((c, index) => ({
						chartUniqueKey: question.question_unique_key + '_crossChart_' + c.value,
						choiceLabel: c.label
					}));
				if (question.options && question.options.noDKNA !== 'true') {
					question.matrixCrossStats.push({
						chartUniqueKey: question.question_unique_key + '_crossChart_' + 999,
						choiceLabel: $rootScope.dknaTranslatedLabel
					});
				}
			}
			if (question.type === "combodate") {
				question.matrixCrossStats = [];
				question.matrixCrossStats.push({
					chartUniqueKey: question.question_unique_key + "_crossChart_1",
					choiceLabel: "18-24"
				});
				question.matrixCrossStats.push({
					chartUniqueKey: question.question_unique_key + "_crossChart_2",
					choiceLabel: "25-34"
				});
				question.matrixCrossStats.push({
					chartUniqueKey: question.question_unique_key + "_crossChart_3",
					choiceLabel: "35-44"
				});
				question.matrixCrossStats.push({
					chartUniqueKey: question.question_unique_key + "_crossChart_4",
					choiceLabel: "45-54"
				});
				question.matrixCrossStats.push({
					chartUniqueKey: question.question_unique_key + "_crossChart_5",
					choiceLabel: "55-64"
				});
				question.matrixCrossStats.push({
					chartUniqueKey: question.question_unique_key + "_crossChart_6",
					choiceLabel: "65+"
				});

			}
			if (question.type === "cityCounty" && typeof vm.divisions !== 'undefined' && typeof vm.divisionsData !== 'undefined') {
				let currentDivisionId = chartConfig.divisionId;
				question.matrixCrossStats = vm.divisionsData[currentDivisionId]
					.sort((a, b) => a.region_id - b.region_id)
					.map((c) => ({
						chartUniqueKey: question.question_unique_key + '_crossChart_' + c.region_id,
						choiceLabel: c.region_name
					}));
			}
		}

		vm.questionHasCrossSettings = function (question) {
			return vm.chartConfigs[question.question_unique_key] && vm.chartConfigs[question.question_unique_key].cross;
		};

		vm.refreshChart = function (question) {
			var chartConfig = vm.chartConfigs[question.question_unique_key];

			if (chartConfig.crossWithMatrix){
				createDivsForCrossWithMatrix(question, chartConfig);
			} else {
				chartConfig.showCrossWithMatrix && (chartConfig.showCrossWithMatrix = false);
				vm.getDataAndBuildChart(question);
			}

		};

		vm.refreshAllCharts = function () {
			vm.questions.forEach(question => vm.refreshChart(question));
		};

		vm.chartConfigsDecorator = function(question_unique_key){
		   vm.chartConfigs[question_unique_key].colors = [];
		   vm.chartConfigs[question_unique_key].colors = vm.selectedPalette[question_unique_key];
		};

		vm.exportPpt = function() {
			vm.completed = false;

			var surveyId = vm.surveyId;
			var hostname = $window.location.hostname;

			var chartsPayload = {
				surveyTitle: vm.surveyTitle,
				surveyId: surveyId,
				textsAsSlideTitles: JSON.parse(vm.textsAsSlideTitles),
				chartsDetails: []
			};

			var exportableQuestions = vm.questions.filter(function (question) {
				return (question.type !== "multipleOpen" && typeof vm.charts[question.question_unique_key] !== "undefined" && typeof vm.charts[question.question_unique_key].export !== "undefined");
			});
			for (const question of exportableQuestions.slice()) {
				const chartConfig = vm.chartConfigs[question.question_unique_key];

				if (chartConfig.showCrossWithMatrix) {
					const questionIndex = exportableQuestions.findIndex(q => q.question_unique_key == question.question_unique_key),
						matrixCrossStats = exportableQuestions[questionIndex].matrixCrossStats;

					exportableQuestions.splice(questionIndex, 1);
					for (let i = 0; i < matrixCrossStats.length; i++) {
						const crossWithMatrixText = question.id + ". " + question.question +
							"\n(Choice '" + matrixCrossStats[i].choiceLabel + "' crossed with '" +
							chartConfig.crossQuestion.question_quest_qid + ". " + chartConfig.crossQuestion.question_text.trim() + "')";
						exportableQuestions.splice(questionIndex + i, 0, {
							customSlideText: crossWithMatrixText,
							question_unique_key: matrixCrossStats[i].chartUniqueKey,
							total: question.total
						});
					}
				}
			}
			var numCharts = exportableQuestions.length;

			var chartsExported = 0;
			angular.forEach(exportableQuestions, function (question, key) {
				var chartText = question.customSlideText ? question.customSlideText
					: question.id + ". " + question.question;

				var chartData = {
				    id: question.id,
					chartText: chartText,
					chartBase64: null,
					numAnswers: question.total
				};

				if (question.type === "number") {
					html2canvas(document.getElementById("number_chart" + question.question_unique_key)).then(function (canvas) {
					    chartsExported++;
						chartData.chartBase64 = canvas.toDataURL();
						chartsPayload.chartsDetails.push(chartData);
						chartsExported === numCharts && getPpt();
					});
					return;
				}

				vm.charts[question.question_unique_key].export.capture({}, function() {
					this.toPNG({
						"fit": [827.88, 313.20]
					}, function(data) {
						chartsExported ++;
						chartData.chartBase64 = data;
						chartsPayload.chartsDetails.push(chartData);
						chartsExported === numCharts && getPpt();
					})
				});
			})

			function getPpt() {
				chartsPayload.chartsDetails.sort(function (a, b) {
					return exportableQuestions.findIndex(function (elem) { return a.id === elem.id })
						- exportableQuestions.findIndex(function (elem) { return b.id === elem.id });
				});
				ChartsService.ExportChartsPpt(vm.localReportName,chartsPayload,
					function (response) {
						vm.completed = true;
						var pptFile = new Blob([response.data], {
							type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
						});

						var pptFileName = surveyId + "_" + hostname + ".pptx";

						if (window.navigator && window.navigator.msSaveOrOpenBlob) {
							window.navigator.msSaveOrOpenBlob(pptFile, pptFileName);
						}
						else {
							var downloadLink = document.createElement("a");
							document.body.appendChild(downloadLink);
							downloadLink.style = "display: none";
							var fileURL = URL.createObjectURL(pptFile);
							downloadLink.href = fileURL;
							downloadLink.download = pptFileName;
							downloadLink.click();
							window.URL.revokeObjectURL(fileURL);
							downloadLink.remove();
						}
					},
					function (response) {
						vm.completed = true;
						console.log(response);
					});
			}
		};

		vm.buildGlobalQFiltersForServer = function (globalQFilters) {
			var filtersArray = [];

			if (globalQFilters) {
				for (var i in globalQFilters) {
					var qFilter = globalQFilters[i];
					if (qFilter.qType && qFilter.inputQId) {
						switch (qFilter.qType) {
							case 'cityCounty':
								if (qFilter.cityCountyFilterType === "county") {
									filtersArray.push({
										"type": "other_question_answer",
										"values": [qFilter.inputQId, qFilter.selectedChoice, "county"]
									});
								} else if (qFilter.cityCountyFilterType === "division") {
									filtersArray.push({
										"type": "other_question_answer",
										"values": [qFilter.inputQId, qFilter.selectedChoice, "region"]
									});
								}
								break;
							case 'singleChoice':
							case 'multipleChoice':
								filtersArray.push({
									"type": "other_question_answer",
									"values": [qFilter.inputQId, ...qFilter.selectedChoices]
								});
								break;
							case 'combodate':
								filtersArray.push({
									"type": "other_question_answer",
									"values": [qFilter.inputQId, qFilter.ageGroups, "true"]
								});
								break;
							default:
								console.log(`warning, Question with id ${qFilter.inputQId} type is not supported for this type of filter!`);
						}
					}
				}
			}

			return filtersArray;
		};

		vm.addNewFilterElemToGlobalQFilters = function () {
			var newFilter = JSON.parse(JSON.stringify(vm.defaultFilterElem));
			vm.globalQFilters.push(newFilter);
		};

		vm.removeFilterElemFromGlobalQFilters = function (index) {
			if (vm.globalQFilters.length > 1) {
				vm.globalQFilters.splice(index, 1);
			}
		};

		vm.handleCountyFilterTypeChange = function (index) {
			vm.globalQFilters[index].choices = [];
			vm.globalQFilters[index].selectedChoice = undefined;
			vm.globalQFilters[index].selectedDivisionId = "";
			if (vm.globalQFilters[index].cityCountyFilterType && vm.globalQFilters[index].cityCountyFilterType === "county") {
				var queryParams = {
					cityCountyType: "counties"
				};
				ChartsService.GetQuestChoices(vm.globalQFilters[index].inputQId, queryParams, function (response) {
					vm.parseFilterChoicesFromResponse(index, response);
				}, function (error) {
					console.log(error);
				});
			}
		};

		vm.handleDivisionCountyFilterTypeChange = function (index) {
			var divisionId = vm.globalQFilters[index].selectedDivisionId;
			var queryParams = {
				cityCountyType: "regions",
				divisionId: divisionId
			};
			ChartsService.GetQuestChoices(vm.globalQFilters[index].inputQId, queryParams, function (response) {
				vm.parseFilterChoicesFromResponse(index, response);
			}, function (error) {
				console.log(error);
			});
		};

		vm.findQIdAndSetUpForFilter = function (index) {
			var currentQId = vm.globalQFilters[index].inputQId;

			var question = vm.questions.find(function (question) {
				return question.question_id == currentQId;
			});

			if (question) {
				vm.resetQFilterAtIndex(index);
				vm.globalQFilters[index].qType = JSON.parse(JSON.stringify(question.type));

				if (vm.globalQFilters[index].qType === 'combodate') return;

				if (vm.globalQFilters[index].qType === 'cityCounty') return;

				ChartsService.GetQuestChoices(currentQId, {}, function (response) {
					vm.parseFilterChoicesFromResponse(index, response);
				}, function (error) {
					console.log(error);
				});
			} else {
				vm.resetQFilterAtIndex(index);
			}
		};

		vm.parseFilterChoicesFromResponse = function(index, response) {
			vm.globalQFilters[index].choices = [];
			for (var key in response.data) {
				if (response.data.hasOwnProperty(key)) {
					vm.globalQFilters[index].choices.push({value: key, label: response.data[key]});
				}
			}

			var defaultSelectedValue = JSON.parse(JSON.stringify(vm.globalQFilters[index].choices[0].value));
			if (vm.globalQFilters[index].qType === "multipleChoice") {
				vm.globalQFilters[index].selectedChoices.push(defaultSelectedValue);
			} else {
				vm.globalQFilters[index].selectedChoice = defaultSelectedValue;
			}
		};

		vm.resetQFilterAtIndex = function (index) {
			vm.globalQFilters[index].choices = [];
			vm.globalQFilters[index].selectedChoice = undefined;
			vm.globalQFilters[index].selectedChoices = [];
			vm.globalQFilters[index].qType = "";
			vm.globalQFilters[index].ageGroups = "";
			vm.globalQFilters[index].cityCountyFilterType = "";
			vm.globalQFilters[index].selectedDivisionId = "";
		};

        vm.onClickCrossVoteChecks = function (type, question_unique_key) {
            const chartConfig = vm.chartConfigs[question_unique_key],
				  showNotDecided = chartConfig.crossWithVoteShowNotDecided,
				  showNotVote = chartConfig.crossWithVoteShowNotVote;

            chartConfig.redoRequest = true;
            switch (type) {
				case 'NOT_DECIDED':
					if (!!showNotVote && !!showNotDecided)
						chartConfig.crossWithVoteShowNotVote = false;
					break;
				case 'NOT_VOTE':
					break;
			}
		 };

		vm.switchAnswers = function (qid1, qid2) {
			vm.switchLoading = true;
			ChartsService.SwitchAnswers(qid1, qid2,
				function (response) {
					vm.switchAnswersDone = "SUCCESS. STATUS: " + response.data;
					delete vm.switchLoading;
					window.location.href = "/charts?qid=" + qid2;
				},
				function (response) {
					vm.switchAnswersDone = "ERROR. STATUS: " + response.data;
					delete vm.switchLoading;
				});
		};

		vm.clearSurveyCache = function() {
			var surveyQuestionIds = '(' + vm.questions.map(function (element) { return "\'chart_" + element.question_id + "\'"; }).toString() + ')';
			ChartsService.ClearSurveyCache(surveyQuestionIds,
				function (response) {
                    $mdDialog.show(
                        $mdDialog.alert()
                            .title('Success')
                            .textContent('The cache was cleared')
                            .ok('Close')
                    );
				},
				function (response) {
					console.log(response);
				});
		};


		vm.goto = function (page) {
			$state.go(page);
		};

        vm.goToSpeedsters = function (qid) {
            $state.go("speedsters", {qid: qid});
        };

        vm.goToRecurrentHistory = function (withUndecided, cumulated) {
        	const url = $state.href("recurrentHistory", {withUndecided: withUndecided, cumulated: cumulated});
			$window.open(url,'_blank');
		};

		vm.goToRecurrentHistoryPredefinedQ = function (parentQuestionId) {
			const url = $state.href("recurrentHistory", {predefinedQuestionId: parentQuestionId});
			$window.open(url,'_blank');
		};

		vm.goToRCSChartsPage = function (questId, withWeighting) {
			window.location = '/surveys/rcs/' + questId + (withWeighting ? '?withWeighting=true' : '');
		}

        vm.questionContainsOtherThreeDotChoice = function (question) {
			var choices = question.choices;
			if(choices){
				for(let i in choices) {
					if(choices[i].label && choices[i].label.endsWith("...")){
						return true
					}
				}
			}
			return false
		};

		vm.getWordCloudDataForQuestionWithThreeDotsChoice = function (question) {
			vm.chartConfigs[question.question_unique_key].loadingOtherWordCloud = true;
			vm.chartConfigs[question.question_unique_key].wordCloudConfig = vm.configureWordCloud(question);
			ChartsService.getQuestionThreeDotsChoiceWordCloudStats(question.question_id,
				function (response) {
					vm.chartConfigs[question.question_unique_key].wordCloudConfig.words = ChartsUtils.setUpWordsForWordCloud(response.data);
					vm.chartConfigs[question.question_unique_key].loadingOtherWordCloud = false;
				},
				function (response) {
					console.log(response);
					vm.chartConfigs[question.question_unique_key].loadingOtherWordCloud = false;
				});
			if (!vm.greyBackground) {
				vm.chartConfigs[question.question_unique_key].backgroundColor = "#ffffff";
			}
		};

		vm.configureWordCloud = function (question) {
			return {
				selectedWordId: "selectedWord" + question.question_unique_key,
				selectedSizeId: "selectedSize" + question.question_unique_key,
				selectedWord:"",
				selectedSize:"",
				height: 700,
				// height: $window.innerHeight * 0.5,
				width: 1500,
				rotate: function(){
					return ~~(Math.random() * 2) * 90;
				},
				random: function(){
					return 0.4; // a constant value here will ensure the word position is fixed upon each page refresh.
				},
				useTooltip: true,
				useTransition: false,
				words: [],
				wordsFont: 'Roboto, "Helvetica Neue", sans-serif',
				wordClicked: function(word){
					// alert('text: ' + word.text + ',size: ' + word.size);
				}
			};
		}
	}

})();
