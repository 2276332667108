(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .factory('LanguagesService', LanguagesService);

    LanguagesService.$inject = ['$http'];

    function LanguagesService($http) {
        var service = {};

        service.GetAllLanguages = function (okCallback, koCallback) {
            $http.get("/rest/languages/all")
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.GetAdminLanguages = function (okCallback, koCallback) {
            $http.get("/rest/languages/admin")
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.GetPanelLanguages = function (okCallback, koCallback) {
            $http.get("/rest/languages/panel")
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        return service;
    }
})();
