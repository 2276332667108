(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .controller('TranslateController', TranslateController);

    TranslateController.$inject = ['TranslateService','$mdDialog', '$rootScope', 'LanguagesService'];

    function TranslateController(TranslateService,$mdDialog, $rootScope, LanguagesService) {
        var vm = this;
        vm.editModeCards = {};
        vm.tabsIndexMapper = {};
        vm.search = '';
        vm.results = [];
        vm.selectedLanguage = undefined;
        vm.dropdownLanguage = undefined;
        vm.availableLanguages = [];
        vm.search = {
            string: "",
            tab: "",
            section: "",
            value: ""
        };

        vm.changeLanguage = function (dropdownLanguage) {
            if(typeof vm.selectedLanguage !== "undefined") {
                if(dropdownLanguage.lang_id === vm.selectedLanguage.lang_id) return;
                var confirm = $mdDialog.confirm({
                    onComplete: function afterShowAnimation() {
                        var $dialog = angular.element(document.querySelector('md-dialog'));
                        var $actionsSection = $dialog.find('md-dialog-actions');
                        var $confirmButton = $actionsSection.children()[1];
                        angular.element($confirmButton).addClass('md-raised');
                    }
                })
                    .title("Change translation language to " + dropdownLanguage.lang_ui_name)
                    .textContent("Warning! Make sure you saved any changes made to the current language. If you did, you can safely change the language!")
                    .cancel("No")
                    .ok("Yes");
                $mdDialog.show(confirm).then(function () {
                    vm.loadTexts(dropdownLanguage);
                }).catch(function () {
                });
            } else {
                vm.loadTexts(dropdownLanguage);
            }
        };

        vm.loadTexts = function(newSelectedLanguage) {
            vm.selectedLanguage = newSelectedLanguage;
            if(typeof vm.selectedLanguage !== "undefined") {
                TranslateService.getTexts(vm.selectedLanguage.lang_panel_directory_name,
                    function (response) {
                        if (!response.data.length && response.data.length < 2) return;
                        vm.defaultTexts = JSON.parse(response.data[0]);
                        vm.translatedTexts = JSON.parse(response.data[1]);
                        vm.originalTexts = JSON.parse(response.data[1]);
                        vm.changeList = [];
                    }
                );
            }
        };

        vm.clearSectionsOnChange = function() {
            vm.selectedSection = undefined;
        };

        vm.setSearchString = function(target){
            vm.openCard(target);
        };

        vm.filterTranslationOfForbiddenCharacters = function() {
            for (var tab in vm.translatedTexts) {
                for (var card in vm.translatedTexts[tab]) {
                    for(var value in vm.translatedTexts[tab][card]){
                        vm.translatedTexts[tab][card][value] = vm.replaceAllOccurrences(vm.translatedTexts[tab][card][value], "\"", "'");
                        vm.translatedTexts[tab][card][value] = vm.replaceAllOccurrences(vm.translatedTexts[tab][card][value], "\n", " ");
                    }
                }
            }
        };

        vm.replaceAllOccurrences = function(str, word, toReplaceWith) {
              var re = new RegExp(word, 'g');
              return str.replace(re, toReplaceWith);
        };

        vm.saveJSONForSelectedLanguage = function(){
            vm.savingJson = true;
            vm.filterTranslationOfForbiddenCharacters();
            const jsonData = JSON.stringify(vm.constructLanguageTranslate());
            TranslateService.saveJson(
                jsonData,
                vm.selectedLanguage.lang_panel_directory_name,
                function (succes) {
                    vm.loadTexts(vm.selectedLanguage);
                    vm.savingJson = false;
                    var confirm = $mdDialog.confirm({
                        onComplete: function afterShowAnimation() {
                            var $dialog = angular.element(document.querySelector('md-dialog'));
                            var $actionsSection = $dialog.find('md-dialog-actions');
                            var $confirmButton = $actionsSection.children()[1];
                            angular.element($confirmButton).addClass('md-raised');
                        }
                    })
                        .title("Translate module")
                        .textContent("Your translation had been saved")
                        .ok("OK");
                    $mdDialog.show(confirm);
                },
                function (fail){
                    vm.savingJson = false;
                    var confirm = $mdDialog.confirm({
                        onComplete: function afterShowAnimation() {
                            var $dialog = angular.element(document.querySelector('md-dialog'));
                            var $actionsSection = $dialog.find('md-dialog-actions');
                            var $confirmButton = $actionsSection.children()[1];
                            angular.element($confirmButton).addClass('md-raised');
                        }
                    })
                        .title("Translate module")
                        .textContent("Something went wrong. Your translation had not been saved ")
                        .ok("OK");
                    $mdDialog.show(confirm);
                }
            );
        };

        vm.openCard = function(foundText){
            vm.selectedTab = foundText.tab;
            vm.selectedSection = foundText.section;
            vm.goToAnchor(foundText.tab + foundText.section + foundText.value);
        };

        vm.findValue = function(enteredValue){
            if(vm.results){
                vm.results.length = 0;
            }
            if(enteredValue.length < 1){
                alert('Please enter a string with at least 2 characters');
            }else{
                var uEnteredValue = enteredValue.toUpperCase();
                if (typeof uEnteredValue === 'string' || uEnteredValue instanceof String){
                    for (var tab in vm.defaultTexts) {
                        for (var section in vm.defaultTexts[tab]) {
                            for(var value in vm.defaultTexts[tab][section]){
								var myString = "";
								if (!vm.translatedTexts[tab] || !vm.translatedTexts[tab][section] || !vm.translatedTexts[tab][section][value]){
									myString = vm.defaultTexts[tab][section][value];
								}else{
									myString = vm.translatedTexts[tab][section][value];
								}
                                if (!(typeof myString === 'string' || myString instanceof String)) break;
								var uMyString = myString.toUpperCase();
								var valueString = value.toUpperCase();
								if(uMyString.includes(uEnteredValue) || valueString.includes(uEnteredValue)){
									var result = {
										string: myString,
										tab: tab,
										section: section,
										value: value
									};
									vm.results.push(result);
								}
                            }
                        }
                    }
                }
            }
        };

        vm.addToChangeList = function (selectedTab, selectedSection, field) {
            const fieldLocation = selectedTab + '.' + selectedSection + '.' + field;
            const translatedText = vm.translatedTexts[selectedTab][selectedSection][field];
            const index = vm.changeList.findIndex(change => change.fieldLocation === fieldLocation);

            if (index !== -1) {
                vm.changeList[index].text = translatedText;
            } else {
                vm.changeList.push({fieldLocation: fieldLocation, text: translatedText});
            }
        };

        vm.constructLanguageTranslate = function () {
            const languageTranslate = {};
            for (const change of vm.changeList) {
                vm.setValueInObject(languageTranslate, change.fieldLocation, change.text);
            }
            return languageTranslate;
        }

        vm.setValueInObject = function (obj, location, val) {
            const keys = location.split('.');
            const lastKey = keys.pop();
            const lastObj = keys.reduce((obj, key) =>
                    obj[key] = obj[key] || {},
                obj);
            lastObj[lastKey] = val;
        }

        vm.goToAnchor = function(x){
            setTimeout(function () {
                if(x) {
                    var element = document.getElementById(x);
                    element.scrollIntoView();
                    element.focus();
                }
            }, 500);

        };

        vm.getLanguages = function () {
            LanguagesService.GetPanelLanguages(
                function (success) {
                    vm.availableLanguages = success.data;
                },
                function (error) {
                    console.log(error)
                }
            );
        };

        (function initController() {
            vm.activeSubmenu = 'setup_translate';
            vm.breadcrumbs = 'Setup: Translate';

            vm.getLanguages();
        })();

    }
})();
