(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.controller('CampaignStatsController', CampaignStatsController);

	CampaignStatsController.$inject = ['$rootScope', 'UsersService'];
	function CampaignStatsController($rootScope, UsersService) {
		var vm = this;

		vm.getStats = function() {
			if(!vm.minDate || !vm.maxDate) return;
			UsersService.GetCampaignStatistics(vm.minDate.format("YYYY-MM-DD"), vm.maxDate.format("YYYY-MM-DD"),
				function SuccessCallback(response) {
					vm.data = response.data;
					//count all logins for the period
					vm.totalLogins = vm.data.login.reduce(
						function(sum, current){
							return sum + parseInt(current[1]);
						}, 0
					);
					vm.totalUsers = {
						confirmed: {organic:0, fb_signup: 0, fb_leads: 0, mvf: 0, total: 0},
						reachable: {organic:0, fb_signup: 0, fb_leads: 0, mvf: 0, total: 0},
						all: {organic:0, fb_signup: 0, fb_leads: 0, mvf: 0, total: 0}
					};
					//count totals for users
					for(var i = 0; i < vm.data.source.length; i++) {
						var source = vm.data.source[i];
						vm.data.source[i].date = moment(vm.data.source[i].date).format("YYYY-MM-DD");
						vm.data.source[i].counts.total = (source.counts.fb_signup || 0) + (source.counts.fb_leads || 0);
						vm.totalUsers.all.total += vm.data.source[i].counts.total;
						vm.totalUsers.all.organic += (source.counts.organic || 0);
						vm.totalUsers.all.fb_signup += (source.counts.fb_signup || 0);
						vm.totalUsers.all.fb_leads += (source.counts.fb_leads || 0);
						vm.totalUsers.all.mvf += (source.counts.mvf || 0);
					}
					//count totals for users
					for(i = 0; i < vm.data.source_reachable.length; i++) {
						source = vm.data.source_reachable[i];
						vm.data.source_reachable[i].date = moment(vm.data.source_reachable[i].date).format("YYYY-MM-DD");
						vm.data.source_reachable[i].counts.total = (source.counts.fb_signup || 0) + (source.counts.fb_leads || 0);
						vm.totalUsers.reachable.total += vm.data.source_reachable[i].counts.total;
						vm.totalUsers.reachable.organic += (source.counts.organic || 0);
						vm.totalUsers.reachable.fb_signup += (source.counts.fb_signup || 0);
						vm.totalUsers.reachable.fb_leads += (source.counts.fb_leads || 0);
						vm.totalUsers.reachable.mvf += (source.counts.mvf || 0);
					}
					//count totals for users
					for(i = 0; i < vm.data.source_reachable_active.length; i++) {
						source = vm.data.source_reachable_active[i];
						vm.data.source_reachable_active[i].date = moment(vm.data.source_reachable_active[i].date).format("YYYY-MM-DD");
						vm.data.source_reachable_active[i].counts.total =
							(source.counts.fb_signup || 0) + (source.counts.fb_leads || 0);
						vm.totalUsers.confirmed.total += vm.data.source_reachable_active[i].counts.total;
						vm.totalUsers.confirmed.organic += (source.counts.organic || 0);
						vm.totalUsers.confirmed.fb_signup += (source.counts.fb_signup || 0);
						vm.totalUsers.confirmed.fb_leads += (source.counts.fb_leads || 0);
						vm.totalUsers.confirmed.mvf += (source.counts.mvf || 0);
					}
					vm.totalUsers.confirmed.totalAll = vm.totalUsers.confirmed.total + vm.totalUsers.confirmed.mvf + vm.totalUsers.confirmed.organic;
				},
				function ErrorCallback() {
					console.log("error getting Statistics data");
				}
			);
		};

		vm.getCountUsersBySourceCSVArray = function (data) {
			var arrayForCSV = [];
			for(var each in data) {
				arrayForCSV.push({
					date: data[each].date,
					fb_leads: data[each].counts.fb_leads || 0,
					fb_signup: data[each].counts.fb_signup || 0,
					total: data[each].counts.total || 0,
					mvf: data[each].counts.mvf || 0,
					organic: data[each].counts.organic ||0
				});
			}
			return arrayForCSV;
		};

		vm.getUniqueUsersWhoAnsweredCSVArray = function (data) {
			var arrayForCSV = [];
			arrayForCSV.push({
				source: "FB",
				profile: data.fb.profile || 0,
				short: data.fb.short || 0,
				spot: data.fb.spot || 0
			});
			arrayForCSV.push({
				source: "MVF",
				profile: data.mvf.profile || 0,
				short: data.mvf.short || 0,
				spot: data.mvf.spot || 0
			});
			arrayForCSV.push({
				source: "ORG",
				profile: data.org.profile || 0,
				short: data.org.short || 0,
				spot: data.org.spot || 0
			});
			return arrayForCSV;
		};

		vm.getCountLastLoginPerDayCSVArray = function (data) {
			var arrayForCSV = [];
			for(var login in data) {
				arrayForCSV.push({
					day: data[login][0],
					logins: data[login][1] || 0
				})
			}
			return arrayForCSV;
		};

		(function initController() {
			vm.breadcrumbs = "Recruitment: Campaign Stats";
			vm.activeSubmenu = 'recruitment';
			vm.getStats();
		})();
	}

})();