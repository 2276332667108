(function () {
	'use strict';
	angular
		.module('questiaPlatformApp')
		.factory('SurveyLogicUtils', [SurveyLogicUtils]);

	function SurveyLogicUtils() {
		var DKNA_LABEL = 900;
		return {
			initServerToUi: initServerToUi,
			populateSkipGroupsWithUIData: populateSkipGroupsWithUIData,
			addRecurrent: addRecurrent,
			createMultipleChoiceLimits: createMultipleChoiceLimits,
			insertFirstName: insertFirstName,
			makeHeadline: makeHeadline,
			makeDisabled: makeDisabled,
			makeHidden: makeHidden,
			addMultipleOptionSyntax: addMultipleOptionSyntax,
			findNextAvailableQid: findNextAvailableQid
		};

		function initServerToUiSelect(question) {
			if (!question.selects) return;
			question.disabledItemCheckbox = {};
			question.disabledValuesForItem = {};
			for (var i = 0; i < question.selects.length; i++) {
				var select = question.selects[i];
				for (var j = 0; j < select.options.length; j++) {
					var option = select.options[j];
					if (option.disabled) {
						question.disabledItemCheckbox[option.value] = true;
						if (!question.disabledValuesForItem[option.value]) question.disabledValuesForItem[option.value] = [];
						question.disabledValuesForItem[option.value].push(select.id);
					}
				}
			}
			question.selectsScale = question.selects[0].options.map(function (option) {
				return {
					label: option.label,
					value: option.value
				}
			});
		}

		function initServerToUi(quest, i) {
			var indexOfFirstItem;
			var currentMultipliedQuestionId;
			if (quest.start_date) {
				quest.start_date = moment.utc(quest.start_date);
			}
			if (quest.end_date) {
				quest.end_date = moment.utc(quest.end_date);
			}
			i === undefined && (i = 0);
			while(i < quest.questions.length) {
				//is inherit answer from multiple choice
				if (quest.questions[i].options && quest.questions[i].options.inheritTextFrom) {
					if (currentMultipliedQuestionId !== quest.questions[i].id.split("_")[0]) {
						currentMultipliedQuestionId = quest.questions[i].id.split("_")[0];
						indexOfFirstItem = i;

						var controlQuestionToAppend = angular.copy(quest.questions[i]);
						var parentQuestion = quest.questions.filter(function (obj) {
							return obj.id === controlQuestionToAppend.options.inheritTextFrom.question;
						})[0];
						parentQuestion.choices.filter(function (obj) {
							var regexp = new RegExp(obj.label);
							if (regexp.test(obj.label)) {
								controlQuestionToAppend.question = controlQuestionToAppend.question.replace(regexp, "##" + parentQuestion.id + "##");
							}
						});
						var regexp = new RegExp('#.+#');
						var timesMultiplied = 0;
						for (var j = 0; j < parentQuestion.choices.length; j++) {
							if (!regexp.test(parentQuestion.choices[j].value) && parseInt(parentQuestion.choices[j].value) < DKNA_LABEL) {
								timesMultiplied++;
							}
						}
						controlQuestionToAppend.timesMultiplied = timesMultiplied;
						controlQuestionToAppend.id = controlQuestionToAppend.id.split("_")[0];
						quest.questions.splice(indexOfFirstItem, 0, controlQuestionToAppend);
					}
					else {
						quest.questions.splice(i, 1);
						i--;
						continue;
					}
				}
				if (quest.questions[i].correlationArrayJson) {
					quest.questions[i].correlationArray = JSON.parse(quest.questions[i].correlationArrayJson);
					//add choices in the correlation panel
					for (var y = 0; y < quest.questions[i].correlationArray.length; y++) {
						var correlationChoiceGroup = quest.questions[i].correlationArray[y];
						for (var yy = 0; yy < correlationChoiceGroup.correlationGroup.length; yy++) {
							var correlationGroup = correlationChoiceGroup.correlationGroup[yy];
							for (var yyy = 0; yyy < correlationGroup.correlationArray.length; yyy++) {
								if (!correlationGroup.correlationArray[yyy].question_id) {
									continue;
								}
								var correlationQuestion = quest.questions.filter(function (q) {
									return q.id === correlationGroup.correlationArray[yyy].question_id;
								});
								if (correlationQuestion[0].type === "selects") {
									correlationGroup.correlationArray[yyy].correlationSelects = correlationQuestion[0].selects;
									correlationGroup.correlationArray[yyy].chosenSelectsScale = correlationQuestion[0].selectsScale;
								}
								else {
									correlationGroup.correlationArray[yyy].correlationQuestionChoices = correlationQuestion[0].choices;
								}
							}
						}
					}
				}
				if (quest.questions[i].type === "selects") {
					initServerToUiSelect(quest.questions[i]);
				}
				else if (quest.questions[i].type === "quote") {
					var qt = quest.questions[i].question.split(' | ');
					quest.questions[i].question = qt[0];
					quest.questions[i].quoteSignature = qt[1];
				}
				if (quest.questions[i].options && quest.questions[i].type === 'multipleChoice') {
					if (quest.questions[i].options.multiplemax) {
						if (!quest.questions[i].multipleOptions) quest.questions[i].multipleOptions = {};
						quest.questions[i].multipleOptions.choiceLimitType = "multiplemax";
						quest.questions[i].multipleOptions.choiceLimitValue = quest.questions[i].options.multiplemax;
					}
					else if (quest.questions[i].options.multiplemin) {
						if (!quest.questions[i].multipleOptions) quest.questions[i].multipleOptions = {};
						quest.questions[i].multipleOptions.choiceLimitType = "multiplemin";
						quest.questions[i].multipleOptions.choiceLimitValue = quest.questions[i].options.multiplemin;
					}
					else if (quest.questions[i].options.multiplefixed) {
						if (!quest.questions[i].multipleOptions) quest.questions[i].multipleOptions = {};
						quest.questions[i].multipleOptions.choiceLimitType = "multiplefixed";
						quest.questions[i].multipleOptions.choiceLimitValue = quest.questions[i].options.multiplefixed;
					}
				}
				if (quest.questions[i].options) {
					var regex = new RegExp("#H#[-0-9]+");
					var splitDisabledOptions = null;
					var splitHiddenOptions = null;
					if (quest.questions[i].options.disabledOptions) {
						splitDisabledOptions = quest.questions[i].options.disabledOptions.split(",")
					}
					if (quest.questions[i].options.hiddenOptions) {
						splitHiddenOptions = quest.questions[i].options.hiddenOptions.split(",")
					}
					if (quest.questions[i].choices && quest.questions[i].choices.length > 0) {
						for (var xx = 0; xx < quest.questions[i].choices.length; xx++) {
							if (splitDisabledOptions && splitDisabledOptions.indexOf(quest.questions[i].choices[xx].value) !== -1) {
								quest.questions[i].choices[xx].specialState = 'disabled';
								quest.questions[i].choices[xx].disabledOption = true;
							}
							if (splitHiddenOptions && splitHiddenOptions.indexOf(quest.questions[i].choices[xx].value) !== -1) {
								quest.questions[i].choices[xx].specialState = 'hidden';
								quest.questions[i].choices[xx].hiddenOption = true;
							}
							if (regex.test(quest.questions[i].choices[xx].value)) {
								quest.questions[i].choices[xx].specialState = 'headline';
								quest.questions[i].choices[xx].headlineOption = true;
							}
						}
					}
					if (quest.questions[i].options.pointsChoiceVal && quest.questions[i].options.pointsChoiceValCond) {
						if (['eq_multi','not_multi','not_intersect'].indexOf(quest.questions[i].options.pointsChoiceValCond) !== -1)
							quest.questions[i].options.pointsChoiceVal = quest.questions[i].options.pointsChoiceVal.split("|");
						else if (quest.questions[i].options.pointsChoiceValCond === 'interval') {
							let intervalLimits = quest.questions[i].options.pointsChoiceVal.split("-");
							quest.questions[i].options.minIntervalValue = parseInt(intervalLimits[0]);
							quest.questions[i].options.maxIntervalValue = parseInt(intervalLimits[1]);
						}
					}
				}
				i++;
			}
			return quest;

		}

		function populateSkipGroupsWithUIData(question, quest) {
			if (question.skipGroup && question.skipGroup.length > 0) {
				for (let x = 0; x < question.skipGroup.length; x++) {
					for (let xx = 0; xx < question.skipGroup[x].skipArray.length; xx++) {
						if (angular.equals(question.skipGroup[x].skipArray[xx], {})) continue;

						const dependentQuestion = quest.questions.filter(function (q) {
							return q.id === question.skipGroup[x].skipArray[xx].question_id;
						});
						if (dependentQuestion[0].type === "selects") {
							question.skipGroup[x].skipArray[xx].skipSelects = dependentQuestion[0].selects;
							question.skipGroup[x].skipArray[xx].chosenSelectsScale = dependentQuestion[0].selectsScale;
						}
						else {
							question.skipGroup[x].skipArray[xx].skipQuestionChoices = dependentQuestion[0].choices;
						}
					}
				}
			}
		}

		function addRecurrent(questions, index, type) {
			if (!questions[index].options && type !== "delete") {
				questions[index].options = {};
			}
			if (type === "delete") {
				delete questions[index].options['insertRecurrent'];
				return questions;
			}
			if (type === "asl" || type === "vote") {

				//check if there is any question with ASL+VOTE option
				var questionWithBothOptionsAlreadyAdded = questions.filter(function (question) {
					if (question.options) {
						return question.options['insertRecurrent'] === "all";
					}
				});

				//if we have a question with both asl+vote options, check to see type of recurrent question user chose to add to a new question
				if (questionWithBothOptionsAlreadyAdded && questionWithBothOptionsAlreadyAdded.length === 1) {
					if (type === "vote") {
						//user wants to add vote recurrent after ANOTHER question, change questionWithBothOptions to only have "asl" as option
						questionWithBothOptionsAlreadyAdded[0].options['insertRecurrent'] = "asl";
					}
					else {
						//user wants to add ASL recurrent after ANOTHER question, change questionWithBothOptions to only have "vote" as option
						questionWithBothOptionsAlreadyAdded[0].options['insertRecurrent'] = "vote";
					}
				}

				//if there is no question with both ASL+VOTE added, check to see if we have any question with either ASL or VOTE
				else {
					var questionWithTypeOptionAlreadyAdded = questions.filter(function (question) {
						if (question.options) {
							return question.options['insertRecurrent'] === type;
						}
					});
					//delete it, since we're going to add it to another question
					if (questionWithTypeOptionAlreadyAdded && questionWithTypeOptionAlreadyAdded.length === 1) {
						delete questionWithTypeOptionAlreadyAdded[0].options['insertRecurrent'];
					}
				}

				//if question has vote option and user wants to add asl, or question has asl and user wants to add vote, change to "all" to insert both questions AFTER this question
				if (questions[index].options['insertRecurrent'] === "vote" && type === "asl" || questions[index].options['insertRecurrent'] === "asl" && type === "vote") {
					questions[index].options['insertRecurrent'] = "all";
				}
				//else add current type
				else {
					questions[index].options['insertRecurrent'] = type;
				}

			}
			else if (type === "erg") {
				var questionWithErgOptionAlreadyAdded = questions.filter(function (question) {
					if (question.options) {
						return question.options['insertRecurrent'] === "erg";
					}
				});

				if (questionWithErgOptionAlreadyAdded && questionWithErgOptionAlreadyAdded.length === 1) {
					delete questionWithErgOptionAlreadyAdded[0].options['insertRecurrent'];
				}
				else {
					questions[index].options['insertRecurrent'] = type;
				}

			}
			return questions;
		}

		function createMultipleChoiceLimits(question) {
			if (question.multipleOptions.choiceLimitType === 'none') {
				if (question.options) {
					delete question.options.multiplemin;
					delete question.options.multiplemax;
					delete question.options.multiplefixed;
				}
			}
			else {
				if (!question.options) question.options = {};
				var restriction = question.multipleOptions;
				for (var option in question.options) {
					if (question.options.hasOwnProperty(option) && ["multiplemin", "multiplemax", "multiplefixed"].includes(option)) {
						delete question.options[option];
					}
				}
				if (restriction.choiceLimitType !== "none" && question.multipleOptions.choiceLimitValue) {
					question.options[restriction.choiceLimitType] = restriction.choiceLimitValue;
				}
			}
			return question;
		}

		function insertFirstName(q) {
			if (q.question) {
				q.question += '##user_first_name##';
			}
			else {
				q.question = '##user_first_name##';
			}
			if (!q.options) {
				q.options = {
					insertFirstName: "true"
				}
			} else {
				q.options.insertFirstName = "true"
			}
			return q;
		}

		function makeHeadline(choice) {
			choice.headlineOption = !choice.headlineOption;
			if (choice.headlineOption) {
				choice.specialState = "headline";
			}
			//it was cleared
			else {
				delete choice.specialState;
			}

			var regex = new RegExp("#H#[-0-9]+");
			if (choice.headlineOption) {
				if (!regex.test(choice.value)) {
					choice.value = "#H#" + choice.value;
				}
			}
			else {
				choice.value = choice.value.substr("#H#".length);
			}
		}

		function makeDisabled(question, choice) {
			if (!question.options) question.options = {};
			choice.disabledOption = !choice.disabledOption;
			if (choice.disabledOption) {
				choice.specialState = "disabled";
			}
			else {
				delete choice.specialState;
			}
			var disabledOptions = [];
			for (var i = 0; i < question.choices.length; i++) {
				if (question.choices[i].disabledOption) {
					disabledOptions.push(question.choices[i].value);
				}
			}
			if (disabledOptions.length === 0) {
				delete question.options.disabledOptions;
			}
			else {
				question.options.disabledOptions = disabledOptions.join(",");
			}
		}

		function makeHidden(question, choice) {
			if (!question.options) question.options = {};
			choice.hiddenOption = !choice.hiddenOption;
			if (choice.hiddenOption) {
				choice.specialState = "hidden";
			}
			else {
				delete choice.specialState;
			}
			var hiddenOptions = [];
			for (var i = 0; i < question.choices.length; i++) {
				if (question.choices[i].hiddenOption) {
					hiddenOptions.push(question.choices[i].value);
				}
			}
			if (hiddenOptions.length === 0) {
				delete question.options.hiddenOptions;
			}
			else {
				question.options.hiddenOptions = hiddenOptions.join(",");
			}
		}

		function addMultipleOptionSyntax(question, type, qid) {
			if (qid) {
				question.multipleOptions.choiceLimitValue = type + "##" + qid;
			}
			else {
				question.multipleOptions.choiceLimitValue = "";
			}
			return createMultipleChoiceLimits(question);
		}

		function findNextAvailableQid(questions) {
			let count = 1, found = false;
			do {
				found = true;
				for (let j in questions) {
					if (questions[j].id && questions[j].id.toUpperCase() === ("Q" + count)) {
						found = false;
						count++;
					}
				}
			} while (!found);
			return "Q" + count;
		}
	}
})();

