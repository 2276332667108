(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('SurveyValidationService', ['CloneService', 'SkipLogicService', 'FilterUtils', 'QuestiaUtils', SurveyValidationService]);

	function SurveyValidationService(CloneService, SkipLogicService, FilterUtils, QuestiaUtils) {
		var DKNA_LABEL = 900;
		return {
			validateSurvey: validateSurvey,
			performBeforeSaveManipulation: performBeforeSaveManipulation
		};

		function checkDuplicateInObject(propertyName, inputArray) {
			var seenDuplicate = false,
				testObject = {};
			if (inputArray[0][propertyName] === undefined) {
				return seenDuplicate;
			}

			inputArray.map(function(item) {
				var itemPropertyName = item[propertyName];
				if (itemPropertyName in testObject) {
					testObject[itemPropertyName].duplicate = true;
					item.duplicate = true;
					seenDuplicate = true;
				}
				else {
					testObject[itemPropertyName] = item;
					delete item.duplicate;
				}
			});

			return seenDuplicate;
		}

		function doesQuestionExistByQID(qid, questions) {
            var found = questions.find(function (elem) {
				return elem.id === qid
            });
            return typeof found !== "undefined";
        }
		function isNaturalNumber(n) {
			n = n.toString(); // force the value incase it is not
			var n1 = Math.abs(n),
				n2 = parseInt(n, 10);
			return !isNaN(n1) && n2 === n1 && n1.toString() === n;
		}

		function cleanSpecialChars(string) {
			return string
				.replace(/&#8206;/g, "")
				.replace(/&#8207;/g, "");
		}

        function isRelationQuestionBeforeCurrentQuestion(parentQID, childQID, questions) {
			var parentIndex = questions.findIndex(function(elem){
				return elem.id === parentQID;
			});
			var childIndex = questions.findIndex(function(elem){
                return elem.id === childQID;
            });
			return parentIndex > childIndex;
        }

		function checkDuplicateSelectsIDs(quest) {
			var selectsIDsList=[];
			var map = new Map();

			for (const question of quest.questions) {
				var temporalySelectList = [];
				if (["selects", "ranking", "multipleOpen"].includes(question.type)) {
					for (const select of question.selects) {
						selectsIDsList = selectsIDsList.concat(select.id);
						temporalySelectList = temporalySelectList.concat(select.id);
					}
				}
				map.set(question.id, temporalySelectList);
			}

			var duplicate = selectsIDsList.filter((item, index) =>  selectsIDsList.indexOf(item) != index);
			let toReturn = new Map();

			for (var i = 0; i < duplicate.length; i++) {
				var temporaryList = [];
				map.forEach(function (value, key) {
					if (value.includes(duplicate[i])) {
						temporaryList = temporaryList.concat(key);
					}
				});
				toReturn.set(duplicate[i], temporaryList)
			}
			return toReturn;
		}

		function validateSurvey(vm) {
			var result = '<ul style="list-style-type:none">';

			if (vm.quest.title === undefined) {
				result += "<li>Title must not be empty</li>";
			}
			if (vm.quest.description === undefined) {
				result += "<li>Description must not be empty</li>";
			}
			if (vm.quest.internal_id === undefined) {
				result += "<li>Internal ID must not be empty</li>";
			}
			if (vm.quest.points === undefined) {
				result += "<li>Points must not be empty</li>";
			}
			else if (vm.quest.points < 0) {
				result += "<li>Points must not be negative</li>";
			}
			if (vm.quest.tickets === undefined && vm.ticketsEnabled) {
				result += "<li>Tickets must not be empty</li>";
			}
			else if (vm.quest.tickets < 0) {
				result += "<li>Tickets must not be negative</li>";
			}
			if (vm.quest.start_date && vm.quest.end_date) {
				if (vm.quest.start_date.toJSON() > vm.quest.end_date.toJSON()) {
					result += "<li>Start date must be more recent than end date</li>";
				}
			}
			if (vm.quest.completionTime === undefined && vm.quest.type !== "forecast") {
				result += "<li>Please add a value (in minutes) for the estimated time " +
					"to complete the survey in Completion Time tab</li>";
			}

			if(typeof vm.quest.respondents === 'undefined' || vm.quest.respondents === null){
				if(vm.hasAgeQuotas || vm.hasSexQuotas || vm.hasLocationQuotas) {
					result += "<li>You must specify the number of respondents if you specify quotas</li>";
				}
			}

			if(vm.quest.type && vm.quest.type === "forecast") {
				const listOfPredictorEventTypes = ["forecast_sport", "forecast_politic", "forecast_economic", "forecast_entertainment"];
				if(!listOfPredictorEventTypes.includes(vm.quest.title)){
					result += "<li>If quest type is forecast, title must be one of the following: forecast_sport, forecast_politic, forecast_economic, forecast_entertainment</li>";
				}
			}

			if(checkDuplicateInObject("id", vm.quest.questions)) {
				result += "<li>There are more questions with the same id</li>";
			}

			var profileQuotasPercentSum = 0;
			var oneHundredPercentProfileQuota = false;
			if (vm.quest.ageQuotas.length != 0) {
				for (var i = 0; i < vm.quest.ageQuotas.length; i ++) {
					if (Math.floor(vm.quest.ageQuotas[i].percent) == 100)
						oneHundredPercentProfileQuota = true;
					profileQuotasPercentSum += vm.quest.ageQuotas[i].percent;
				}
				if(!vm.quest.ageQuotas[0].percent_deviation || (vm.quest.ageQuotas[0].percent_deviation < 0 || vm.quest.ageQuotas[0].percent_deviation > 20)) {
					result += "<li>Survey has invalid age quotas percent deviation, must be between 0 and 20!</li>";
				}
				if(Math.floor(profileQuotasPercentSum)!==100){
					result += "<li>Survey has age quotas percents which don't sum up to 100</li>";
				}
			}

			profileQuotasPercentSum = 0;
			if (vm.quest.sexQuotas.length != 0) {
				for (var i = 0; i < vm.quest.sexQuotas.length; i ++) {
					if (Math.floor(vm.quest.sexQuotas[i].percent) == 100)
						oneHundredPercentProfileQuota = true;
					profileQuotasPercentSum += vm.quest.sexQuotas[i].percent;
				}
				if(!vm.quest.sexQuotas[0].percent_deviation || (vm.quest.sexQuotas[0].percent_deviation < 0 || vm.quest.sexQuotas[0].percent_deviation > 20)) {
					result += "<li>Survey has invalid sex quotas percent deviation, must be between 0 and 20!</li>";
				}
				if(Math.floor(profileQuotasPercentSum)!==100){
					result += "<li>Survey has sex quotas percents which don't sum up to 100</li>";
				}
			}

			profileQuotasPercentSum = 0;
			if (vm.quest.locationQuotas.length != 0) {
				for (var i = 0; i < vm.quest.locationQuotas.length; i ++) {
					if (Math.floor(vm.quest.locationQuotas[i].percent) == 100)
						oneHundredPercentProfileQuota = true;
					profileQuotasPercentSum += vm.quest.locationQuotas[i].percent;
				}
				if(!vm.quest.locationQuotas[0].percent_deviation || (vm.quest.locationQuotas[0].percent_deviation < 0 || vm.quest.locationQuotas[0].percent_deviation > 20)) {
					result += "<li>Survey has invalid location quotas percent deviation, must be between 0 and 20!</li>";
				}
				if(Math.floor(profileQuotasPercentSum)!==100){
					result += "<li>Survey has location quotas percents which don't sum up to 100</li>";
				}
			}

			if (oneHundredPercentProfileQuota)
				result += "<li>Survey has profile quotas with percent equal to 100, please use filters instead</li>";

			let map = checkDuplicateSelectsIDs(vm.quest);
			if(map.size >0) {
				map.forEach(function (value, key ) {
						result += "<li>The choice ID " + key + " was found duplicated at " + value + "</li>";
				});
			}

            var quotasExists = false;
			for (var i=0; i < vm.quest.questions.length; i++) {
				var currentQuestionId = vm.quest.questions[i].id === undefined ? (i+1): vm.quest.questions[i].id;
				if (vm.quest.questions[i].id === undefined) {
					result += "<li>Question " + currentQuestionId + " has no Q ID</li>";
				}
                if (vm.quest.questions[i].id !== undefined && !vm.quest.questions[i].id.match(/^[a-z0-9_]+$/i)) {
                    result += "<li>Question " + currentQuestionId + " Q ID must contain only alphanumeric characters and underscores</li>";
                }
				if (vm.quest.questions[i].type === undefined) {
					result += "<li>Question " + currentQuestionId + " has no type</li>";
				}
				if (vm.quest.questions[i].question === undefined) {
					result += "<li>Question " + currentQuestionId + " has no text</li>";
				} else {
                    var splitQuestionText = vm.quest.questions[i].question.split("##");
                    if (splitQuestionText && splitQuestionText.length === 3 && splitQuestionText[1] !== 'user_first_name' && splitQuestionText[1].indexOf(' ') > -1) {
                        result += "<li>Question " + currentQuestionId + " text formatting id '##" + splitQuestionText[1] + "##'  contains a white space</li>";
                    }
				}

				var choicesHideRelationTo = vm.quest.questions[i].choicesHideRelationTo;

				if(typeof choicesHideRelationTo !== "undefined" && choicesHideRelationTo !== "" && !doesQuestionExistByQID(choicesHideRelationTo, vm.quest.questions)) {
                    result += "<li>Question " + currentQuestionId + " cannot have a choices hide relation with " + vm.quest.questions[i].choicesHideRelationTo + " (Q ID inexistent)</li>";
				}

				if(typeof choicesHideRelationTo !== "undefined" && choicesHideRelationTo !== "" &&
					!isRelationQuestionBeforeCurrentQuestion(vm.quest.questions[i].id, choicesHideRelationTo, vm.quest.questions)) {
                    result += "<li>Question " + currentQuestionId + " cannot have a choices hide relation with " + vm.quest.questions[i].choicesHideRelationTo
						+ ", "+ vm.quest.questions[i].choicesHideRelationTo + " must be added before the question that has a relation to it! </li>";
				}

				var currentQuestion = vm.quest.questions[i];

				// If question is predefined, check for unicity
				if (currentQuestion.predefinedQuestion && currentQuestion.id !== undefined) {
					var existingPredefinedQuestion = vm.predefinedQuestions.find(function (question) {
						return question.id === currentQuestionId && question.question_id !== currentQuestion.question_id
					});

					if (typeof existingPredefinedQuestion !== "undefined") {
						result += "<li>Predefined question " + currentQuestionId + " has an ID reserved to another predefined question, please modify the ID! </li>";
					}
				}

                if(currentQuestion.skipGroup && currentQuestion.skipGroup.length > 0) {
                    for (var k = 0; k < currentQuestion.skipGroup.length; k++) {
                        for (var l = 0; l < currentQuestion.skipGroup[k].skipArray.length; l++) {
							var skipArrayElement = currentQuestion.skipGroup[k].skipArray[l];
							var skipArrayQuestionId = skipArrayElement.question_id;
							var skipArrayQuestionType = skipArrayElement.skipQtype;
							if(skipArrayElement.question_id){
                                var exist = false;
                                var questionTypeChanged = false;
                                for(var w = 0 ; w < i; w++){
                                    if(vm.quest.questions[w].id === skipArrayQuestionId){
                                        exist = true;
                                        if(vm.quest.questions[w].type !== skipArrayQuestionType){
                                            questionTypeChanged = true;
										}
                                        break;
                                    }
                                }
                                if(!exist){
                                    result += "<li>Question " + currentQuestionId +
                                        " has a skip referencing a question that was deleted/changed id. Please reconfigure its logic</li>";
                                    break;
                                }
                                if(questionTypeChanged) {
                                    result += "<li>Question " + currentQuestionId +
                                        " has a skip condition on question " + skipArrayQuestionId + ". Skip condition is invalid due to " + skipArrayQuestionId + " having type changed. " +
										"Please delete the old skip condition and reconfigure a new one.</li>";
                                    break;
                                }
							}
                        }
                    }
                }

				if(currentQuestion.correlationArray && currentQuestion.correlationArray.length > 0) {
					for (var k = 0; k < currentQuestion.correlationArray.length; k++) {
						/* Check for missing main choices */
						var mainChoice = currentQuestion.correlationArray[k].mainChoice;
						if (mainChoice) {
							for (var mainChoiceIdx = 0; mainChoiceIdx < mainChoice.length; mainChoiceIdx ++) {
								let choiceIndex = currentQuestion.choices.findIndex(function (element) {
									return (element.value == mainChoice[mainChoiceIdx])
								});
								if (choiceIndex === -1) {
									result += "<li>Question " + currentQuestionId +
										" has a correlation referencing a main choice that was deleted. Please reconfigure its logic</li>";
									break;
								}
							}
						}
						/* Then check correlation groups */
						for (var l = 0; l < currentQuestion.correlationArray[k].correlationGroup.length; l++) {
							var currentCorrelationGroup = currentQuestion.correlationArray[k].correlationGroup[l]
							for(var w = 0 ; w < currentCorrelationGroup.correlationArray.length; w++) {
								var correlationArrayQuestId = currentCorrelationGroup.correlationArray[w].question_id;
								var correlationArrayQuestType = currentCorrelationGroup.correlationArray[w].correlationQtype;
								var correlationArrayValue = currentCorrelationGroup.correlationArray[w].value;
								var correlationArrayQuestionChoices = currentCorrelationGroup.correlationArray[w].correlationQuestionChoices;
								if(correlationArrayQuestId){
									var exist = false;
									var questionTypeChanged = false;
									var correlatedChoiceMissing = false;
									for(var y = 0 ; y < i; y++){
										if(vm.quest.questions[y].id === correlationArrayQuestId){
											exist = true;
											if(vm.quest.questions[y].type !== correlationArrayQuestType){
												questionTypeChanged = true;
											}
											/* Check for missing correlated choices */
											if(typeof correlationArrayValue === "string" || typeof correlationArrayValue === "number") {
												let choiceIndex = correlationArrayQuestionChoices.findIndex(function (element) {
													return (element.value == correlationArrayValue);
												});
												if (choiceIndex === -1)
													correlatedChoiceMissing = true;
											}
											else {
												for (var corrIdx = 0; corrIdx < correlationArrayValue.length; corrIdx++) {
													let choiceIndex = correlationArrayQuestionChoices.findIndex(function (element) {
														return (element.value == correlationArrayValue[corrIdx]);
													});
													if (choiceIndex === -1) {
														correlatedChoiceMissing = true;
														break;
													}
												}
											}
											break;
										}
									}
									if(!exist){
										result += "<li>Question " + currentQuestionId +
											" has a correlation referencing a question that was deleted/changed id. Please reconfigure its logic</li>";
										break;
									}
									if(questionTypeChanged) {
										result += "<li>Question " + currentQuestionId +
											" has a correlation on question " + correlationArrayQuestId + ". Correlation is invalid due to " + correlationArrayQuestId + " having type changed. " +
											"Please delete the old correlation and reconfigure a new one.</li>";
										break;
									}
									if(correlatedChoiceMissing) {
										result += "<li>Question " + currentQuestionId +
											" has a correlation referencing a correlated choice that was deleted. Please reconfigure its logic</li>";
										break;
									}
								}
							}
						}
					}
				}

                if(currentQuestion.selects && currentQuestion.selects.length > 0){
                	var surveyQuestions = vm.quest.questions;
					for(var selectsCounter = 0; selectsCounter < currentQuestion.selects.length; selectsCounter++){
						for(var surveyQuestion  = 0; surveyQuestion < surveyQuestions.length; surveyQuestion++){
							if(currentQuestion.selects[selectsCounter].id === surveyQuestions[surveyQuestion].id){
                                result += "<li>In the items list of the matrix question with id " + currentQuestionId + "" +
									" ,the item with id " +surveyQuestions[surveyQuestion].id+ " is also attributed to a question."+"</li>";
							}
						}
					}
				}

                if(currentQuestion.options && currentQuestion.options.hasQuota === 'false' && currentQuestion.quotas){
                    delete currentQuestion.quotas;
                }

                if(currentQuestion.options && currentQuestion.options.showOnlyOne === ""){
					delete currentQuestion.options.showOnlyOne;
				}

                if(currentQuestion.options && currentQuestion.options.hasQuota === 'true' && currentQuestion.quotas){
                	quotasExists = true;
				}

				if(currentQuestion.options && currentQuestion.options.hasQuota ==='true'
					&& (!currentQuestion.quotas || currentQuestion.quotas.length === 0)){
                    result += "<li>You must specify the quotas for the question with id "+currentQuestionId+"</li>";
				}

				if(currentQuestion.options && currentQuestion.options.hasQuota==='true'
				&& currentQuestion.options.hasRegionQuota==='true' && !currentQuestion.options.quotaDivision){
                    result += "<li>You must specify the division for the city/county question with id "+currentQuestionId+"</li>";
				}

                //validate quota percents for each kind of questions with quotas
                if(currentQuestion.quotas && currentQuestion.options && currentQuestion.options.hasQuota === 'true'){
                	if(currentQuestion.type === 'singleChoice'){
                        var percentSum = 0;
                        var objectCounter = 0;
                        for(var key in currentQuestion.quotas){
                            if(currentQuestion.quotas.hasOwnProperty(key)){
                                var quota_question = currentQuestion.quotas[key];
                                if(quota_question === null){
                                    result += "<li>Question " + currentQuestionId +
                                        " an empty quota</li>";
								}
                                percentSum+=quota_question.percent;
                                objectCounter++;
                            }
                        }
                        if(Math.floor(percentSum)!==100){
                            result += "<li>Question " + currentQuestionId +
                                " has quotas percents which don't sum up to 100</li>";
                        }
                        if(objectCounter!==currentQuestion.choices.length || (currentQuestion.options.hasQuota === 'true' && Object.keys(currentQuestion.quotas).length===0)){
                            result += "<li>Question " + currentQuestionId +
                                " has unspecified quotas</li>";
						}
					}
					if(currentQuestion.type === 'combodate' || currentQuestion.type ==='cityCounty'){
                		var percentSum = 0;
                		var disjunctAgePairs = true;
                		var orderedAgePairs =  true;
                		for(var counter = 0 ; counter < currentQuestion.quotas.length;  counter++){
                            percentSum +=currentQuestion.quotas[counter].percent;
                            var lowRange = currentQuestion.quotas[counter].lowerRange;
                            var upRange = currentQuestion.quotas[counter].upperRange;
                            if(lowRange!==undefined && upRange!==undefined && lowRange === upRange){
                            	disjunctAgePairs = false;
							}
                            if(lowRange!==undefined && upRange!==undefined && lowRange > upRange){
                                orderedAgePairs = false;
                            }
						}
                        if(Math.floor(percentSum)!==100){
                            result += "<li>Question " + currentQuestionId +
                                " has quotas percents which don't sum up to 100</li>";
                        }
                        if(!disjunctAgePairs){
                            result += "<li>Combodate Question with ID: " + currentQuestionId +
                                " has an age group which contains non-disjunct values</li>";
						}
                        if(!orderedAgePairs){
                            result += "<li>Combodate Question with ID: " + currentQuestionId +
                                " has an age group which contains a lower limit greater than an upper limit</li>";
                        }

					}
				}

				if(currentQuestion.options && currentQuestion.options.pointsChoiceValCond === "-1"){
					delete currentQuestion.options.pointsChoiceValCond;
					if(currentQuestion.options.pointsChoiceVal) delete currentQuestion.options.pointsChoiceVal;
				}

                if(currentQuestion.options && currentQuestion.options.pointsChoiceValCond) {
	                var screenOutCondition = currentQuestion.options.pointsChoiceValCond;
	                var screenOutValue = currentQuestion.options.pointsChoiceVal;
	                var isScreenOutValid = true;

	                if (!screenOutValue) {
	                	isScreenOutValid = false;
					}
	                else {
						/* eq_multi, not_multi and not_intersect conditions require a non-empty array */
						if (['eq_multi', 'not_multi', 'not_intersect'].indexOf(screenOutCondition) !== -1)
						    isScreenOutValid = (screenOutValue.length > 0);
						/* not_range needs a valid range */
						if (screenOutCondition === 'not_range') {
							if (!screenOutValue)
								isScreenOutValid = false;
							else {
								var rangeEnds = screenOutValue.split('\-');
								if (rangeEnds.length !== 2)
									isScreenOutValid = false;
								/* Check both range ends for whitespaces */
								else {
									var regex = new RegExp("^(?!\s*$).+");
									isScreenOutValid = (regex.test(rangeEnds[0]) && regex.test(rangeEnds[1]));
								}

							}
						}
						/* Combodate screenout conditions start */
						else if (screenOutCondition === 'interval') {
							if (!currentQuestion.options.minIntervalValue) {
								result += "<li>Question " + currentQuestionId + " has an Interval screenout condition, but a missing minimum limit." +  "</li>";
								isScreenOutValid = false;
							}
							if (!currentQuestion.options.maxIntervalValue) {
								result += "<li>Question " + currentQuestionId + " has an Interval screenout condition, but a missing maximum limit." +  "</li>";
								isScreenOutValid = false;
							}

							if (currentQuestion.options.minIntervalValue && currentQuestion.options.maxIntervalValue) {
								let minIntervalValue = parseInt(currentQuestion.options.minIntervalValue);
								let maxIntervalValue = parseInt(currentQuestion.options.maxIntervalValue);

								let minIntervalValueValid = !isNaN(minIntervalValue) && minIntervalValue >= 1;
								let maxIntervalValueValid = !isNaN(maxIntervalValue) && maxIntervalValue >= 1;

								if (!minIntervalValueValid) {
									result += "<li>Question " + currentQuestionId + " has an Interval screenout condition, but an invalid minimum limit : " + currentQuestion.options.minIntervalValue + "</li>";
									isScreenOutValid = false;
								}
								if (!maxIntervalValueValid) {
									result += "<li>Question " + currentQuestionId + " has an Interval screenout condition, but an invalid maximum limit : " + currentQuestion.options.maxIntervalValue + "</li>";
									isScreenOutValid = false;
								}
								if (minIntervalValueValid && maxIntervalValueValid && (minIntervalValue > maxIntervalValue)) {
									result += "<li>Question " + currentQuestionId + " has an Interval screenout condition, but a maximum limit lower than the minimum one." +  "</li>";
								}
							}
						}
						else if (['min', 'max'].indexOf(screenOutCondition) !== -1) {
							let screenOutValueInt = parseInt(screenOutValue);
							if (isNaN(screenOutValueInt) || screenOutValueInt < 1) {
								isScreenOutValid = false;
							}
						}
						/* Combodate screenout conditions end */
					}

					if (!isScreenOutValid) {
						if (['one_of_regions', 'not_one_of_regions'].indexOf(screenOutCondition) !== -1) {
							if (typeof screenOutValue === "undefined") {
								result += "<li>Question " + currentQuestionId + " has a region screenout condition, but no regions selected.</li>";
							}
						}
						else if (screenOutCondition !== 'interval') {
							result += "<li>Question " + currentQuestionId + " has " + (screenOutValue ? "an invalid screen-out value " + screenOutValue: "no screen-out value")
								+ " for condition " + screenOutCondition +  "</li>";
						}
					}
                }
				if (currentQuestion.type === "multipleChoice" && typeof currentQuestion.options.inheritFrom === "undefined") {
					const noOfOpenTextChoices = currentQuestion.choices.reduce((noOfOpenText, choice) => {
						return noOfOpenText + (choice.label.endsWith("...") ? 1 : 0)
					}, 0);
					if (noOfOpenTextChoices > 1) {
						result += "<li>The question " + currentQuestionId + " have more than one open text choice</li>";
					}
				}

				if (currentQuestion.type === "multipleChoice" || currentQuestion.type === "singleChoice") {
					if(checkDuplicateInObject("value", currentQuestion.choices)) {
						result += "<li>At question " + currentQuestionId + " there are more choices with the same value</li>";
					}
					for (var j=0; j < currentQuestion.choices.length; j++) {
						if (!currentQuestion.choices[j].label && !currentQuestion.options.inheritFrom) {
							result += "<li>Choice " + (j+1) + " from question " + currentQuestionId + " has no label</li>";
						}
						if (!currentQuestion.choices[j].value && !currentQuestion.options.inheritFrom) {
							result += "<li>Choice " + (j+1) + " from question " + currentQuestionId + " has no value</li>";
						}
						if (!isNaturalNumber(currentQuestion.choices[j].value)) {
							result += "<li>Choice " + (j+1) + " from question " + currentQuestionId + " should be integer >= 0</li>";
						}
					}
				}
				else if (currentQuestion.type === "quote") {
					if (!currentQuestion.quoteSignature) {
						result += "<li>Question " + currentQuestionId + " has no quote signature</li>";
					}

				}
				else if (currentQuestion.type === "selects") {
					if(checkDuplicateInObject("id", currentQuestion.selects)) {
						result += "<li>At question " + currentQuestionId + " there are more items with the same id</li>";
					}
					if(checkDuplicateInObject("value", currentQuestion.selectsScale)) {
						result += "<li>At question " + currentQuestionId + " there are more scales with the same value</li>";
					}
					for (var j=0; j < currentQuestion.selects.length; j++) {
						if (!currentQuestion.selects[j].label && !currentQuestion.options.inheritItemsFrom) {
							result += "<li>Item " + (j+1) + " from question " + currentQuestionId + " has no label</li>";
						}
						if (!currentQuestion.selects[j].id && !currentQuestion.options.inheritItemsFrom) {
							result += "<li>Item " + (j+1) + " from question " + currentQuestionId + " has no id</li>";
						}
					}
					for (var j=0; j < currentQuestion.selectsScale.length; j++) {
						if (!currentQuestion.selectsScale[j].label) {
							result += "<li>Scale " + (j+1) + " from question " + currentQuestionId + " has no label</li>";
						}
						if (!currentQuestion.selectsScale[j].value) {
							result += "<li>Scale " + (j+1) + " from question " + currentQuestionId + " has no value</li>";
						}
						if (!isNaturalNumber(currentQuestion.selectsScale[j].value )) {
							result += "<li>Scale " + (j+1) + " from question " + currentQuestionId + " should be integer >= 0</li>";
						}
					}
				}
				else if (currentQuestion.type === "ranking") {
					if(checkDuplicateInObject("id", currentQuestion.selects)) {
						result += "<li>At question " + currentQuestionId + " there are more items with the same id</li>";
					}
					if(currentQuestion.selects.length < 2){
						result += "<li>Ranking question at " + currentQuestionId + " has less than 2 items</li>";
					}
					for (var j=0; j < currentQuestion.selects.length; j++) {
						if (!currentQuestion.selects[j].label && !currentQuestion.options.inheritItemsFrom) {
							result += "<li>Item " + (j+1) + " from question " + currentQuestionId + " has no label</li>";
						}
						if (!currentQuestion.selects[j].id && !currentQuestion.options.inheritItemsFrom) {
							result += "<li>Item " + (j+1) + " from question " + currentQuestionId + " has no id</li>";
						}
					}
				}
				else if (currentQuestion.type === "multipleOpen") {
					if(checkDuplicateInObject("id", currentQuestion.selects)) {
						result += "<li>At question " + currentQuestionId + " there are more items with the same id</li>";
					}
					for (var j=0; j < currentQuestion.selects.length; j++) {
						if (!currentQuestion.selects[j].label && !currentQuestion.options.inheritItemsFrom) {
							result += "<li>Item " + (j+1) + " from question " + currentQuestionId + " has no label</li>";
						}
						if (!currentQuestion.selects[j].id && !currentQuestion.options.inheritItemsFrom) {
							result += "<li>Item " + (j+1) + " from question " + currentQuestionId + " has no id</li>";
						}
					}
				}
			}

			if(quotasExists && !vm.quest.respondents){
                result += "<li>You must specify the number of respondents if you set quotas</li>";
			}

			if ((vm.filters === undefined ||  vm.filters.user === undefined || vm.filters.user.maxPoints === undefined) &&
				(vm.quest.type != 'forecast') ){
				result += "<li>Please add a value for Max points in User Profile Filters tab.</li>";
			}

			if (result === '<ul style="list-style-type:none">') {
				return "";
			}
			else {
				return result + "</ul>";
			}
		}

		function performBeforeSaveManipulation(quest, filters) {
			var questCopy = angular.copy(quest);
			for (var i = 0; i < questCopy.questions.length; i++) {
				var question = questCopy.questions[i];
				//FIX textangular issue with adding <br> at the end
				question.question = question.question.replace(/<br\/>(?!.*<br\/>)/, '');
				if (!question.options) {
					question.options = {};
				}

				var currentQuestion = question;

				if (currentQuestion.skipGroup && currentQuestion.skipGroup.length > 0) {
					for (var k = 0; k < currentQuestion.skipGroup.length; k++) {
						for (var l = 0; l < currentQuestion.skipGroup[k].skipArray.length; l++) {
							var skipArrayElement = currentQuestion.skipGroup[k].skipArray[l];
							if (skipArrayElement.skipQuestionChoices)
								delete skipArrayElement.skipQuestionChoices;
						}
					}
				}

				if(currentQuestion.correlationArray && currentQuestion.correlationArray.length > 0) {
					for (var k = 0; k < currentQuestion.correlationArray.length; k++) {
						for(var l = 0; l < currentQuestion.correlationArray[k].correlationGroup.length; l++) {
							let correlationGroupElement = currentQuestion.correlationArray[k].correlationGroup[l];
							for(var w = 0 ; w < correlationGroupElement.correlationArray.length; w++){
								var correlationArrayElement = correlationGroupElement.correlationArray[w];
								if(correlationArrayElement.correlationQuestionChoices)
									delete correlationArrayElement.correlationQuestionChoices;
							}
						}
					}
				}

                if(currentQuestion.quotas && Object.keys(currentQuestion.quotas).length>0){
                	if(currentQuestion.type === 'singleChoice'){
                        for(var key in currentQuestion.quotas){
                            if(currentQuestion.quotas.hasOwnProperty(key)){
                                if(currentQuestion.options.quotasDeviation){
                                    currentQuestion.quotas[key].percent_deviation = currentQuestion.options.quotasDeviation;
								}
                            }
                        }
					}
                    if(currentQuestion.type ==='combodate') {
                        var quotas = {};
                        var quotasFromOptionsDate = currentQuestion.options.quotasDeviation;
                        var deviationDate = quotasFromOptionsDate ? quotasFromOptionsDate : 0;
                        for (var quotasCounter = 0; quotasCounter < currentQuestion.quotas.length; quotasCounter++) {
                            var ageRange = currentQuestion.quotas[quotasCounter].lowerRange + '-' + currentQuestion.quotas[quotasCounter].upperRange;
                            quotas[ageRange] = {
                                percent: currentQuestion.quotas[quotasCounter].percent,
                                percent_deviation: deviationDate
                            };

                        }
                        currentQuestion.quotas = quotas;
                    }
                    if(currentQuestion.type === 'cityCounty'){
                    	if(currentQuestion.options && currentQuestion.options.quotaDivision){
                            var quotas = {};
                            var quotasFromOptionsCityCounty = currentQuestion.options.quotasDeviation;
                            for (var quotasCounter = 0; quotasCounter < currentQuestion.quotas.length; quotasCounter++) {
                                var regionData = currentQuestion.quotas[quotasCounter].region_data;
                                if(regionData){
                                    var quotaKey = currentQuestion.quotas[quotasCounter].region_data.region_id;
                                    quotas[quotaKey] = {
                                        percent: currentQuestion.quotas[quotasCounter].percent,
                                        percent_deviation: quotasFromOptionsCityCounty,
                                        percent_filled: currentQuestion.quotas[quotasCounter].percent_filled
                                    };
                                }
                            }
                            if(currentQuestion.options.isSimpleRuralUrbal){
                            	delete currentQuestion.options.isSimpleRuralUrbal;
							}
                            currentQuestion.quotas = quotas;
						}else{
                            var quotas = {};
                            var quotasFromOptionsCityCounty = currentQuestion.options.quotasDeviation;
                            var deviationCityCounty = quotasFromOptionsCityCounty ? quotasFromOptionsCityCounty : 0;
                            var simpleRuralUrban = [{id:0,label:'Rural Simple'},{id:1,label:'Urban Simple'}];
                            for (var quotasCounter = 0; quotasCounter < simpleRuralUrban.length; quotasCounter++) {
                                var foundQuota = currentQuestion.quotas.find(x=>x.categoryName === simpleRuralUrban[quotasCounter].label);
                                if(foundQuota){
                                    quotas[simpleRuralUrban[quotasCounter].id] = {
                                        percent: foundQuota.percent,
                                        percent_deviation: deviationCityCounty
                                    };
                                }
                            }
                            currentQuestion.options.isSimpleRuralUrbal = 'true';
                            if(currentQuestion.options.hasRegionQuota){
                            	delete currentQuestion.options.hasRegionQuota;
							}
                            currentQuestion.quotas = quotas;
						}
					}
                }

				var screenOutCondition = question.options.pointsChoiceValCond;
				if (screenOutCondition) {
					if (['eq_multi','not_multi','not_intersect'].indexOf(screenOutCondition) !== -1) {
						var screenOutValue = question.options.pointsChoiceVal;
						if (screenOutValue.constructor === Array)
							screenOutValue = screenOutValue.join('\|');
						question.options.pointsChoiceVal = screenOutValue;
					}
					else if (screenOutCondition === 'interval' && question.type === 'combodate') {
						question.options.pointsChoiceVal = question.options.minIntervalValue + "-" + question.options.maxIntervalValue;
					}
				}

				delete question.options.minIntervalValue;
				delete question.options.maxIntervalValue;

				if(question.choicesHideRelationTo === "") {
                    delete question.choicesHideRelationTo;
				}

				if (question.options.inheritFrom) {
					question = CloneService.mergeChoices(question, questCopy.questions, question.options.inheritFrom);
				}
				if (question.options.inheritItemsFrom) {
					// question = CloneService.cloneMatrixItems(question, questCopy.questions, question.options.inheritItemsFrom);
					question = CloneService.mergeMatrixInheritItems(question, questCopy.questions, question.options.inheritItemsFrom);
				}
				if(question.options.hasQuota === "false") {
					delete question.options.quotaAgeGroups;
					delete question.options.quotaDivision;
				}

				if(question.options && (typeof question.options.randomize !== 'undefined' && question.options.randomize === 'false')){
					delete question.options.randomize;
				}

				if(question.options && (typeof question.options.reverse !== 'undefined' && question.options.reverse === 'false')){
					delete question.options.reverse;
				}

				if (question.type === "selects") {

					for (var ii = 0; ii < question.selects.length; ii++) {
						if (question.selects[ii].options) {
							var newScale = CloneService.compareAndGetOptionsForMatrixIfDifferent(question.selects[ii].options, question.selectsScale);
							question.selects[ii].options = angular.copy(newScale);
						}
					}

					if (question.disabledValuesForItem) {
						angular.forEach(question.disabledValuesForItem, function (value, key) {
							if (question.disabledItemCheckbox[key] === true) {
								for (var j = 0; j < value.length; j++) {
									var select = question.selects.filter(function (q) {
										return q.id === value[j];
									})[0];
									if (select) {
										var option = select.options.filter(function (q) {
											return q.value === key;
										})[0];
										if (option) {
											option.disabled = true;
										}
									}
								}
							}
						});
					}

					if (!question.options.inline && !question.options.slider) {
						question.options.matrix = "true";
					}
					else {
						delete question.options.matrix;
					}
				}
				if (question.type === "ranking") {
					const selectsScale = question.selects.map(function (select, index) {
						return { value: index+1, label: (index+1).toString() };
					});
					question.selects.forEach(function (select) {
						var newScale = CloneService.compareAndGetOptionsForMatrixIfDifferent(select.options, selectsScale);
						select.options = angular.copy(newScale);
					});
				}
				else if (question.type === "quote") {
					question.question = cleanSpecialChars(question.question) + ' | ' + question.quoteSignature;
					delete question.quoteSignature;
				}
				if (question.type.indexOf("Choice") === -1) {
					delete question.choices;
				}

				if (question.question) {
					var splitQuestionText = question.question.split("##");
				}
				if (splitQuestionText && splitQuestionText.length === 3 && splitQuestionText[1] !== 'user_first_name') {
					var questionToInheritTextFrom = questCopy.questions.filter(function (q) {
						return q.id === splitQuestionText[1];
					})[0];
					if (questionToInheritTextFrom.type === "multipleChoice") {
						var indexOfQuestionToSpliceAt = i;
						var headlineRegexp = new RegExp('#.+#');
						for (var j = 0; j < questionToInheritTextFrom.choices.length; j++) {
							if (headlineRegexp.test(questionToInheritTextFrom.choices[j].value) || parseInt(questionToInheritTextFrom.choices[j].value) > DKNA_LABEL) {
								continue;
							}
							var regexp = new RegExp('##.+##');
							var questionToAppend = angular.copy(question);
							if (questionToAppend.choices && questionToAppend.choices.length > 0) {
								for (var x = 0; x < questionToAppend.choices.length; x++) {
									if (questionToAppend.choices[x].choice_id) delete questionToAppend.choices[x].choice_id;
								}
							}
							else if (questionToAppend.selects && questionToAppend.selects.length > 0) {
								for (var x = 0; x < questionToAppend.selects.length; x++) {
									if (questionToAppend.selects[x].select_id) delete questionToAppend.selects[x].select_id;
								}
							}
							questionToAppend.id += "_" + questionToInheritTextFrom.choices[j].value;
							questionToAppend.options.inheritTextFrom = {
								"question": questionToInheritTextFrom.id,
								"choice": questionToInheritTextFrom.choices[j].value
							};

							if(questionToAppend.skipGroup && questionToAppend.skipGroup.length > 0){
								SkipLogicService.saveSkipJson(questionToAppend);
								questionToAppend.skipString += "||";

							}
							if(typeof questionToAppend.skipString === "undefined") questionToAppend.skipString = "";
							questionToAppend.skipString += "((model.answers['" + questionToInheritTextFrom.id + "'].split(',').filter(function(n){return ['"
								+ questionToInheritTextFrom.choices[j].value + "'].indexOf(n) !== -1;}).length==0))";

							delete questionToAppend.question_id;
							questionToAppend.question = questionToAppend.question.replace(regexp, questionToInheritTextFrom.choices[j].label);
							questCopy.questions.splice(++i, 0, questionToAppend);
						}
						questCopy.questions.splice(indexOfQuestionToSpliceAt, 1);
						i--;
					}
					else {
						question.options.insertPreviousAnswerAsText = "true";
					}
				}
				else if (splitQuestionText && splitQuestionText.length === 3 && splitQuestionText[1] === 'user_first_name') {
					if(question.options && !question.options.insertFirstName) {
						question.options.insertFirstName = "true";
					}
				}
				else {
					delete question.options.insertFirstName;
					delete question.options.insertPreviousAnswerAsText;
				}
			}
			if(filters && !angular.equals({}, filters)) {
				questCopy.filters = FilterUtils.buildFiltersForSaveSurvey(filters);
			}
			return questCopy;
		}
	}
})();

