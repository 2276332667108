(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .factory('WallDashboardService', WallDashboardService);

    WallDashboardService.$inject = ['$http', 'Upload'];

    function WallDashboardService($http, Upload) {
        var service = {};

        service.GetScrollAveragesByDay = function (okCallback, koCallback) {
            $http.get("/rest/walldashboard/scrollAverages/")
                .then(function successCallback(response) {
                        okCallback(response);
                    }, function errorCallback(response) {
                        koCallback && koCallback(response);
                    }
                );
        };

        service.GetTopicsWithAnswersCounts = function (okCallback, koCallback) {
            $http.get("/rest/walldashboard/getAnswerCountsByTopic/")
                .then(function successCallback(response) {
                        okCallback(response);
                    }, function errorCallback(response) {
                        koCallback && koCallback(response);
                    }
                );
        };

        return service;
    }
})();
