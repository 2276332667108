(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .controller('SnackbarController', SnackbarController);

    function SnackbarController($scope, $mdToast, data) {
        $scope.data = data;

        $scope.close = function() {
            $mdToast.hide();
        };
    }
})();
