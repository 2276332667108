(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .controller('UsersWinnersController', UsersWinnersController);

    UsersWinnersController.$inject = ['$state', 'DTOptionsBuilder', '$q', 'UsersService', 'UsersWinnersService', '$filter', '$rootScope','$scope', 'IncentivesService'];
    function UsersWinnersController($state, DTOptionsBuilder, $q, UsersService, UsersWinnersService, $filter, $rootScope,$scope, IncentivesService) {

        var vm = this;
        var originatorEv;
        vm.disabledSaveWinners = false;

        $scope.data = {
            winners : [{
                user_id:'',
                user_win_date: moment(),
                user_start_date: '',
                user_end_date: '',
                user_prize_id: null
            }]
        };

        $scope.saveWinners = function(){
            var winnersPayload = $scope.data.winners.map(function (winner) {
                    winner.user_start_date = moment(winner.user_start_date).format('YYYY-MM-DD 00:00:00');
                    winner.user_end_date = moment(winner.user_end_date).format('YYYY-MM-DD 23:59:59');
                    winner.user_win_date = moment(winner.user_win_date).format('YYYY-MM-DD HH:mm:ss');
                    return winner;
                }
            );

            vm.disabledSaveWinners = true;
            UsersWinnersService.addWinners(winnersPayload,
                function succes(response) {
                    vm.addWinnersCheck = {
                        show: true,
                        added: true,
                        message: "Winners were successfully saved in Prizes panel page!"
                    };
                },
                function error(response) {
                    vm.disabledSaveWinners = false;
                    if(response.status === 500){
                        vm.addWinnersCheck = {
                            show: true,
                            added: false,
                            message: "Please insert valid user IDs or distinct user IDs!"
                        };
                    }
                });
        };

        $scope.deleteRow = function($event,winner){
            var index = $scope.data.winners.indexOf(winner);
            if($event.which == 1)
                $scope.data.winners.splice(index,1);

        };

        vm.removeWinnersByDrawDate = function() {
            var draw_date = vm.drawDate;
            if(confirm('Are you sure that you want to delete all winners from the selected draw date?')) {
                UsersWinnersService.removeWinnersByDrawDate(draw_date,
                    function (success) {
                        vm.removeWinnersCheck = {
                            show: true,
                            removed: true,
                            message: "Successfully removed winners!"
                        };
                    },
                    function (error) {
                        vm.removeWinnersCheck = {
                            show: true,
                            removed: false,
                            message: "Failed to remove winners!"
                        };
                    }
                );
            }
        };

        vm.qchoicesSplice = function qchoicesSplice(index) {
            var firstWinner = $scope.data.winners[0];
            var winner = {
                user_id: '',
                //last day of previous month
                user_win_date: firstWinner.user_win_date,
                user_start_date: firstWinner.user_start_date,
                user_end_date: firstWinner.user_end_date,
                user_prize_id: null
            };
            if($scope.data.winners.length <= index+1) {
                $scope.data.winners.splice(index + 1, 0, winner);
            }
        };

        vm.checkValues = function(){

            var winners = $scope.data.winners;
            for(var i = 0; i < winners.length ;i++){
                if(winners[i]){
                    if(!winners[i].user_id || !winners[i].user_win_date ||
                        !winners[i].user_start_date || !winners[i].user_end_date || !winners[i].user_prize_id
                        || vm.disabledSaveWinners == true){
                        return false;
                    }
                }
            }
            return true;
        };

        vm.openMenu = function ($mdMenu, ev) {
            originatorEv = ev;
            $mdMenu.open(ev);
        };

        vm.changeYear = function (year) {
            vm.year = parseInt(year);
            vm.minDate = vm.year + '-01-01';
            vm.maxDate = vm.year + '-12-31';
        };

        vm.changeDrawDate = function () {
            vm.drawDate = moment(vm.drawDate).format('YYYY-MM-DD')
        };

        vm.initVarsTimeControllers = function (){
            vm.date = new Date();
            vm.year = vm.date.getFullYear();
            vm.minDate = vm.year + '-01-01';
            vm.maxDate = vm.year + '-12-31';
            if(vm.year) {
                vm.displayedYears = [vm.year, vm.year - 1, vm.year - 2];
            }
        };

        vm.loadUsersFromDB = function(){
            UsersService.GetAllUsersForCSV('/rest/users/all' + vm.serializeFilters(),
                function (response) {
                    deferme.resolve(vm.doStuffWithUsersArr(response.data));
                    vm.loadingAllCsv = false;
                },
                function (response) {
                    deferme.resolve(null);
                    vm.loadingAllCsv = false;
                });
        };

        vm.getRaffleLabels = function() {
            IncentivesService.getRafflePrizesLabel(
                function (success) {
                    vm.raffleLabels = success.data;
                },
                function (error) {
                    console.log(error);
                }
            );
        };

        (function initController() {
            vm.breadcrumbs = "Rewards: Raffle Winners";
            vm.activeSubmenu = 'panel_rewards';
            vm.invalidFields = true;
            vm.raffleLabels = [];
            vm.getRaffleLabels();
            vm.initVarsTimeControllers();
        })();

    }
})();
