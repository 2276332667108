(function () {
    'use strict';
    /*
        Description: directive that shows a dialog that helps select resize and upload an image to a
        selected object

        Usage:
            HTML:
                <add-image-directive object-id="objectId" upload-function="uploadFunction">
                </add-image-directive>

            The directive requires:
                1. uploadFunction => a function that uploads a blobImg based on 4 fields:
                        - objectId: the ID of the object to update with the image
                        - blobImg: the img file as a Blob
                        - success callback function
                        - error callback function

                2. objectId: the ID of the object to update

                3. fixedWidth: if defined you cannot manually set the resize width (55 for choices images,
                 642 for question images)

                4. successFunction: the function to be called when the modal closes with promise resolve
                    note: this functions receives a single param representing the "objectId"

                5. errorFunction: the function to be called when the modal closes with promise reject

                !!! if 4 and 5 are left undefined they will not be called
     */
    angular
        .module('questiaPlatformApp').directive('addImageDirective', function () {
        return {
            restrict: 'E',
            scope: {
                objectId: '=',
                uploadFunction: '&',
                fixedWidth: '=',
                hideResize: '=',
                targetName: '=',
                recommendMessage: '=',
                successFunction: '&',
                errorFunction: '&'
            },
            templateUrl: 'js/ng-templates/subviews/directives/addimagedirective.view.html',
            controllerAs: 'vm',
            controller: ['$scope','$mdDialog', function ($scope, $mdDialog) {
                var vm = this;
                vm.objectId = $scope.objectId;
                vm.uploadFunction = $scope.uploadFunction;
                vm.fixedWidth = $scope.fixedWidth;
                vm.targetName = $scope.targetName;
                vm.hideResize = $scope.hideResize;
                vm.recommendMessage = $scope.recommendMessage;
                vm.successFunction = $scope.successFunction;
                vm.errorFunction = $scope.errorFunction;

                vm.addImageToObject = function (objectId, uploadFunction, fixedWidth, hideResize, targetName,recommendMessage) {
                    $mdDialog.show({
                        templateUrl: 'js/ng-templates/subviews/directives/addimagedialog.subview.html',
                        locals: {
                            objectId: objectId,
                            uploadFunction: uploadFunction,
                            fixedWidth: fixedWidth,
                            hideResize: hideResize,
                            targetName : targetName,
                            recommendMessage : recommendMessage
                        },
                        controller: AddImageDialogController
                    }).then(
                        function () {
                            // Saved
                            if(vm.successFunction() instanceof Function) vm.successFunction()();
                        },
                        function () {
                            //Canceled
                            if(vm.errorFunction() instanceof Function) vm.errorFunction()();
                        });
                };

                function AddImageDialogController($scope, $mdDialog, Upload, objectId, uploadFunction, fixedWidth, hideResize, targetName, recommendMessage) {
                    $scope.imgWidth = 200;
                    $scope.imgHeight = 200;
                    $scope.objectId = objectId;
                    $scope.uploadFunction = uploadFunction;
                    $scope.fixedWidth = fixedWidth;
                    $scope.hideResize = hideResize;
                    $scope.targetName = targetName;
                    $scope.recommendMessage = recommendMessage;
                    $scope.errorMessage = undefined;
                    $scope.toUploadBlobSize = undefined;
                    $scope.imgFile = undefined;

                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };

                    $scope.imgFileToBase64 = function (blobImg) {
                        Upload.dataUrl(blobImg, true).then(function (dataUrl) {
                            $scope.base64ImgFile = dataUrl;
                            setTimeout(function () {
                                var imageLoaded = document.getElementById('base64ImgTagId');
                                $scope.naturalImgHeight = imageLoaded.naturalHeight;
                                $scope.imgHeight = imageLoaded.naturalHeight;
                                $scope.naturalImgWidth = imageLoaded.naturalWidth;
                                $scope.imgWidth = imageLoaded.naturalWidth;

                                if (hideResize && $scope.validateImg(blobImg, imageLoaded)) {
                                    $scope.dataUrlImg = dataUrl;
                                }
                            }, 0);
                        })
                    };

                    $scope.validateImg = function (blobImg, imageDom) {
                        $scope.errorMessage = undefined;
                        if (imageDom.naturalHeight !== $scope.fixedWidth || imageDom.naturalWidth !== $scope.fixedWidth) {
                            $scope.errorMessage = {
                                message: "Please choose a image with the correct size!",
                                limit: "The image must be  a png with exact size of 55 x 55 px."
                            };
                            return false;
                        } else if (blobImg.size > 400000) {
                            $scope.errorMessage = {
                                message: "Resized image is too big, please reduce the initial image's size!",
                                limit: "The maximum size allowed is 400000 bytes (400KB)!"
                            }
                            return false;
                        }

                        return true;
                    };

                    $scope.resize = function (blobImg, width, height) {
                        if(typeof $scope.fixedWidth !== "undefined")
                            width = $scope.fixedWidth;

	                    var type = width > 100 ? 'image/jpeg' : blobImg.type;
	                    var qual = width > 100 ? .8 : 1;

	                    var heightBasedOnWidth = height * width/$scope.naturalImgWidth;
                        var options = {
                            width: width || 200,
                            height: heightBasedOnWidth || 200,
                            quality: qual,
                            type: type,
                            centerCrop: true
                        };
                        Upload.resize(blobImg, options)
                            .then(function (resizedImage) {
                                $scope.toUploadBlobSize = resizedImage.size;
                                Upload.dataUrl(resizedImage, true).then(function (dataUrl) {
                                    $scope.dataUrlImg = dataUrl;
                                })
                            });
                    };

                    $scope.save = function (dataUrlImg) {
                        if(fixedWidth === 55 && $scope.naturalImgWidth !== $scope.naturalImgHeight){
                            $scope.errorMessage = {
                                message: "Choice's image should be NxN (width=height) size! Please upload a 'width=height' image!",
                                limit: ""
                            };
                            return;
                        }
                        if($scope.fixedWidth && $scope.fixedWidth === $scope.naturalImgWidth) {
                            var blobImg = $scope.imgFile;
                        } else {
                            var blobImg = Upload.dataUrltoBlob(dataUrlImg);
                        }

                        if (blobImg.size <= 400000) {
                            if(typeof objectId === 'undefined'){
                                $mdDialog.hide();
                            }
                            $scope.uploadFunction()(
                                $scope.objectId,
                                blobImg,
                                function (success) {
                                    $mdDialog.hide();
                                },
                                function (error) {
                                    console.log("Error uploading picture to object!");
                                    $mdDialog.cancel();
                                }
                            )

                        } else {
                            if($scope.fixedWidth && $scope.fixedWidth === $scope.naturalImgWidth) {
                                $scope.errorMessage = {
                                    message: "Picture is already of fixedWidth but still too big, please manually lower the image's size!",
                                    limit: "The maximum size allowed is 400000 bytes (400KB)!"
                                }
                            } else {
                                $scope.errorMessage = {
                                    message: "Resized image is too big, please reduce the initial image's size!",
                                    limit: "The maximum size allowed is 400000 bytes (400KB)!"
                                }
                            }
                        }
                    };
                }
            }]
        };
    })
})();
