(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('TasksService', TasksService);

	TasksService.$inject = ['$http'];
	function TasksService($http) {
		return {
			getStatus: function (type, okCallback, koCallback) {
				$http.get("/rest/schedulers/" + type)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			},
			getFbLeadsStatusForLanguageId: function (langId, okCallback, koCallback) {
				$http.get("/rest/schedulers/fbLeads/" + langId)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			},
			startScheduler: function (task, okCallback, koCallback) {
				$http.post("/rest/schedulers/" + task.type, task.data)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			},
			stopScheduler: function (task, okCallback, koCallback) {
				$http.delete("/rest/schedulers/" + task.type)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			},
			stopFbLeadsScheduler: function (task, okCallback, koCallback) {
				$http.delete("/rest/schedulers/fbLeads/" + task.data.language.lang_id)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			}
		}
	}
})();
