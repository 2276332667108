(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('CloneService', ['SurveysService', CloneService]);

	function CloneService(SurveysService) {
		var DKNA_LABEL = 900;
		return {
			cloneChoices: cloneChoices,
			mergeChoices: mergeChoices,
			cloneMatrixItems: cloneMatrixItems,
            mergeMatrixInheritItems: mergeMatrixInheritItems,
			compareAndGetOptionsForMatrixIfDifferent: compareAndGetOptionsForMatrixIfDifferent,
			cloneQuest: cloneQuest,
			cloneChoicesAsScale: cloneChoicesAsScale,
			cloneQuestion: cloneQuestion
		};

		function areChoicesIdentical(choice1, choice2) {
			return choice1.label === choice2.label && parseInt(choice1.value) === parseInt(choice2.value) && parseInt(choice1.choice_order_id) === parseInt(choice2.choice_order_id);
		}

		function cloneChoices(question, questions, cloneId) {
			!question.options && (question.options = {});
			if (!cloneId) {
				question.choices = [{}];
				return;
			}
			var cloned = questions.filter(function (obj) {
				return obj.id == cloneId;
			});
			if (cloned && cloned.length > 0 && cloned[0].choices && cloned[0].choices.length > 0) {
				question.choices = angular.copy(cloned[0].choices);
				question.choices.map(function (choice) {
					delete choice.choice_id;
				})
			}
			else {
				question.choices = [{}];
			}
			return question;
		}

		function mergeChoices(question, questions, inheritId) {
			!question.options && (question.options = {});
			if (!inheritId) {
				question.choices = [{}];
				return;
			}
			var parentQuestion = questions.filter(function (obj) {
				return obj.id == inheritId;
			});
			if (parentQuestion && parentQuestion.length > 0 && parentQuestion[0].choices && parentQuestion[0].choices.length > 0) {
				var parentChoices = parentQuestion[0].choices;
				var clonedChoices = angular.copy(question.choices);
				question.choices = [];
				for(var i = 0; i < parentChoices.length; i++) {
						var mergedChoice = angular.copy(parentChoices[i]);

						var choiceFound = clonedChoices.find(function (elem) {
							return areChoicesIdentical(elem, mergedChoice)
						});

						if(choiceFound) {
							mergedChoice.choice_id = choiceFound.choice_id;
						}
						else delete mergedChoice.choice_id

						question.choices.push(mergedChoice);
					
				}
			}
			else {
				question.choices = [{}];
			}
			return question;
		}

		function areOptionsIdentical(scale1, scale2) {
			return scale1.label === scale2.label && parseInt(scale1.value) === parseInt(scale2.value);
		}

		function compareAndGetOptionsForMatrixIfDifferent(oldScale, newScale) {
			if (typeof oldScale === "undefined" || (oldScale.length !== newScale.length)) {
				return newScale;
			}
			for(var i in newScale) {
				if(!areOptionsIdentical(newScale[i], oldScale[i])) {
					return newScale;
				}
			}
			return oldScale;
		}

		function mergeMatrixInheritItems(question, questions, inheritId) {
			if(!inheritId) {
				return question;
			} else {
                var parentQuestion = questions.filter(function (obj) {
                    return obj.id === inheritId;
                });
                if (parentQuestion && parentQuestion.length > 0 && parentQuestion[0].choices && parentQuestion[0].choices.length > 0) {
                    var filteredChoices = parentQuestion[0].choices.filter(function (choice) {
                        return parseInt(choice.value) < 900;
                    });
                    // SAVE OLD SELECTS
                    var clonedSelects = angular.copy(question.selects);
                    // CONVERT INHERITED CHOICES INTO SELECTS
                    question.selects = [];
                    var i;
                    for(i = 0; i < filteredChoices.length; i++) {
                    	if(parseInt(filteredChoices[i].value) < DKNA_LABEL){
                    		var selectQid = question.id + "_" + filteredChoices[i].value;
                    		var mergedSelect = {
                                id: selectQid,
                                label: filteredChoices[i].label,
                                options: [{}]
                            };

                            var selectFound = clonedSelects.find(function (elem) {
                                return elem.id === selectQid;
                            });

                            if(selectFound) {
                                mergedSelect.select_id = selectFound.select_id;
                            }

                            question.selects.push(mergedSelect);
						}
                    }
				}
			}
            return question;
		}

		function cloneMatrixItems(question, questions, cloneId) {
			if (!cloneId) {
				question.selects = [{
					id: question.id + "_0",
					"options": [{}]
				}];
				delete question.options.inheritItemsFrom;
				return;
			}
			var cloned = questions.filter(function (obj) {
				return obj.id == cloneId;
			});
			if (cloned && cloned.length > 0 && cloned[0].choices && cloned[0].choices.length > 0) {
				var filteredChoices = cloned[0].choices.filter(function (choice) {
					return parseInt(choice.value) < 900;
				});
				question.selects = [];
				for(var i = 0; i < filteredChoices.length; i++) {
					if(parseInt(filteredChoices[i].value) < DKNA_LABEL) {
						question.selects.push({
							id: question.id + "_" + filteredChoices[i].value,
							label: filteredChoices[i].label,
							options: [{}]
						});
					}
				}
			}
			else {
				question.selects = [{
					id: questions[index].id + "_0",
					"options": [{}]
				}];
				delete question.options.inheritItemsFrom;
			}
			return question;
		}

		function cloneQuest(id, okCallback) {
			SurveysService.CloneQuest(id,
				function (response) {okCallback(response)});
		}

		function cloneChoicesAsScale(question, questions, qidToCloneChoices) {
			var questionToInheritChoicesFrom = questions.filter(function (q) {
				return q.id === qidToCloneChoices;
			});
			if (questionToInheritChoicesFrom && questionToInheritChoicesFrom.length > 0) {
				question.selectsScale = questionToInheritChoicesFrom[0].choices;
			}
			return question;
		}

		function cloneQuestion(questions, index) {
			var cloned = angular.copy(questions[index]);
			cloned.editMode = true;
			cloned.id += "_clone";
			delete cloned.question_id;
			if(cloned.selects) {
				for(var i = 0; i < cloned.selects.length; i++) {
					cloned.selects[i].id = cloned.id + "_" + i;
					delete cloned.selects[i].select_id;
					if(typeof cloned.selects[i].options !== "undefined") {
						for(var j = 0; j < cloned.selects[i].options.length; j++) {
							if(typeof cloned.selects[i].options[j].options_id !== "undefined"){
								delete cloned.selects[i].options[j].options_id;
							}
						}
					}
				}
			}
			if(cloned.choices) {
				for(i = 0; i < cloned.choices.length; i++) {
					delete cloned.choices[i].choice_id;
				}
			}
			questions.splice(index+1, 0, cloned);
			return questions;
		}
	}
})();

