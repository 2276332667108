(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.controller('UsersController', UsersController);

	UsersController.$inject = ['$state', '$window', 'DTOptionsBuilder', '$q', 'DTColumnBuilder', 'UsersService', '$filter', '$rootScope'];
	function UsersController($state, $window, DTOptionsBuilder, $q, DTColumnBuilder, UsersService, $filter, $rootScope) {

		var vm = this;
		var originatorEv;

		vm.openMenu = function ($mdMenu, ev) {
			originatorEv = ev;
			$mdMenu.open(ev);
		};

		vm.changeYear = function (year) {
			vm.year = parseInt(year);
		};
		vm.changeMonth = function (month, index) {
			vm.month = month;
			vm.month_index = index+1;
		};

		vm.getActiveUsersIn = function (year, month) {
			var deferme = $q.defer();
			vm.headerCsv = ["User Id", "Confirmed", "Subscribed", "Email", "Phone", "Points",
				"Age", "Gender", "City Name", "County Name", "User Source"];
			vm.loadingFilteredActiveUsers = true;
			UsersService.GetActiveUsersIn(year, month,
				function (response) {
					deferme.resolve(vm.doStuffWithUsersArr(response.data, true));
					vm.loadingFilteredActiveUsers = false;
				},
				function (response) {
					deferme.resolve(null);
					vm.loadingFilteredActiveUsers = false;
				});
			return deferme.promise;
		};

		vm.doStuffWithUsersArr = function (data, privateData) {
			var users = [];
			for (var i = 0; i < data.length; i++) {
				var user = {
					user_id: data[i].user_id,
					user_active: data[i].user_active,
					user_subscribed: data[i].user_unsubscribe_qd
				};
				if (privateData) {
					user.user_email = data[i].user_email;
					user.user_phone = data[i].profile.user_phone;
					user.user_points = data[i].profile.user_points;
					if (!!data[i].profile.user_birthdate) {
						user.user_age = new Date().getFullYear() - new Date(data[i].profile.user_birthdate).getFullYear();
					} else {
						user.user_age = "NA";
					}
					if (data[i].profile.user_sex != null) {
						user.user_gender = data[i].profile.user_sex ? "Female" : "Male";
					} else {
						user.user_gender = "NA";
					}
					if (!!data[i].profile.city) {
						user.user_city_name = data[i].profile.city.city_name;
						if(!!data[i].profile.city.county) {
							user.user_county_name = data[i].profile.city.county.county_name;
						} else {
							user.user_county_name = "NA";
						}
					} else {
						user.user_city_name = "NA";
						user.user_county_name = "NA";
					}
				}
				user.user_recommended_by = (data[i].profile.user_recommended_by ? data[i].profile.user_recommended_by : "NULL");
				users.push(user);
			}
			return users;
		};

		vm.convertToLocaleTime = function (milliseconds) {
			var tempDate = new Date(milliseconds);
			return tempDate.toLocaleString();
		};

		vm.fillTable = function () {
			vm.dtOptions = DTOptionsBuilder.fromSource('/rest/users')
				.withDataProp('data')
				.withOption('processing', true)
				.withOption('serverSide', true)
				.withOption('pageLength', 25)
				.withOption('lengthMenu', [[25, 50, 100, -1], [25, 50, 100, "All"]])
				.withSelect(true)
				.withPaginationType('simple_numbers');

			vm.dtColumns = [
				DTColumnBuilder.newColumn('user_id').renderWith(function (data) {
					if($rootScope.globals.permissions &&
						$rootScope.globals.permissions.indexOf('users') !== -1) {
						return '<a style="font-weight: 700" href="/users/user/' + data + '">' + data + '</a>';
					}
					return data;
				}),
				DTColumnBuilder.newColumn('user_active').renderWith(function (data) {
					return data ? '<strong class="text-primary">YES</strong>' : '<strong class="text-danger">NO</strong>';
				}),
				DTColumnBuilder.newColumn('user_unsubscribe_qd').renderWith(function (data) {
					return data ? '<strong class="text-danger">NO</strong>' : '<strong class="text-primary">YES</strong>';
				}),
				DTColumnBuilder.newColumn('profile.user_points').withOption('type', 'num'),
				DTColumnBuilder.newColumn('profile.user_registration_datetime').renderWith(function (data) {
					return new Date(data).toLocaleString('en-GB', {timeZone: 'UTC'});
				}),
				DTColumnBuilder.newColumn('profile.user_phone').renderWith(function (data) {
					if($window.location.hostname === "kz") {
						return '<strong class="text-primary">' + data ? data : " " + '</strong>';
					} else {
						return '<strong class="text-primary"></strong>';
					}
				})
			];

			// vm.dtColumnDefs = [
			// 	{ targets: 3, type: 'num' } //by column index
			// ];
			vm.dtInstance = {};
		};
		vm.serializeFilters = function () {
			var params = '';
			if (vm.userRegistrationDateMin) {
				params += 'userRegistrationDateMin=' + $filter('date')(vm.userRegistrationDateMin, 'yyyy-MM-dd') + "&";
			}
			if (vm.userRegistrationDateMax) {
				params += 'userRegistrationDateMax=' + $filter('date')(vm.userRegistrationDateMax, 'yyyy-MM-dd') + "&";
			}
			if (params) {
				if (params[params.length - 1] === '&') {
					params = params.substring(0, params.length - 1);
				}
				params = '?' + params;
			}
			return params;
		};
		vm.filter = function () {
			vm.dtInstance.changeData('/rest/users' + vm.serializeFilters());
		};
		vm.goToInfo = function (uid) {
			$state.go("userInfo", {uid: uid});
		};

		vm.getAllUsersCSV = function () {
			vm.loadingAllCsv = true;
			vm.headerCsv = ["User Id", "Confirmed", "Subscribed", "Email", "Phone", "Points",
				"Age", "Gender", "City Name", "County Name", "User Source"];
			var deferme = $q.defer();
			UsersService.GetAllUsersForCSV('/rest/users/all' + vm.serializeFilters(),
				function (response) {
					deferme.resolve(vm.doStuffWithUsersArr(response.data, true));
					vm.loadingAllCsv = false;
				},
				function (response) {
					deferme.resolve(null);
					vm.loadingAllCsv = false;
				});
			return deferme.promise;
		};

		vm.createTableCsvRecords = function() {
			var table = $('#users').DataTable();
			var data = table.rows().data();

			var tableUsers = [];
			for (var i = 0; i < data.length; i ++) {
				var tableUser = data[i];
				if (!tableUser)
					break;
				var user = {
					user_id: tableUser.user_id+"",
					user_active: tableUser.user_active ? "YES": "NO",
					user_subscribed: tableUser.user_unsubscribe_qd ? "NO": "YES",
					user_points: tableUser.profile.user_points+"",
					user_registration_datetime: new Date(data[i].profile.user_registration_datetime).toLocaleString('en-GB', {timeZone: 'UTC'})
				};
				tableUsers.push(user);
			}
			return tableUsers;
		};

		vm.getTableUsersCSV = function () {
			vm.tableCsvHeader = ["DB ID","Confirmed","Subscribed","Points","Register date"];
			var deferme = $q.defer();
			deferme.resolve(vm.createTableCsvRecords());
			return deferme.promise;
		};
		(function initController() {
			vm.breadcrumbs = "Users: List";
			vm.activeSubmenu = 'users';
			vm.monthsArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
			vm.hostname = $window.location.hostname;
			vm.privacyGdpr = $rootScope.globals.privacyGdpr;
			vm.fillTable();
		})();
	}
})();
