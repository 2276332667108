(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .controller('SidebarController', SidebarController);

    SidebarController.$inject = ['$window', '$state', '$rootScope', '$location', '$mdSidenav', '$mdMedia'];
    function SidebarController($window, $state, $rootScope, $location, $mdSidenav, $mdMedia) {

        var vm = this;

        (function initController() {
            vm.hostname = $window.location.hostname;
            vm.logoPath = '/images/logoQuestia.png';
        })();

        vm.goto = function (state) {
            $state.go(state);
        };

        vm.redirectTo = function (link) {
            $window.location.href = link;
        }

    }
})();
