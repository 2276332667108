(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .controller('ViewEmailsTemplatesController', ViewEmailsTemplatesController);

    ViewEmailsTemplatesController.$inject = ['$rootScope', '$window', 'LanguagesService'];
    function ViewEmailsTemplatesController($rootScope, $window, LanguagesService) {
        var vm = this;

        vm.getAutoEmailsUrlToOpen = function (emailFileName) {
            var hostname = $window.location.hostname;
            return "specific/admin-emails/" + vm.currentLanguage + "/" + emailFileName;
        };

        vm.getSiteEmailsToOpen = function (emailFileName) {
            var panelHostname = $rootScope.globals.panelHostname;
            return 'https://' + panelHostname + "/specific/panel-emails/" + vm.currentLanguage + '/' + emailFileName;
        };


        (function initController() {
            vm.breadcrumbs = "Email: Templates";
            vm.activeSubmenu = 'mail';
            vm.languagesLoaded = false;
            vm.ticketsEnabled = $rootScope.globals.ticketsEnabled;

            LanguagesService.GetPanelLanguages(function(response) {
                vm.availableLanguages = response.data;
                vm.languagesLoaded = true;
            })
        })();
    }

})();