(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('SkipLogicService', [SkipLogicService]);

	function SkipLogicService() {
		return {
			addEmptySkip: addEmptySkip,
			resetSkipGroup: resetSkipGroup,
			changeOperatorWithinSkipGroup: changeOperatorWithinSkipGroup,
			changeOperatorBetweenSkipGroup: changeOperatorBetweenSkipGroups,
			initializeCloseSkip: initializeCloseSkip,
			findSkipQuestionChoices: findSkipQuestionChoices,
			getScaleChoicesForSkipSelect: getScaleChoicesForSkipSelect,
			addSkipGroup: addSkipGroup,
			saveSkipJson: saveSkipJson
		};

		function addEmptySkip(question, parentIndex, index) {
			if (question.skipGroup[parentIndex].skipArray.length === 0) {
				question.skipGroup[parentIndex].skipArray[0] = {operator: "and"};
			}
			else {
				question.skipGroup[parentIndex].skipArray[index].operator = "and";
				question.skipGroup[parentIndex].skipArray.splice(index + 1, 0, {});
			}
			return question;
		}

		function resetSkipGroup(question, parentIndex, index) {
			delete question.skipGroup[parentIndex].skipArray[index].value;
			delete question.skipGroup[parentIndex].skipArray[index].condition;
			return question;
		}

		function changeOperatorWithinSkipGroup(question, parentIndex, index) {
			if (question.skipGroup[parentIndex].skipArray[index].operator === "and") {
				question.skipGroup[parentIndex].skipArray[index].operator = "or";
			}
			else {
				question.skipGroup[parentIndex].skipArray[index].operator = "and";
			}
			return question;
		}

		function changeOperatorBetweenSkipGroups(question, parentIndex) {
			if (question.skipGroup[parentIndex].operator === "and") {
				question.skipGroup[parentIndex].operator = "or";
			}
			else {
				question.skipGroup[parentIndex].operator = "and";
			}
			return question;
		}

		function initializeCloseSkip(question) {
			question.skipGroup = [{
				skipArray: [{}],
				operator: "and"
			}];
			return question;
		}

		function findSkipQuestionChoices(question, previousQuestions, parentIndex, index) {
			var filteredQuestion = previousQuestions.filter(function (q) {
				return q.id === question.skipGroup[parentIndex].skipArray[index].question_id;
			});
			if (!filteredQuestion || filteredQuestion.length === 0) {
				delete question.skipGroup[parentIndex].skipArray[index].skipQtype;
				delete question.skipGroup[parentIndex].skipArray[index].skipQuestionChoices;
				delete question.skipGroup[parentIndex].skipArray[index].value;
				return;
			}
			var questionToInheritChoicesFrom;
			question.skipGroup[parentIndex].skipArray[index].skipQtype = filteredQuestion[0].type;

			if (filteredQuestion[0].type === "selects") {
				if(filteredQuestion[0].options && filteredQuestion[0].options.inheritItemsFrom) {
					questionToInheritChoicesFrom = vm.previousQuestions.filter(function (q) {
						return q.id === filteredQuestion[0].options.inheritItemsFrom;
					});
				}
				if(questionToInheritChoicesFrom && questionToInheritChoicesFrom.length > 0 && questionToInheritChoicesFrom[0].choices) {
					var filteredChoices = questionToInheritChoicesFrom[0].choices.filter(function (choice) {
						return parseInt(choice.value) < 900;
					});
					question.skipGroup[parentIndex].skipArray[index].skipSelects = [];
					for(var i = 0; i < filteredChoices.length; i++) {
						if(parseInt(filteredChoices[i].value) < DKNA_LABEL) {
							question.skipGroup[parentIndex].skipArray[index].skipSelects.push({
								id: filteredQuestion[0].id + "_" + filteredQuestion[0].selects.length,
								label: filteredChoices[i].label,
								options: [{}]
							});
						}
					}
				}
				else {
					question.skipGroup[parentIndex].skipArray[index].skipSelects = filteredQuestion[0].selects;
				}
			}
			else {
				if(filteredQuestion[0].options && filteredQuestion[0].options.inheritFrom) {
					questionToInheritChoicesFrom = previousQuestions.filter(function (q) {
						return q.id === filteredQuestion[0].options.inheritFrom;
					});
				}
				if(questionToInheritChoicesFrom && questionToInheritChoicesFrom.length > 0 && questionToInheritChoicesFrom[0].choices) {
					question.skipGroup[parentIndex].skipArray[index].skipQuestionChoices = questionToInheritChoicesFrom[0].choices;
				}
				else {
					question.skipGroup[parentIndex].skipArray[index].skipQuestionChoices = filteredQuestion[0].choices;
				}
			}
			return question;
		}

		function getScaleChoicesForSkipSelect(question, previousQuestions, parentIndex, index) {
			var filteredQuestion = previousQuestions.filter(function (q) {
				return q.id === question.skipGroup[parentIndex].skipArray[index].question_id;
			});
			question.skipGroup[parentIndex].skipArray[index].chosenSelectsScale = filteredQuestion[0].selectsScale;
			return question;
		}

		function addSkipGroup(question, parentIndex) {
			//add group starting at currentIndex+1
			question.skipGroup.splice(parentIndex + 1, 0, {skipArray: [{}]});
			//added group BETWEEN 2 other groups, NOT at the end
			if (question.skipGroup[parentIndex].operator) {
				//group at index already has operator, add operator to the new group
				question.skipGroup[parentIndex + 1].operator = "and";
			}
			//added group at the END
			else {
				//add operator to added group
				question.skipGroup[parentIndex].operator = "and";
			}
			return question;
		}

		function saveSkipJson(question) {
			if (question.skipGroup && question.skipGroup.length === 1 &&
				(question.skipGroup[0].skipArray.length === 0 || angular.equals(question.skipGroup[0].skipArray[0], {}))) {
				question.skipGroup = null;
				return;
			}
			cleanSkipGroups(question);
		}

		function cleanSkipGroups(question) {
			if (question.skipGroup && question.skipGroup.length > 0) {
				for (var i = 0; i < question.skipGroup.length; i++) {
					if (question.skipGroup[i].skipArray && question.skipGroup[i].skipArray.length > 0) {
						for (var ii = 0; ii < question.skipGroup[i].skipArray.length; ii++) {
							if (angular.equals(question.skipGroup[i].skipArray[ii], {})) {
								question.skipGroup[i].skipArray.splice(ii, 1);
								ii--;
								if (question.skipGroup[i].skipArray.length === 0) {
									question.skipGroup[i].skipArray.splice(i, 1);
									i--;
								}
							}
							else {
								if (question.skipGroup[i].skipArray[ii].skipQuestionChoices) delete question.skipGroup[i].skipArray[ii].skipQuestionChoices;
								if (question.skipGroup[i].skipArray[ii].skipSelects) delete question.skipGroup[i].skipArray[ii].skipSelects;
								if (question.skipGroup[i].skipArray[ii].chosenSelectsScale) delete question.skipGroup[i].skipArray[ii].chosenSelectsScale;
							}
						}
					}
				}
			}
		}

	}
})();