(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .controller('ForecastHistoryController', ForecastHistoryController);

	ForecastHistoryController.$inject = ['$rootScope', '$location', 'ForecastHistoryService'];
    function ForecastHistoryController($rootScope, $location, ForecastHistoryService) {
        var vm = this;
		(function initController() {
			var path = $location.path();
			vm.forecastId = path.substring(path.lastIndexOf("/") + 1);
			vm.chartId = "forecast" + vm.forecastId;
			vm.progress = false;
			vm.breadcrumbs = "Predictor > History";
			vm.activeSubmenu = 'predictor';

			ForecastHistoryService.GetStats(vm.forecastId, function (response) {
				var chartData = [];
				for (var i = response.data.data.length - 1; i >= 0; i--) {
					chartData.push(response.data.data[i]);
				}
				function getBalloon(date) {
					return AmCharts.formatDate(date, "DD/MM/YYYY");
				}
				var graphs = [];
				for (var k in response.data.labels) {
					if(response.data.labels.hasOwnProperty(k)) {
						graphs.push({
							"id": k,
							"bullet": "round",
							"bulletBorderAlpha": 1,
							"bulletColor": "#FFFFFF",
							"bulletSize": 5,
							"hideBulletsCount": 50,
							"lineThickness": 2,
							"title": response.data.labels[k],
							"useLineColorForBulletBorder": true,
							"valueField": k,
							"balloonText": response.data.labels[k] + " : [[value]]"
						});
					}
				}
				var chart = AmCharts.makeChart(vm.chartId + "chart", {
					"type": "serial",
					"theme": "light",
					"titles": [
						{
							"text": response.data.quest.quest_description,
							"size": 15
						}
					],
					"marginRight": 80,
					"dataProvider": chartData,
					"dataDateFormat": "YYYY-MM-DD JJ:NN:SS",
					"valueAxes": [{
						"position": "left",
						"title": "Percent"
					}],
					"graphs": graphs,
					"path": "/js/lib/amcharts",
					"chartScrollbar": {
						"graph": graphs[0].id,
						"scrollbarHeight": 50,
						"backgroundAlpha": 0,
						"selectedBackgroundAlpha": 0.1,
						"selectedBackgroundColor": "#888888",
						"graphFillAlpha": 0.5,
						"graphLineAlpha": 0.5,
						"selectedGraphFillAlpha": 0,
						"selectedGraphLineAlpha": 1,
						"autoGridCount": true,
						"color": "#AAAAAA"
					},
					"valueScrollbar": {
						"backgroundAlpha": 0,
						"selectedBackgroundColor": "#888888",
						"autoGridCount": true,
						"color": "#AAAAAA"
					},
					"chartCursor": {
						"cursorPosition": "mouse",
						"categoryBalloonFunction": getBalloon
					},
					"categoryField": "date",
					"categoryAxis": {
						"minPeriod": "mm",
						"parseDates": true
					},
					"legend": {
						"useGraphSettings": true,
						divId: vm.chartId + "legend"
					},
					"export": {
						"enabled": true,
						"dateFormat": "YYYY-MM-DD HH:NN:SS"
					}
				});

				chart.addListener("rendered", zoomChart);
				zoomChart();
				// this method is called when chart is first inited as we listen for "rendered" event
				function zoomChart() {
					// different zoom methods can be used - zoomToIndexes, zoomToDates, zoomToCategoryValues
					chart.zoomToIndexes(chartData.length - 800, chartData.length - 1);
				}
				vm.graphLoaded = true;
			});

		})();
    }
})();
