(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('AuthenticationService', AuthenticationService);

	AuthenticationService.$inject = ['$http', '$cookies', '$rootScope', 'QuestiaUtils'];
	function AuthenticationService($http, $cookies, $rootScope, QuestiaUtils) {
		return {
			setCredentials: function (token, userId, roles, uiConfigs) {
				$rootScope.globals = QuestiaUtils.initGlobals(uiConfigs);
				$rootScope.globals.userId = userId;
				$rootScope.globals.permissions = roles;
				$rootScope.globals.authorization = userId + ":" + token;

				$http.defaults.headers.common['Authorization'] = "Token " + userId + ":" + token;
				window.jQuery.ajaxSetup({
					headers: {'Authorization': "Token " + userId + ":" + token}
				});
				var now = new Date(),
					// this will set the expiration to 10 years
					exp = new Date(now.getFullYear() + 10, now.getMonth(), now.getDate());
				$cookies.putObject('globals_admin', $rootScope.globals, {expires: exp});
			},

			clearCredentials: function () {
				$rootScope.globals = {};
				$cookies.remove('globals_admin');
				$http.defaults.headers.common['Authorization'] = "";
				window.jQuery.ajaxSetup({
					headers: {'Authorization': ""}
				});
			},

			getUIConfigs: function(auth ,okCallback, koCallback) {
			$http.get('/rest/users/getUIConfigs', {headers: {'Authorization': 'Token ' + auth}})
				.then(function successCallback(response) {
					okCallback && okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
			},

			login: function (username, password, okCallback, koCallback) {
				var authdata = QuestiaUtils.encodeBase64(username + ':' + password);
				var that = this;
				$http.get('/rest/users/login', {headers: {'Authorization': 'Basic ' + authdata}})
					.then(function successCallback(responseLogin) {
						that.getUIConfigs(responseLogin.data.userId + ":" + responseLogin.data.token,
							function (success) {
								if(_.isEqual(responseLogin.data.roles, ["guest","home"])) return;
								that.setCredentials(responseLogin.data.token, responseLogin.data.userId, responseLogin.data.roles, success.data);
								okCallback && okCallback(responseLogin);
								//loadCurrentUser(response.data.user_id, okCallback);
							},
							function (error) {
								koCallback(error);
							}
						);
					}, function errorCallback(response) {
						koCallback(response);
					});
			}
		}
	}
})();