(function () {
	'use strict';
	angular
		.module('questiaPlatformApp')
		.factory('QuestiaUtils', ['$window','$location', '$mdDialog', QuestiaUtils]);

	function QuestiaUtils($window ,$location, $mdDialog) {
		var keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
		return {
			isEmptyObject: function (obj) {
				return typeof obj === 'undefined' || (Object.keys(obj).length === 0 && obj.constructor === Object);
			},
			showAlert: function (title, content, okcallback) {
				var alert = $mdDialog.alert()
					.title(title)
					.textContent(content)
					.ok('OK');

				$mdDialog
					.show(alert)
					.then(function() {
						if(okcallback) okcallback();
					})
					.finally(function () {
						alert = undefined;
					});
			},

			sha1: function (str) {
				//  discuss at: http://locutus.io/php/sha1/
				// original by: Webtoolkit.info (http://www.webtoolkit.info/)
				// improved by: Michael White (http://getsprink.com)
				// improved by: Kevin van Zonneveld (http://kvz.io)
				//    input by: Brett Zamir (http://brett-zamir.me)
				//      note 1: Keep in mind that in accordance with PHP, the whole string is buffered and then
				//      note 1: hashed. If available, we'd recommend using Node's native crypto modules directly
				//      note 1: in a steaming fashion for faster and more efficient hashing
				//   example 1: sha1('Kevin van Zonneveld')
				//   returns 1: '54916d2e62f65b3afa6e192e6a601cdbe5cb5897'

				var hash
				try {
					var crypto = require('crypto')
					var sha1sum = crypto.createHash('sha1')
					sha1sum.update(str)
					hash = sha1sum.digest('hex')
				} catch (e) {
					hash = undefined
				}

				if (hash !== undefined) {
					return hash
				}

				var _rotLeft = function (n, s) {
					var t4 = (n << s) | (n >>> (32 - s))
					return t4
				}

				var _cvtHex = function (val) {
					var str = ''
					var i
					var v

					for (i = 7; i >= 0; i--) {
						v = (val >>> (i * 4)) & 0x0f
						str += v.toString(16)
					}
					return str
				}

				var blockstart
				var i, j
				var W = new Array(80)
				var H0 = 0x67452301
				var H1 = 0xEFCDAB89
				var H2 = 0x98BADCFE
				var H3 = 0x10325476
				var H4 = 0xC3D2E1F0
				var A, B, C, D, E
				var temp

				// utf8_encode
				str = unescape(encodeURIComponent(str))
				var strLen = str.length

				var wordArray = []
				for (i = 0; i < strLen - 3; i += 4) {
					j = str.charCodeAt(i) << 24 |
						str.charCodeAt(i + 1) << 16 |
						str.charCodeAt(i + 2) << 8 |
						str.charCodeAt(i + 3)
					wordArray.push(j)
				}

				switch (strLen % 4) {
					case 0:
						i = 0x080000000
						break
					case 1:
						i = str.charCodeAt(strLen - 1) << 24 | 0x0800000
						break
					case 2:
						i = str.charCodeAt(strLen - 2) << 24 | str.charCodeAt(strLen - 1) << 16 | 0x08000
						break
					case 3:
						i = str.charCodeAt(strLen - 3) << 24 |
							str.charCodeAt(strLen - 2) << 16 |
							str.charCodeAt(strLen - 1) <<
							8 | 0x80
						break
				}

				wordArray.push(i)

				while ((wordArray.length % 16) !== 14) {
					wordArray.push(0)
				}

				wordArray.push(strLen >>> 29)
				wordArray.push((strLen << 3) & 0x0ffffffff)

				for (blockstart = 0; blockstart < wordArray.length; blockstart += 16) {
					for (i = 0; i < 16; i++) {
						W[i] = wordArray[blockstart + i]
					}
					for (i = 16; i <= 79; i++) {
						W[i] = _rotLeft(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1)
					}

					A = H0
					B = H1
					C = H2
					D = H3
					E = H4

					for (i = 0; i <= 19; i++) {
						temp = (_rotLeft(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5A827999) & 0x0ffffffff
						E = D
						D = C
						C = _rotLeft(B, 30)
						B = A
						A = temp
					}

					for (i = 20; i <= 39; i++) {
						temp = (_rotLeft(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ED9EBA1) & 0x0ffffffff
						E = D
						D = C
						C = _rotLeft(B, 30)
						B = A
						A = temp
					}

					for (i = 40; i <= 59; i++) {
						temp = (_rotLeft(A, 5) + ((B & C) | (B & D) | (C & D)) + E + W[i] + 0x8F1BBCDC) & 0x0ffffffff
						E = D
						D = C
						C = _rotLeft(B, 30)
						B = A
						A = temp
					}

					for (i = 60; i <= 79; i++) {
						temp = (_rotLeft(A, 5) + (B ^ C ^ D) + E + W[i] + 0xCA62C1D6) & 0x0ffffffff
						E = D
						D = C
						C = _rotLeft(B, 30)
						B = A
						A = temp
					}

					H0 = (H0 + A) & 0x0ffffffff
					H1 = (H1 + B) & 0x0ffffffff
					H2 = (H2 + C) & 0x0ffffffff
					H3 = (H3 + D) & 0x0ffffffff
					H4 = (H4 + E) & 0x0ffffffff
				}

				temp = _cvtHex(H0) + _cvtHex(H1) + _cvtHex(H2) + _cvtHex(H3) + _cvtHex(H4)
				return temp.toLowerCase()
			},

			encodeBase64: function (input) {
				var output = "";
				var chr1, chr2, chr3 = "";
				var enc1, enc2, enc3, enc4 = "";
				var i = 0;

				do {
					chr1 = input.charCodeAt(i++);
					chr2 = input.charCodeAt(i++);
					chr3 = input.charCodeAt(i++);

					enc1 = chr1 >> 2;
					enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
					enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
					enc4 = chr3 & 63;

					if (isNaN(chr2)) {
						enc3 = enc4 = 64;
					} else if (isNaN(chr3)) {
						enc4 = 64;
					}

					output = output +
						keyStr.charAt(enc1) +
						keyStr.charAt(enc2) +
						keyStr.charAt(enc3) +
						keyStr.charAt(enc4);
					chr1 = chr2 = chr3 = "";
					enc1 = enc2 = enc3 = enc4 = "";
				} while (i < input.length);

				return output;
			},

			decodeBase64: function (input) {
				var output = "";
				var chr1, chr2, chr3 = "";
				var enc1, enc2, enc3, enc4 = "";
				var i = 0;

				// remove all characters that are not A-Z, a-z, 0-9, +, /, or =
				var base64test = /[^A-Za-z0-9\+\/\=]/g;
				if (base64test.exec(input)) {
					window.alert("There were invalid base64 characters in the input text.\n" +
						"Valid base64 characters are A-Z, a-z, 0-9, '+', '/',and '='\n" +
						"Expect errors in decoding.");
				}
				input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

				do {
					enc1 = keyStr.indexOf(input.charAt(i++));
					enc2 = keyStr.indexOf(input.charAt(i++));
					enc3 = keyStr.indexOf(input.charAt(i++));
					enc4 = keyStr.indexOf(input.charAt(i++));

					chr1 = (enc1 << 2) | (enc2 >> 4);
					chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
					chr3 = ((enc3 & 3) << 6) | enc4;

					output = output + String.fromCharCode(chr1);

					if (enc3 != 64) {
						output = output + String.fromCharCode(chr2);
					}
					if (enc4 != 64) {
						output = output + String.fromCharCode(chr3);
					}

					chr1 = chr2 = chr3 = "";
					enc1 = enc2 = enc3 = enc4 = "";

				} while (i < input.length);

				return output;
			},

			initGlobals: function (configs) {
				var asl = {
					ageID: parseInt(configs.ageID),
					sexID: parseInt(configs.sexID),
					locationID: parseInt(configs.locationID)
				};
				return {
					panelHostname: configs.panelHostname,
					asl: asl,
					voteQid: parseInt(configs.voteQid),
					voteQuestId: parseInt(configs.voteQuestId),
					privacyGdpr: parseInt(configs.privacyGdpr),
					ticketsEnabled: parseInt(configs.ticketsEnabled)
				};
			},

            listToMatrix: function (list, elementsPerSubArray) {
                var matrix = [], i, k;
                for (i = 0, k = -1; i < list.length; i++) {
                    if (i % elementsPerSubArray === 0) {
                        k++;
                        matrix[k] = [];
                    }
                    matrix[k].push(list[i]);
                }
                return matrix;
            },

			sumArrayElements: function (arrayList) {
				return arrayList.reduce(function (acc, currentVal) {
					return acc + currentVal;
				}, 0);
			},

			sumMatrixElements: function (matrix) {
				return matrix.reduce(function (acc, currentVal) {
					var auxTotal = currentVal.reduce(function (acc2, currentVal2) {
						return acc2 + currentVal2;
					}, 0);
					return acc + auxTotal;
				}, 0);
			}
		};
	}
})();

