(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('ForecastHistoryService', ForecastHistoryService);

	ForecastHistoryService.$inject = ['$http'];
	function ForecastHistoryService($http) {
		return {
			GetStats: function (qid, okCallback, koCallback) {
				$http.get("/rest/forecast/history/quest/" + qid)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			}
		}
	}
})();
