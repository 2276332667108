(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.controller('LoginController', LoginController);

	LoginController.$inject = ['AuthenticationService', '$location', '$state', '$rootScope'];
	function LoginController(AuthenticationService, $location, $state, $rootScope) {
		var redirLogin = $location.search().redir || 'home';
		var vm = this;

		(function initController() {
			vm.loginError = false;
			AuthenticationService.clearCredentials();
			vm.formData = {};
		})();

		vm.login = function () {
			if (!vm.formData.email || !vm.formData.password) {
				return;
			}
			AuthenticationService.login(vm.formData.email, vm.formData.password,
				function (response) {
					if($rootScope.globals.permissions.includes("export")){
						$state.go("surveys");
					} else {
                        $state.go(redirLogin);
					}
				},
				function (response) {
					vm.loginError = true;
					console.log("LOGIN ERROR!");
				}
			);
		}
	}
})();
