

(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('SurveyTranslateService', SurveyTranslateService);

	SurveyTranslateService.$inject = ['$http', '$rootScope'];
	function SurveyTranslateService($http, $rootScope) {
		var service = {};

		service.SaveTranslation = function (questId, translation, okCallback, koCallback) {
			$http.post("/rest/translate/quest/" + questId, translation)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback && koCallback(response);
				});
		};

		service.GetTranslation = function (questId, langId, okCallback, koCallback) {
			$http.get("/rest/translate/quest/" + questId + "/language/" + langId)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback && koCallback(response);
				});
		};

		return service;
	}

})();
