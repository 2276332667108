(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .controller('WidgetsTopicsController', WidgetsTopicsController);

    WidgetsTopicsController.$inject = ['$rootScope', '$timeout', 'WidgetsService', 'LanguagesService', 'SnackbarUtils', '$mdDialog', 'NgTableParams'];

    function WidgetsTopicsController($rootScope, $timeout, WidgetsService, LanguagesService, SnackbarUtils, $mdDialog, NgTableParams) {
        var vm = this;

        vm.addNewTopic = function() {
            var newTopic = {
                topicId: null,
                topicName: "",
                description: "",
                exampleText: "",
                editing: true,
                newTopic: true
            };

            var translations = {};
            angular.forEach(vm.languagesList, function(language) {
               translations[language.lang_ui_name] = {
                   translateId: null,
                   languageId: language.lang_id,
                   text: ""
               }
            });
            newTopic.topicTranslations = translations;

            vm.topics.push(newTopic);
            vm.initialSettings.dataset = vm.topics;
            vm.topicTableParams.reload();

            // Scroll to last table row
            $timeout( function(){
                var newTopicRow = document.getElementById("topicsRow" + (vm.topics.length - 1));
                newTopicRow && newTopicRow.scrollIntoView({ behavior: "smooth"});
            }, 150);
        };

        vm.deleteTopic = function(topicIndex) {
            var topicId = vm.topics[topicIndex].topicId;

            if (topicId != null) {
                if(!vm.isTopicDelitionProcessStarted) {
                    vm.isTopicDelitionProcessStarted = true;
                    WidgetsService.GetTopicDeletionInfo(topicId,
                        function (success) {
                            var confirm = $mdDialog.confirm({
                                template:
                                    '<md-dialog md-theme="default">' +
                                    '   <md-dialog-content class="md-dialog-content" style="margin-left:20px;margin-right: 20px">' +
                                    '<div style="display:flex; justify-content: center"><img src="images/warning.PNG" alt=""/></div>'+
                                    '       <h2 class="md-title">' +
                                    '           <b>If you delete this topic, <span style="color:#EF4000">' + success.data["no_questions_affected"] + '</span> wall questions will be deleted.</b>' +
                                    '       </h2>' +
                                    '       <div class="md-dialog-content-body" style="text-align: center;margin-top:3%">' +
                                    '           <p>' +
                                    '               Are you sure you want to delete this topic?' +
                                    '           </p>' +
                                    '       </div>' +
                                    '   </md-dialog-conent>' +
                                    '   <md-dialog-actions style="justify-content: center;margin-top:6%;margin-bottom:5%" ng-disabled="$scope.isTopicDelitionProcessStarted === true">' +
                                    '       <md-button ng-click="deleteTopic()" class="md-raised md-warn md-hue-2">' +
                                    '           Delete topic' +
                                    '       </md-button>' +
                                    '       <md-button ng-click="cancel()" class="md-background md-hue-1">' +
                                    '           Go back' +
                                    '       </md-button>' +
                                    '   </md-dialog-actions>' +
                                    '</md-dialog>',
                                controller: function TopicRemovalDialogController($scope, $mdDialog) {

                                    $scope.cancel = function () {
                                        $mdDialog.hide();
                                        vm.isTopicDelitionProcessStarted = false;
                                    }
                                    $scope.deleteTopic = function () {
                                        $mdDialog.hide();
                                        WidgetsService.RemoveTopic(
                                            topicId,
                                            function (success) {
                                                vm.isTopicDelitionProcessStarted = false;
                                                vm.reloadTopicsList();
                                            },
                                            function (error) {
                                                vm.isTopicDelitionProcessStarted = false;
                                                SnackbarUtils.displaySnackbar("error", "Failed to remove topic!");
                                            });
                                    }
                                }
                            });
                            $mdDialog.show(confirm).then(function () {
                                vm.isTopicDelitionProcessStarted = false;
                            });
                        }, function (error) {
                            var errorConfirm = $mdDialog.confirm()
                                .title("Deletion error")
                                .textContent("Could not delete this topic")
                                .ok("Yes");
                        });
                }

            }
            else {
                vm.topics.splice(topicIndex, 1);
                vm.initialSettings.dataset = vm.topics;
                vm.topicTableParams.reload();
            }
        };

        vm.editTopic = function(topicIndex) {
            vm.topics[topicIndex].editing = true;
        }

        vm.saveTopic = function(topicIndex) {
            var topic = angular.copy(vm.topics[topicIndex]);
            var topicValidationResult = vm.validateTopic(topic);
            if (topicValidationResult !== "") {
                SnackbarUtils.displaySnackbar("error", topicValidationResult);
                return ;
            }

            // If saving new topic, remember its index for excluding it when filtering unsaved topics
            if (topic.topicId == null)
                vm.newQuestionIndexToExclude = topicIndex;

            delete topic.newTopic;
            delete topic.editing;
            WidgetsService.SaveTopic(
                topic,
                function success(response) {
                    SnackbarUtils.displaySnackbar("success", "Your topic has been saved!");
                    vm.topics[topicIndex].newTopic = false;
                    vm.reloadTopicsList();
                },
                function error(response) {
                    SnackbarUtils.displaySnackbar("error", "Failed to save topic!");
                }
            )
        };

        vm.reloadTopicsList = function() {
            // Preserve unsaved topics
            var newTopics = [];
            if (vm.topics && vm.topics.length > 0) {
                newTopics = vm.topics.filter(function(topic, index) { return topic.topicId === null && index !== vm.newQuestionIndexToExclude });
            }

            delete vm.topics;
            delete vm.newQuestionIndexToExclude;
            WidgetsService.GetAllTopics(
                function success(response) {
                    vm.topics = response.data;
                    angular.forEach(vm.topics, function(topic) {
                        vm.addEditingFlagAndMissingLanguagesEntries(topic);
                    });

                    vm.topics = vm.topics.concat(newTopics);
                    vm.initialSettings.dataset = vm.topics;
                    vm.topicTableParams = new NgTableParams(vm.initialParams, vm.initialSettings);
                },
                function error(response){
                    console.log(response);
                })
        }

        vm.addEditingFlagAndMissingLanguagesEntries = function(topic) {
            topic.editing = false;
            angular.forEach(vm.languagesList, function(language) {
               if (!topic.topicTranslations[language.lang_ui_name]) {
                   topic.topicTranslations[language.lang_ui_name] = {
                       translateId: null,
                       languageId: language.lang_id,
                       text: ""
                   }
               }
            });
        }

        vm.validateTopic = function(topic) {
            var result = "";
            if (!topic.topicName) {
                result = "Please add a topic label.";
                return result;
            }
            if (!topic.description) {
                result = "Please add a topic description.";
                return result;
            }
            if (!topic.exampleText) {
                result = "Please add a topic example.";
                return result;
            }
            for (var i = 0; i < vm.languagesList.length; i ++) {
                var language = vm.languagesList[i];
                if (!topic.topicTranslations[language.lang_ui_name] || !topic.topicTranslations[language.lang_ui_name].text) {
                    result = "Please translate the topic label into all panel languages.";
                    return result;
                }
            }
            return "";
        }

        vm.getLanguages = function (okCallback) {
            LanguagesService.GetPanelLanguages(
                function (success) {
                    vm.languagesList = success.data;
                    okCallback();
                },
                function (error) {
                    console.log(error);
                }
            );
        };

        (function initController() {
            vm.activeSubmenu = 'setup_translate';
            vm.breadcrumbs = 'Setup: Question Topics';

            vm.isTopicDelitionProcessStarted = false;

            vm.initialParams = {
                count: 20
            };

            vm.initialSettings = {
                counts: [],
                paginationMaxBlocks: 20,
                paginationMinBlocks: 2,
                dataset: []
            };

            vm.newQuestionIndexToExclude = undefined;

            vm.getLanguages(function (success) {
                vm.reloadTopicsList();
            });
        })();
    }
})();
