(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('EmailsService', EmailsService);

	EmailsService.$inject = ['$http'];
	function EmailsService($http) {
		return {
			GetUserCountForMail: function (mailData, okCallback, koCallback) {
				$http.post("/rest/mail/usercount", mailData)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			},
			getMailHistoryGroups: function(id, okCallback, koCallback) {
				$http.get("/rest/mail/getMailHistoryGroupsForUser/" + id)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			},
			getCustomMailHistoryGroups: function(okCallback, koCallback) {
				$http.get("/rest/mail/getCustomMailHistoryGroups")
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			},
			sendQuestionStatusEmail: function(questionId, status, okCallback, koCallback) {
				$http.post("/rest/mail/sendQuestionStatusEmail/" + questionId + "/" + status)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			},
			sendUnsuspendUserEmail: function(userId, okCallback, koCallback) {
				$http.post("/rest/mail/sendUnsuspendEmail/" + userId)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			},
			resendActivation: function(userId, okCallback, koCallback) {
				$http.post("/rest/mail/resendActivationEmail/" + userId)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			},
			cancelEmail: function(mailHistoryGroupId, okCallback, koCallback) {
				$http.post("/rest/mail/cancelEmail/" + mailHistoryGroupId)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			},
			removeEmail: function(mailHistoryGroupId, okCallback, koCallback) {
				$http.delete("/rest/mail/removeEmail/" + mailHistoryGroupId)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			}
		}
	}
})();
