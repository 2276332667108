(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.controller('CsvResultsController', CsvResultsController);

	CsvResultsController.$inject = ['$rootScope', '$window', 'SurveysService', 'LanguagesService', 'DTOptionsBuilder', 'RegionsService', '$stateParams', '$q', 'NgTableParams','$mdDialog'];
	function CsvResultsController($rootScope, $window, SurveysService, LanguagesService, DTOptionsBuilder, RegionsService, $stateParams, $q, NgTableParams,$mdDialog) {

		var vm = this;
		var multiples = [];

		vm.getCSV = function () {
			vm.csvButtonDisabled = true;
			var deferme = $q.defer();
			// vm.CSV = true;
			var reqId = vm.surveyId;
			if (vm.crossId) {
				reqId += "/" + vm.crossId;
			}
			if(vm.division_id) {
				reqId += "/division/" + vm.division_id;
			}
			if(vm.dynamicAgeGroups) {
				var ageGroups = vm.dynamicAgeGroups.replace(" ", "").split(",");
			}
			SurveysService.GetSurveyCsv(reqId, ageGroups, function successCallback(response) {
					vm.csvButtonDisabled = false;
					var data = response.data.answers;
					vm.spssVars = response.data.labels;
					vm.setLanguageLabels(response.data.defaultLanguageId, vm.spssVars, response.data.translatedLabels);
					if (data) {
						data[0].answers.push("Respondent ID");
						vm.csvData = data.map(function (elem, index) {
							if(index !== 0) elem.answers.push(elem.userId);
							return elem.answers;
						});
					}
					// vm.CSV = false;
					deferme.resolve(vm.csvData);
				},
				function errorCallback(response) {
					// vm.CSV = false;
					vm.csvButtonDisabled = false;
					deferme.resolve(null);
				});
			return deferme.promise;
		};

		vm.getRespondentsIds = function () {
			vm.exportRespIdsDisabled = true;
			var deferme = $q.defer();
			SurveysService.getRespondentsUserNamesForQuestAndDomain(vm.surveyId, "new.fingerprint",
				function (success) {
					vm.exportRespIdsDisabled = false;
					deferme.resolve(success.data);
				},
				function (error) {
					vm.exportRespIdsDisabled = false;
					deferme.resolve(null);
				});
			return deferme.promise;
		};

		vm.setLanguageLabels = function(defaultLanguageId, defaultLanguageLabels, translatedLabels) {
			LanguagesService.GetAllLanguages(function (response) {
				const defaultLanguageId = response.data.defaultLanguageId;

				vm.translationVars = response.data.languagesList
					.map(function (language) {
						return defaultLanguageId === language.lang_id
							? {
								language: language.lang_ui_name + '_default',
								labels: vm.spssVars
							}
							: {
								language: language.lang_ui_name,
								labels: translatedLabels[language.lang_ui_name]
							};
					});
				const defaultLanguageLabelIndex = vm.translationVars.findIndex(function (languageLabels) {
					return languageLabels.language.includes('_default');
				});
				const defaultLanguageLabels = vm.translationVars[defaultLanguageLabelIndex];
				vm.translationVars.splice(defaultLanguageLabelIndex, 1);
				vm.translationVars.unshift(defaultLanguageLabels);
			});
		}

		vm.getResults = function () {
			delete vm.dtOptions;
			delete vm.dtColumns;
			delete vm.dataSource;

			var reqId = vm.surveyId;
			if (vm.crossId) {
				reqId += "/" + vm.crossId;
			}
			if(vm.division_id) {
				reqId += "/division/" + vm.division_id;
			}
			if(vm.dynamicAgeGroups) {
				var ageGroups = vm.dynamicAgeGroups.replace(" ", "").split(",");
			}
			vm.error = false;
			vm.nodata = false;
			SurveysService.GetSurveyCsv(reqId, ageGroups,
				function successCallback(response) {
					if (response.data.answers && response.data.answers.length > 0) {
						var data = response.data.answers;
						vm.spssVars = response.data.labels;
						vm.setLanguageLabels(response.data.defaultLanguageId, vm.spssVars, response.data.translatedLabels);
						vm.dtColumns = [];
						for (var index = 0; index < data[0].answers.length; ++index) {
							vm.dtColumns.push({'title': data[0].answers[index]});
						}
						vm.dtColumns.push({'title': 'UserId'});
						vm.dataSource = [];
						for (index = 1; index < data.length; ++index) {
							data[index].answers.push(data[index].userId);
							vm.dataSource.push(data[index].answers);
						}
						vm.dtOptions = DTOptionsBuilder.newOptions()
							.withPaginationType('simple_numbers')
							.withOption('pageLength', 10)
							.withOption('order', [])
							.withButtons(['csvHtml5']);
					}
					else {
						vm.nodata = true;
					}
				},
				function errorCallback(response) {
					vm.error = true;
				}
			);
		};

		vm.getSPSSVars = function () {
			vm.spssVars = vm.translationVars.find(function (languageLabels) {
				return languageLabels.language === vm.selectedTranslation
			}).labels;

			var YES_STRING = "Yes";
			if($window.location.hostname === 'admin2.questia.ro') {
				YES_STRING = "Da";
			}
			var link = document.createElement("a");
			var spssMultiples = {};
			var qid;
			link.download = "SPSS_quest" + vm.surveyId + ".txt";
			var spssCode="* Encoding: UTF-8.\r\n";
			for (var question_id in vm.spssVars){
				//if it seems to be a multiple answer question, prepare for syntax
				var matchesMultiple = question_id.match(/_0\d+(_answer)?$/);
				var labels = vm.spssVars[question_id]['valueLabels'];

				if(!matchesMultiple) {
					spssCode+="VARIABLE LABELS "+question_id+" \""+vm.spssVars[question_id]['label']+"\".\r\n";
					if(labels){
						spssCode+="VALUE LABELS "+question_id;
						for (var label in labels){
							spssCode+="\r\n     " +label+" "+"'"+labels[label]+"'";
						}
						spssCode+=".\r\n";
					}
				}
				else {
					if(labels) {
						spssCode += "VARIABLE LABELS " + question_id + " \"" + labels[Object.keys(labels)[0]] + "\".\r\n";
						spssCode += "VALUE LABELS " + question_id + " 1'" + YES_STRING + "'.\r\n";
					}

					qid=question_id.substring(0,question_id.indexOf(matchesMultiple[0]));
					//if it's a multiple question
					if(multiples.indexOf(qid) !== -1) {
						if(!spssMultiples[qid]) {
							spssMultiples[qid] = { label: vm.spssVars[question_id]['label'], arr: [] };
						}
						spssMultiples[qid].arr.push(question_id);
					}
				}
			}

			//multiple questions group syntax
			if(Object.keys(spssMultiples).length !== 0){
				for (qid in spssMultiples){
					spssCode+="\r\n" + "MULT RESPONSE GROUPS=$" + qid + "_all '" + spssMultiples[qid].label + "' (" + spssMultiples[qid].arr.sort().join(" ") + " (1))";
					spssCode+="\r\n/FREQUENCIES=$" + qid + "_all.";
				}
			}

			spssCode = vm.appendSexQuestionToSPSS(spssCode);

			var data = "text/plain;charset=utf-8," + encodeURIComponent(spssCode);
			link.href = "data:" + data;
			link.click();
		};

		vm.getLocationsSPSS = function(){

            var link = document.createElement("a");
            link.download = "SPSS_quest_cities_locations_" + vm.surveyId + ".txt";
            var spssCode="* Encoding: UTF-8.\r\n";
            spssCode = vm.appendLocationsToSPSS(spssCode);
            var data = "text/plain;charset=utf-8," + encodeURIComponent(spssCode);
            link.href = "data:" + data;
            link.click();

		};

        vm.appendLocationsToSPSS = function(spssCode){

        	if(spssCode) {
                spssCode = vm.appendCitiesToSPSS(spssCode);
                spssCode = vm.appendCountiesToSPSS(spssCode);
                spssCode = vm.appendRegionsToSPSS(spssCode);
            }
            return spssCode;

        };

		vm.appendSexQuestionToSPSS = function (spssCode){
			if(spssCode) {
                var sexQuestionId = "Sex";
                var sexQuestionLabel = "Sex";
                var maleChoice = "0";
                var maleLabel = "Male";
                var femaleLabel = "Female";
                var femaleChoice = "1";
                var NAChoice = "-1";
                var NAChoiceLabel = "NA";

                spssCode += "\r\n\r\n";
                spssCode += "VARIABLE LABELS " + sexQuestionId + " \"" + sexQuestionLabel + "\".\r\n";
                spssCode += "VALUE LABELS " + sexQuestionId;
                spssCode += "\r\n     " + maleChoice + " " + "'" + maleLabel + "'";
                spssCode += "\r\n     " + femaleChoice + " " + "'" + femaleLabel + "'";
                spssCode += "\r\n     " + NAChoice + " " + "'" + NAChoiceLabel + "'";
                spssCode += ".\r\n";
            }
            return spssCode;
		};

		vm.appendCitiesToSPSS = function(spssCode){

			if(vm.cities && spssCode && vm.cities.length > 0) {

                spssCode += "\r\n\r\n";
                var citiesId = "Cities";
                var citiesLabel = "Cities";

                spssCode += "VARIABLE LABELS " + citiesId + " \"" + citiesLabel + "\".\r\n";
                spssCode += "VALUE LABELS " + citiesId;
                for (var i = 0; i < vm.cities.length; i++) {
                    if(vm.cities[i])
                    {
                        spssCode += "\r\n     " + vm.cities[i].city_id + " " + "'" + vm.cities[i].city_name + "'";
                    }
                }
                spssCode += ".\r\n";

            }
            return spssCode;
		};

        vm.appendRegionsToSPSS = function(spssCode){

            if(vm.regions && spssCode && vm.regions.length > 0) {

                spssCode += "\r\n\r\n";
                var regionsId = "Region";
                var regionsLabel = "Region";

                spssCode += "VARIABLE LABELS " + regionsId + " \"" + regionsLabel + "\".\r\n";
                spssCode += "VALUE LABELS " + regionsId;
                for (var i = 0; i < vm.regions.length; i++) {
                    if(vm.regions[i])
                    {
                        spssCode += "\r\n     " + vm.regions[i].region_id + " " + "'" + vm.regions[i].region_name + "'";
                    }
                }
                spssCode += ".\r\n";

            }
            return spssCode;
        };

		vm.appendCountiesToSPSS = function(spssCode){

            if(vm.counties && spssCode && vm.counties.length > 0) {

                spssCode += "\r\n\r\n";
                var countiesId = "County";
                var countiesLabel = "County";

                spssCode += "VARIABLE LABELS " + countiesId + " \"" + countiesLabel + "\".\r\n";
                spssCode += "VALUE LABELS " + countiesId;
                for (var i = 0; i < vm.counties.length; i++) {
                    if(vm.counties[i]) {
                        spssCode += "\r\n     " + vm.counties[i].county_id + " " + "'" + vm.counties[i].county_name + "'";
                    }
                }
                spssCode += ".\r\n";

            }
            return spssCode;

		};

		vm.getQuotaStats = function() {
			vm.tableDataset = [];

            SurveysService.GetQuestQuotas(vm.surveyId,
                function (response) {
                    vm.quotas = response.data;
                    for (var index = 0; index < vm.quotas.length; ++index) {
                        var quota = vm.quotas[index];
                        vm.tableDataset.push({
							"quota_id" : quota.quota_id,
                            "main_type_name": quota.main_type_name,
                            "main_label": quota.main_label,
                            "percent": quota.percent,
                            "percent_filled": quota.percent_filled ? quota.percent_filled : 0
                        });
                    }
                    var initialParams = {
                        count: 15 // initial page size
                    };
                    var initialSettings = {
                        // page size buttons (right set of buttons in demo)
                        counts: [],
                        // determines the pager buttons (left set of buttons in demo)
                        paginationMaxBlocks: 15,
                        paginationMinBlocks: 2,
                        dataset: vm.tableDataset
                    };
                    vm.tableParams = new NgTableParams(initialParams, initialSettings);
                },
                function (response) {

                }
            );

		};

		vm.loadDataFromDB = function(){
            RegionsService.getDivisionsList().then(function(response) {
                vm.divisions = response.data;
            });

            RegionsService.getAllCounties().then(function(response) {
                vm.counties = response.data;
            });

            RegionsService.getAllCities().then(function(response) {
                vm.cities = response.data;
            });

            RegionsService.getRegionsList().then(function(response) {
                vm.regions = response.data;
            });

		};

		vm.validateQuotas = function (quotasToUpdate,editedQuotasTypes) {
			var areQuotasCorrect = true;
              for(var i = 0; i < editedQuotasTypes.length; i++){
				  var correspondentQuotas = quotasToUpdate.filter(function(x){
				  	return x.main_type_name === editedQuotasTypes[i];
				  });
				  var percentSum = 0;
				  for(var j = 0 ; j < correspondentQuotas.length; j++){
                      percentSum+=correspondentQuotas[j].percent;
				  }
				  if(Math.floor(percentSum)!==100){
				  	vm.showCustomAlert("sum_percents_error",editedQuotasTypes[i]);
				  	areQuotasCorrect = false;
				  }else{
                      vm.removeSolvedError(editedQuotasTypes[i]);
				  }
			  }
			  return areQuotasCorrect;
        };

        vm.removeSolvedError = function(type){
            var index = vm.errorParams.indexOf(type);
            if(index > -1){
                vm.errorParams.splice(index,1);
                vm.showErrorMessage(vm.errorParams);
            }
		};

		vm.parseQuotasBeforeSave = function (editedQuotas) {

			var editedQuotasTypes = [];

			// find correspondent quota type for edited quotas
			for(var i = 0 ; i < vm.quotas.length; i++){
				if(!editedQuotasTypes.includes(vm.quotas[i].main_type_name)){
                    var correspondentEditedQuota = editedQuotas.find(function(x){
                    	return x.quota_id === vm.quotas[i].quota_id;
					});
                    if(vm.quotas[i].percent !== correspondentEditedQuota.percent){
                        editedQuotasTypes.push(vm.quotas[i].main_type_name);
                    }else{
                         vm.removeSolvedError(vm.quotas[i].main_type_name);
					}
				}
			}

			if(editedQuotasTypes.length > 0){
				var quotasToUpdate = [];
				for(var k = 0 ; k  < editedQuotasTypes.length; k++){
					var quotasWithType = editedQuotas.filter(function(x){
						return x.main_type_name === editedQuotasTypes[k];
					});
					if(quotasWithType.length > 0){
						for(var j = 0 ; j < quotasWithType.length ; j++){
							quotasToUpdate.push(quotasWithType[j]);
						}
					}
				}

				var areQuotasCorrect = vm.validateQuotas(quotasToUpdate,editedQuotasTypes);
				if(areQuotasCorrect){
                    return quotasToUpdate;
				}else{
					return null;
				}
			}
			else{
				vm.showCustomAlert("no_update_required",null);
				return null;
			}
        };

		vm.showCustomAlert = function(key,params){
			if(key === "no_update_required"){
                vm.quotasError = "";
                var alert = $mdDialog.alert({
                    title: 'Attention',
                    textContent: 'There are no modified quotas',
                    ok: 'Close'
                });
                $mdDialog.show( alert );
			}else if(key === "saved_succes"){
                var alert = $mdDialog.alert({
                    title: 'Success',
                    textContent: 'Modified quotas had been saved.',
                    ok: 'Close'
                });
                $mdDialog.show( alert );
			}else if(key === "sum_percents_error"){
				if(!vm.errorParams.includes(params)){
                    vm.errorParams.push(params);
                    vm.showErrorMessage(vm.errorParams);
				}
			}else if(key === "internal_server_error"){
                var alert = $mdDialog.alert({
                    title: 'Error',
                    textContent: 'Internal server error. Please contact the support team',
                    ok: 'Close'
                });
                $mdDialog.show( alert );
			}
		};

		vm.showErrorMessage = function(quotasTypes){
            vm.quotasError = "";
			if(quotasTypes.length > 0){
				for(var i = 0 ; i < quotasTypes.length ; i++){
                    vm.quotasError += '<li>Quotas with type ' + quotasTypes[i] + ' has percents that does not sum up to 100</li>';
				}
			}
		};

		vm.saveEditedQuotas = function () {
		   var quotas = vm.parseQuotasBeforeSave(vm.tableDataset);
		   if(quotas!==null){
               SurveysService.PostEditedQuotas(quotas,
                   function (response) {
                       vm.quotasError = "";
                       vm.showCustomAlert("saved_succes",null);
                   },
                   function (response) {
                       vm.showCustomAlert("internal_server_error",null);
                   }
               );
		   }
		   console.log(quotas);
        };

		(function initController() {
			vm.breadcrumbs = "Surveys : CSV Results";
			vm.activeSubmenu = 'surveys';
			vm.surveyId = $stateParams.qid || 1;
			vm.toLanguage = null;
			vm.areQuotasEditable = false;
            vm.quotasError = "";
            vm.errorParams = [];
			vm.csvButtonDisabled = false;
			vm.importFunction = SurveysService.importSurveyResultsFromCsv;

			SurveysService.GetSurveyJson(vm.surveyId, false,vm.toLanguage, function (response) {
				vm.quest = response.data;
				for(var i=0; i< vm.quest.questions.length; i++) {
					if (vm.quest.questions[i].type === "multipleChoice") {
						multiples.push(vm.quest.questions[i].id);
					}
				}
			});
			vm.getQuotaStats();

            vm.loadDataFromDB();

		})();
	}
})();
