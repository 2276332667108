(function () {
    'use strict';


    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    function configureStack(dataProvider,color,key,options) {
        if(options && options.rotate===true){
            dataProvider.graphsData.push({
                "type": "ColumnSeries",
                "name":key,
                "stacked" : true,
                "columns" : {
                    "template" : {
                        "strokeOpacity" : 0,
                        "fillOpacity" : 0.8,
                        "tooltipText":key + ": [bold]{valueX.value}"
                    }
                },
                "events": {
                    "datavalidated": function (ev) {
                        var dataItems = ev.target.dataItems.values;
                        for(var i = 0 ; i< dataItems.length; i++){
                            if(dataItems[i].valueX <=0){
                                dataItems[i].hidden = true;
                            }
                        }
                    }
                },
                "dataFields": {
                    "valueX": key,
                    "categoryY" : options.showBase ? "titleWithBase"  : "title"
                },
                "bullets": [{
                    "type": "LabelBullet",
                    "locationX" : 0.5,
                    "label":{
                        "text" : "{valueX} "
                    }
                }]
            });
        }else{
            dataProvider.graphsData.push({
                "type": "ColumnSeries",
                "name":key,
                "stacked" : true,
                "columns" : {
                    "template" : {
                        "strokeOpacity" : 0,
                        "fillOpacity" : 0.8,
                        "tooltipText":key + ": [bold]{valueY.value}"
                    }
                },
                "events": {
                    "datavalidated": function (ev) {
                        var dataItems = ev.target.dataItems.values;
                        for(var i = 0 ; i< dataItems.length; i++){
                            if(dataItems[i].valueY <=0){
                                dataItems[i].hidden = true;
                            }
                        }
                    }
                },
                "dataFields": {
                    "valueY": key,
                    "categoryX" : options.showBase ? "titleWithBase"  : "title"
                },
                "bullets": [{
                    "type": "LabelBullet",
                    "locationY" : 0.5,
                    "label":{
                        "text" : "{valueY} "
                    }
                }]
            });
        }
    }
    
    function configureCluster(dataProvider,color,key,options) {
        if(options && options.rotate===true){
            dataProvider.graphsData.push({
                "type": "ColumnSeries",
                "name":key,
                "columns" : {
                    "template" : {
                        "strokeOpacity" : 0,
                        "fillOpacity" : 0.8,
                        "tooltipText":key + ": [bold]{valueX.value}"
                    }
                },
                "events": {
                    "datavalidated": function (ev) {
                        var dataItems = ev.target.dataItems.values;
                        for(var i = 0 ; i< dataItems.length; i++){
                            if(dataItems[i].valueX <=0){
                                dataItems[i].hidden = true;
                            }
                        }
                    }
                },
                "dataFields": {
                    "valueX": key,
                    "categoryY" : options.showBase ? "titleWithBase"  : "title"
                },
                "bullets": [{
                    "type": "LabelBullet",
                    "label":{
                        "horizontalCenter" : "left",
                        "dx" : 10,
                        "hideOversised" : false,
                        "truncate" : false,
                        "text" : "{valueX} "
                    }
                }]
            });
        }else{
            dataProvider.graphsData.push({
                "type": "ColumnSeries",
                "name":key,
                "columns" : {
                    "template" : {
                        "strokeOpacity" : 0,
                        "fillOpacity" : 0.8,
                        "tooltipText":key + ": [bold]{valueY.value}"
                    }
                },
                "events": {
                    "datavalidated": function (ev) {
                        var dataItems = ev.target.dataItems.values;
                        for(var i = 0 ; i< dataItems.length; i++){
                            if(dataItems[i].valueY <=0){
                                dataItems[i].hidden = true;
                            }
                        }
                    }
                },
                "dataFields": {
                    "valueY": key,
                    "categoryX" : options.showBase ? "titleWithBase"  : "title"
                },
                "bullets": [{
                    "type": "LabelBullet",
                    "label":{
                        "verticalCenter" : "bottom",
                        "hideOversised" : false,
                        "truncate" : false,
                        "text" : "{valueY} "
                    }
                }]
            });
        }
    }

    function setDataProvider(dataProvider,color,key,options){

        switch (options.type) {
            case 'stack':
                configureStack(dataProvider,color,key,options);
                return;
            case 'cluster':
                configureCluster(dataProvider,color,key,options);
                return;
        }
    }

    angular
        .module('questiaPlatformApp')
        .factory('AmCharts4Utils', ['$filter', '$window', '$rootScope', 'ChartsService','ChartsJsonBuilder', AmCharts4Utils]);

    function AmCharts4Utils($filter, $window, $rootScope, ChartsService,ChartsJsonBuilder) {
        var dknaLabel;
        switch ($window.location.hostname) {
            case "admin2.questia.ro":
                dknaLabel = "Nu ştiu/Nu răspund";
                break;
            default:
                dknaLabel = "Don't know/No answer";
                break;
        }

        return {

            DKNA: dknaLabel,

            CHART_GREY_BAR_COLOR: "#A8A8A8",

            GREYS: ["#787878", "#909090", "#A8A8A8", "#C0C0C0", "#D8D8D8"],

            CHART_COLORS: [ "#B1CA54", "#095478", "#1DA77D", "#F8A587", "#9A1B3C", "#D5DF83", "#318BA8",
                            "#6BC399", "#FCC9B2", "#CB2B56", "#E0EABB", "#A4D5E5", "#C4E7D6", "#F8804B",
                            "#EDA8BA", "#AA5C93", "#751BB7", "#C260E0", "#D6D6D6", "#B5B5B5", "#ADD6F7",
                            "#00AFF0", "#0078D7", "#B0FFAF", "#D1BE96"],

            //for stacked column - some fonts need contrast
            FONT_COLORS: [
                "#595959", "#FFFFFF", "#595959", "#595959", "#FFFFFF",
                "#595959", "#FFFFFF", "#595959", "#595959", "#FFFFFF",
                "#595959", "#595959", "#595959", "#595959", "#595959"
            ],

            DEFAULT_CHART_CONFIG: {
                height:  500,
                width: 1400,
                scale: 100,
                marginLeft: 250,
                labelRadius: 70,
                maxLabelWidth: 150,
                marginBottom: 100,
                nsnrOptions: 0,
                //colors array defined in chart utils
                primaryColor: "#B1CA54",
                targetColor: "#FFFFFF",
                crossColumn: '',
                crossColor: '',
                crossHistory: [],
                targetChoice : {},
                previousChoices: [],
                crossWithId: null,
                fontSize: 19,
                fontFamily: "Calibri",
                fontColor: "#595959",
                backgroundColor: "#f4f4f4",
                sort: true,
                innerRadius: 50,
                showLegend: false,
                rotate: true,
                dknaOption: 0,
                redoRequest: false,
                reverseData: false
            },

            buildChartConfig: function (id, dataProvider, options) {
                var q =  angular.extend(this._getSpecificConfig(dataProvider, options), this._getCommonConfig(id, options));
                return q;
            },

            _getCommonConfig: function (id, options) {
                return {
                    "theme": "light",
                    "startDuration": 0,
                    "marginRight": 100,
                    "marginTop": 50,
                    "addClassNames": true,
                    "fontFamily": this.DEFAULT_CHART_CONFIG.fontFamily,
                    "color": this.DEFAULT_CHART_CONFIG.fontColor,
                    "fontSize": options.fontSize,
                    "backgroundAlpha": 1,
                    "backgroundColor": options.backgroundColor,
                    "exporting": {
                        "menu": {
                            "align": "left"
                        }
                    },
                    "precision": 1
                };
            },

            _getSpecificConfig: function (dataProvider, options) {
                //cross between 2 questions?
                var self = this;
                if (options.crossWithId) return this._getSpecificConfigSelects(dataProvider, options);

                switch (options.type) {
                    case 'bar':
                        return this._getSpecificConfigBar(dataProvider, options);
                    case 'pie':
                        return this._getSpecificConfigPie(dataProvider, options);
                    case 'stack':
                        return this._getSpecificConfigSelects(dataProvider, options);
                    case 'cluster':
                        return this._getSpecificConfigSelects(dataProvider, options);
                    case 'radar':
                        return this._getSpecificConfigRadar(dataProvider, options);
                    default:
                        break;
                }
            },

            _getSpecificConfigBar: function (dataProvider, options) {

                var self = this;
                if (options.sort) {
                    dataProvider = this._sortDataProvider(dataProvider);
                }
                var title = "title";
                if (options.showBase) {
                    title = "titleWithBase";
                }
                for (var i = 0; i < dataProvider.length; i++) {
                    if (dataProvider[i].choiceVal >= 800) {
                        dataProvider[i].color = this.CHART_GREY_BAR_COLOR;
                    }
                    else {
                        dataProvider[i].color = options.primaryColor ? options.primaryColor : self.CHART_COLORS[0];
                    }
                }


                var xAxes = {};
                var yAxes = {};
                var dateField = {};
                var toolTipText = "";
                if(options.rotate === false){
                   xAxes = ChartsJsonBuilder.barXAxesVertical(title);
                   yAxes = ChartsJsonBuilder.barYAxesVertical(options);
                   dateField = {
                       "valueY": "value",
                       "categoryX" : title
                   };
                   toolTipText =  "{categoryX}: [bold]{valueY.value}";
                }else{
                   xAxes = ChartsJsonBuilder.barXAxesHorizontal(options);
                   yAxes = ChartsJsonBuilder.barYAxesHorizontal(title);
                   dateField = {
                        "categoryY": title,
                        "valueX" : "value"
                    };
                   toolTipText = "{categoryY}: [bold]{valueX.value}";
                }

                return ChartsJsonBuilder.barChartBody(dataProvider,xAxes,yAxes,dateField,toolTipText,options.primaryColor);

            },

            _getSpecificConfigRadar: function (dataProvider, options) {
                if (options.sort) {
                    dataProvider = this._sortDataProvider(dataProvider);
                }
                var title = "title";
                if (options.showBase) {
                    title = "titleWithBase";
                }
                return ChartsJsonBuilder.radarChartBody(dataProvider,title,options);
            },

            _getSpecificConfigPie: function (dataProvider, options) {
                var self = this;

                var targetColor = options.targetColor;
                var targetChoice = options.targetChoice;
                var previousChoices = options.previousChoices;

                if (options.sort) {
                    dataProvider = this._sortDataProvider(dataProvider);
                }
                var title = "title";
                if (options.showBase) {
                    title = "titleWithBase";
                }
                for (var i = 0; i < dataProvider.length; i++) {
                    if(dataProvider[i].choiceVal == targetChoice.choiceVal){
                        dataProvider[i].color = targetColor;
                        previousChoices.push(dataProvider[i]);

                    }else {

                        dataProvider[i].color = i < self.CHART_COLORS.length ? self.CHART_COLORS[i] : getRandomColor();

                        for(var k = 0; k < previousChoices.length; k++){
                            if(dataProvider[i].choiceVal == previousChoices[k].choiceVal){
                                dataProvider[i].color = previousChoices[k].color;
                            }
                        }
                    }

                }

                return{
                    "data": dataProvider,
                    "paddingRight" : 50,
                    "colorField": "color",
                    "series": [{
                        "type": "PieSeries",
                        "dataFields": {
                            "value": "value",
                            "category": title
                        },
                        "ticks": {
                            "template": {
                                "adapter": {
                                    "hidden": function hideSmall(hidden, target) {
                                        return target.dataItem.values.value.percent > 0 ? false : true;
                                    }
                                }
                            }
                        },
                        "labels": {
                            "adapter": {
                                "hidden": function hideSmall(hidden, target) {
                                    return target.dataItem.values.value.percent > 0 ? false : true;
                                }
                            }
                        },
                        "slices":{
                            "propertyFields": {
                                "fill": "color",
                                "stroke": "color"
                            }
                        }
                    }],
                    "legend": {
                        "visible" : options.showLegend
                    },
                    "innerRadius" : 60
                };
            },

            _getSpecificConfigSelects: function (dataProvider, options) {
                //sort by UI column
                function comparatorFunc(sortOptions) {
                    return function (a, b) {
                        var valA = parseFloat(a[Object.keys(a)[sortOptions.columnToSortBy]]);
                        var valB = parseFloat(b[Object.keys(a)[sortOptions.columnToSortBy]]);
                        if (valA < valB)
                            return (sortOptions.direction == 'Descending' ? -1 : 1);
                        if (valA > valB)
                            return (sortOptions.direction == 'Descending' ? 1 : -1);
                        return 0;
                    }
                }

                if (options.type === "cluster" || options.type ==="stack") {
                    dataProvider.graphsData = [];
                    dataProvider.legendData = [];

                    var crossColorValue = options.crossColor;
                    var crossColumnValue = options.crossColumn;
                    var ref = this;
                    var index = 0;

                    var title = "title";
                    if (options.showBase && !options.crossWithId && !options.crossWithDivisionId) {
                        title = "titleWithBase";
                    }

                    angular.forEach(dataProvider.dataProvider.values[0], function (value, key) {
                        if (["title", "titleWithBase"].indexOf(key) === -1) {
                            var color = ref.CHART_COLORS[index];
                            index = index + 1;
                            if(key===crossColumnValue){
                                setDataProvider(dataProvider,crossColorValue,key,options);
                                var obj = {};
                                obj.name = key;
                                obj.color = crossColorValue;
                                options.crossHistory.push(obj);
                            }else {

                                if(options.crossHistory.length > 0) {
                                    for (var i = 0; i < options.crossHistory.length; i++) {
                                        if (options.crossHistory[i].name === key) {
                                            color = options.crossHistory[i].color;
                                        }
                                    }
                                }
                                setDataProvider(dataProvider,color,key,options);
                            }
                        }
                    });
                }

                //choose only some elements from the data provider?
                var dataP = dataProvider.dataProvider.values;
                if (options.stackArray && options.stackArray.length > 0) {
                    dataP = [];
                    for (i = 0; i < dataProvider.dataProvider.values.length; i++) {
                        if (options.stackArray.indexOf(i) !== -1) {
                            dataP.push(dataProvider.dataProvider.values[i]);
                        }
                    }
                }

                if (options.sortBy) {
                    if (options.sortBy.order) options.sortBy.direction = "Ascending";
                    else options.sortBy.direction = "Descending";
                    if (options.sortBy.columnToSortBy !== null && options.sortBy.columnToSortBy !== -1) dataP.sort(comparatorFunc(options.sortBy));
                }
                //graphs font size
                for (var i = 0; i < dataProvider.graphsData.length; i++) {
                    dataProvider.graphsData[i].fontSize = options.fontSize;
                }
                if (options.reverseData) {
                    dataProvider.graphsData.reverse();
                }

                var data = {
                    title: title,
                    dataP: dataP,
                    graphsData: dataProvider.graphsData,
                    options: options
                };

                if (options.type === "cluster") {
                    return this._getSpecificConfigCluster(data);
                }
                else {
                    return this._getSpecificConfigStack(data);
                }
            },

            _getSpecificConfigCluster: function (data) {

               // am4core.useTheme(am4themes_animated);

                if(data.options && data.options.rotate===true) {
                    var xAxes = ChartsJsonBuilder.clusterXAxesHorizontal(data);
                    var yAxes = ChartsJsonBuilder.clusterYAxesHorizontal(data);
                }else{
                    var xAxes = ChartsJsonBuilder.stackedXAxesVertical(data);
                    var yAxes = ChartsJsonBuilder.stackedYAxesVertical(data);
                }

                return ChartsJsonBuilder.selectsChartBody(data,this.CHART_COLORS,xAxes,yAxes);
            },

            _getSpecificConfigStack: function (data) {

                //am4core.useTheme(am4themes_animated);

                if(data.options && data.options.rotate===true) {
                    var xAxes = ChartsJsonBuilder.stackedXAxesHorizontal(data);
                    var yAxes = ChartsJsonBuilder.stackedYAxesHorizontal(data);
                }else{
                    var xAxes = ChartsJsonBuilder.stackedXAxesVertical(data);
                    var yAxes = ChartsJsonBuilder.stackedYAxesVertical(data);
                }

                return ChartsJsonBuilder.selectsChartBody(data,this.CHART_COLORS,xAxes,yAxes);
            },

            crossChange: function (question, chartConfig) {
                if (!chartConfig.cross) {
                    //RESET CROSS
                    chartConfig.crossWithId = null;
                    chartConfig.crossWithDivisionId = null;

                    if (question.type === "selects") {
                        chartConfig.selectsCrossWith = false;
                        chartConfig.selectsId = -1;
                        chartConfig.type = 'stack';
                    }
                    else {
                        chartConfig.crossWithSelects = false;
                        chartConfig.selectsOptions = null;
                        chartConfig.type = 'bar';
                    }
                }
                else {
                    switch (chartConfig.cross) {
                        case "age":
                            chartConfig.crossWithId = $rootScope.globals.asl.ageID;
                            chartConfig.crossWithDivisionId = null;
                            break;
                        case "sex":
                            chartConfig.crossWithId = $rootScope.globals.asl.sexID;
                            chartConfig.crossWithDivisionId = null;
                            break;
                        case "location":
                            chartConfig.crossWithId = $rootScope.globals.asl.locationID;
                            chartConfig.crossWithDivisionId = null;
                            delete chartConfig.crossWithLocationExt;
                            break;
                        case "location_ext":
                            chartConfig.crossWithId = $rootScope.globals.asl.locationID;
                            chartConfig.crossWithLocationExt = true;
                            chartConfig.crossWithDivisionId = null;
                            break;
                        case "regions":
                            chartConfig.crossWithId = null;
                            break;
                        default:
                            //
                            break;
                    }
                    if (question.type !== "selects") {
                        chartConfig.type = (question.type === "multipleChoice") ? 'cluster' : 'stack';
                        chartConfig.crossWithSelects = false;
                    }
                    else {
                        chartConfig.selectsCrossWith = true;
                    }
                }
            },

            determineChartRequestUrl: function (question, chartConfig, questionConfig) {
                if (chartConfig.crossWithDivisionId) {
                    // select cross with division
                    if (chartConfig.selectsCrossWith)
                        return "select/" + chartConfig.selectsId + "/division/" + chartConfig.crossWithDivisionId;
                    return question.question_id + "/division/" + chartConfig.crossWithDivisionId;
                }
                //single choice cross with a selects item
                if (chartConfig.crossWithSelects) {
                    if (chartConfig.transposeCross) {
                        return "select/" + chartConfig.crossWithSelectsId + "/" + question.question_id;
                    }
                    return question.question_id + "/select/" + chartConfig.crossWithSelectsId;
                }
                //selects item cross with a single choice
                else if (chartConfig.selectsCrossWith) {
                    if (chartConfig.transposeCross) {
                        return chartConfig.crossWithId + "/select/" + chartConfig.selectsId;
                    }
                    return "select/" + chartConfig.selectsId + "/" + chartConfig.crossWithId;
                }
                if(chartConfig.crossWithId && question.type === "cityCounty") {
                    switch (questionConfig.cityCountyType) {
                        case "rural_urban":
                            return question.question_id + "/" + chartConfig.crossWithId;
                        case "division":
                            return chartConfig.crossWithId + "/division/" + questionConfig.divisionId;
                    }
                }
                return chartConfig.crossWithId ? question.question_id + "/" + chartConfig.crossWithId : question.question_id;
            },

            buildDataProvider: function (rawData, options) {
                if (options && (options.crossWithId || options.crossWithDivisionId)) {
                    return this._buildDataProviderCross(rawData, options);
                }
                if (options && options.questionType === 'selects') {
                    return this._buildDataProviderSelects(rawData, options);
                }

                return this._buildDataProviderSingleMultipleChoice(rawData, options);
            },

            _buildDataProviderSelects: function (rawData, options) {
                var dataProvider = [];
                var graphsData = [];
                var key;
                var self = this;
                var total;
                for (key in rawData) {
                    // skip loop if the property is from prototype
                    if (!rawData.hasOwnProperty(key)) continue;

                    var orderedValues = [];
                    total = rawData[key]['total'];

                    for (var label in rawData[key]) {
                        // skip loop if the property is from prototype
                        if (!rawData[key].hasOwnProperty(label)) continue;
                        if (label === 'total') {
                            continue;
                        }
                        var splits = label.split(";;");
                        if (splits[0] === '999') {
                            splits[1] = this.DKNA;
                        }
                        orderedValues.push({
                            "title": splits[1],
                            "count": rawData[key][label],
                            "order": splits[0]
                        });
                    }
                    orderedValues.sort(function (a, b) {
                        if (a.order > b.order)return 1;
                        if (a.order < b.order)return -1;
                        return 0;
                    });
                    var newValues = {};
                    var codes = [];
                    for (var i = 0; i < orderedValues.length; i++) {
                        newValues[orderedValues[i].title] = orderedValues[i].count / total;
                        codes.push(orderedValues[i].order);
                    }
                    dataProvider.push({
                        "title": key,
                        "titleWithBase": key + " (" + total + ")",
                        "values": newValues,
                        "codes": codes
                    });
                }

                var dataProviderFinal = {
                    total: total,
                    values: []
                };
                for (var index = 0; index < dataProvider.length; index++) {
                    dataProviderFinal.values.push({
                        title: dataProvider[index].title,
                        titleWithBase: dataProvider[index].titleWithBase
                    });

                    var index2 = 0;

                    for (key in dataProvider[index].values) {
                        // skip loop if the property is from prototype
                        if (!dataProvider[index].values.hasOwnProperty(key)) continue;
                        dataProviderFinal.values[index][key] = (dataProvider[index].values[key] * 100).toFixed(1);
                        if (index === 0) {
                            var color, fontColor;
                            //greys?
                            if (dataProvider[index].codes[index2] >= 900) {
                                color = self.GREYS[index2 % 5];
                                fontColor = "#000000";
                            }
                            else {
                                color = index2 < self.CHART_COLORS.length ? self.CHART_COLORS[index2] : getRandomColor();
                                fontColor = index2 < self.FONT_COLORS.length ? self.FONT_COLORS[index2] : self.DEFAULT_CHART_CONFIG.fontColor;
                            }
                            graphsData.push({
                                "balloonText": "<span style='font-size:14px'>" + key + ": <b>[[value]]%</b></span>",
                                "fillAlphas": 1,
                                "labelText": "[[value]]%",
                                "labelPosition ": "inside",
                                "lineAlpha": 1,
                                "color": fontColor,
                                "fontSize": options.fontSize,
                                "title": key,
                                "type": "column",
                                "lineColor": color,
                                "valueField": key,
                                "_code": parseInt(dataProvider[index].codes[index2])
                            });
                        }
                        index2++;
                    }
                }
                return {
                    dataProvider: dataProviderFinal,
                    graphsData: graphsData
                };
            },

            _buildDataProviderSingleMultipleChoice: function (rawData, options) {
                var dataProvider = {
                    total: 0,
                    values: []
                };
                var total = 0;
                if (rawData['total']) {
                    total = rawData['total'];
                }
                else {
                    for (var key in rawData) {
                        if (!rawData.hasOwnProperty(key)) continue;
                        total += rawData[key];
                    }
                }
                dataProvider.total = total;

                for (var key in rawData) {
                    if (!rawData.hasOwnProperty(key)) continue;

                    if (key.indexOf("#") === 0) {
                        var splittedKey = key.split("#");
                        //label for DK/NA
                        if (splittedKey[2] === "NsNr") {
                            splittedKey[2] = this.DKNA;
                        }
                        dataProvider.values.push({
                            "choiceVal": parseInt(splittedKey[1]),
                            "title": splittedKey[2],
                            "titleWithBase": splittedKey[2] + " (" + rawData[key] + ")",
                            "value": (rawData[key] * 100. / total).toFixed(1)
                        });
                    }
                }
                dataProvider.values = _.sortBy(dataProvider.values, function (obj) {
                    return obj.choiceVal;
                });
                return dataProvider;
            },

            _buildDataProviderCross: function (data, options) {
                var i, ii;
                var dataProvider = {
                    total: 0,
                    values: []
                };
                var graphsData = [];
                var totals = [];
                var self = this;

                if(options.sort && options.questionType !== 'selects') {
                    data = this._sortCrossData(data);
                }

                //server always treats location as extended (rural, urban small, urban medium and urban big)
                //transform data for location not(ext) -> rural-urban simple
                //here we know that the data is an array of 4, so it's easier
                //not very clean, generic code, but simple enough to change
                if (options.cross === "location") {
                    //remove all values after first two - last three are all Urban in fact
                    data.labels2.length = 2;
                    data.labels2[1] = "Urban";
                    for (i = 0; i < data.crossMatrix.length; i++) {
                        //1 2 and 3 are all parts of Urban - so add them up
                        data.crossMatrix[i][1] += data.crossMatrix[i][2] + data.crossMatrix[i][3];
                        //remove 2 and 3, already added
                        data.crossMatrix[i].length = 2;
                    }
                }
                //calc percentages
                if (options.questionType === "multipleChoice" && options.multipleQuestionBaseScale) {
                    angular.forEach(options.multipleQuestionBaseScale, function (value, key) {
                        if (key !== "total") {
                            totals.push({
                                index: parseInt(key.split("#")[1]),
                                value: value
                            });
                        }
                    });

                    if(options.sort){
                        totals = $filter('orderBy')(totals, 'value', true);
                    } else {
                        totals = $filter('orderBy')(totals, 'index');
                    }

                    for (i = 0; i < data.crossMatrix.length; i++) {
                        for (ii = 0; ii < data.crossMatrix[i].length; ii++) {
                            data.crossMatrix[i][ii] = (data.crossMatrix[i][ii] / totals[i].value * 100).toFixed(1);
                        }
                    }
                }
                else {
                    for (i = 0; i < data.crossMatrix.length; i++) {
                        var lineTotal = 0;
                        for (ii = 0; ii < data.crossMatrix[i].length; ii++) {
                            lineTotal += data.crossMatrix[i][ii];
                        }
                        for (ii = 0; ii < data.crossMatrix[i].length; ii++) {
                            data.crossMatrix[i][ii] = (data.crossMatrix[i][ii] / lineTotal * 100).toFixed(1);
                        }
                        totals.push(lineTotal);
                    }
                }

                //transform data to chart-form data
                for (var index = 0; index < data.crossMatrix.length; index++) {
                    if (options.showBase) {
                        if (options.questionType !== "multipleChoice") {
                            dataProvider.values.push({title: data.labels1[index] + " (" + totals[index] + ") "});
                        }
                        else if (options.questionType === "multipleChoice") {
                            dataProvider.values.push({title: data.labels1[index] + " (" + totals[index].value + ") "});
                        }

                    }
                    else {
                        dataProvider.values.push({title: data.labels1[index]});
                    }
                    for (ii = 0; ii < data.crossMatrix[index].length; ii++) {
                        dataProvider.values[index][data.labels2[ii]] = data.crossMatrix[index][ii];
                    }
                }
                return {
                    title1: data.title1,
                    title2: data.title2,
                    dataProvider: dataProvider,
                    graphsData: graphsData
                };
            },

            //sort only values < 800
            _sortDataProvider: function (dataProvider) {

                var specialValues = dataProvider.filter(function (obj) {
                    return obj.choiceVal >= 800;
                });
                var normalValues = dataProvider.filter(function (obj) {
                    return obj.choiceVal < 800;
                });
                normalValues = _.sortBy(normalValues, function (obj) {
                    return -obj.value;
                });
                return normalValues.concat(specialValues);
            },

            _sortCrossData: function (data) {
                var arrayToSort = data.crossMatrix.map(function (elem, index) {
                    var sum = elem.reduce(function (sum, currentValue) {
                        return sum + currentValue
                    });

                    return {
                        sum: sum,
                        label: data.labels1[index],
                        values: elem
                    }
                });

                arrayToSort.sort(function (a, b) {
                    return b.sum - a.sum;
                });

                for(var i = 0; i < data.crossMatrix.length; i++) {
                    data.crossMatrix[i] = arrayToSort[i].values;
                    data.labels1[i] = arrayToSort[i].label;
                }

                return data;
            },

            getChartType : function(chartConfig){
            var chartType = null;
            switch (chartConfig) {
                case "bar":
                    chartType = am4charts.XYChart;
                    break;
                case "pie":
                    chartType = am4charts.PieChart;
                    break;
                case "radar":
                    chartType = am4charts.RadarChart;
                    break;
                case 'stack':
                    chartType = am4charts.XYChart;
                    break;
                case 'cluster':
                    chartType = am4charts.XYChart;
                    break;
                default :
                    break
            }
            return chartType;
        },

            getColors : function () {
                return this.CHART_COLORS;
            }

        };
    }
})();
