(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('SurveyTranslationUtils', ['$rootScope', 'QuestiaUtils', SurveyTranslationUtils]);

	function SurveyTranslationUtils($rootScope, QuestiaUtils) {
		return {
			validateTranslation: function (title, original, translation) {
				if (!title)
					return "Empty title!";
				if (QuestiaUtils.isEmptyObject(translation))
					return "Empty translation!";

				for (var questionIndex = 0; questionIndex < original.questions.length; questionIndex ++) {
					var question = original.questions[questionIndex];
					var questionId = question.id;
					var questionTranslation = translation[questionId];

					if (QuestiaUtils.isEmptyObject(questionTranslation))
						return "No translations filled for question " + questionId + "!";
					if (!questionTranslation.text)
						return "Empty text for question " + questionId + "!";

					var originalChoices = question.choices;
					var translationChoices = translation[questionId].choices;
					if (!QuestiaUtils.isEmptyObject(originalChoices)) {
						if (QuestiaUtils.isEmptyObject(translationChoices))
							return "No choices filled for question " + questionId + "!";

						for (var choiceIndex = 0; choiceIndex < originalChoices.length; choiceIndex++) {
							var choiceValue = originalChoices[choiceIndex].value;
							var choiceLabel = originalChoices[choiceIndex].label;
							if (!translationChoices[choiceValue])
								return "Empty text for question " + questionId + ", choice " + choiceLabel + "!";
						}
					}

					var originalSelects = question.selects;
					var translationSelects = translation[questionId].selects;
					if (!QuestiaUtils.isEmptyObject(originalSelects)) {
						if (QuestiaUtils.isEmptyObject(translationSelects))
							return "No selects filled for question " + questionId + "!";

						for (var selectIndex = 0; selectIndex < originalSelects.length; selectIndex ++) {
							var originalSelect = originalSelects[selectIndex];
							var originalSelectId = originalSelect.id;
							var originalSelectLabel = originalSelect.label;

							if (QuestiaUtils.isEmptyObject(translationSelects[originalSelectId]))
								return "Empty select item for question " + questionId + ", select label " + originalSelectLabel + "!";
							if (!translationSelects[originalSelectId].text)
								return "Empty select text for question " + questionId + ", select label " + originalSelectLabel + "!";

							var originalSelectOptions = originalSelect.options;

							if (translationSelects[originalSelectId].options === undefined) translationSelects[originalSelectId].options = {};
							var translationSelectOptions = translationSelects[originalSelectId].options;

							if (!QuestiaUtils.isEmptyObject(originalSelectOptions)) {
								if (QuestiaUtils.isEmptyObject(translationSelectOptions)) {
									if (question.type === "ranking") {
										for (var optionIndex = 1; optionIndex <= originalSelectOptions.length; optionIndex++) {
											translationSelectOptions[optionIndex] = optionIndex.toString();
										}
									} else return "No options filled for question " + questionId + "!";
								}
								for (var optionIndex = 0; optionIndex < originalSelectOptions.length; optionIndex++) {
									var originalOptionValue = originalSelectOptions[optionIndex].value;
									var originalOptionLabel = originalSelectOptions[optionIndex].label;

									if (!translationSelectOptions[originalOptionValue])
										return "Empty option for question " + questionId + ", option " + originalOptionLabel + "!";
								}
							}
						}
					}
				}
				return "";
			}
		}
	}
})();