(function () {
	'use strict';

	angular
		.module('questiaPlatformApp').directive('surveyAddPredefined', function () {
		return {
			restrict: 'E',
			scope: {
				qIdx: '=',
				predefinedQuestions: '=',
				addPredefinedQuestion: '&'
			},
			templateUrl: 'js/ng-templates/subviews/surveys/survey.addpredefined.directive.html',
			link: function (scope, elm, attrs) {
				scope.callUpdate = function ($index) {
					var predefinedQuestion = scope.predefinedQuestions[$index];
					scope.addPredefinedQuestion()(scope.qIdx, predefinedQuestion);
				}
			}
		};
	})
})();