(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .controller('ConfigurationsController', ConfigurationsController);

    ConfigurationsController.$inject = ['ConfigurationsService', '$mdDialog'];

    function ConfigurationsController(ConfigurationsService, $mdDialog) {
        var vm = this;

        vm.isConfigsValid = function (key, configuration) {
            var invalidConfigsMessages = [];
                    if (typeof configuration.points_number === "undefined" || configuration.points_number > 10000 || configuration.points_number < 0) {
                        invalidConfigsMessages.push("Number of points for \"" + vm.rewardsConfigsTitles[key] + "\" activity is not valid!")
                    }
                    if (typeof configuration.tickets_number === "undefined" || configuration.tickets_number > 10000 || configuration.tickets_number < 0) {
                        invalidConfigsMessages.push("Number of tickets for \"" + vm.rewardsConfigsTitles[key] + "\" activity is not valid!")
                    }
                    if (typeof configuration.tickets_permanent === "undefined" || !(configuration.tickets_permanent === 0 || configuration.tickets_permanent === 1)) {
                        invalidConfigsMessages.push("Ticket temporal type for \"" + vm.rewardsConfigsTitles[key] + "\" activity is not valid!")
                    }
                    if (typeof configuration.tickets_type === "undefined" || !(configuration.tickets_type === "regular" || configuration.tickets_type === "special")) {
                        invalidConfigsMessages.push("Ticket type for \"" + vm.rewardsConfigsTitles[key] + "\" activity is not valid!")
                    }
            return invalidConfigsMessages;
        };

        vm.saveRewardsConfiguration = function (key, configuration) {
            var invalidMessagesArray = vm.isConfigsValid(key, configuration);

            if(invalidMessagesArray.length > 0) {
                vm.validationMessages = invalidMessagesArray;
            } else {
                vm.validationMessages = [];
                var payload = {
                    key: key,
                    rewardsConfiguration: configuration
                };

                ConfigurationsService.saveRewardsConfiguration(payload,
                    function success(response) {
                        $mdDialog.show(
                            $mdDialog.alert()
                                .title('Success')
                                .textContent("Successfully saved configurations for \"" + vm.rewardsConfigsTitles[key] + "\" activity!")
                                .ok('Close')
                        );
                    }, function error (response) {
                        $mdDialog.show(
                            $mdDialog.alert()
                                .title('Config Alert')
                                .textContent("Failed to save the configurations for \"" + vm.rewardsConfigsTitles[key] + "\" activity!")
                                .ok('Close')
                        );
                    });
            }
        };

        (function initController() {
            vm.activeSubmenu = 'setup_rewards';
            vm.breadcrumbs = 'Setup: Configurations';
            vm.loading = true;
            vm.error = false;
            vm.validationMessages = [];

            ConfigurationsService.getRewardsConfigurations(function success (response) {
                vm.rewardsConfigs = response.data;
                vm.rewardsConfigsTitles = {};
                vm.rewardsConfigsTitles["REWARDS_BIRTHDAY"] = "Birthday";
                vm.rewardsConfigsTitles["REWARDS_PROFILE_COMPLETE"] = "Complete profile settings";
                vm.rewardsConfigsTitles["REWARDS_INVITE_REC_BY"] = "Invite friends";
                vm.loading = false;
                console.log(response.data);
            }, function error(response) {
                vm.error = true;
                vm.loading = false;
            })
        })();

    }
})();
