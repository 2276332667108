(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('ForecastStatisticsService', ForecastStatisticsService);

	ForecastStatisticsService.$inject = ['$http'];
	function ForecastStatisticsService($http) {
		return {
			GetStats: function (url, okCallback, koCallback) {
				$http.get(url)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			}
		}
	}
})();
