(function () {
    'use strict';
    angular
        .module('questiaPlatformApp')
        .factory('SnackbarUtils', ['toaster', SnackbarUtils]);

    function SnackbarUtils(toaster) {
        return {
            displaySnackbar: function(type, message) {
                if (!["success", "warning", "error"].includes(type))
                    type = "error";

                var data = {type, message};
                var snackbarData = {
                    template: 'js/ng-templates/subviews/snackbars/snackbar.subview.html',
                    data: data
                };

                toaster.pop(type, null, JSON.stringify(snackbarData), null, 'templateWithData');
            },
            displaySnackbarWithLanguagesEnum: function (type, message, languagesList) {
                if (!["success", "warning", "error"].includes(type))
                    type = "error";

                var data = {type, message, languagesList};
                var snackbarData = {
                    template: 'js/ng-templates/subviews/snackbars/snackbar.languages.subview.html',
                    data: data
                };

                toaster.pop(type, null, JSON.stringify(snackbarData), null, 'templateWithData');
            },
            displaySnackbarWithLink: function (type, message, linkState, linkText) {
                if (!["success", "warning", "error"].includes(type))
                    type = "error";

                var data = {
                    type: type,
                    message: message,
                    linkInfo: {linkState, linkText}
                };
                var snackbarData = {
                    template: 'js/ng-templates/subviews/snackbars/snackbar.link.subview.html',
                    data: data
                };

                toaster.pop(type, null, JSON.stringify(snackbarData), null, 'templateWithData');
            }
        };
    }
})();

