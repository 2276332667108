(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('RcsUtils', ['$rootScope', 'ChartsService', RcsUtils]);

	function RcsUtils($rootScope, ChartsService) {

		const STEP_LENGTH_LABEL_VISIBILITY = 12;

		return {
			getPercentages: function(object, dataArrayIndex) {
				var results = {};
				var total_abs = 0;
				var total = 0;
				for(var key in object) {
					if(key !== 'date'){
						total_abs += parseInt(object[key]);
					}
					if(key !== 'date' && parseInt(key) < 1000) {
						total += parseInt(object[key]);
					}
				}
				for(key in object) {
					if(key !== 'date' && parseInt(key) < 1000) {
						var percentage = total === 0 ? 0 : (parseInt(object[key]) * 100 / total).toFixed(2);
						results[key] = percentage;
						results[key + "_labelText"] = dataArrayIndex !== 0 && dataArrayIndex % STEP_LENGTH_LABEL_VISIBILITY === 0 ? percentage.toString() : "";
					}
					if(key === 'date') {
						results[key] = "" + object[key];
					}
				}
				results.total = total_abs;
				return results;
			},

			getGraphs: function (resp) {
				var graphs = [];
				for (var k in resp.labels) {
					if (parseInt(k) < 1000) {
						graphs.push({
							"id": k,
							"bullet": "round",
							"bulletBorderAlpha": 1,
							"bulletColor": "#FFFFFF",
							"bulletSize": 5,
							"hideBulletsCount": 50,
							"lineThickness": 2,
							"title": resp.labels[k],
							"useLineColorForBulletBorder": true,
							"valueField": k,
							"labelText": "[[" + k + "_labelText]]",
							"balloonText": resp.labels[k].split(" ")[0] + " " + (resp.labels[k].split(" ")[1] ? resp.labels[k].split(" ")[1]:"")  + " : [[value]]"
						});
					}
				}
				return graphs;
			},

			buildRcsChart: function(qid, resp, chartData) {

				function getBalloon(date) {
					return chartData.volumes[AmCharts.formatDate(date, "YYYY-MM-DD JJ:NN")] + " // " + AmCharts.formatDate(date, "DD/MM/YYYY JJ:NN:SS");
				}

				var colrs = [
					"#CB2B56", "#A4D5E5", "#318BA8", "#F8804B", "#9A1B3C",
					"#D5DF83", "#6BC399", "#FCC9B2", "#B1CA54", "#cccccc",
					"#095478", "#C4E7D6", "#F8A587", "#EDA8BA"
				];
				if(qid === 25536) {
					colrs = [
						"#318BA8", "#A4D5E5", "#F8804B", "#CB2B56", "#9A1B3C",
						"#D5DF83", "#6BC399", "#FCC9B2", "#B1CA54", "#cccccc",
						"#095478", "#C4E7D6", "#F8A587", "#EDA8BA"
					];
				}
				var graphs = this.getGraphs(resp);
				var chart = AmCharts.makeChart("chart" + qid, {
					"type": "serial",
					"theme": "light",
					"path": "/js/lib/amcharts",
					"zoomOutOnDataUpdate": false,
					"titles": [
						{
							"text": resp.question.question_text,
							"size": 15
						}
					],
					"marginRight": 80,
					"dataProvider": chartData.dataProvider,
					"dataDateFormat": "YYYY-MM-DD JJ:NN:SS",
					"valueAxes": [{
						"position": "left",
						"title": "Percent"
					}],
					"colors": colrs,
					"graphs": graphs,
					"chartScrollbar": {
						"graph": graphs[0].id,
						"scrollbarHeight": 50,
						"backgroundAlpha": 0,
						"selectedBackgroundAlpha": 0.1,
						"selectedBackgroundColor": "#888888",
						"graphFillAlpha": 0.5,
						"graphLineAlpha": 0.5,
						"selectedGraphFillAlpha": 0,
						"selectedGraphLineAlpha": 1,
						"autoGridCount": true,
						"color": "#AAAAAA"
					},
					"valueScrollbar": {
						"backgroundAlpha": 0,
						"selectedBackgroundColor": "#888888",
						"autoGridCount": true,
						"color": "#AAAAAA"
					},
					"chartCursor": {
						"cursorPosition": "mouse",
						"categoryBalloonFunction": getBalloon
					},
					"categoryField": "date",
					"categoryAxis": {
						"minPeriod": "mm",
						"parseDates": true
					},
					"legend": {
						"useGraphSettings": true,
						divId: "legend" + qid
					}
				});

				chart.addListener("rendered", zoomChart);
				zoomChart();

				// this method is called when chart is first inited as we listen for "rendered" event
				function zoomChart() {
					// different zoom methods can be used - zoomToIndexes, zoomToDates, zoomToCategoryValues
					chart.zoomToIndexes(chartData.dataProvider.length - 800, chartData.dataProvider.length - 1);
				}
				return chart;
			}
		};
	}
})();
