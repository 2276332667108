(function () {
	'use strict';
	var VERSION = "2.0.alpha3";
	var app = angular
		.module('questiaPlatformApp', ['textAngular', 'ui.router','ui.select', 'permission', 'permission.ui', 'ngMaterial', 'ngAnimate', 'ngAria', 'ngMessages', 'ngCookies', 'angular-loading-bar',
			'angularMoment', 'ngTable', 'angular-flatpickr', 'ngSanitize', 'ngCsv', 'ngMaterialDatePicker', 'datatables', 'datatables.select',
			'datatables.buttons', 'hl.sticky', 'ngQueue', 'ngMaterialSidemenu', 'ngOnload', 'moment-picker', 'ngFileUpload', 'angular-d3-word-cloud', 'ngImgCrop', 'toaster'])
		.config(config)
		.run(run);

	config.$inject = ['$compileProvider', '$stateProvider', '$urlRouterProvider', '$locationProvider', '$mdThemingProvider', '$mdAriaProvider', '$injector'];
	function config($compileProvider, $stateProvider, $urlRouterProvider, $locationProvider, $mdThemingProvider, $mdAriaProvider, $injector) {
		angular.lowercase = angular.$$lowercase;

		$mdAriaProvider.disableWarnings();
		$compileProvider.debugInfoEnabled(false);
		$compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|data|chrome-extension):/);
		$mdThemingProvider.theme('light-green').backgroundPalette('green').dark();


		$locationProvider.html5Mode(true);

		$urlRouterProvider.otherwise( function($injector) {
			var $state = $injector.get("$state");
			$state.go('/login');
		});

		$stateProvider
			.state('home', {
				url: '/',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/home.view.html',
						controller: 'HomeController',
						controllerAs: 'vm'
					},
					'sidebar@home': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@home': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						except: 'anonymous',
						only: 'home',
						redirectTo: 'login'
					}
				}
			})
			.state('dashboard', {
				url: '/users/dashboard',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/dashboard.view.html',
						controller: 'DashboardController',
						controllerAs: 'vm'
					},
					'sidebar@dashboard': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@dashboard': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'users',
						redirectTo: 'login'
					}
				}
			})
			.state('login', {
				url: '/login?redir',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/login.view.html',
						controller: 'LoginController',
						controllerAs: 'vm'
					}
				}
			})
			.state('surveys', {
				url: '/surveys',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/surveys.view.html',
						controller: 'SurveysController',
						controllerAs: 'vm'
					},
					'sidebar@surveys': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@surveys': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: ['survey_read','survey_write'],
						redirectTo: 'login'
					}
				}
			})
			.state('survey', {
				url: '/surveys/survey?qid&focusOnQuestionWithId',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/survey.editor.view.html',
						controller: 'SurveyEditorController',
						controllerAs: 'vm'
					},
					'sidebar@survey': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@survey': {
						templateUrl: 'js/ng-templates/subviews/surveys/survey.editor.header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: ['survey_read','survey_write'],
						redirectTo: 'login'
					}
				}
			})
			.state('surveytranslate', {
				url: '/surveys/translate?qid',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/survey.translate.view.html',
						controller: 'SurveyTranslateController',
						controllerAs: 'vm'
					},
					'sidebar@surveytranslate': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@surveytranslate': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: ['survey_read','survey_write'],
						redirectTo: 'login'
					}
				}
			})
            .state('speedsters', {
                url: '/surveys/speedsters?qid',
                views: {
                    '': {
                        templateUrl: 'js/ng-templates/views/speedsters.view.html',
                        controller: 'SpeedstersController',
                        controllerAs: 'vm'
                    },
                    'sidebar@speedsters': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
                    },
                    'header@speedsters': {
                        templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
                        controller: 'HeaderController',
                        controllerAs: 'headerVm'
                    }
                },
				data: {
					permissions: {
						only: ['survey_read','survey_write'],
						redirectTo: 'login'
					}
				}
            })
			.state('users', {
				url: '/users/list',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/users.view.html',
						controller: 'UsersController',
						controllerAs: 'vm'
					},
					'sidebar@users': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@users': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'users',
						redirectTo: 'login'
					}
				}
			})
            .state('rafflePrizes', {
                url: '/setup/rewards/rafflePrizes',
                views: {
                    '': {
                        templateUrl: 'js/ng-templates/views/raffle.prizes.view.html',
                        controller: 'RafflePrizesController',
                        controllerAs: 'vm'
                    },
                    'sidebar@rafflePrizes': {
                        templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
                        controller: 'SidebarController',
                        controllerAs: 'sidebarVm'
                    },
                    'header@rafflePrizes': {
                        templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
                        controller: 'HeaderController',
                        controllerAs: 'headerVm'
                    }
                },
				data: {
					permissions: {
						only: 'rewards',
						redirectTo: 'login'
					}
				}
            })
			.state('widgetstopics', {
				url: '/setup/translate/wallCardsTopics',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/widgets.topics.view.html',
						controller: 'WidgetsTopicsController',
						controllerAs: 'vm'
					},
					'sidebar@widgetstopics': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@widgetstopics': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'translate',
						redirectTo: 'login'
					}
				}
			})
			.state('widgetschoices', {
				url: '/setup/translate/wallCardsChoices',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/widgets.choices.view.html',
						controller: 'WidgetsChoicesController',
						controllerAs: 'vm'
					},
					'sidebar@widgetschoices': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@widgetschoices': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'translate',
						redirectTo: 'login'
					}
				}
			})
			.state('questions', {
				url: '/engagement/wallCards',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/questions.view.html',
						controller: 'QuestionsController',
						controllerAs: 'vm'
					},
					'sidebar@questions': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@questions': {
                        templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
                        controller: 'HeaderController',
                        controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'wall',
						redirectTo: 'login'
					}
				}
			})
			.state('walldashboard', {
				url: '/engagement/wallStats',
				views: {
                    '': {
                        templateUrl: 'js/ng-templates/views/wall.dashboard.view.html',
                        controller: 'WallDashboardController',
                        controllerAs: 'vm'
                    },
                    'sidebar@walldashboard': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
                    },
                    'header@walldashboard': {
                        templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
                        controller: 'HeaderController',
                        controllerAs: 'headerVm'
                    }
				},
				data: {
					permissions: {
						only: 'wall',
						redirectTo: 'login'
					}
				}
			})
			.state('ml', {
				url: '/ml',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/ml.view.html',
						controller: 'MlController',
						controllerAs: 'vm'
					},
					'sidebar@ml': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar_ml.subview.html'
					},
					'header@ml': {
						templateUrl: 'js/ng-templates/subviews/common/header_ml.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'admin',
						redirectTo: 'login'
					}
				}
			})
            .state('ml2', {
                url: '/ml2',
                views: {
                    '': {
                        templateUrl: 'js/ng-templates/views/ml.view.html',
                        controller: 'MlController',
                        controllerAs: 'vm'
                    },
                    'sidebar@ml2': {
                        templateUrl: 'js/ng-templates/subviews/common/sidebar_ml.subview.html'
                    },
                    'header@ml2': {
                        templateUrl: 'js/ng-templates/subviews/common/header_ml2.subview.html',
                        controller: 'HeaderController',
                        controllerAs: 'headerVm'
                    },
					'footer@ml2': {
						templateUrl: 'js/ng-templates/subviews/common/footer_ml2.subview.html'
					}
                },
				data: {
					permissions: {
						only: 'admin',
						redirectTo: 'login'
					}
				}
            })
			.state('profiling', {
				url: '/profiling',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/ml_profile.view.html',
						controller: 'MlController',
						controllerAs: 'vm'
					},
					'header@profiling': {
						templateUrl: 'js/ng-templates/subviews/common/header_ml.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'admin',
						redirectTo: 'login'
					}
				}
			})
            .state('votersProfile', {
                url: '/votersProfile',
                views: {
                    '': {
                        templateUrl: 'js/ng-templates/views/ml_profile2.view.html',
                        controller: 'VoterProfileController',
                        controllerAs: 'vm'
                    },
                    'header@votersProfile': {
                        templateUrl: 'js/ng-templates/subviews/common/header_ml2.subview.html',
                        controller: 'HeaderController',
                        controllerAs: 'headerVm'
                    },
					'footer@votersProfile': {
						templateUrl: 'js/ng-templates/subviews/common/footer_ml2.subview.html'
					}
                },
				data: {
					permissions: {
						only: 'admin',
						redirectTo: 'login'
					}
				}
            })
            .state('votersSimulator', {
                url: '/votersSimulator',
                views: {
                    '': {
                        templateUrl: 'js/ng-templates/views/voterSimulator.subview.html',
                        controller: 'VoterSimulatorController',
                        controllerAs: 'vm'
                    },
                    'header@votersSimulator': {
                        templateUrl: 'js/ng-templates/subviews/common/header_ml2.subview.html',
                        controller: 'HeaderController',
                        controllerAs: 'headerVm'
                    },
					'footer@votersSimulator': {
						templateUrl: 'js/ng-templates/subviews/common/footer_ml2.subview.html'
					}
                },
				data: {
					permissions: {
						only: 'admin',
						redirectTo: 'login'
					}
				}
            })
			.state('voterProfile', {
                url: '/voterProfile',
                views: {
                    '': {
                        templateUrl: 'js/ng-templates/views/voterProfile.view.html',
                        controller: 'VoterProfileController',
                        controllerAs: 'vm'
                    },
                    'header@voterProfile': {
                        templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
                        controller: 'HeaderController',
                        controllerAs: 'headerVm'
                    }
                },
				data: {
					permissions: {
						only: 'admin',
						redirectTo: 'login'
					}
				}
            })
            .state('demographics_ml', {
                url: '/ml',
                views: {
                    '': {
                        templateUrl: 'js/ng-templates/views/ml.view.html',
                        controller: 'MlController',
                        controllerAs: 'vm'
                    },
                    'sidebar@demographics_ml': {
                        templateUrl: 'js/ng-templates/subviews/common/sidebar_ml.subview.html'
                    },
                    'header@demographics_ml': {
                        templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
                        controller: 'HeaderController',
                        controllerAs: 'headerVm'
                    }
                },
				data: {
					permissions: {
						only: 'admin',
						redirectTo: 'login'
					}
				}
            })
			.state('userInfo', {
				url: '/users/user/:uid',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/userinfo.view.html',
						controller: 'UserInfoController',
						controllerAs: 'vm'
					},
					'sidebar@userInfo': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@userInfo': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'users',
						redirectTo: 'login'
					}
				}
			})
			.state('demographics', {
				url: '/users/demographics',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/demographics.view.html',
						controller: 'DemographicsController',
						controllerAs: 'vm'
					},
					'sidebar@demographics': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@demographics': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'users',
						redirectTo: 'login'
					}
				}
			})
			.state('campaignStats', {
				url: '/recruitment/campaignStats',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/campaign.stats.view.html',
						controller: 'CampaignStatsController',
						controllerAs: 'vm'
					},
					'sidebar@campaignStats': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@campaignStats': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'recrute',
						redirectTo: 'login'
					}
				}
			})
			.state('charts', {
				url: '/surveys/charts?qid',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/charts.view.html',
						controller: 'ChartsController',
						controllerAs: 'vm'
					},
					'sidebar@charts': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@charts': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: ['survey_read','survey_write'],
						redirectTo: 'login'
					}
				}
			})
			.state('csv', {
				url: '/surveys/csv?qid',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/csv.results.view.html',
						controller: 'CsvResultsController',
						controllerAs: 'vm'
					},
					'sidebar@csv': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@csv': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: ['survey_read','survey_write'],
						redirectTo: 'login'
					}
				}
			})
			.state('rcs', {
				url: '/surveys/rcs?withWeighting',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/rcs.view.html',
						controller: 'RcsController',
						controllerAs: 'vm'
					},
					'sidebar@rcs': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@rcs': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: ['survey_read','survey_write'],
						redirectTo: 'login'
					}
				}
			})
			.state('survey_rcs', {
				url: '/surveys/rcs/:questId?withWeighting',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/rcs.view.html',
						controller: 'RcsController',
						controllerAs: 'vm'
					},
					'sidebar@survey_rcs': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@survey_rcs': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: ['survey_read','survey_write'],
						redirectTo: 'login'
					}
				}
			})
			.state('translate', {
				url: '/setup/translate/website',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/translate.view.html',
						controller: 'TranslateController',
						controllerAs: 'vm'
				},
				'sidebar@translate': {
					templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
					controller: 'SidebarController',
					controllerAs: 'sidebarVm'
				},
				'header@translate': {
					templateUrl: 'js/ng-templates/subviews/common/translate.header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'translate',
						redirectTo: 'login'
					}
				}
			})
			.state('configurations', {
				url: '/setup/rewards/rewardTriggers',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/configurations.view.html',
						controller: 'ConfigurationsController',
						controllerAs: 'vm'
					},
					'sidebar@configurations': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@configurations': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'setup_rewards',
						redirectTo: 'login'
					}
				}
			})
			.state('forecast', {
				url: '/predictor/events',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/forecast.view.html',
						controller: 'ForecastController',
						controllerAs: 'vm'
					},
					'sidebar@forecast': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@forecast': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'predictor',
						redirectTo: 'login'
					}
				}
			})
            .state('forecastOperations', {
                url: '/predictor/operations',
                views: {
                    '': {
                        templateUrl: 'js/ng-templates/views/forecast.operations.view.html',
                        controller: 'ForecastOperationsController',
                        controllerAs: 'vm'
                    },
                    'sidebar@forecastOperations': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
                    },
                    'header@forecastOperations': {
                        templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
                        controller: 'HeaderController',
                        controllerAs: 'headerVm'
                    }
                },
				data: {
					permissions: {
						only: 'predictor',
						redirectTo: 'login'
					}
				}
            })
            .state('forecastUserDetails', {
                url: '/predictor/forecasters/:uid',
                views: {
                    '': {
                        templateUrl: 'js/ng-templates/views/forecast.user.details.view.html',
                        controller: 'ForecastUserDetailsController',
                        controllerAs: 'vm'
                    },
                    'sidebar@forecastUserDetails': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
                    },
                    'header@forecastUserDetails': {
                        templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
                        controller: 'HeaderController',
                        controllerAs: 'headerVm'
                    }
                },
				data: {
					permissions: {
						only: 'predictor',
						redirectTo: 'login'
					}
				}
            })
			.state('resend', {
				url: '/engagement/email',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/resend.emails.view.html',
						controller: 'ResendController',
						controllerAs: 'vm'
					},
					'sidebar@resend': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@resend': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'email',
						redirectTo: 'login'
					}
				}
			})
			.state('rewards', {
				url: '/engagement/rewards/raffleDraw',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/raffle.view.html',
						controller: 'RaffleController',
						controllerAs: 'vm'
					},
					'sidebar@rewards': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@rewards': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'rewards',
						redirectTo: 'login'
					}
				}
			})
			.state('winners', {
				url: '/engagement/rewards/raffleWinners',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/users.winners.view.html',
						controller: 'UsersWinnersController',
						controllerAs: 'vm'
					},
					'sidebar@winners': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@winners': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'rewards',
						redirectTo: 'login'
					}
				}
			})
			.state('conversions', {
				url: '/engagement/rewards/conversions',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/conversions.view.html',
						controller: 'ConversionsController',
						controllerAs: 'vm'
					},
					'sidebar@conversions': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@conversions': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'rewards',
						redirectTo: 'login'
					}
				}
			})
			.state('incentives', {
				url: '/setup/rewards/ticketConversions',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/incentives.view.html',
						controller: 'IncentivesController',
						controllerAs: 'vm'
					},
					'sidebar@incentives': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@incentives': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'setup_rewards',
						redirectTo: 'login'
					}
				}
			})
			.state('recurrentHistory', {
				url: '/surveys/recurrentHistory?withUndecided&cumulated&predefinedQuestionId',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/recurrent.history.view.html',
						controller: 'RecurrentHistoryController',
						controllerAs: 'vm'
					},
					'sidebar@recurrentHistory': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@recurrentHistory': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: ['survey_read','survey_write'],
						redirectTo: 'login'
					}
				}
			})
			.state('forecastHistory', {
				url: '/predictor/forecastHistory/:qid',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/forecast.history.view.html',
						controller: 'ForecastHistoryController',
						controllerAs: 'vm'
					},
					'sidebar@forecastHistory': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@forecastHistory': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'predictor',
						redirectTo: 'login'
					}
				}
			})
			.state('forecastEventStats', {
				url: '/predictor/forecastEventStats/:qid',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/forecast.event.stats.view.html',
						controller: 'ForecastEventStatsController',
						controllerAs: 'vm'
					},
					'sidebar@forecastEventStats': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@forecastEventStats': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'predictor',
						redirectTo: 'login'
					}
				}
			})
			.state('forecastStatistics', {
				url: '/predictor/forecastStatistics',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/forecast.statistics.view.html',
						controller: 'ForecastStatisticsController',
						controllerAs: 'vm'
					},
					'sidebar@forecastStatistics': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@forecastStatistics': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'predictor',
						redirectTo: 'login'
					}
				}
			})
			.state('tasks', {
				url: '/setup/automatedTasks',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/tasks.view.html',
						controller: 'TasksController',
						controllerAs: 'vm'
					},
					'sidebar@tasks': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'header@tasks': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'tasks',
						redirectTo: 'login'
					}
				}
			})
			.state('report', {
				url: '/setup/report',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/report.templates.view.html',
						controller: 'ReportTemplatesController',
						controllerAs: 'vm'
					},
					'sidebar@report': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'footer@report': {
						templateUrl: 'js/ng-templates/subviews/common/footer.subview.html'
					},
					'header@report': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'survey_write',
						redirectTo: 'login'
					}
				}
			})
			.state('regions', {
				url: '/setup/regions',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/regions.view.html',
						controller: 'RegionsController',
						controllerAs: 'vm'
					},
					'sidebar@regions': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'footer@regions': {
						templateUrl: 'js/ng-templates/subviews/common/footer.subview.html'
					},
					'header@regions': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'regions',
						redirectTo: 'login'
					}
				}
			})
	       .state('amcharts4', {
				url: '/amcharts4',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/amcharts4.view.html',
						controller: 'Amcharts4Controller',
						controllerAs: 'vm'
					},
					'sidebar@amcharts4': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'footer@amcharts4': {
						templateUrl: 'js/ng-templates/subviews/common/footer.subview.html'
					},
					'header@amcharts4': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: ['survey_read','survey_write'],
						redirectTo: 'login'
					}
				}
			})
		    .state('prizeConversions',{
		    	url : '/setup/rewards/pointConversions',
				views: {
					'': {
						templateUrl: 'js/ng-templates/views/prizes.view.html',
						controller: 'PrizesController',
						controllerAs: 'vm'
					},
					'sidebar@prizeConversions': {
						templateUrl: 'js/ng-templates/subviews/common/sidebar.subview.html',
						controller: 'SidebarController',
						controllerAs: 'sidebarVm'
					},
					'footer@prizeConversions': {
						templateUrl: 'js/ng-templates/subviews/common/footer.subview.html'
					},
					'header@prizeConversions': {
						templateUrl: 'js/ng-templates/subviews/common/header.subview.html',
						controller: 'HeaderController',
						controllerAs: 'headerVm'
					}
				},
				data: {
					permissions: {
						only: 'setup_rewards',
						redirectTo: 'login'
					}
				}				
			})
	}

	run.$inject = ['$rootScope', '$http', '$cookies', 'PermissionUtils','$anchorScroll','$window'];
	function run($rootScope, $http, $cookies, PermissionUtils,$anchorScroll,$window) {

        $anchorScroll.yOffset = 50;   // always scroll by 50 extra pixels

		$rootScope.version = VERSION;
		$rootScope.globals = $cookies.getObject('globals_admin') || {};
		$rootScope.dknaTranslatedLabel = "Don't know/No answer";
		if(!$rootScope.globals.permissions) {
			$rootScope.globals.permissions = [];
		}
		PermissionUtils.definePermissions();

		if ($rootScope.globals.authorization) {
			$http.defaults.headers.common['Authorization'] = "Token " + $rootScope.globals.authorization;
			window.jQuery.ajaxSetup({
				headers: {'Authorization': "Token " + $rootScope.globals.authorization}
			});
		}

		document.getElementById("admin_website_title").innerHTML = 'Majoritas INSIGHTS';
	}



})();