(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .factory('DistributionsService', DistributionsService);

    DistributionsService.$inject = ['$http', 'Upload'];

    function DistributionsService($http) {
        var service = {};

        service.getSurveyDistributions = function (surveyId, okCallback, koCallback) {
            $http.get("/rest/distributions/all/" + surveyId)
                .then(function successCallback(response) {
                    okCallback && okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.getSurveyDistributionsFromQuotas = function (surveyId, okCallback, koCallback) {
            $http.get("/rest/distributions/from/quotas/" + surveyId)
                .then(function successCallback(response) {
                    okCallback && okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.saveSurveyDistributions = function (surveyId, payload, okCallback, koCallback) {
            $http.post("/rest/distributions/all/" + surveyId, payload)
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.getDistributionListForDivision = function (divisionId, okCallback, koCallback) {
            $http.get("/rest/distributions/regions/" + divisionId)
                .then(function successCallback(response) {
                    okCallback && okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.calculateWeightsForQuestId = function (surveyId, okCallback, koCallback) {
            $http.get("/rest/distributions/calculateWeights/" + surveyId)
                .then(function successCallback(response) {
                    okCallback && okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.getWeightsStatsForQuestId = function (surveyId, okCallback, koCallback) {
            $http.get("/rest/distributions/getWeightsStats/" + surveyId)
                .then(function successCallback(response) {
                    okCallback && okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        return service;
    }
})();
