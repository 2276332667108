(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('DemographicsService', DemographicsService);

	DemographicsService.$inject = ['$http'];
	function DemographicsService($http) {
		var service = {};

		service.getAgeGroups = getAgeGroups;
		service.getDemographicsByGendersAndAgeGroups = getDemographicsByGendersAndAgeGroups;
		service.getDemographicsByRegionsAndGenders = getDemographicsByRegionsAndGenders;
		service.getDemographicsByCitiesAndGenders = getDemographicsByCitiesAndGenders;
		service.getDemographicsByRegionsAndAgeGroups = getDemographicsByRegionsAndAgeGroups;
		service.getDemographicsByCitiesAndAgeGroups = getDemographicsByCitiesAndAgeGroups;
		service.getDemographicsByRegionsAgeGroupsAndGenders = getDemographicsByRegionsAgeGroupsAndGenders;
		service.getDemographicsByCitiesAgeGroupsAndGenders = getDemographicsByCitiesAgeGroupsAndGenders;
		return service;

		function getAgeGroups(okCallback, koCallback) {
			$http({
				url: "/rest/demographics/ageGroups",
				type: 'get'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function getDemographicsByGendersAndAgeGroups(divisionId, surveyId, respondersXDays, loggedInXDays, okCallback, koCallback){
			$http({
				url: "/rest/demographics/demographicsByGendersAndAgeGroups?divisionId=" + divisionId + "&surveyId=" + surveyId +
					"&respondersXDays=" + respondersXDays + "&loggedInXDays=" + loggedInXDays,
				type: 'get'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function getDemographicsByRegionsAndGenders(divisionId, surveyId, respondersXDays, loggedInXDays, okCallback, koCallback){
			$http({
				url: "/rest/demographics/demographicsByRegionsAndGenders?divisionId=" + divisionId + "&surveyId=" + surveyId
					+ "&respondersXDays=" + respondersXDays + "&loggedInXDays=" + loggedInXDays,
				type: 'get'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function getDemographicsByCitiesAndGenders(surveyId, respondersXDays, loggedInXDays, okCallback, koCallback){
			$http({
				url: "/rest/demographics/demographicsByCitiesAndGenders?" + "surveyId=" + surveyId
					+ "&respondersXDays=" + respondersXDays + "&loggedInXDays=" + loggedInXDays,
				type: 'get'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function getDemographicsByRegionsAndAgeGroups(divisionId, surveyId, respondersXDays, loggedInXDays, okCallback, koCallback){
			$http({
				url: "/rest/demographics/demographicsByRegionsAndAgeGroups?divisionId=" + divisionId + "&surveyId=" + surveyId
					+ "&respondersXDays=" + respondersXDays + "&loggedInXDays=" + loggedInXDays,
				type: 'get'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function getDemographicsByCitiesAndAgeGroups(surveyId, respondersXDays, loggedInXDays, okCallback, koCallback){
			$http({
				url: "/rest/demographics/demographicsByCitiesAndAgeGroups?" + "surveyId=" + surveyId
					+ "&respondersXDays=" + respondersXDays + "&loggedInXDays=" + loggedInXDays,
				type: 'get'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function getDemographicsByRegionsAgeGroupsAndGenders(divisionId, surveyId, respondersXDays, loggedInXDays, okCallback, koCallback) {
			$http({
				url: "/rest/demographics/demographicsByRegionsAgeGroupsAndGenders?divisionId=" + divisionId + "&surveyId=" + surveyId
					+ "&respondersXDays=" + respondersXDays + "&loggedInXDays=" + loggedInXDays,
				type: 'get'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function getDemographicsByCitiesAgeGroupsAndGenders(surveyId, respondersXDays, loggedInXDays, okCallback, koCallback) {
			$http({
				url: "/rest/demographics/demographicsByCitiesAgeGroupsAndGenders?surveyId=" + surveyId +
					"&respondersXDays=" + respondersXDays + "&loggedInXDays=" + loggedInXDays,
				type: 'get'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}
	}

})();