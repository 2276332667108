(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .factory('MlService', MlService);

    MlService.$inject = ['$http'];

    function MlService($http) {
        var service = {};

        service.getMlData = function (type, partiesArray, okCallback, koCallback) {
	        $http.post("/rest/ml/featureImportance/"+type,JSON.stringify(partiesArray))
		        .then(function successCallback(response) {
			       okCallback && okCallback(response.data);
		        }, function errorCallback(response) {
			        koCallback(response);
		        });
        };

        service.getParties = function (okCallback, koCallback) {
            $http.get("/rest/quests/choices/2456")
                .then(function successCallback(response) {
                    okCallback && okCallback(response.data);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.getProfilingData = function (selectedParty,okCallback, koCallback) {
            $http.get("/rest/ml/votersProfile/"  + selectedParty)
                .then(function successCallback(response) {
                    okCallback && okCallback(response.data);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.postFeaturesValues = function(featuresValuesArray,okCallback, koCallback){
            $http.post("/rest/ml/votersSimulator", featuresValuesArray)
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        return service;
    }
})();
