(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .controller('MlController', MlController);

    MlController.$inject = ['$rootScope', 'MlService', '$window'];

    function MlController($rootScope, MlService, $window) {
        var vm = this;

        vm.mlSocioData = {};
		vm.mlPsychoData = {};
		vm.mlTop10Data = {};
        vm.editModeCards = {};

        vm.parties = [];
        vm.noOfParties = [];

        vm.fetchParties = function () {

            MlService.getParties(
                function (response) {
                    var parties = Object.keys(response).map(function (e) {
                        var party = {};
                        party.value = e;
                        party.name = response[e];
                        return party;
                    });
                    vm.parties = parties;
                },
                function (error) {
                    console.log(error);
                }
            );


        };

        vm.roundIt = function(double) {
        	return double.toFixed(1);
        };

        vm.runAnalysis = function () {
            if (!vm.firstParty) return;
            var partiesArray = [vm.firstParty.value, (vm.secondParty ? vm.secondParty.value : -1)];
	        vm.mlSocioData = {};
	        vm.mlPsychoData = {};
	        vm.mlTop10Data = {};
	        MlService.getMlData('socio',partiesArray,
                function (response) {
                    vm.mlSocioData = response;
                }
            );
            MlService.getMlData('psycho',partiesArray,
                function (response) {
                    vm.mlPsychoData = response;
                }
            );
            MlService.getMlData('top10',partiesArray,
                function (response) {
                    vm.mlTop10Data = response;
                }
            );			
        };

        vm.initNoOfParties = function () {
            vm.noOfParties["admin2.questia.ro"] = 6;
            vm.noOfParties["admin2.questia.co.id"] = 15;
	        vm.noOfParties["admin2.questia.co.th"] = 8;
            vm.noOfParties["admin2.questia.com.ar"] = 5;
        };

        vm.handlePartyChange = function (selectedItem, comboIndex) {

            if(selectedItem && selectedItem.value) {

                var noOfParties = vm.noOfParties[$window.location.hostname];

                var elements = null;
                if (comboIndex === 1) {
                    elements = document.getElementsByClassName("first_party_image");
                } else {
                    elements = document.getElementsByClassName("second_party_image");
                }

                var path = null;
                if (selectedItem.value > noOfParties) {
                    path = "images/ml_parties/" + $window.location.hostname + "/DEFAULT.jpg";
                } else {
                    path = "images/ml_parties/" + $window.location.hostname + "/" + selectedItem.value + ".jpg";
                }

                if (path && elements) {
                    for (var i = 0; i < elements.length; i += 1) {
                        elements[i].src = path;
                    }
                }
            }

        };

        vm.handlePartyChangeProfile = function (selectedItem, comboIndex) {
            vm.mlSocioData = null;
			vm.mlPsychoData = null;
			vm.mlTop10Data = null;
            vm.handlePartyChange(selectedItem, comboIndex);
        };

        (function initController() {
            vm.activeSubmenu = 'ml';
            vm.breadcrumbs = 'Machine Learning';

            vm.initNoOfParties();
            vm.fetchParties();
        })();
    }
})();
