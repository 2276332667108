(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .factory('UsersWinnersService', UsersWinnersService);

    UsersWinnersService.$inject = ['$http'];
    function UsersWinnersService($http) {
        return {
            addWinners: function (winners, okCallback, koCallback) {
                $http.post("/rest/winners/save", winners)
                    .then(function successCallback(response) {
                        okCallback(response);
                    }, function errorCallback(response) {
                        koCallback(response);
                    });
            },
            removeWinnersByDrawDate: function (drawDate, okCallback, koCallback) {
                $http.post("/rest/winners/delete_by_draw_date", drawDate)
                    .then(function successCallback(response) {
                        okCallback(response);
                    }, function errorCallback(response) {
                        koCallback(response);
                    });
            }
        }
    }

})();
