(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .controller('PrizesController', PrizesController);

    PrizesController.$inject = ['$rootScope', '$state', 'IncentivesService','LanguagesService','$mdDialog','NgTableParams','$timeout'];
    function PrizesController($rootScope, $state, IncentivesService,LanguagesService,$mdDialog,NgTableParams,$timeout) {

        var vm = this;

        vm.initData = function(){
            vm.initialSettings = {
                // page size buttons (right set of buttons in demo)
                counts: [],
                // determines the pager buttons (left set of buttons in demo)ss
                paginationMaxBlocks: 20,
                paginationMinBlocks: 2,
                dataset: []
            };
            vm.initialParams = {
                // initial page size
                count: 20
            };
            vm.prizesData = [];
            vm.dialogIdentifier = "conversion_prizes";
        };

        vm.goto = function (state) {
            $state.go(state);
        };

        vm.uploadImgToPrize = function(id, data){
            IncentivesService.addPrizeImage(id,data,function(){
                    var prize = vm.prizesData.find(function(element){
                       return element.id === id;
                    });
                    prize.img = data;

                    $mdDialog.show(
                        $mdDialog.alert()
                            .title('Success')
                            .textContent('The image of the prize was successfully updated')
                            .ok('Close'));
            },
                    function(){
                        $mdDialog.show(
                            $mdDialog.alert()
                                .title('Error')
                                .textContent('There was an error while trying to save the image into the database. Please contact the support team')
                                .ok('Close'));
                    })
        };

        vm.prizesSplice = function(index) {
            var prizesElement = {
                ui_order : 0,
                price : 0,
                category : 'prizes',
                published : false,
                img : null
            };
            if(vm.prizesData.length <= index+1) {
                vm.prizesData.splice(index + 1, 0, prizesElement);
            }
        };

        vm.deleteAPrize = function(prizeId,uiIndex){

            var confirm = $mdDialog.confirm()
                .title("Prize deletion")
                .textContent("Are you sure that you want to delete the prize ?")
                .cancel("No")
                .ok("Yes");

            $mdDialog.show(confirm).then(function() {

                IncentivesService.deleteAPrize(prizeId,
                    function(response){
                        $mdDialog.show(
                            $mdDialog.alert()
                                .title('Success')
                                .textContent('The prize was successfully deleted')
                                .ok('Close'));
                        vm.prizesData.splice(uiIndex, 1);
                        vm.initialSettings.dataset = vm.prizesData;
                        vm.tableParams.reload();
                    },
                    function(response){
                        $mdDialog.show(
                            $mdDialog.alert()
                                .title('Error')
                                .textContent('The prize was not deleted. Please contact the support team')
                                .ok('Close'));
                    });

            }, function() {

            });


        };

        vm.viewImageOfPrize = function (prizeId) {
            IncentivesService.getPrizeImage(
                prizeId,
                function success(response) {
                    var file = new Blob([ response.data ], {
                        type : 'image/jpeg'
                    });
                    var fileURL = URL.createObjectURL(file);
                    $mdDialog.show({
                        template:
                            '<md-dialog>' +
                            '   <md-dialog-content layout="row" layout-align="center center">' +
                            '       <img ng-src="{{prizeImg}}"/>' +
                            '   </md-dialog-content>' +
                            '   <md-dialog-actions>' +
                            '       <md-button ng-click="cancel()" class="md-primary">' +
                            '           Close' +
                            '       </md-button>' +
                            '   </md-dialog-actions>' +
                            '</md-dialog>',
                        locals: {
                            prizeImg: fileURL
                        },
                        controller: function ($scope, $mdDialog, prizeImg) {
                            $scope.prizeImg = prizeImg;
                            $scope.cancel = function () {
                                $mdDialog.cancel();
                            };
                        }
                    }).then(
                        function () {
                        },
                        function () {
                        });

                },
                function error(response) {
                    alert("Failed to retrieve the prize of the image");
                }
            );
        };

        vm.findTranslation = function(prize,language){
            var foundPrize = vm.prizesTranslations.find(function(element){
               return (element.prize.id === prize.id) && (element.language.lang_id === language.lang_id)
            });

            if(typeof foundPrize === 'undefined') return '';

            return foundPrize.translation;
        };

        vm.editPrize = function(prize){
            vm.currentPrize = angular.copy(prize);
            for(var i = 0 ; i < vm.languagesList.length; i++){
                vm.languagesList[i].translation = vm.findTranslation(prize,vm.languagesList[i]);
            }
            $timeout( function(){
                var editPrizeCardElement = document.getElementById("editPrizeCard");
                editPrizeCardElement && editPrizeCardElement.scrollIntoView();
            }, 500);
        };

        vm.preserveUiSaveDetails = function(prize) {

            if(typeof prize.prizeId === 'undefined') {
                return;
            }

            var correspondingPrize = vm.prizesData.find(function (element) {
                return element.id === prize.prizeId;
            });
            correspondingPrize.price = prize.price;

            var noOfNewTranslations = Object.keys(prize.translations).length;
            var noOfOldTranslation = vm.languagesList.length;

            if(noOfNewTranslations !== noOfOldTranslation){
                Object.keys(prize.translations).forEach(function(key,index) {
                    var correspondingTranslation = vm.prizesTranslations.find(function(element){
                        return element.language.lang_id === key
                    });
                    if(typeof correspondingTranslation === 'undefined'){
                        var prizeTranslation = {prize:{ id : prize.prizeId } , language:{lang_id : key}, translation:prize.translations[key]};
                        vm.prizesTranslations.push(prizeTranslation);
                    }
                });
                return;
            }

            for(var k = 0 ; k < vm.languagesList.length; k++){
                var prizeCorrespondingTranslation = vm.prizesTranslations.find(function(element){
                    return (element.prize.id === prize.prizeId) && (element.language.lang_id === vm.languagesList[k].lang_id);
                });
                if(typeof prizeCorrespondingTranslation !== 'undefined') {
                    prizeCorrespondingTranslation.translation = vm.languagesList[k].translation;
                }else{
                    var prizeTranslation = {prize:{ id : prize.prizeId } , language:{lang_id : vm.languagesList[k].lang_id}, translation:prize.translations[ vm.languagesList[k].lang_id]};
                    vm.prizesTranslations.push(prizeTranslation);
                }
            }
        };

        vm.mapTranslations = function(){
            var translationsMap = {};
            for(var i = 0 ; i < vm.languagesList.length; i++){
                var id = vm.languagesList[i].lang_id;
                translationsMap[id] = vm.languagesList[i].translation;
            }
            return translationsMap;
        };

        vm.generateNewPrizesTranslations = function(newPrizeElem,translationsMap){
            for(var i = 0 ; i < vm.languagesList.length; i++){
                var newTranslation = {
                    prize:{
                        id : newPrizeElem.id
                    },
                    language:{
                        lang_id : vm.languagesList[i].lang_id
                    },
                    translation : translationsMap[vm.languagesList[i].lang_id]
                };
                vm.prizesTranslations.push(newTranslation);
            }
        };

        vm.savePrizeDetails = function(prize){

           var prizeDetails = {};
           prizeDetails.prizeId = prize.id;
           prizeDetails.price = prize.price;

           var translationsMap = vm.mapTranslations();
           prizeDetails.translations = translationsMap;

           vm.preserveUiSaveDetails(prizeDetails);

           IncentivesService.postPrizeDetails(prizeDetails,function (success) {
               if(typeof prize.id === 'undefined'){
                 var currentLength = vm.prizesData.length-1;
                 var newPrizeElem = success.data;

                 vm.generateNewPrizesTranslations(newPrizeElem,translationsMap);

                 vm.prizesData.splice(currentLength + 1, 0, newPrizeElem);
                 vm.initialSettings.dataset = vm.prizesData;
                 vm.tableParams.reload();

               }

               $mdDialog.show(
                   $mdDialog.alert()
                       .title('Success')
                       .textContent('The prize data was successfully saved')
                       .ok('Close'));
               delete vm.currentPrize;
           },function(error){
               console.log(error);
           })
        };

        vm.publish = function(prizeId,publish){

        var confirm;
        if(publish){
            confirm = $mdDialog.confirm()
                .title("Publish prize")
                .textContent("Are you sure that you want to publish the prize ? It will appear on the website.")
                .cancel("No")
                .ok("Yes");
        }else{
            confirm = $mdDialog.confirm()
                .title("Prize deletion")
                .textContent("Are you sure that you want to disable the prize ? It will disappear from the website.")
                .cancel("No")
                .ok("Yes");
        }


        $mdDialog.show(confirm).then(function() {
                IncentivesService.publishPrize(prizeId,publish,
                    function(response){
                        if(publish){
                            $mdDialog.show(
                                $mdDialog.alert()
                                    .title('Success')
                                    .textContent('The prize was successfully published')
                                    .ok('Close'));
                        }else{
                            $mdDialog.show(
                                $mdDialog.alert()
                                    .title('Success')
                                    .textContent('The prize was disabled.It will no longer appear on the website.')
                                    .ok('Close'));
                        }

                        var prize = vm.prizesData.find(function(prize){
                           return prize.id === prizeId;
                        });
                        prize.published = publish;

                    },function(response){
                        if(publish){
                            $mdDialog.show(
                                $mdDialog.alert()
                                    .title('Error')
                                    .textContent('The prize could not be published')
                                    .ok('Close'));
                        }else{
                            $mdDialog.show(
                                $mdDialog.alert()
                                    .title('Error')
                                    .textContent('The prize could not be disabled')
                                    .ok('Close'));
                        }
                    });
        }, function() {

        });

        };

        vm.getPrizes = function(){
            IncentivesService.getPrizes(
                function success(response) {

                    if(response.data.length === 0) {
                        $mdDialog.show(
                            $mdDialog.alert()
                                .title('Warning')
                                .textContent('No prizes was found into the database')
                                .ok('Close'));
                    }

                    vm.prizesData = response.data;

                    vm.initialSettings.dataset = vm.prizesData;
                    vm.tableParams = new NgTableParams(vm.initialParams, vm.initialSettings);
                    vm.getAllLanguages();
                },
                function err(response) {
                    console.log(response);
                }
            );
        };

        vm.getAllLanguages = function(){
            LanguagesService.GetPanelLanguages(
                function (success) {
                    vm.languagesList = success.data;
                    vm.getPrizesTranslation();
                },
                function (error) {
                    console.log(error);
                }
            );
        };

        vm.getPrizesTranslation = function(){
            IncentivesService.getPrizesTranslations(
                function success(response){
                    vm.prizesTranslations = response.data;
                },
                function error(response){
                    console.log(response);
                }
            )
        };

        vm.clearTranslationFields = function(){
          if(typeof vm.languagesList !== 'undefined') {
              vm.languagesList.map(function (element) {
                  if (typeof element.translation !== 'undefined') {
                      element.translation = '';
                  }
              })
          }
        };

        vm.addNewPrize = function(){
            vm.clearTranslationFields();
            vm.currentPrize = {
                item_count : 1,
                price : 0,
                category : 'prizes'
            };
            $timeout( function(){
                var editPrizeCardElement = document.getElementById("editPrizeCard");
                editPrizeCardElement && editPrizeCardElement.scrollIntoView();
            }, 500);
        };

        vm.cancelCurrentQuestion = function() {
            var confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent('You will lose all your unsaved changes.')
                .ok('OK')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function() {
                delete vm.currentPrize;
            }, function() {
            });
        };

        (function initController() {
            vm.activeSubmenu = 'setup_rewards';
            vm.breadcrumbs = 'Rewards: Conversion to Prizes';
            vm.initData();
            vm.getPrizes();

    })();


    }
})();
