(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('RegionsService', RegionsService);

	RegionsService.$inject = ['$http'];
	function RegionsService($http) {
		return {
			getRegionsList: function () {
				return $http.get("/rest/regions");
			},
			addCityToCounty: function(city, okCallback, koCallback) {
				// Here, city includes its county
				$http.post("/rest/regions/city", city)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			},
			addCounty: function (county, okCallback, koCallback) {
				$http.post("/rest/regions/county", county)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			},
			addRegion: function (region, okCallback, koCallback) {
				$http.post("/rest/regions", region)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			},
			addCountiesToRegion: function (data, okCallback, koCallback) {
				$http.post("/rest/regions/content", data)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			},
			getDivisionsList: function () {
				return $http.get("/rest/regions/divisions");
			},
			addDivision: function (division, okCallback, koCallback) {
				$http.post("/rest/regions/divisions", division)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			},
			addRegionsToDivision: function (data, okCallback, koCallback) {
				$http.post("/rest/regions/divisions/content", data)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			},
			getAllCounties: function () {
				return $http.get("/rest/regions/counties");
			},
            getAllCities: function () {
				return $http.get("/rest/regions/cities");
            },
			getCountiesListForRegion: function (id, okCallback, koCallback) {
				return $http.get("/rest/regions/counties/" + id);
			},
			getRegionsListForDivision: function (id) {
				return $http.get("/rest/regions/divisions/" + id);
			},
			getDivisionsToRegionsMap: function () {
				return $http.get("/rest/regions/divisionsToRegionsMap/");
			},
			getCitiesByCounty : function (id){
                return $http.get("/rest/regions/cities/" + id);
			},
			getCountiesAndCitiesCsvData: function (limit, okCallback, koCallback) {
			    const params = !!limit ? { limit: limit } : {};
				return $http.get("/rest/regions/countiesAndCitiesCsvData", { params: params })
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			},
			importCountiesAndCitiesFromCsv: function (csvFile, okCallback, koCallback) {
				var formData = new FormData();
				formData.append('file', csvFile);
				var url = "/rest/regions/import/";
				$http.post(url, formData, {
					transformRequest: angular.identity,
					headers: {'Content-Type': undefined}
				})
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			},
			getAllZonesForCounty: function (countyId, okCallback, koCallback) {
				$http.get("/rest/regions/zones/" + countyId)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			},
			saveZoneForCounty: function (zone, okCallback, koCallback) {
				$http.post("/rest/regions/zones", zone)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			},
			deleteZoneById: function (zoneId, okCallback, koCallback) {
				$http.post("/rest/regions/zones/remove/" + zoneId)
					.then(function successCallback(response) {
						okCallback(response);
					}, function errorCallback(response) {
						koCallback(response);
					});
			}
		}
	}
})();
