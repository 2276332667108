(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('CorrelationLogicService', [CorrelationLogicService]);

	function CorrelationLogicService() {
		return {
			addEmptyCorrelation: addEmptyCorrelation,
			addEmptyCorrelationGroup: addEmptyCorrelationGroup,
			changeOperatorWithinCorrelationGroup: changeOperatorWithinCorrelationGroup,
			changeOperatorBetweenCorrelationGroups: changeOperatorBetweenCorrelationGroups,
			initializeCloseCorrelation: initializeCloseCorrelation,
			findCorrelationQuestionChoices: findCorrelationQuestionChoices,
			getScaleChoicesForCorrelationSelect: getScaleChoicesForCorrelationSelect,
			addCorrelationGroup: addCorrelationGroup,
			addCorrelationChoiceGroup: addCorrelationChoiceGroup,
			saveCorrelationJson: saveCorrelationJson
		};

		function addEmptyCorrelation(question, choiceGroupIndex, correlationGroupIndex, index) {
			question.correlationArray[choiceGroupIndex].correlationGroup[correlationGroupIndex].correlationArray[index].operator = "and";
			question.correlationArray[choiceGroupIndex].correlationGroup[correlationGroupIndex].correlationArray.splice(index+1,0,{});
			return question;
		}

		function addEmptyCorrelationGroup(question, choiceGroupIndex, correlationGroupIndex) {
			question.correlationArray[choiceGroupIndex].correlationGroup[correlationGroupIndex].operator = "and";
			question.correlationArray[choiceGroupIndex].correlationGroup.splice(correlationGroupIndex+1,0,{
				correlationArray: [{}]
			});
			return question;
		}

		function changeOperatorWithinCorrelationGroup(question, choiceGroupIndex, correlationGroupIndex, index) {
			if (question.correlationArray[choiceGroupIndex].correlationGroup[correlationGroupIndex].correlationArray[index].operator === "and") {
				question.correlationArray[choiceGroupIndex].correlationGroup[correlationGroupIndex].correlationArray[index].operator = "or";
			}
			else {
				question.correlationArray[choiceGroupIndex].correlationGroup[correlationGroupIndex].correlationArray[index].operator = "and";
			}
			return question;
		}

		function changeOperatorBetweenCorrelationGroups(question, choiceGroupIndex, correlationGroupIndex) {
			if (question.correlationArray[choiceGroupIndex].correlationGroup[correlationGroupIndex].operator === "and") {
				question.correlationArray[choiceGroupIndex].correlationGroup[correlationGroupIndex].operator = "or";
			}
			else {
				question.correlationArray[choiceGroupIndex].correlationGroup[correlationGroupIndex].operator = "and";
			}
			return question;
		}

		function initializeCloseCorrelation(question) {
			question.correlationArray = [{
				mainChoice: null,
				mainQuestionId: question.id,
				errorMessage: null,
				correlationGroup: [{
					correlationArray: [{}]
				}]
			}];
			question.correlationArrayJson = null;
			return question;
		}

		function findCorrelationQuestionChoices(question, previousQuestions, choiceGroupIndex, correlationGroupIndex, index) {
			var selectedQuestion = previousQuestions.filter(function (q) {
				return q.id === question.correlationArray[choiceGroupIndex]
					.correlationGroup[correlationGroupIndex].correlationArray[index].question_id;
			});
			if (!selectedQuestion || selectedQuestion.length === 0) {
				delete question.correlationArray[choiceGroupIndex]
					.correlationGroup[correlationGroupIndex].correlationArray[index].correlationQtype;
				delete question.correlationArray[choiceGroupIndex]
					.correlationGroup[correlationGroupIndex].correlationArray[index].correlationQuestionChoices;
				delete question.correlationArray[choiceGroupIndex]
					.correlationGroup[correlationGroupIndex].correlationArray[index].value;
				return question;
			}
			var questionToInheritChoicesFrom;
			question.correlationArray[choiceGroupIndex]
				.correlationGroup[correlationGroupIndex].correlationArray[index].correlationQtype = selectedQuestion[0].type;

			if (selectedQuestion[0].type === "selects") {
				if(selectedQuestion[0].options && selectedQuestion[0].options.inheritItemsFrom) {
					questionToInheritChoicesFrom = previousQuestions.filter(function (q) {
						return q.id === selectedQuestion[0].options.inheritItemsFrom;
					});
				}
				if(questionToInheritChoicesFrom && questionToInheritChoicesFrom.length > 0 && questionToInheritChoicesFrom[0].choices) {
					var filteredChoices = questionToInheritChoicesFrom[0].choices.filter(function (choice) {
						return parseInt(choice.value) < 900;
					});
					question.correlationArray[choiceGroupIndex]
						.correlationGroup[correlationGroupIndex].correlationArray[index].correlationSelects = [];
					for(var i = 0; i < filteredChoices.length; i++) {
						if(parseInt(filteredChoices[i].value) < 900) {
							question.correlationArray[choiceGroupIndex]
								.correlationGroup[correlationGroupIndex].correlationArray[index].correlationSelects.push({
								id: selectedQuestion[0].id + "_" + selectedQuestion[0].selects.length,
								label: filteredChoices[i].label,
								options: [{}]
							});
						}
					}
				}
				else {
					question.correlationArray[choiceGroupIndex]
						.correlationGroup[correlationGroupIndex].correlationArray[index].correlationSelects = selectedQuestion[0].selects;
				}
			}
			else {
				if(selectedQuestion[0].options && selectedQuestion[0].options.inheritFrom) {
					questionToInheritChoicesFrom = previousQuestions.filter(function (q) {
						return q.id === selectedQuestion[0].options.inheritFrom;
					});
				}
				if(questionToInheritChoicesFrom && questionToInheritChoicesFrom.length > 0 && questionToInheritChoicesFrom[0].choices) {
					question.correlationArray[choiceGroupIndex]
						.correlationGroup[correlationGroupIndex].correlationArray[index].correlationQuestionChoices = questionToInheritChoicesFrom[0].choices;
				}
				else {
					question.correlationArray[choiceGroupIndex]
						.correlationGroup[correlationGroupIndex].correlationArray[index].correlationQuestionChoices = selectedQuestion[0].choices;
				}
			}
			return question;
		}

		function getScaleChoicesForCorrelationSelect(question, previousQuestions, choiceGroupIndex, correlationGroupIndex, index) {
			var selectedQuestion = previousQuestions.filter(function (q) {
				return q.id === question.correlationArray[choiceGroupIndex]
					.correlationGroup[correlationGroupIndex].correlationArray[index].question_id;
			});
			question.correlationArray[choiceGroupIndex]
				.correlationGroup[correlationGroupIndex].correlationArray[index].chosenSelectsScale = selectedQuestion[0].selectsScale;
			return question;
		}

		function addCorrelationGroup(question, parentIndex) {
			//add group starting at currentIndex+1
			question.skipGroup.splice(parentIndex + 1, 0, {skipArray: [{}]});
			//added group BETWEEN 2 other groups, NOT at the end
			if (question.skipGroup[parentIndex].operator) {
				//group at index already has operator, add operator to the new group
				question.skipGroup[parentIndex + 1].operator = "and";
			}
			//added group at the END
			else {
				//add operator to added group
				question.skipGroup[parentIndex].operator = "and";
			}
			return question;
		}

		function addCorrelationChoiceGroup(question, parentIndex) {
			//add group starting at currentIndex+1
			question.correlationArray.splice(parentIndex + 1, 0, {
				mainChoice: null,
				mainQuestionId: question.id,
				errorMessage: null,
				correlationGroup: [{
					correlationArray: [{}]
				}]
			});
			return question;
		}

		function saveCorrelationJson(question) {
			//need to work with duplicated question, since we cant remove choices from main question - they will no longer be visible in admin
			var questionDuplicate = angular.copy(question);
			if(question.correlationArray) {
				//no correlation at all, delete entire obj
				if(question.correlationArray.length === 1 && question.correlationArray[0].mainChoice === null) {
					question = initializeCloseCorrelation(question);
					return question;
				}
				//each choice group
				for(var i=0;i<question.correlationArray.length;i++) {
					var choice = question.correlationArray[i];
					//if no mainChoice, empty choice group - splice
					if(choice.mainChoice === null && !choice.mainChoiceCondition) {
						question.correlationArray.splice(i, 1);
						i--;
						continue;
					}
					//for each correlationGroup in choice, check if not empty
					for(var ii=0;ii<choice.correlationGroup.length;ii++) {
						var group = choice.correlationGroup[ii];
						//group has no conditions, delete group
						if(group.correlationArray.length === 0) {
							choice.correlationGroup.splice(ii, 1);
							ii--;
							continue;
						}
						//each condition in group, check to make sure no empty ones
						for(var iii=0;iii<group.correlationArray.length;iii++) {
							var correlationCondition = group.correlationArray[iii];
							//if empty or no value, delete it
							if(angular.equals({}, correlationCondition) || (!correlationCondition.value && !correlationCondition.selectsItemValue)) {
								group.correlationArray.splice(iii, 1);
								iii--;
							}
							//delete choices/scale from duplicate question, dont save them in json
							else {
								delete questionDuplicate.correlationArray[i].correlationGroup[ii].correlationArray[iii].correlationQuestionChoices;
								delete questionDuplicate.correlationArray[i].correlationGroup[ii].correlationArray[iii].chosenSelectsScale;
								delete questionDuplicate.correlationArray[i].correlationGroup[ii].correlationArray[iii].correlationSelects;
							}
						}
						//check group again, if all conditions were empty, delete group as well since group is now empty.
						if(group.correlationArray.length === 0) {
							choice.correlationGroup.splice(ii, 1);
							ii--;
						}
					}
				}
				question.correlationArrayJson = angular.toJson(questionDuplicate.correlationArray);
			}
			else {
				delete question.correlationArrayJson;
			}
			return question;
		}
	}
})();