(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('RaffleService', RaffleService);

	RaffleService.$inject = ['$http'];
	function RaffleService($http) {
		var service = {};

		service.getWinners = getWinners;
		service.getDrawCounts = getDrawCounts;
		service.makeWinners = makeWinners;
		service.countWinners = countWinners;

		return service;

		function getDrawCounts(okCallback, koCallback) {
			$http({
				method: 'GET',
				url: '/rest/tickets/drawCounts/'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function constructQueryParams(params, drawUrl) {
			if (params.type)
				drawUrl += '?type=' + params.type;

			if (params.surveysCompleted)
				drawUrl += '&questsCompleted=' + params.surveysCompleted;

			return drawUrl;
		}

		function getWinners(params, okCallback, koCallback) {
			var drawUrl = '/rest/tickets/winners/' + params.initialDate + '/' + params.finalDate + '/' + params.noWinners + '/' + params.lastActive;

			drawUrl = constructQueryParams(params, drawUrl);

			$http({
				method: 'GET',
				url: drawUrl
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function makeWinners(winnersArr, okCallback, koCallback) {
			$http({
				method: 'POST',
				url: '/rest/users/makeWinners',
				data: winnersArr
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}


		function countWinners(params, okCallback, koCallback) {

			var drawUrl = '/rest/tickets/winners'
			if (params.initialDate != null)
				drawUrl += '/' + params.initialDate;
			else
				drawUrl += '/' + null;

			if (params.finalDate != null)
				drawUrl +='/' + params.finalDate;
			else
				drawUrl += '/' + null;

			if (params.lastActive != null)
				drawUrl += '/' + params.lastActive;
			else
				drawUrl += '/' + null;

			drawUrl = constructQueryParams(params, drawUrl);

			$http({
				method: 'GET',
				url: drawUrl
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}
	}

})();