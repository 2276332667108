(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('IncentivesService', IncentivesService);

    IncentivesService.$inject = ['$http'];
	function IncentivesService($http) {
		var service = {};

		service.getPrizes = getPrizes;
		service.setConfigurationsMap = setConfigurationsMap;
		service.getExtraPointsMap = getExtraPointsMap;
		service.getDates = getDates;
		service.resetTickets = resetTickets;
		service.deleteTicket = deleteTicket;
		service.addPrizeImage = addPrizeImage;
		service.getPrizeImage = getPrizeImage;
		service.postPrizeDetails = postPrizeDetails;
		service.getPrizesTranslations = getPrizesTranslations;
		service.deleteAPrize = deleteAPrize;
		service.publishPrize = publishPrize;
		service.getTickets = getTickets;
		service.getRafflePrizes = getRafflePrizes;
		service.getRafflePrizesLabel = getRafflePrizesLabel;
		service.getRafflePrizeById = getRafflePrizeById;
		service.saveRafflePrize = saveRafflePrize;
		service.switchRafflePrizesRank = switchRafflePrizesRank;
		service.togglePublishForRafflePrize = togglePublishForRafflePrize;
		return service;

		function setConfigurationsMap(data, okCallback, koCallback) {
			$http({
				method: 'PUT',
				url: '/rest/config/editTickets',
				data: data
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function getTickets(okCallback, koCallback) {
			$http({
				method: 'GET',
				url: '/rest/config/tickets'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function getPrizes(okCallback, koCallback) {
			$http({
				method: 'GET',
				url: '/rest/config/prizes'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function getPrizesTranslations(okCallback, koCallback) {
			$http({
				method: 'GET',
				url: '/rest/config/prizesTranslations'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}


		function deleteTicket(ticket,okCallback, koCallback){
			$http({
				method: 'POST',
				url: '/rest/config/removeTicket',
				data: ticket
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

        function getExtraPointsMap(okCallback, koCallback) {
            $http({
                method: 'GET',
                url: '/rest/tickets/points/bonus'
            }).then(function successCallback(response) {
                okCallback && okCallback(response);
            }, function errorCallback(response) {
                koCallback && koCallback(response);
            });
        }

		function getDates(okCallback, koCallback) {
			$http({
				method: 'GET',
				url: '/rest/tickets/dates'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function resetTickets(data, okCallback, koCallback) {
			$http({
				method: 'POST',
				url: '/rest/tickets/reset',
				data: data
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function addPrizeImage(prizeId, image,  okCallback, koCallback){
			var formData = new FormData();
			formData.append('file', image);

			$http.put('/rest/config/addImageToPrize/' + prizeId, formData, {
				transformRequest: angular.identity,
				headers: {'Content-Type': undefined}
			})
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		}

		function getPrizeImage(prizeId, okCallback, koCallback){
			$http.get("/rest/config/getImageToPrize/" + prizeId, {responseType : 'arraybuffer'})
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		}

		function postPrizeDetails(prize, okCallback, koCallback){
			$http.post("/rest/config/savePrizeDetails", prize)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		}

		function deleteAPrize(prizeId, okCallback, koCallback){
			$http.post("/rest/config/deleteAPrize/" + prizeId)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		}

		function publishPrize(prizeId,publish,okCallback,koCallback){
			$http.post("/rest/config/publishPrize/" + prizeId + "/" + publish)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		}

		function getRafflePrizes(okCallback, koCallback) {
			return $http.get("/rest/config/prizes/raffle")
				.then(function successCallback(response) {
					return okCallback && okCallback(response);
				}, function errorCallback(response) {
					koCallback && koCallback(response);
				});
		}

		function getRafflePrizesLabel(okCallback, koCallback) {
			$http.get("/rest/config/prizes/raffleLabel")
				.then(function successCallback(response) {
					okCallback && okCallback(response);
				}, function errorCallback(response) {
					koCallback && koCallback(response);
				});
		}

		function getRafflePrizeById(prizeId, okCallback, koCallback) {
			$http.get("/rest/config/prize/raffle/" + prizeId)
				.then(function successCallback(response) {
					okCallback && okCallback(response);
				}, function errorCallback(response) {
					koCallback && koCallback(response);
				});
		}

		function saveRafflePrize(prizeData, okCallback, koCallback) {
			$http.post("/rest/config/save/prize/raffle", prizeData)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		}

		function switchRafflePrizesRank(prizeId1, prizeId2, okCallback, koCallback) {
			$http.post("/rest/config/switch/prizes/rank/" + prizeId1 + "/" + prizeId2)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		}

		function togglePublishForRafflePrize(prizeId, okCallback, koCallback) {
			$http.post("/rest/config/toggle/publish/raffle/prize/" + prizeId)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		}
	}

})();