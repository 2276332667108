(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .controller('VoterSimulatorController', VoterSimulatorController);

    VoterSimulatorController.$inject = ['$rootScope','MlService'];
    function VoterSimulatorController($rootScope,MlService) {
        var vm = this;

        vm.buildMlFeaturesConfig = function () {
            vm.income = [{
                element_id : 204,
                element_value : '...'
            },{
                element_id : 1,
                element_value : 'No income'
            },{
                element_id : 2,
                element_value : '800 RON or less'
            },
                {
                    element_id : 3,
                    element_value : 'Between 801 RON and 1600 RON'
                }
                ,{
                    element_id : 4,
                    element_value : 'Between 1601 RON and 2000 RON'
                },{
                    element_id : 5,
                    element_value : 'Between 2001 RON and 3000 RON'
                },{
                    element_id : 6,
                    element_value : 'Between 3001 RON and 4000 RON'
                },
                {
                    element_id : 7,
                    element_value : 'Between 4001 RON and 7000 RON'
                },
                {
                    element_id : 8,
                    element_value : 'Over 7000 RON'
                }
            ];

            vm.age=[{
                element_id : 204,
                element_value : '...'
            },{
                element_id : 1,
                element_value : '18-24'
            },{
                element_id : 2,
                element_value : '25-34'
            },{
                element_id : 3,
                element_value : '35-44'
            },{
                element_id : 4,
                element_value : '45-54'
            },{
                element_id : 5,
                element_value : '55-64'
            },{
                element_id : 6,
                element_value : '65+'
            }
            ];

            vm.education=[{
                element_id : 204,
                element_value : '...'
            },{
                element_id : 1,
                element_value : 'I do not have formal studies'
            },{
                element_id : 2,
                element_value : 'Primary School (4 grades)'
            },{
                element_id : 3,
                element_value : 'Gymnasium (8 classes)'
            },{
                element_id : 4,
                element_value : 'High school'
            },{
                element_id : 5,
                element_value : 'Vocational school'
            },{
                element_id : 6,
                element_value : 'Junior college'
            },{
                element_id : 7,
                element_value : 'Faculty'
            },{
                element_id : 8,
                element_value : 'Postgraduate studies'
            },{
                element_id : 9,
                element_value : 'Doctorate'
            }];

            vm.sex = [{
                element_id : 204,
                element_value : '...'
            },{
                element_id : 0,
                element_value : 'Male'
            },{
                element_id : 1,
                element_value : 'Female'
            }];

            vm.location = [{
                element_id : 204,
                element_value : '...'
            },{
                element_id : 0,
                element_value : 'Rural'
            },{
                element_id : 1,
                element_value : 'Urban Small'
            },{
                element_id : 2,
                element_value : 'Urban Medium'
            },{
                element_id : 3,
                element_value : 'Urban Big'
            }];
        };

        vm.runAnalysisForFeatures = function(){
            vm.featuresValues = [];
            vm.featuresValues[0] = vm.selectedAge;
            vm.featuresValues[1] = vm.selectedSex;
            vm.featuresValues[2] = vm.selectedLocation;
            vm.featuresValues[3] = vm.selectedEducation;
            vm.featuresValues[4] = vm.selectedIncome;


            MlService.postFeaturesValues(
                vm.featuresValues,
                function (response) {
                    vm.isDataFetched = true;
                      if(vm.initialData === undefined) {
                          vm.initialData = {};
                          for (var i = 0; i < response.data.length; i++) {
                              vm.initialData[response.data[i].label] = response.data[i].value;
                          }
                      }

                    vm.buildMLChartsConfig(response.data);
                    console.log(response);
                },
                function (error) {
                    console.log(error);
                }
            );
        };

        vm.initFeaturesValues = function(){

            vm.selectedAge = -1;
            vm.selectedSex = -1;
            vm.selectedLocation = -1;
            vm.selectedEducation = -1;
            vm.selectedIncome = -1;

        };

        vm.checkChoice = function(item,type){
            if(item===204){
                switch(type) {
                    case 'age':
                        vm.selectedAge = -1;
                        break;
                    case 'sex':
                        vm.selectedSex = -1;
                        break;
                    case 'location':
                        vm.selectedLocation = -1;
                        break;
                    case 'education':
                        vm.selectedEducation = -1;
                        break;
                    case 'income':
                        vm.selectedIncome = -1;
                        break;
                    default:
                        break;
                }
            }
          console.log(item);
        };

        vm.buildMLChartsConfig = function (featuresData) {
            var chart = am4core.create("voterSimulatorChart", am4charts.XYChart);

            for(var elem of featuresData){
                elem.base = vm.initialData[elem.label];
            }
            console.log(featuresData);
            chart.data = featuresData;

            chart.numberFormatter.numberFormat = "#.0'%'";

            var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "label";
            categoryAxis.renderer.inversed = true;
            categoryAxis.renderer.grid.template.location = 0;


            var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
            valueAxis.renderer.opposite = true;
            valueAxis.max = 100;
            valueAxis.min = 0;


//create columns

            vm.createSeries("value",chart);
            vm.createSeries("base",chart);

            // series.columns.template.adapter.add("fill", function(fill, target) {
            //     return colors[target.dataItem.index];
            // });

//add chart cursor
            chart.cursor = new am4charts.XYCursor();
            chart.cursor.behavior = "zoomY";

            var legendData = [];
            chart.legend = new am4charts.Legend();

            var colors = ['#FF0000','#CCCC00','#0000ff','#D3D3D3'];
            for(var i = 0 ; i < chart.data.length; i++){
                // var red = chart.colors.getIndex(i)._value.r;
                // var green = chart.colors.getIndex(i)._value.g;
                // var blue = chart.colors.getIndex(i)._value.b;
                legendData.push({
                    "name":chart.data[i].label + "-" + chart.data[i].value.toFixed(1) + "%" ,
                    "fill": colors[i]
                });
            }
            chart.legend.data = legendData;
            console.log(legendData);

        };

        vm.rgbToHex = function (rgb) {
            var hex = Number(rgb).toString(16);
            if (hex.length < 2) {
                hex = "0" + hex;
            }
            return hex;
        };

        vm.fullColorHex = function(r,g,b) {
            var red = vm.rgbToHex(r);
            var green = vm.rgbToHex(g);
            var blue = vm.rgbToHex(b);
            return '#' + red+green+blue;
        };

        vm.createSeries = function(field,chart){
            var series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.categoryY = "label";
            series.dataFields.valueX = field;
            series.name = "Value";
            series.columns.template.fillOpacity = 0.5;
            series.columns.template.strokeOpacity = 0;

            var toolTipMessage = '';
            var colors = [];
            var bulletName = '';
            if(field === 'value'){
                toolTipMessage = "{categoryY}: {valueX.value}";
                colors = ['#FF0000','#CCCC00','#0000ff','#D3D3D3'];
                bulletName = 'actual';
            }else if(field === 'base'){
                toolTipMessage = "Baseline {categoryY}: {valueX.value}";
                colors = ['#FF7F7F','#CCCC7F','#7F00ff','#A3A3A3'];
                bulletName = 'baseline';
            }
            series.tooltipText = toolTipMessage;

            series.columns.template.adapter.add("fill", function(fill, target) {
                return colors[target.dataItem.index];
            });

            var valueLabel = series.bullets.push(new am4charts.LabelBullet());
            valueLabel.label.text = "{valueX}";
            valueLabel.label.horizontalCenter = "left";
            valueLabel.label.dx = 10;
            valueLabel.label.hideOversized = false;
            valueLabel.label.truncate = false;

            var categoryLabel = series.bullets.push(new am4charts.LabelBullet());
            categoryLabel.label.text = bulletName;
            categoryLabel.label.horizontalCenter = "right";
            categoryLabel.label.dx = -10;
            categoryLabel.label.fill = am4core.color("#fff");
            categoryLabel.label.hideOversized = false;
            categoryLabel.label.truncate = false;

        };

        (function initController() {
            vm.breadcrumbs = "Voter Simulator";
            vm.activeSubmenu = 'voterSimulator';
            vm.isDataFetched = false;
            vm.initialData = undefined;

            vm.initFeaturesValues();
            vm.buildMlFeaturesConfig();
            vm.runAnalysisForFeatures();
        })();
    }

})();