(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .factory('SurveyEditsService', SurveyEditsService);

    SurveyEditsService.$inject = ['$http', '$rootScope'];
    function SurveyEditsService($http, $rootScope) {
        var service = {};

        service.CheckQuestEdits = function (questId, updateQuestEdit, okCallback, koCallback) {
            $http.post("/rest/quests/checkQuestEdits/" + questId + "?updateQuestEdit=" + updateQuestEdit)
                .then(function successCallback(response) {
                    okCallback && okCallback(response);
                }, function errorCallback(response) {
                    koCallback && koCallback(response);
                });
        };

        return service;
    }

})();
