(function () {
	'use strict';
	angular
		.module('questiaPlatformApp')
		.factory('PermissionUtils', ['$rootScope', 'PermPermissionStore', PermissionUtils]);

	function PermissionUtils($rootScope, PermPermissionStore) {
		function userHasPermission(permission) {
			if(permission === 'anonymous') {
				return !$rootScope.globals.authorization;
			}
			return $rootScope.globals.permissions &&
				   $rootScope.globals.permissions.indexOf(permission) !== -1;
		}

		return {
			definePermissions: function() {
				var permissions = ['anonymous','admin','panel_predictor','reserved','home','survey_read','survey_write',
					'email','rewards','wall','users','recrute','predictor','translate','setup_rewards','regions','tasks',
					'user_information', 'buy_responses', 'weighting', 'assistant'];
				PermPermissionStore.defineManyPermissions(permissions, /*@ngInject*/ function (permissionName) {
					return userHasPermission(permissionName);
				});
			}
		};
	}
})();

