(function () {
    'use strict';

	var COLORS = ["#D5DF83", "#318BA8", "#6BC399", "#FCC9B2", "#CB2B56", "#B1CA54", "#095478", "#1DA77D", "#F8A587", "#9A1B3C", "#E0EABB", "#A4D5E5", "#C4E7D6", "#F8804B", "#EDA8BA"];

	function getRandomColor() {
		var letters = '0123456789ABCDEF';
		var color = '#';
		for (var i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}

    angular
        .module('questiaPlatformApp')
        .controller('ForecastEventStatsController', ForecastEventStatsController);

	ForecastEventStatsController.$inject = ['$rootScope', '$location', '$filter', 'ForecastService'];
    function ForecastEventStatsController($rootScope, $location, $filter, ForecastService) {
        var vm = this;

        vm.initForecastAndShowQuestions = function (forecastId) {
			var url = "/rest/quests/" + forecastId;
			vm.forecastEndDate = new Date();
			vm.scoresSetOk = null;

			ForecastService.getSingleForecast(url,
				function (response) {
					vm.forecast = response;
					vm.forecastQuestions = response.questions;

					vm.questionsObj = {};
					for (var i = 0; i < vm.forecastQuestions.length; i++) {
						if (vm.forecastQuestions[i].forecastOptions) {
							vm.questionsObj[vm.forecastQuestions[i].question_id] = vm.forecastQuestions[i].forecastOptions;
						}
					}

					vm.drawForecastGraphs(vm.forecastId);
				},
				function (response) {
				}
			);
		};

		vm.drawForecastGraphs = function (forecastId) {
			ForecastService.getSingleForecastStats(forecastId,
				function (response) {
					var forecastStats = [];
					vm.base0 = response.data.userBase;
					for (var i = 0; i < response.data.averages.length; i++) {
						forecastStats.push({
							title: response.data.averages[i].text,
							average: response.data.averages[i].average.toFixed(1),
							color: COLORS[i] || getRandomColor()
						});
					}
					vm.chart = makeChart(forecastStats, "chartdiv");
				},
				function (response) {
					console.log(response);
				}
			);

			ForecastService.getWeighedSingleForecastStats(forecastId, 1,
				function (response) {
					var forecastStats = [];
					vm.base1 = response.data.userBase;
					for (var i = 0; i < response.data.averages.length; i++) {
						forecastStats.push({
							title: response.data.averages[i].text,
							average: response.data.averages[i].average.toFixed(1),
							color: COLORS[i] || getRandomColor()
						});
					}
					vm.chart = makeChart(forecastStats, "chartdiv1");
				}
			);

			ForecastService.getWeighedSingleForecastStats(forecastId, 2,
				function (response) {
					var forecastStats = [];
					vm.base2 = response.data.userBase;
					for (var i = 0; i < response.data.averages.length; i++) {
						forecastStats.push({
							title: response.data.averages[i].text,
							average: response.data.averages[i].average.toFixed(1),
							color: COLORS[i] || getRandomColor()
						});
					}
					vm.chart = makeChart(forecastStats, "chartdiv2");
				}
			);

			ForecastService.getWeighedSingleForecastStats(forecastId, 3,
				function (response) {
					var forecastStats = [];
					vm.base3 = response.data.userBase;
					for (var i = 0; i < response.data.averages.length; i++) {
						forecastStats.push({
							title: response.data.averages[i].text,
							average: response.data.averages[i].average.toFixed(1),
							color: COLORS[i] || getRandomColor()
						});
					}
					vm.chart = makeChart(forecastStats, "chartdiv3");
				}
			);
		};

		function makeChart(data, divId) {
			return AmCharts.makeChart(divId, {
				"titles": [
					{
						"text": vm.forecast.description,
						"size": 18
					}],
				"type": "pie",
				"dataProvider": data,
				"valueField": "average",
				"titleField": "title",
				"colorField": "color",
				"labelText": "[[title]] ([[value]]%) ",
				"labelRadius": 5,
				"balloonText": "[[title]] : [[value]]%",
				"radius": "35%",
				"outlineThickness": 1,
				"outlineAlpha": 1,
				"outlineColor": undefined,
				"balloon": {
					"fixedPosition": true
				},
				"export": {
					"enabled": true,
					"libs": {
						"path": "/js/lib/amcharts/plugins/export/libs/"
					}
				}
			});
		};

		vm.setScores = function (scores) {
			vm.postQuestionsObj = {
				"forecast_id": vm.forecastId,
				"forecastEndDate": $filter('date')(vm.forecastEndDate, 'yyyy-MM-dd H:m:s'),
				"forecast_scores": scores
			};
			vm.scoresTotal = 0;
			angular.forEach(vm.postQuestionsObj.forecast_scores, function (value, key) {
				vm.scoresTotal += parseFloat(value);
			});
			if (vm.scoresTotal == 100) {
				ForecastService.postForecastResult(vm.postQuestionsObj,
					function (response) {
						vm.scoresSetOk = true;
						ForecastService.updateForecastScores(
							function (response) {
								vm.usersUpdated = response.data;
							});
					}
				);
			}
			else {
				vm.scoresSetOk = false;
			}
		};

		vm.dateOpts = {
			dateFormat: 'Y-m-d H:i:s',
			enableTime: true,
			defaultDate: new Date(),
			// create an extra input solely for display purposes
			altInput: true,
			altFormat: "F j, Y h:i K"
		};

		(function initController() {
			var locationPath = $location.path();
			vm.forecastId = locationPath.substring(locationPath.lastIndexOf('/')+1);

			vm.breadcrumbs = "Predictor: Event Stats " + vm.forecastId;
			vm.activeSubmenu = 'predictor';

			vm.initForecastAndShowQuestions(vm.forecastId);
		})();
    }
})();
