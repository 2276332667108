(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('UsersService', UsersService);

	UsersService.$inject = ['$http'];
	function UsersService($http) {
		var service = {};

		service.GetStatistics = function (okCallback, koCallback) {
			$http.get("/rest/users/dashstats")
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					if(koCallback) koCallback(response);
					if(response.status==403)
						window.location.href = '/login';
				});
		};

		service.GetCampaignStatistics = function (minDate, maxDate, okCallback, koCallback) {
			$http.get("/rest/users/campaign/" + minDate + "/" + maxDate)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					if(koCallback) koCallback(response);
				});
		};

		service.GetConversions = function (minDate, maxDate, okCallback, koCallback) {
			$http.get("/rest/users/conversions/" + minDate + "/" + maxDate)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					if(koCallback) koCallback(response);
				});
		};

		service.GetAllUsersForCSV = function (url, okCallback, koCallback) {
			$http.get(url)
				.then(function (response) {
					okCallback(response);
				}, function (response) {
					if(koCallback) koCallback(response);
				});
		};

		service.GetActiveUsersIn = function (year, month, okCallback, koCallback) {
			$http.get('/rest/users/activeIn/' + year + '/' + month)
				.then(function (response) {
					okCallback(response);
				}, function (response) {
					if(koCallback) koCallback(response);
				});
		};

		service.SuspendUser = function (userId, okCallback, koCallback) {
			$http.post('/rest/users/deactivate/' + userId)
				.then(function (response) {
					okCallback(response);
				}, function (response) {
					if(koCallback) koCallback(response);
				});
		};

		service.UnsuspendUser = function (userId, okCallback, koCallback) {
			$http.post('/rest/users/reactivate/' + userId)
				.then(function (response) {
					okCallback(response);
				}, function (response) {
					if(koCallback) koCallback(response);
				});
		};

		service.GetSusceptibleUsers = function (minFingerPrints, okCallback, koCallback) {
            $http.get('/rest/users/panelpolice/' + minFingerPrints)
                .then(function (response) {
                    okCallback(response);
                }, function (response) {
                    if(koCallback) koCallback(response);
                });
		};

		return service;
	}

})();
