(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.controller('ConversionsController', ConversionsController);

	ConversionsController.$inject = ['$rootScope', '$q', 'UsersService'];
	function ConversionsController($rootScope, $q, UsersService) {
		var vm = this;

		vm.getConversions = function () {
			if (!vm.minDate || !vm.maxDate) return;

			vm.loadingConversions = true;
			var deferme = $q.defer();
			UsersService.GetConversions(vm.minDate.format("YYYY-MM-DD"), vm.maxDate.format("YYYY-MM-DD"),
				function SuccessCallback(response) {
					var conversions = angular.copy(response.data);
					conversions.forEach(function(conversion) {
						conversion.conversion_date = moment(conversion.conversion_date).format("YYYY-MM-DD");
					});
					deferme.resolve(conversions);
					vm.loadingConversions = false;
				},
				function ErrorCallback(response) {
					console.log("error getting conversions data!");
					deferme.reject();
					vm.loadingConversions = false;
				}
			);

			return deferme.promise;
		};

		(function initController() {
			vm.breadcrumbs = "Rewards: Conversions";
			vm.activeSubmenu = 'panel_rewards';

			vm.conversionsCsvHeader  = ["User ID", "Email", "Conversion type", "Points converted", "Prize/Number of tickets", "Conversion date"];
		})();
	}

})();