(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .controller('WallDashboardController', WallDashboardController);

    WallDashboardController.$inject = ['$rootScope', '$state', '$mdSidenav', 'QuestiaUtils', 'WallDashboardService'];

    function WallDashboardController($rootScope, $state, $mdSidenav, QuestiaUtils, WallDashboardService) {
        var vm = this;

        vm.getTopicsWithAnswersCounts = function () {
            WallDashboardService.GetTopicsWithAnswersCounts(
                function success(response) {
                    vm.topicsBlocks = QuestiaUtils.listToMatrix(response.data, 4);
                },
                function error(response) {
                    console.log(response);
                }
            );
        };

        vm.makeChart = function () {
            vm.chartData = [];
            vm.chartTitle = [
                {
                    "text": "",
                    "size": 16
                }
            ];
            vm.stockEvents = [];
            vm.chart = AmCharts.makeChart("chartWallDashboard", {
                "type": "stock",
                "theme": "light",
                "path": "/js/lib/amcharts",
                "dataSets": [{
                    "title": "",
                    "fieldMappings": [{
                        "fromField": "value",
                        "toField": "value"
                    }, {
                        "fromField": "volume",
                        "toField": "volume"
                    }],
                    "dataProvider": vm.chartData,
                    "categoryField": "date",
                    "stockEvents": vm.stockEvents
                }],

                "panels": [{
                    "showCategoryAxis": false,
                    "titles": vm.chartTitle,
                    "title": "Value",
                    "percentHeight": 70,
                    "stockGraphs": [{
                        "id": "g1",
                        "valueField": "value",
                        "comparable": true,
                        "compareField": "value",
                        "balloonText": "No. Users [[volume]] / Avg. Cards Viewed <b>[[value]]</b>",
                        "compareGraphBalloonText": "<b>[[value]]</b>"
                    }],
                    "stockLegend": {
                        "periodValueTextComparing": "[[percents.value.close]]",
                        "periodValueTextRegular": "[[value.close]]"
                    }
                }, {
                    "title": "Volume",
                    "percentHeight": 30,
                    "stockGraphs": [{
                        "valueField": "volume",
                        "type": "column",
                        "showBalloon": false,
                        "fillAlphas": 1
                    }],
                    "stockLegend": {
                        "periodValueTextRegular": "[[value.close]]"
                    }
                }],

                "chartScrollbarSettings": {
                    "graph": "g1"
                },

                "chartCursorSettings": {
                    "valueBalloonsEnabled": true,
                    "fullWidth": true,
                    "cursorAlpha": 0.1,
                    "valueLineBalloonEnabled": true,
                    "valueLineEnabled": true,
                    "valueLineAlpha": 0.5
                },

                "export": {
                    "enabled": true
                }
            });
            vm.refreshChart();
        };

        vm.refreshChart = function () {
            WallDashboardService.GetScrollAveragesByDay(
                function success(response) {
                    var data = response.data;
                    for(var i in data) {
                        var newDate = new Date(data[i][0]);
                        vm.chartData.push({
                            "date": newDate,
                            "value": data[i][2],
                            "volume": data[i][1]
                        });
                    }
                    vm.chart.validateData();
                    vm.chartRendered = true;
                },
                function error(response) {
                    console.log(response);
                }
            );
        };

        (function initController() {
            vm.activeSubmenu = 'panel';
            vm.breadcrumbs = 'Panel: Wall Stats';

            vm.topicsBlocks = undefined;
            vm.getTopicsWithAnswersCounts();

            vm.chartRendered = false;
            vm.makeChart();
        })();
    }
})();
