(function () {
	'use strict';

	var UNDECIDED_VALUE = 997;

	angular
		.module('questiaPlatformApp')
		.controller('RecurrentHistoryController', RecurrentHistoryController);

	RecurrentHistoryController.$inject = ['$rootScope', '$stateParams', 'RecurrentHistoryService', '$mdDialog'];
	function RecurrentHistoryController($rootScope, $stateParams, RecurrentHistoryService, $mdDialog) {
		var vm = this;
		const STEP_LENGTH_LABEL_VISIBILITY = 2;

		(function initController() {
			vm.progress = false;
			vm.breadcrumbs = "";
			vm.activeSubmenu = 'surveys';

			vm.chartObject = undefined;
			vm.hiddenChoices = [];

			vm.withUndecided = false;
			vm.cumulated = false;
			vm.predefinedQuestionId = $stateParams.predefinedQuestionId;
			vm.chartTitle = "";

			if(vm.predefinedQuestionId) {
				vm.breadcrumbs = 'Predefined questions data history';
				RecurrentHistoryService.GetPredefinedQuestionHistoryData(vm.predefinedQuestionId, function (response) {
					vm.stats = response.data;
					vm.choicesArray = vm.createChoicesArray(vm.stats);
					vm.chartTitle = vm.stats.question.question_quest_qid + " | " + vm.stats.question.question_id + " | " + vm.stats.question.question_type + " | " + vm.stats.question.question_text;
					vm.makeChart(vm.stats);
				}, function (error) {
					if (error.data && error.data.null_end_date_quests) {
						$mdDialog.show(
							$mdDialog.alert()
								.title('Error')
								.textContent(error.data.error_message)
								.ok('Close'));
					}
				});
			} else {
				vm.breadcrumbs = "Surveys: Vote intention history";

				vm.withUndecided = $stateParams.withUndecided ? JSON.parse($stateParams.withUndecided) : false;
				vm.cumulated = $stateParams.cumulated ? JSON.parse($stateParams.cumulated) : false;
				vm.chartTitle = (vm.withUndecided ? "With undecided" : "Without undecided") +
					(vm.cumulated ? ", cumulated": ", not cumulated");

				RecurrentHistoryService.GetStats(vm.withUndecided, vm.cumulated, function (response) {
					vm.stats = response.data;
					vm.choicesArray = vm.createChoicesArray(vm.stats);
					vm.makeChart(vm.stats);
				});

				RecurrentHistoryService.GetVotesByUser(function (response) {
					response.data[0].answers.unshift("userId");
					// build array for ng-csv
					var array = [];
					for (var i = 1; i < response.data.length; i++) {
						response.data[i].answers.unshift(response.data[i].userId);
						array[i] = response.data[i].answers;
					}
					vm.history = {
						data: array,
						header: response.data[0].answers
					};
				});
			}
		})();

		vm.refreshChartButton = function () {
			var statsCopy = angular.copy(vm.stats);
			statsCopy = vm.removeValuesFromStats(statsCopy, vm.hiddenChoices);
			vm.refreshChartData(statsCopy);
		}

		vm.refreshChartData = function (stats){
			var result = vm.calculateChartDataAndVolumes(stats);
			var chartData = result.chartData;
			vm.volumes = result.volumes;

			vm.chartObject.dataProvider = chartData;
			vm.chartObject.validateData();
		}

		vm.calculateChartDataAndVolumes = function (stats) {
			var chartData = [];
			var volumes = {};
			for (var i = 0; i < stats.data.length; i++) {
				var perc = _getPercentages(stats.data[i], i);
				chartData.push(perc);
				volumes[stats.data[i].date.substring(0, 16)] = perc.total;
			}

			return {
				chartData: chartData,
				volumes: volumes
			}
		}

		vm.createChoicesArray = function (stats) {
			var choices = [];
			for (var key in stats.labels) {
				choices.push({
					"value": key,
					"label": stats.labels[key]
				});
			}
			return choices;
		}

		vm.removeValuesFromStats = function (stats, values) {
			for(var i in values) {
				var currentValue = values[i];
				for(var j in stats.data) {
					delete stats.data[j][currentValue];
				}
				delete stats.labels[currentValue];
			}
			return stats;
		}

		vm.makeChart = function (stats) {
			var result = vm.calculateChartDataAndVolumes(stats);
			var chartData = result.chartData;
			vm.volumes = result.volumes;
			var graphs = _getGraphs();

			function getBalloon(date) {
				return vm.volumes[AmCharts.formatDate(date, "YYYY-MM-DD")] + " // " + AmCharts.formatDate(date, "DD/MM/YYYY JJ:NN:SS");
			}

			vm.chartObject = AmCharts.makeChart("chart", {
				"type": "serial",
				"theme": "light",
				"titles": [
					{
						"text": stats.question.question_text,
						"size": 15
					}
				],
				"marginRight": 80,
				"dataProvider": chartData,
				"dataDateFormat": "YYYY-MM-DD JJ:NN:SS",
				"valueAxes": [{
					"position": "left",
					"title": "Percent"
				}],
				"graphs": graphs,
				"path": "/js/lib/amcharts",
				"chartScrollbar": {
					"graph": graphs[0].id,
					"scrollbarHeight": 50,
					"backgroundAlpha": 0,
					"selectedBackgroundAlpha": 0.1,
					"selectedBackgroundColor": "#888888",
					"graphFillAlpha": 0.5,
					"graphLineAlpha": 0.5,
					"selectedGraphFillAlpha": 0,
					"selectedGraphLineAlpha": 1,
					"autoGridCount": true,
					"color": "#AAAAAA"
				},
				"valueScrollbar": {
					"backgroundAlpha": 0,
					"selectedBackgroundColor": "#888888",
					"autoGridCount": true,
					"color": "#AAAAAA"
				},
				"chartCursor": {
					"cursorPosition": "mouse",
					"categoryBalloonFunction": getBalloon
				},
				"categoryField": "date",
				"categoryAxis": {
					"minPeriod": "mm",
					"parseDates": true
				},
				"legend": {
					"useGraphSettings": true
				},
				"export": {
					"enabled": true,
					"dateFormat": "YYYY-MM-DD HH:NN:SS"
				}
			});
		};


		function _getGraphs() {
			var graphs = [];
			for (var k in vm.stats.labels) {
				graphs.push({
					"id": k,
					"bullet": "round",
					"bulletBorderAlpha": 1,
					"bulletColor": "#FFFFFF",
					"bulletSize": 5,
					"hideBulletsCount": 50,
					"lineThickness": 2,
					"title": vm.stats.labels[k],
					"useLineColorForBulletBorder": true,
					"valueField": k,
					"labelText": "[[" + k + "_labelText]]",
					"balloonText": vm.stats.labels[k] + " : [[value]]"
				});
			}
			return graphs;
		}

		function _getPercentages(object, dataArrayIndex) {
			var results = {};
			// var total_abs = 0;
			var total = 0;
			for (var key in object) {
				if (key !== 'date') {
					total += parseInt(object[key]);
				}
			}
			for (key in object) {
				if (key !== 'date') {
					var percentage = (parseInt(object[key]) * 100 / total).toFixed(2);
					results[key] = percentage;
					results[key + "_labelText"] = dataArrayIndex !== 0 && dataArrayIndex % STEP_LENGTH_LABEL_VISIBILITY === 0 ? percentage.toString() : "";
				}
				else {
					results[key] = "" + object[key];
				}
			}
			results.total = total;
			return results;
		}

		vm.goto = function (page) {
			window.location.href = "/" + page;
		};

	}
})();