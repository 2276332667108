(function () {
	'use strict';
	var DEFAULT_TIME_SLOT = 90;
	angular
		.module('questiaPlatformApp')
		.controller('DashboardController', DashboardController);

	DashboardController.$inject = ['$rootScope', '$scope', 'DashboardService'];
	function DashboardController($rootScope, $scope, DashboardService) {
		var vm = this;

		vm.makeChart = function () {
			vm.chartInfo = {
				type: "active",
				title: "ACTIVE (monthly)"
			};
			vm.chartData = [];
			vm.chartTitle = [
				{
					"text": "",
					"size": 16
				}
			];
			vm.stockEvents = [];
			vm.chart = AmCharts.makeChart("chart", {
				"type": "stock",
				"theme": "light",
				"path": "/js/lib/amcharts",
				"dataSets": [{
					"title": "",
					"fieldMappings": [{
						"fromField": "value",
						"toField": "value"
					}, {
						"fromField": "volume",
						"toField": "volume"
					}],
					"dataProvider": vm.chartData,
					"categoryField": "date",
					"stockEvents": vm.stockEvents
				}],

				"panels": [{
					"showCategoryAxis": false,
					"titles": vm.chartTitle,
					"title": "Value",
					"percentHeight": 70,
					"stockGraphs": [{
						"id": "g1",
						"valueField": "value",
						"comparable": true,
						"compareField": "value",
						"balloonText": "[[volume]] = <b>[[value]]%</b>",
						"compareGraphBalloonText": "<b>[[value]]%</b>"
					}],
					"stockLegend": {
						"periodValueTextComparing": "[[percents.value.close]]%",
						"periodValueTextRegular": "[[value.close]]"
					}
				}, {
					"title": "Volume",
					"percentHeight": 30,
					"stockGraphs": [{
						"valueField": "volume",
						"type": "column",
						"showBalloon": false,
						"fillAlphas": 1
					}],
					"stockLegend": {
						"periodValueTextRegular": "[[value.close]]"
					}
				}],

				"chartScrollbarSettings": {
					"graph": "g1"
				},

				"chartCursorSettings": {
					"valueBalloonsEnabled": true,
					"fullWidth": true,
					"cursorAlpha": 0.1,
					"valueLineBalloonEnabled": true,
					"valueLineEnabled": true,
					"valueLineAlpha": 0.5
				},

				"export": {
					"enabled": true
				}
			});
			vm.refreshChart();
		};

		vm.refreshChart = function (type, title) {
			var chartDetails = {};
			if (type && title) {
				chartDetails = {
					type: type,
					title: title
				};

				if (!vm.chartRendered) {
					alert("Please wait for chart to be rendered...patience is a virtue!");
					return false;
				}
			}
			else {
				chartDetails = {
					type: vm.chartInfo.type,
					title: vm.chartInfo.title
				};
			}

			switch (chartDetails.type) {
				case "active":
				case "gp":
					vm.renderChartBy2Types(chartDetails.type, "confirmed", chartDetails.title);
					break;
				case "monthlygp":
					vm.renderChartBy2Types(chartDetails.type, "active", chartDetails.title);
					break;
				default:
					vm.renderChartByType(chartDetails.type, chartDetails.title);
					break;
			}
		};

		vm.renderChartBy2Types = function (type1, type2, title) {
			vm.chartData.length = 0;
			vm.chartInfo = {
				type: type1,
				title: title
			};
			vm.chartRendered = false;
			DashboardService.getStatisticsForPeriod(type1, vm.period,
				function SuccessCallback(response) {
					vm.array1 = response.data;
					DashboardService.getStatisticsForPeriod(type2, vm.period,
						function SuccessCallback(response) {
							vm.array2 = response.data;
							for (var i = vm.array1.length - 1; i >= 0; i--) {
								var newDate = new Date(vm.array1[i][0]);
								newDate.setHours(0, 0, 0);
								var activeUsersDay = vm.array1[i][1];
								var confirmedUsersDay = vm.array2[i][1];
								var percentageActive = Math.round((activeUsersDay / confirmedUsersDay) * 100);
								vm.chartData.push({
									"date": newDate,
									"value": percentageActive,
									"volume": activeUsersDay
								});
							}
							DashboardService.getEvents(
								function SuccessCallback(response) {
									vm.chartEventData = response.data;
									if (vm.stockEvents.length == 0) {
										for (var ii = 0; ii < vm.chartEventData.length; ii++) {
											vm.stockEvents.push({
												date: new Date(vm.chartEventData[ii].event_date),
												type: "flag",
												graph: "g1",
												backgroundColor: "#009900",
												color: "#ffffff",
												text: vm.chartEventData[ii].event_label,
												description: vm.chartEventData[ii].event_description
											});
										}
									}
									vm.chartTitle[0].text = title;
									vm.chart.validateData();
									vm.chartRendered = true;
								},
								function ErrorCallback(response) {
									console.log(response);
								}
							);
						},
						function ErrorCallback(response) {
							console.log(response);
						}
					);
				},
				function ErrorCallback(response) {
					console.log(response);
				}
			);
		};

		vm.renderChartByType = function (type, title) {
			vm.chartData.length = 0;
			vm.chartInfo = {
				type: type,
				title: title
			};
			vm.chartRendered = false;
			DashboardService.getStatisticsForPeriod(type, vm.period,
				function SuccessCallback(response) {
					for (var i = response.data.length - 1; i >= 0; i--) {
						var newDate = new Date(response.data[i][0]);
						newDate.setHours(0, 0, 0);
						vm.chartData.push({
							"date": newDate,
							"value": response.data[i][1],
							"volume": response.data[i][1]
						});
					}
					vm.chartTitle[0].text = title;
					vm.chart.validateData();
					vm.chartRendered = true;
				},
				function ErrorCallback(response) {

				});

		};

		vm.changePeriod = function (newPeriod) {
			vm.period = newPeriod;
			vm.refreshChart();
		};

		vm.addChartEvent = function () {
			DashboardService.postChartEvent(vm.event,
				function SuccessCallback(response) {
					alert("Event added");
				},
				function ErrorCallback(response) {
					alert("Error ");
					console.log(response);
				});

			vm.stockEvents.push({
				date: vm.event.event_date,
				type: "flag",
				graph: "g1",
				backgroundColor: "#009900",
				color: "#ffffff",
				text: vm.event.event_label,
				description: vm.event.event_description
			});
			vm.chart.validateData();
		};

        vm.getStats = function () {
			vm.period = DEFAULT_TIME_SLOT;
			vm.chartRendered = false;
			DashboardService.getStatistics(
				function SuccessCallback(response) {
					vm.generalStats = response.data;
					vm.generalStats.inactive = vm.generalStats.confirmed - vm.generalStats.activeUsers;
					vm.generalStats.inactivePercentage = (vm.generalStats.inactive / vm.generalStats.confirmed * 100).toFixed(2);
					vm.generalStats.activePercentage = (vm.generalStats.activeUsers / vm.generalStats.confirmed * 100).toFixed(2);
					vm.generalStats.GPpercentage = (vm.generalStats.completedGP / vm.generalStats.confirmed * 100).toFixed(2);
					vm.generalStats.GP_mPercentage = (vm.generalStats.completedMonthlyGP / vm.generalStats.activeUsers * 100).toFixed(2);

				},
				function ErrorCallback() {
					console.log("error getting Statistics data");
				}
			);

			//last row user data
			DashboardService.getUsersFromSource("fb",
				function SuccessCallback(response) {
					vm.userStats = {};
					vm.userStats.fb = response.data[0][0][1];
					DashboardService.getUsersFromSource("google",
						function (response) {
							vm.userStats.google = response.data[0][0][1];
							DashboardService.getUsersFromSource("users",
								function (response) {
									vm.userStats.users = response.data[0][0][1];
								}
							)
						}
					)
				}
			);
			//table data
			vm.loadSurveyActivity = function () {
				vm.loadingSurveyActivity = true;
				var listener = $scope.$watch('vm.generalStats', function (newVal) {
					if (newVal) {
						DashboardService.getStatsByQuestType(
							function SuccessCallback(response) {
								vm.IntervalStatsByQuest = [];
								for (var i = 0; i < response.data.length; i++) {
									if (response.data[i][0] !== "profile") {
										var quest = [];
										quest[0] = response.data[i][0];
										for (var j = 1; j < response.data[i].length; j++) {
											quest.push((parseInt(response.data[i][j]) / vm.generalStats.confirmed * 100).toFixed(1) + "% (" + response.data[i][j] + ")");
										}
										vm.IntervalStatsByQuest.push(quest);
									}
								}
								vm.showSurveyActivity = true;
								vm.loadingSurveyActivity = false;
							},
							function ErrorCallback(response) {
								console.log(response);
								vm.loadingSurveyActivity = false;
							}
						);
						listener();
					}
				});
			};
			vm.makeChart();
		};

		(function initController() {
			vm.breadcrumbs = "Stats: Detailed Dashboard";
			vm.activeSubmenu = 'stats';
			vm.showOverall = true;
			vm.show24h = true;
			vm.showLast30Days = true;
			vm.getStats();
		})();

	}

})();