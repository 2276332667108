(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .factory('SpeedstersService', SpeedstersService);

    SpeedstersService.$inject = ['$http', 'Upload'];

    function SpeedstersService($http, Upload) {
        var service = {};

        service.toggleAllSpeedstersForSurvey = function (surveyId, userType, okCallback, koCallback) {
            $http.put("/rest/quests/toggleAllSpeedsters/" + surveyId + "/" + userType)
                .then(function successCallback(response) {
                    okCallback && okCallback(response);
                }, function errorCallback(response) {
                    koCallback && koCallback(response);
                });
        };

        service.getAllSpeedstersForSurvey = function (surveyId, okCallback, koCallback) {
            $http.get("/rest/quests/getAllSpeedsters/" + surveyId)
                .then(function successCallback(response) {
                    okCallback && okCallback(response);
                }, function errorCallback(response) {
                    koCallback && koCallback(response);
                });
        };

        service.getMedianTimeForSurvey = function (surveyId, okCallback, koCallback) {
            $http.get("/rest/quests/medianTime/" + surveyId)
                .then(function successCallback(response) {
                    okCallback && okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.toggleSingleSpeedster = function (qatId, okCallback, koCallback) {
            $http.put("/rest/quests/toggleSingleSpeedster/" + qatId)
                .then(function successCallback(response) {
                    okCallback && okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.getSurveyState = function (surveyId, okCallback, koCallback) {
            $http.get("/rest/quests/questState/" + surveyId)
                .then(function successCallback(response) {
                    okCallback && okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        return service;
    }
})();
