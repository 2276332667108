(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.controller('RegionsController', RegionsController);

	RegionsController.$inject = ['RegionsService', 'NgTableParams', '$q', '$mdDialog'];
	function RegionsController(RegionsService, NgTableParams, $q, $mdDialog) {

		var vm = this;
		var NUM_EXAMPLE_COUNTIES = 3;
		var NUM_EXAMPLE_CITIES_PER_COUNTY = 3;

		vm.buildTable = function (type, dataset) {
			var sortingString = "";
			if (type === "region") {
				sortingString = {'region_id': "asc"}
			}
			else if (type === "division") {
				sortingString = {'division_id': "asc"};
			}
			else if (type === "county") {
				sortingString = {'county_id': "asc"}
			}
			else if (type === "city") {
				sortingString = {'city_id': "asc"}
			}
			else if(type === "zone") {
				sortingString = {'zoneId': "asc"}
			}
			var initialParams = {
				count: 5, // initial page size
				sorting: sortingString
			};
			var initialSettings = {
				// page size buttons (right set of buttons in demo)
				counts: [],
				dataset: dataset
			};
			vm.settingsObject[type] = {
				initialParams: initialParams,
				initialSettings: initialSettings
			};
			vm.tableParams[type] = new NgTableParams(vm.settingsObject[type].initialParams, vm.settingsObject[type].initialSettings);
		};

		vm.initPage = function () {
			$q.all([RegionsService.getAllCities(), RegionsService.getAllCounties(), RegionsService.getDivisionsList(), RegionsService.getRegionsList()]).then(function (response) {
				vm.cities = response[0].data;
				vm.counties = response[1].data;
				vm.divisions = response[2].data;
				vm.regions = response[3].data;

				for (var i = 0; i < vm.divisions.length; i++) {
					vm.getRegionsForDivision(vm.divisions[i].division_id, i);
				}
				for (var j = 0; j < vm.regions.length; j++) {
					vm.getCountiesForRegion(vm.regions[j].region_id, j);
				}
				vm.buildTable("division", vm.divisions);
				vm.buildTable("region", vm.regions);
				vm.buildTable("county", vm.counties);
				vm.loading = false;
				vm.selectedCountyId = null;
			});
		};

		vm.initNewDivision = function () {
			vm.divisionToAdd = "";
		};

		vm.initNewRegion = function () {
			vm.regionToAdd = "";
		};

		vm.initNewCounty = function () {
			vm.countyToAdd = "";
		};

		(function initController() {
			vm.activeSubmenu = 'setup';
			vm.breadcrumbs = 'Setup: Regions';
			vm.tableParams = {
				region: null,
				division: null,
				county: null,
				zone: null
			};
			vm.settingsObject = {
				region: null,
				division: null,
				county: null,
				zone: null
			};
			vm.loading = true;
			vm.addedCountiesToRegionSuccess = {};
			vm.addedRegionsToDivisionSuccess = {};
			vm.csvUploadCheck = {};
			vm.selectedCityId = null;
			vm.cityToAdd = {
				city_id: null,
				city_name: null,
				city_population: null,
				city_rural_urban: null,
				county: null
			};
			vm.ruralUrbanOptions = [ {name: 'Rural', value: 0}, {name: 'Urban', value: 1} ];
			vm.importFunction = RegionsService.importCountiesAndCitiesFromCsv;
			vm.loadingZonesTable = true;
			vm.savingNewZone = false;
			vm.zonesListActionOngoing = false;
			vm.zonesConfig = {
				selectedCitiesForZones: {},
				isZoneEdited: {},
				citiesIdsListForZones:{}
			};

			vm.initPage();

		})();

		vm.refreshDivisions = function () {
			var promise = RegionsService.getDivisionsList();
			promise.then(function (response) {
				vm.divisions = response.data;
				for (var i = 0; i < vm.divisions.length; i++) {
					vm.getRegionsForDivision(vm.divisions[i].division_id, i);
				}
				vm.buildTable("division", vm.divisions);
			}).catch(function (e) {

			});
		};

		vm.refreshRegions = function () {
			var promise = RegionsService.getRegionsList();
			promise.then(function (response) {
				vm.regions = response.data;
				for (var j = 0; j < vm.regions.length; j++) {
					vm.getCountiesForRegion(vm.regions[j].region_id, j);
				}
				vm.buildTable("region", vm.regions);
			}).catch(function (e) {

			});
		};

		vm.refreshCounties = function () {
			var promise = RegionsService.getAllCounties();
			promise.then(function (response) {
				vm.counties = response.data;
				vm.buildTable("county", vm.counties);
			}).catch(function (e) {

			});
		};

		vm.refreshCities = function () {
			var promise = RegionsService.getAllCities();
			promise.then(function (response) {
				vm.cities = response.data;
				vm.updateCitiesTable(vm.selectedCountyId);
			}).catch(function (e) {

			});
		};

		vm.getRegionsForDivision = function (divisionId, index) {
			var promise = RegionsService.getRegionsListForDivision(divisionId);
			promise.then(function (response) {
				vm.divisions[index].regionList = response.data;
				for (var j = 0; j < vm.divisions[index].regionList.length; j++) {
					var region = vm.regions.filter(function (obj) {
						return obj.region_id === vm.divisions[index].regionList[j].region.region_id;
					});
					if (region && region.length > 0) {
						if (!region[0].selected) {
							region[0].selected = {};
						}
						if (region[0].region_id === vm.divisions[index].regionList[j].region.region_id) {
							region[0].selected[vm.divisions[index].regionList[j].division.division_id] = true;
						}
					}
				}
			});
		};

		vm.getCountiesForRegion = function (regionId, index) {
			var promise = RegionsService.getCountiesListForRegion(regionId);
			promise.then(function (response) {
				vm.regions[index].countiesList = response.data;
				for (var j = 0; j < vm.regions[index].countiesList.length; j++) {
					var county = vm.counties.filter(function (obj) {
						return obj.county_id === vm.regions[index].countiesList[j].county.county_id;
					});
					if (county && county.length > 0) {
						if (!county[0].selected) {
							county[0].selected = {};
						}
						if (county[0].county_id === vm.regions[index].countiesList[j].county.county_id) {
							county[0].selected[vm.regions[index].countiesList[j].region.region_id] = true;
						}
					}
				}
			});
		};

		vm.getCitiesForCounty = function (countyId) {
			return vm.cities.filter(function (city) {return city.county.county_id === countyId});
		};

		vm.addCity = function (city) {
			delete vm.addCitySuccess;
			city.city_population = city.city_population || 0;
			RegionsService.addCityToCounty(city,
				function success(response) {
					vm.addCitySuccess = true;
					vm.refreshCities();
				},
				function error(response) {
					console.log(response);
					vm.addCitySuccess = false;
				})

		};

		vm.addCounty = function (county) {
			delete vm.addCountySuccess;
			var countyObj = {
				county_id: null,
				county_name: county,
				county_region: null
			};
			RegionsService.addCounty(countyObj,
				function success(response) {
					vm.addCountySuccess = true;
					delete vm.countyToAdd;
					vm.refreshCounties();
				},
				function error(response) {
					console.log(response);
					vm.addCountySuccess = false;
				})

		};

		vm.addRegion = function (region) {
			delete vm.addRegionSuccess;
			var regionObj = {
				region_id: null,
				region_name: region
			};
			RegionsService.addRegion(regionObj,
				function success(response) {
					vm.addRegionSuccess = true;
					delete vm.regionToAdd;
					vm.refreshRegions();
				},
				function error(response) {
					console.log(response);
					vm.addRegionSuccess = false;
				})
		};

		vm.addDivision = function (division) {
			delete vm.addDivisionSuccess;
			var divisionObj = {
				division_id: null,
				division_name: division
			};
			RegionsService.addDivision(divisionObj,
				function success(response) {
					vm.addDivisionSuccess = true;
					delete vm.divisionToAdd;
					vm.refreshDivisions();
				},
				function error(response) {
					console.log(response);
					vm.addDivisionSuccess = false;
				})
		};

		vm.addCountiesToRegion = function (region, countiesIds) {
			delete vm.addedCountiesToRegionSuccess[region.region_id];
			var data = {
				regionId: region.region_id,
				countiesIds: countiesIds
			};
			RegionsService.addCountiesToRegion(data,
				function success(response) {
					vm.addedCountiesToRegionSuccess[region.region_id] = true;
				},
				function error(response) {
					vm.addedCountiesToRegionSuccess[region.region_id] = false;
					if(response.status === 403){
						var alertMessage = "Region \"" + region.region_name + "\" cannot be updated because survey/s " + response.data.toString() + " has/have quotas set for this region. Please create a new region and a new division.";
						$mdDialog.show(
							$mdDialog.alert()
								.title('Region Alert')
								.textContent(alertMessage)
								.ok('Close')
						);
					}
				})
		};

		vm.addRegionsToDivision = function (division, regionsIds) {
			delete vm.addedRegionsToDivisionSuccess[division.division_id];
			var data = {
				divisionId: division.division_id,
				regionsIds: regionsIds
			};
			RegionsService.addRegionsToDivision(data,
				function success(response) {
					vm.addedRegionsToDivisionSuccess[division.division_id] = true;
				},
				function error(response) {
					vm.addedRegionsToDivisionSuccess[division.division_id] = false;
					if(response.status === 403){
						var alertMessage = "Division \"" + division.division_name + "\" cannot be updated because survey/s " + response.data.toString() + " has/have quotas set for this division. Please create a new division.";
						$mdDialog.show(
							$mdDialog.alert()
								.title('Division Alert')
								.textContent(alertMessage)
								.ok('Close')
						);
					}
				})
		};

		vm.updateSelectedCounty = function (countyId) {
			var selectedCounty = vm.counties.find(function(county) { return (county.county_id === countyId); });
			delete selectedCounty.selected;
			vm.cityToAdd['county'] = selectedCounty;
		};

		vm.updateCitiesTable = function (countyId) {
			var citiesList = vm.cities.filter(function (city) { return city.county.county_id === countyId });
			vm.buildTable("city", citiesList);
		};

		vm.updateSelectedCountyAndCitiesTable = function (countyId) {
			vm.updateSelectedCounty(countyId);
			vm.updateCitiesTable(countyId);
		};

		vm.getCountiesAndCitiesCsvExample = function () {
			const deferme = $q.defer();
			RegionsService.getCountiesAndCitiesCsvData(10,
				function success (res) {
					deferme.resolve(res.data);
				},
				function error (res) {
					console.log("Could not fetch example counties and cities csv data!");
					console.log(res);
				}
			);
			return deferme.promise;
		};

		vm.getCountiesAndCitiesCsvFull = function () {
			const deferme = $q.defer();
			RegionsService.getCountiesAndCitiesCsvData(undefined,
				function success (res) {
					deferme.resolve(res.data);
				},
				function error (res) {
					console.log("Could not fetch counties and cities csv data!");
					console.log(res);
				}
			);
			return deferme.promise;
		};

		vm.buildSelectedCitiesMapForAllZones = function (zones) {
			for (var i in zones) {
				vm.toggleCitySelectionForZone(vm.zonesConfig.citiesIdsListForZones[zones[i].zoneId], zones[i].zoneId);
			}
		};

		vm.citiesIdsArrayToCitiesArray = function (citiesIdsList) {
			return vm.citiesForZonesSelectedCounty.filter(function (elem) {
				return citiesIdsList.includes(elem.city_id);
			});
		};

		vm.toggleCitySelectionForZone = function (selectedCitiesIds, zoneId) {
			var selectedCities = vm.citiesIdsArrayToCitiesArray(selectedCitiesIds);

			for (var i in vm.citiesForZonesSelectedCounty) {
				var foundCity = selectedCities.find(function (elem) {
					return elem.city_id === vm.citiesForZonesSelectedCounty[i].city_id
				});

				var isSelected = vm.zonesConfig.selectedCitiesForZones[vm.citiesForZonesSelectedCounty[i].city_id];

				if ((typeof isSelected !== "undefined" && isSelected === zoneId) || typeof isSelected === "undefined") {
					if (typeof foundCity !== "undefined") {
						vm.zonesConfig.selectedCitiesForZones[vm.citiesForZonesSelectedCounty[i].city_id] = zoneId;
					} else {
						vm.zonesConfig.selectedCitiesForZones[vm.citiesForZonesSelectedCounty[i].city_id] = undefined;
					}
				}
			}
		};

		vm.removeSelectionsForDeletedZone = function (zoneId) {
			for(var each in vm.zonesConfig.selectedCitiesForZones) {
				if(vm.zonesConfig.selectedCitiesForZones[each] === zoneId) {
					vm.zonesConfig.selectedCitiesForZones[each] = undefined;
				}
			}
		};

		vm.reloadZonesTableAfterZoneRemoval = function (zoneIdToDelete) {
			vm.zonesConfig.isZoneEdited[zoneIdToDelete] = undefined;
			var zoneIndexInList = vm.settingsObject["zone"].initialSettings.dataset.findIndex(function (element) {
				return element.zoneId === zoneIdToDelete;
			});

			vm.settingsObject["zone"].initialSettings.dataset.splice(zoneIndexInList, 1);
			vm.buildSelectedCitiesMapForAllZones(vm.selectedCountyZonesList);
			vm.removeSelectionsForDeletedZone(zoneIdToDelete);
			vm.tableParams["zone"].reload();
		};

		vm.reloadZonesTableAfterZoneUpdate = function (zone) {
			vm.zonesConfig.isZoneEdited[zone.zoneId] = false;
            var zoneIndexInList = vm.settingsObject["zone"].initialSettings.dataset.findIndex(function (element) {
                return element.zoneId === zone.zoneId;
            });

            vm.settingsObject["zone"].initialSettings.dataset.splice(zoneIndexInList, 1, zone);
			vm.buildSelectedCitiesMapForAllZones(vm.selectedCountyZonesList);
            vm.tableParams["zone"].reload();
		};

		vm.checkIfZonesEditedAndUnsaved = function () {
			for(var i in vm.selectedCountyZonesList){
				vm.zonesConfig.isZoneEdited[vm.selectedCountyZonesList[i].zoneId] = vm.isZoneModifiedAndUnsaved(vm.selectedCountyZonesList[i]);
			}
		};

		vm.isZoneModifiedAndUnsaved = function (zone) {
			var pristineZone = vm.selectedCountyZonesList.find(function (element) {
				return element.zoneId === zone.zoneId;
			});

			var newCitiesIdsList = vm.zonesConfig.citiesIdsListForZones[zone.zoneId];
			var oldCitiesIdsList = pristineZone.citiesList.map(function (elem, index) {
				return elem.city_id;
			});

			if(newCitiesIdsList.length !== oldCitiesIdsList.length) return true;
			if(newCitiesIdsList.length === oldCitiesIdsList.length && newCitiesIdsList.length === 0) return false;

			var sortFunction = function (a, b) {
				if(a < b) return -1;
				if(a > b) return 1;
				return 0;
			};

			newCitiesIdsList.sort(sortFunction);
			oldCitiesIdsList.sort(sortFunction);

			for(var i = 0; i < oldCitiesIdsList.length; i++) {
				if(oldCitiesIdsList[i] !== newCitiesIdsList[i]) return true;
			}

			return false;
		};

		vm.parseSelectedCitiesIntoArraysOfIdsForEachZone = function (zones) {
			for(var i in zones) {
				vm.zonesConfig.citiesIdsListForZones[zones[i].zoneId] = zones[i].citiesList.map(function (elem) {return elem.city_id});
			}
		};

		vm.updateZonesTable = function (county) {
			vm.loadingZonesTable = true;
			RegionsService.getAllZonesForCounty(
				county.county_id,
				function success(response) {
					RegionsService.getCitiesByCounty(county.county_id).then(function success(value) {
						vm.citiesForZonesSelectedCounty = value.data;

						vm.selectedCountyZonesList = response.data;
						vm.zonesConfig.selectedCitiesForZones = {};
						vm.zonesConfig.isZoneEdited = {};
						vm.zonesConfig.citiesIdsListForZones = {};
						vm.parseSelectedCitiesIntoArraysOfIdsForEachZone(vm.selectedCountyZonesList);
						vm.checkIfZonesEditedAndUnsaved();
						vm.buildSelectedCitiesMapForAllZones(vm.selectedCountyZonesList);
						vm.buildTable("zone", vm.selectedCountyZonesList);
						vm.loadingZonesTable = false;
					}, function error(reason) {
						$mdDialog.show(
							$mdDialog.alert()
								.title('Zone Alert')
								.textContent('Failed to get all cities for county with id ' + county.county_id + '!')
								.ok('Close')
						);
					});
				},
				function error(error) {
					$mdDialog.show(
						$mdDialog.alert()
							.title('Zone Alert')
							.textContent('Failed to get all zones for county with id ' + county.county_id + '!')
							.ok('Close')
					);
				}
			);
		};

		vm.addNewZoneToCounty = function (zoneName) {
			var newZone = {
				"zoneId": null,
				"zoneName": zoneName,
				"county": {
					"county_id": vm.zonesSelectedCounty.county_id,
					"county_name": vm.zonesSelectedCounty.county_name,
					"county_region": vm.zonesSelectedCounty.county_region
                },
				"citiesList": []
			};

			vm.savingNewZone = true;
			vm.zonesListActionOngoing = true;
			RegionsService.saveZoneForCounty(newZone,
				function success(response) {
					vm.updateZonesTable(vm.zonesSelectedCounty);
					vm.savingNewZone = false;
					vm.addZoneToCountySuccess = true;
					vm.zonesListActionOngoing = false;
				},
				function error(error) {
					vm.savingNewZone = false;
					vm.zonesListActionOngoing = false;
					if (error.status === 400) {
						$mdDialog.show(
							$mdDialog.alert()
								.title('Zone Alert')
								.textContent('Failed to update zone! ' + error.data)
								.ok('Close')
						);
					} else {
						$mdDialog.show(
							$mdDialog.alert()
								.title('Zone Alert')
								.textContent('Failed to update zone!')
								.ok('Close')
						);
					}
				});
		};

		vm.updateNewZone = function (zone) {
			vm.zonesListActionOngoing = true;
			var zoneToSave = angular.copy(zone);
			zoneToSave.citiesList = vm.citiesIdsArrayToCitiesArray(vm.zonesConfig.citiesIdsListForZones[zone.zoneId]);
			RegionsService.saveZoneForCounty(zoneToSave,
				function success(response) {
					vm.reloadZonesTableAfterZoneUpdate(response.data);
					vm.zonesListActionOngoing = false;
				},
				function error(error) {
					vm.zonesListActionOngoing = false;
					if(error.status === 400) {
						$mdDialog.show(
							$mdDialog.alert()
								.title('Zone Alert')
								.textContent(error.data)
								.ok('Close')
						);
						vm.buildSelectedCitiesMapForAllZones(vm.selectedCountyZonesList);
					} else {
						$mdDialog.show(
							$mdDialog.alert()
								.title('Zone Alert')
								.textContent('Failed to update zone!')
								.ok('Close')
						);
					}
				});
		};

		vm.removeZoneWithId = function (zoneId) {
			var confirm = $mdDialog.confirm()
				.title("Remove zone")
				.textContent("Are you sure that you want to remove this record ?")
				.cancel("No")
				.ok("Yes");

			$mdDialog.show(confirm).then(function() {
				vm.zonesListActionOngoing = true;
				RegionsService.deleteZoneById(zoneId,
					function success(response) {
						vm.reloadZonesTableAfterZoneRemoval(zoneId);
						vm.zonesListActionOngoing = false;
					},
					function error(error) {
						vm.zonesListActionOngoing = false;
						$mdDialog.show(
							$mdDialog.alert()
								.title('Zone Alert')
								.textContent('Failed to remove zone!')
								.ok('Close')
						);
					})
			}, function() {
				//
			});
		}
	}
})();
