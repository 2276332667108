(function () {
	'use strict';


	function setDataProvider(dataProvider,color,key){
        dataProvider.graphsData.push({
            "balloonText": key + ":[[value]]%",
            "fillAlphas": 0.8,
            "lineAlpha": 0.2,
            "title": key,
            "type": "column",
            "valueField": key,
            "lineColor": color,
            "labelText": " [[value]]%"
        });
	}

	function decideChartConfigValue(hostname, propertyName) {
			switch (hostname) {
				case "admin2.questia.co.th":
					if(propertyName === 'fontSize'){
						return 24;
					}
					if(propertyName === 'marginLeft'){
						return 250;
					}
					break;
				case "admin2.questia.co.th_com":
					if(propertyName === 'fontSize'){
						return 18;
					}
					if(propertyName === 'marginLeft'){
						return 500;
					}
					if(propertyName === 'primaryColor'){
						return '#66b26a';
					}
					break;
				default:
					if(propertyName === 'fontSize'){
						return 25;
					}
					if(propertyName === 'marginLeft'){
						return 250;
					}
					break;
			}
		}

	angular
		.module('questiaPlatformApp')
		.factory('ChartsUtils', ['$filter', '$rootScope', '$window', 'ChartsService', 'QuestiaUtils', ChartsUtils]);

	function ChartsUtils($filter, $rootScope, $window, ChartsService, QuestiaUtils) {

		return {

			//for stacked column - some fonts need contrast
			FONT_COLORS: [
				"#595959", "#FFFFFF", "#595959", "#595959", "#FFFFFF",
				"#595959", "#FFFFFF", "#595959", "#595959", "#FFFFFF",
				"#595959", "#595959", "#595959", "#595959", "#595959"
			],


			DEFAULT_CHART_CONFIG: {
				height: $window.location.hostname === "admin2.questia.co.th" ? 500 : 700,
				width: $window.location.hostname === "admin2.questia.co.th" ? 1500 : 1800,
				scale: 100,
				marginLeft: decideChartConfigValue($window.location.hostname,'marginLeft'),
				labelRadius: 70,
				maxLabelWidth: 350,
				marginBottom: 100,
				nsnrOptions: 0,
				//colors array defined in chart utils
				primaryColor: decideChartConfigValue($window.location.hostname,'primaryColor'),
				targetColor: "#FFFFFF",
				crossColumn: '',
				crossColor: '',
				crossHistory: [],
				targetChoice : {},
				previousChoices: [],
				crossWithId: null,
				fontSize: decideChartConfigValue($window.location.hostname,'fontSize'),
				fontFamily: "Calibri",
				fontColor: "#595959",
				backgroundColor: "#f4f4f4",
				sort: true,
				innerRadius: 50,
				showLegend: false,
				rotate: true,
				dknaOption: 0,
				redoRequest: false,
				reverseData: false
			},

			WEIGHT_LABEL_DEFAULT: {
				labelMarginLeft: 100,
				labelAdjustFontSize: -3,
				labelFontColor: "#b02828"
			},

			_addWeightLabel: function (config, weightsCalculusResults) {
				const fontSize = config.fontSize + this.WEIGHT_LABEL_DEFAULT.labelAdjustFontSize;
				const width = config.marginLeft - this.DEFAULT_CHART_CONFIG.marginLeft +
												  this.WEIGHT_LABEL_DEFAULT.labelMarginLeft;
				const isWarning = weightsCalculusResults.percentOfWeightsMoreThanUpperLimit >= 20 ||
								  weightsCalculusResults.percentOfWeightsLessThanLowerLimit >= 20;

				config.marginTop = fontSize*3 + this.DEFAULT_CHART_CONFIG.fontSize;
				config.allLabels = [{
					text: isWarning ? "Interpret with caution: low weighting efficiency" :
						"Survey data have been weighted",
					color: isWarning ? this.WEIGHT_LABEL_DEFAULT.labelFontColor : config.color,
					size: fontSize,
					x: width
				}];
			},

			buildChartConfig: function (id, dataProvider, options, withWeighting, weightsCalculusResults) {
				let config = angular.extend(this._getSpecificConfig(dataProvider, options), this._getCommonConfig(id, options));
				withWeighting && this._addWeightLabel(config, weightsCalculusResults);
				return config;
			},

			_getCommonConfig: function (id, options) {
				return {
					"theme": "light",
					"startDuration": 0,
					"marginRight": 100,
					"marginTop": 50,
					"path": "/js/lib/amcharts",
					"addClassNames": true,
					"fontFamily": this.DEFAULT_CHART_CONFIG.fontFamily,
					"color": options.colors.fontColor,
					"fontSize": options.fontSize,
					"backgroundAlpha": 1,
					"backgroundColor": options.backgroundColor,
					"export": {
						"enabled": true,
						"fileName": "chart_qid" + id,
						// "multiplier": 2,
						"position": "top-left"
					},
					"precision": 1
				};
			},

			_getSpecificConfig: function (dataProvider, options) {
				//cross between 2 questions?
				var self = this;
				if (options.crossWithId) return this._getSpecificConfigSelects(dataProvider, options);

				switch (options.type) {
					case 'bar':
						return this._getSpecificConfigBar(dataProvider, options);
					case 'pie':
						return this._getSpecificConfigPie(dataProvider, options);
					case 'stack':
					case 'cluster':
						return this._getSpecificConfigSelects(dataProvider, options);
					case 'radar':
						return this._getSpecificConfigRadar(dataProvider, options);
					default:
						break;
				}
			},

			//sort only values < 800
			_sortDataProvider: function (dataProvider) {

				var specialValues = dataProvider.filter(function (obj) {
					return obj.choiceVal >= 800;
				});
				var normalValues = dataProvider.filter(function (obj) {
					return obj.choiceVal < 800;
				});
				normalValues = _.sortBy(normalValues, function (obj) {
					return -obj.value;
				});
				return normalValues.concat(specialValues);
			},

			_getSpecificConfigSelects: function (dataProvider, options) {
				//sort by UI column
				function comparatorFunc(sortOptions) {
					return function (a, b) {
						var valA = parseFloat(a[Object.keys(a)[sortOptions.columnToSortBy]]);
						var valB = parseFloat(b[Object.keys(a)[sortOptions.columnToSortBy]]);
						if (valA < valB)
							return (sortOptions.direction == 'Descending' ? -1 : 1);
						if (valA > valB)
							return (sortOptions.direction == 'Descending' ? 1 : -1);
						return 0;
					}
				}

				if (options.type === "cluster" || options.type ==="stack") {
					var codes = [];
					for(var elem of dataProvider.graphsData){
						if(typeof elem._code !== 'undefined') {
							codes.push(elem._code);
						}
					}
					dataProvider.graphsData = [];
					var crossColorValue = options.crossColor;
					var crossColumnValue = options.crossColumn;
					var index = 0;
					var start = 0;
					var greysIndex = 0;

					// labels2 keeps the order right, if its undefined, we use the default order from the first entry in the dataProvider.dataProvider.values matrix
					var labels2 = dataProvider.labels2;
					if(typeof labels2 === "undefined") {
						labels2 = [];
						angular.forEach(dataProvider.dataProvider.values[0], function (value, key) {
							labels2.push(key);
						});
					}

					for (var jj = 0; jj < labels2.length; jj++) {
						var key = labels2[jj];
						if (["title", "titleWithBase", "average"].indexOf(key) === -1) {
							var color;

							if (codes[index] >= 800) {
								color = options.colors.secondaryColors[(start + greysIndex) % options.colors.secondaryColors.length];
								greysIndex++;
							} else {
								color = options.colors.primaryColors[(start + index) % options.colors.primaryColors.length];
							}

							index = index + 1;
							if (key === crossColumnValue) {
								setDataProvider(dataProvider, crossColorValue, key);
								var obj = {};
								obj.name = key;
								obj.color = crossColorValue;
								options.crossHistory.push(obj);
							} else {

								if (options.crossHistory.length > 0) {
									for (var i = 0; i < options.crossHistory.length; i++) {
										if (options.crossHistory[i].name === key) {
											color = options.crossHistory[i].color;
										}
									}
								}
								setDataProvider(dataProvider, color, key);
							}
						}
					}
				}

				var title = "title";
				if (options.showBase) {
					title = "titleWithBase";
				}

				//choose only some elements from the data provider?
				var dataP = dataProvider.dataProvider.values;
				if (options.stackArray && options.stackArray.length > 0) {
					dataP = [];
					for (i = 0; i < dataProvider.dataProvider.values.length; i++) {
						if (options.stackArray.indexOf(i) !== -1) {
							dataP.push(dataProvider.dataProvider.values[i]);
						}
					}
				}

				if (options.sortBy) {
					if (options.sortBy.order) options.sortBy.direction = "Ascending";
					else options.sortBy.direction = "Descending";
					if (options.sortBy.columnToSortBy !== null && options.sortBy.columnToSortBy !== -1) dataP.sort(comparatorFunc(options.sortBy));
				}
				//graphs font size
				for (var i = 0; i < dataProvider.graphsData.length; i++) {
					dataProvider.graphsData[i].fontSize = options.fontSize;
				}
				if (options.reverseData) {
					dataProvider.graphsData.reverse();
				}

				var data = {
					title: title,
					dataP: dataP,
					graphsData: dataProvider.graphsData,
					options: options
				};

				if (options.type === "cluster") {
					return this._getSpecificConfigCluster(data);
				}
				else {
					return this._getSpecificConfigStack(data);
				}
			},

			_getSpecificConfigCluster: function (data) {

				return {
					"type": "serial",
					"categoryField": data.title,
					"legend": this._getLegend(data.options),
					"rotate": data.options.rotate,
					"startDuration": 1,
					"categoryAxis": {
						"ignoreAxisWidth": true,
						"autoWrap": true,
						"gridAlpha": 0
					},
					"graphs": data.graphsData,
					"guides": [],
					"valueAxes": [
						{
							"minimum": 0,
							"maximum": data.options.scale || 100,
							"labelFunction": function (value) {
								return value + "%";
							}
						}
					],
					"dataProvider": data.dataP,
					"marginLeft": data.options.marginLeft || 200,
					"marginBottom": data.options.marginBottom || 80
				}
			},

			_getSpecificConfigBar: function (dataProvider, options) {
				if (options.sort) {
					dataProvider = this._sortDataProvider(dataProvider);
				}
				var title = "title";
				if (options.showBase) {
					title = "titleWithBase";
				}
				var start  = 0;
				var greysIndex = 0;
				for (var i = 0; i < dataProvider.length; i++) {
					if (dataProvider[i].choiceVal >= 800) {
						dataProvider[i].color = options.colors.secondaryColors[(start + greysIndex) % options.colors.secondaryColors.length];
						greysIndex++;
					}
					else {
						dataProvider[i].color = options.primaryColor ? options.primaryColor : options.colors.primaryColors[0];
					}
				}

				return {
					"type": "serial",
					"dataProvider": dataProvider,
					"legend": this._getLegend(options),
					"rotate": options.rotate,
					"autoMargins": true,
					"valueAxes": [{
						"minimum": 0,
						"maximum": options.scale || 100,
						labelFunction: function (value) {
							return value + "%";
						}
					}],
					"graphs": [{
						"balloonText": "[[category]]: <b>[[value]] %</b>",
						"labelText": "[[value]]%",
						"fillAlphas": 1,
						"lineAlpha": 0.2,
						"fillColorsField": "color",
						"type": "column",
						"lineColor": options.primaryColor || "#B1CA54",
						"valueField": "value"
					}],
					"categoryField": title,
					"categoryAxis": {
						"ignoreAxisWidth": true,
						"autoWrap": true,
						"gridAlpha": 0
					},
					"marginLeft": options.marginLeft || 200,
					"marginBottom": options.marginBottom || 80
				};
			},

			_getSpecificConfigPie: function (dataProvider, options) {

				var targetColor = options.targetColor;
				var targetChoice = options.targetChoice;
				var previousChoices = options.previousChoices;

				if (options.sort) {
					dataProvider = this._sortDataProvider(dataProvider);
				}
				var title = "title";
				if (options.showBase) {
					title = "titleWithBase";
				}
				var start = 0;
				var greysIndex = 0;
				for (var i = 0; i < dataProvider.length; i++) {

					if(dataProvider[i].choiceVal >= 800){
						dataProvider[i].color = options.colors.secondaryColors[(start + greysIndex) % options.colors.secondaryColors.length];
						greysIndex++;
					}
					else if(dataProvider[i].choiceVal == targetChoice.choiceVal){
						dataProvider[i].color = targetColor;
                        previousChoices.push(dataProvider[i]);

					}else {
                        dataProvider[i].color = options.colors.primaryColors[(start + i) % options.colors.primaryColors.length] ;

							for(var k = 0; k < previousChoices.length; k++){
								if(dataProvider[i].choiceVal == previousChoices[k].choiceVal){
									dataProvider[i].color = previousChoices[k].color;
								}
							}
					}

				}
				return {
					"type": 'pie',
					"dataProvider": dataProvider,
					"titleField": "title",
					"valueField": "value",
					"colorField": "color",
					"labelRadius": options.labelRadius || 70,
					"maxLabelWidth": options.maxLabelWidth || 350,
					"radius": "35%",
					"outlineThickness": 1,
					"outlineAlpha": 1,
					"outlineColor": undefined,
					"legend": this._getLegend(options),
					"innerRadius": options.innerRadius + "%",
					"labelText": "[[" + title + "]] ([[value]]%) "
				};
			},

			_getSpecificConfigRadar: function (dataProvider, options) {
				if (options.sort) {
					dataProvider = this._sortDataProvider(dataProvider);
				}
				var title = "title";
				if (options.showBase) {
					title = "titleWithBase";
				}
				return {
					"type": "radar",
					"dataProvider": dataProvider,
					"valueAxes": [{
						"axisTitleOffset": 20,
						"minimum": 0,
						"maximum": options.scale || 100,
						labelFunction: function (value) {
							return value + "%";
						}
					}],
					"graphs": [{
						"balloonText": "[[value]]% [[title]]",
						"bullet": "round",
						"lineThickness": 3,
						"valueField": "value"
					}],
					"categoryField": title
				};
			},

			_getSpecificConfigStack: function (data) {

				return {
					"type": "serial",
					"dataProvider": data.dataP,
					"legend": this._getLegend(data.options),
					"valueAxes": [{
						"stackType": "regular",
						"axisAlpha": 0.5,
						"gridAlpha": 0.2,
						"dashLength": 0,
						"minimum": 0,
						"maximum": data.options.scale || 100,
						labelFunction: function (value) {
							return value + "%";
						}
					}],
					"graphs": data.graphsData,
					"rotate": data.options.rotate,
					"categoryField": data.title,
					"categoryAxis": {
						"ignoreAxisWidth": true,
						"autoWrap": true,
						"gridAlpha": 0
					},
					"marginLeft": data.options.marginLeft || 200,
					"marginBottom": data.options.marginBottom || 80
				};
			},

			_getLegend: function (options) {
				return {
					align: "center",
					color: options.fontColor,
					fontSize: options.fontSize,
					backgroundColor: options.backgroundColor,
					backgroundAlpha: 1,
					autoMargins: false,
					useGraphSettings: false,
					enabled: options.showLegend,
					marginLeft: 0,
					marginRight: 0
				};
			},

			extractTotalUsersCountArray: function (data, options) {
				if ((options.questionType === "singleChoice" ||  options.questionType === "selects") 
								&& options.crossQuestionType === "multipleChoice") {
					data.totalArray = [];
					data.crossMatrix.forEach(function (elem) {
						data.totalArray.push(elem[elem.length - 1]);
					});
				}
				if (options.questionType === "multipleChoice" &&
						["singleChoice", "cityCounty", "combodate"].includes(options.crossQuestionType)) {
					data.totalArray = data.crossMatrix.pop();
				}
			},

            _calculateTotalCross: function (rawData, options) {
                var firstQType = options.questionType;
                var secondQType = options.crossQuestionType;

                switch (firstQType) {
                    case "singleChoice":
                        switch (secondQType) {
                            case "singleChoice":
                                return QuestiaUtils.sumMatrixElements(rawData.crossMatrix);
                            case "multipleChoice":
                                return QuestiaUtils.sumArrayElements(rawData.totalArray);
                            default:
                                return 0
                        }
                    case "multipleChoice":
                        switch (secondQType) {
                            case "singleChoice":
                                return QuestiaUtils.sumArrayElements(rawData.totalArray);
                            default:
                                return 0
                        }
                    case "selects":
                        switch (secondQType) {
                            case "singleChoice":
                                return QuestiaUtils.sumMatrixElements(rawData.crossMatrix);
                            case "multipleChoice":
                                return QuestiaUtils.sumArrayElements(rawData.totalArray);
                            default:
                                return 0
                        }
                }

                return 0;
            },

			_calculateTotalSelects: function (rawData, options) {
                if(typeof options.totalWithInherit !== 'undefined' && options.totalWithInherit.total !== 0) {
                	return options.totalWithInherit.total;
				} else {
					if(rawData && Object.keys(rawData)[0]) {
						return rawData[Object.keys(rawData)[0]].total;
					} else {
						return 0;
					}
				}
			},

			_moveTotalDataInheritToOptions: function(rawData, options) {
				if (typeof rawData["data_inherit"] !== 'undefined'){
					options.totalWithInherit = rawData["data_inherit"];
					delete rawData["data_inherit"];
				}
			},

			_calculateTotalSingleMultipleChoice: function (rawData, options) {
				var total = 0;
				if (typeof rawData['total'] !== "undefined") {
					total = rawData['total'];
				}
				else if (typeof rawData['count'] !== "undefined") {
					total = rawData['count'];
				}
				else {
					for (var key in rawData) {
						if (!rawData.hasOwnProperty(key)) continue;
						total += rawData[key];
					}
				}

				return total
			},

			buildDataProvider: function (rawData, options) {
				var utilsData = {
					chartData: {},
					totalUsers: 0
				};

				if (options && (options.crossWithId || options.crossWithDivisionId || options.crossWithVote)) {
					utilsData.chartData = this._buildDataProviderCross(rawData, options);
					utilsData.totalUsers = this._calculateTotalCross(rawData, options);
					return utilsData;
				}
				if (options && (options.questionType === 'selects' || options.questionType === 'ranking')) {
					this._moveTotalDataInheritToOptions(rawData, options);
					utilsData.chartData = this._buildDataProviderSelects(rawData, options);
					utilsData.totalUsers = this._calculateTotalSelects(rawData, options);
					return utilsData;
				}

				utilsData.chartData = this._buildDataProviderSingleMultipleChoice(rawData, options);
				utilsData.totalUsers = this._calculateTotalSingleMultipleChoice(rawData, options);
				return utilsData;
			},

			_sortCrossData: function (data) {
				var arrayToSort = data.crossMatrix.map(function (elem, index) {
                    var sum = elem.reduce(function (sum, currentValue) {
                        return sum + currentValue
                    });

                    return {
                    	sum: sum,
						label: data.labels1[index],
						values: elem
					}
                });

				arrayToSort.sort(function (a, b) {
					return b.sum - a.sum;
                });

				for(var i = 0; i < data.crossMatrix.length; i++) {
					data.crossMatrix[i] = arrayToSort[i].values;
					data.labels1[i] = arrayToSort[i].label;
				}

				return data;
			},

			_buildDataProviderCross: function (rawData, options) {
				var data = JSON.parse(JSON.stringify(rawData));
				var i, ii;
				var dataProvider = {
					total: 0,
					values: []
				};
				var graphsData = [];
				var totals = [];
				var self = this;

				let dknaLabel;
				let dknaLine;
				let otherLabel;
				let otherLine;

				if (data.firstQuestionHasDKNA) {
					dknaLabel = data.labels1.pop();
					dknaLine = data.crossMatrix.pop();
				}

				if (typeof data.firstQuestionOtherChoiceIndex !== "undefined") {
					otherLabel = data.labels1.splice(data.firstQuestionOtherChoiceIndex, 1)[0];
					otherLine = data.crossMatrix.splice(data.firstQuestionOtherChoiceIndex, 1)[0];
				}

				if(options.sort && options.questionType !== 'selects' && options.questionType !== 'ranking') {
                    data = this._sortCrossData(data);
				}

				if (typeof data.firstQuestionOtherChoiceIndex !== "undefined") {
					data.labels1.push(otherLabel);
					data.crossMatrix.push(otherLine);
				}

				if(data.firstQuestionHasDKNA) {
					data.labels1.push(dknaLabel);
					data.crossMatrix.push(dknaLine);
				}

				//server always treats location as extended (rural, urban small, urban medium and urban big)
				//transform data for location not(ext) -> rural-urban simple
				//here we know that the data is an array of 4, so it's easier
				//not very clean, generic code, but simple enough to change
				if (options.cross === "location") {
					//remove all values after first two - last three are all Urban in fact
					data.labels2.length = 2;
					data.labels2[1] = "Urban";
					for (i = 0; i < data.crossMatrix.length; i++) {
						//1 2 and 3 are all parts of Urban - so add them up
						data.crossMatrix[i][1] += data.crossMatrix[i][2] + data.crossMatrix[i][3];
						//remove 2 and 3, already added
						data.crossMatrix[i].length = 2;
					}
				}
				//calc percentages
				for (i = 0; i < data.crossMatrix.length; i++) {
					let lineTotal = 0;
					if(data.crossMatrix[i].length > data.labels2.length){
						lineTotal = data.crossMatrix[i].pop();
					}
					else{
						for (ii = 0; ii < data.crossMatrix[i].length; ii++) {
							lineTotal += data.crossMatrix[i][ii];
						}
					}
					for (ii = 0; ii < data.crossMatrix[i].length; ii++) {
						data.crossMatrix[i][ii] = (data.crossMatrix[i][ii] / lineTotal * 100).toFixed(1);
					}
					totals.push(lineTotal);
				}

				//transform data to chart-form data
				for (var index = 0; index < data.crossMatrix.length; index++) {
					dataProvider.values.push({
						titleWithBase: data.labels1[index] + " (" + totals[index] + ") ",
						title: data.labels1[index]
					});
					var start = 0;
					for (ii = 0; ii < data.crossMatrix[index].length; ii++) {
						dataProvider.values[index][data.labels2[ii]] = data.crossMatrix[index][ii];
						if (index === 0) {
							var color, fontColor;
							// //greys?
							// if(dataProvider[index].codes[ii] >= 900) {
							// 	color = self.GREYS[ii % 5];
							// 	fontColor = "#000000";
							// }
							// else {
							color = options.colors.primaryColors[(start + ii) % options.colors.primaryColors.length];
							fontColor = ii < self.FONT_COLORS.length ? self.FONT_COLORS[ii] : self.DEFAULT_CHART_CONFIG.fontColor;
							// }
							graphsData.push({
								"balloonText": "<span style='font-size:14px'>" + data.labels2[ii] + ": <b>[[value]]%</b></span>",
								"fillAlphas": 1,
								"labelText": "[[value]]%",
								"labelPosition ": "inside",
								"lineAlpha": 1,
								"color": fontColor,
								"fontSize": options.fontSize,
								"title": data.labels2[ii],
								"type": "column",
								"lineColor": color,
								"valueField": data.labels2[ii]
							});
						}
					}
				}
				return {
					labels2: data.labels2,
					title1: data.title1,
					title2: data.title2,
					dataProvider: dataProvider,
					graphsData: graphsData
				};
			},

			_buildDataProviderSelects: function (rawData, options) {
				var dataProvider = [];
				var graphsData = [];
				var key;
				var self = this;
				var total;
				for (key in rawData) {
					// skip loop if the property is from prototype
					if (!rawData.hasOwnProperty(key)) continue;

					var orderedValues = [];
					total = rawData[key]['total'];

					for (var label in rawData[key]) {
						// skip loop if the property is from prototype
						if (!rawData[key].hasOwnProperty(label)) continue;
						if (label.indexOf(";;") === -1) {
							continue;
						}
						var splits = label.split(";;");
						if (splits[0] === '999') {
							splits[1] = $rootScope.dknaTranslatedLabel;
						}
						orderedValues.push({
							"title": splits[1],
							"count": rawData[key][label],
							"order": parseInt(splits[0])
						});
					}
					orderedValues.sort(function (a, b) {
						if (a.order > b.order)return 1;
						if (a.order < b.order)return -1;
						return 0;
					});
					var newValues = {};
					var codes = [];
					for (var i = 0; i < orderedValues.length; i++) {
						if (total === 0) {
							newValues[orderedValues[i].title] = 0;
						} else {
							newValues[orderedValues[i].title] = orderedValues[i].count / total;
						}

						codes.push(orderedValues[i].order);
					}
					dataProvider.push({
						"title": key,
						"titleWithBase": key + " (" + total + ")",
						"values": newValues,
						"codes": codes
					});
				}

				var dataProviderFinal = {
					values: []
				};
				for (var index = 0; index < dataProvider.length; index++) {
					dataProviderFinal.values.push({
						title: dataProvider[index].title,
						titleWithBase: dataProvider[index].titleWithBase
					});

					var index2 = 0;
					var start = 0;
					for (key in dataProvider[index].values) {
						// skip loop if the property is from prototype
						if (!dataProvider[index].values.hasOwnProperty(key)) continue;
						dataProviderFinal.values[index][key] = (dataProvider[index].values[key] * 100).toFixed(1);
						if (index === 0) {
							var color, fontColor;
							//greys?
							if (dataProvider[index].codes[index2] >= 900) {
								color = options.colors.secondaryColors[(start + index2) % options.colors.secondaryColors.length];
								fontColor = "#000000";
							}
							else {
								color = options.colors.primaryColors[(start + index2) % options.colors.primaryColors.length];
								fontColor = index2 < self.FONT_COLORS.length ? self.FONT_COLORS[index2] : self.DEFAULT_CHART_CONFIG.fontColor;
							}
							graphsData.push({
								"balloonText": "<span style='font-size:14px'>" + key + ": <b>[[value]]%</b></span>",
								"fillAlphas": 1,
								"labelText": "[[value]]%",
								"labelPosition ": "inside",
								"lineAlpha": 1,
								"color": fontColor,
								"fontSize": options.fontSize,
								"title": key,
								"type": "column",
								"lineColor": color,
								"valueField": key,
								"_code": parseInt(dataProvider[index].codes[index2])
							});
						}
						index2++;
					}
				}
                if (options.questionType === 'ranking') {
					dataProviderFinal.rankingTableValues = JSON.parse(JSON.stringify(dataProviderFinal.values));
                    dataProviderFinal.rankingTableValues.forEach(function (rankItem) {
                        rankItem.average = rawData[rankItem.title].average;
                    });
					dataProviderFinal.rankingTableValues.sort((a, b) => a.average - b.average);
                }

				return {
					dataProvider: dataProviderFinal,
					graphsData: graphsData
				};
			},

			//retrun a dataProvider sorted by order of choice values, modify sorting in build config, if necessary
			_buildDataProviderSingleMultipleChoice: function (rawData, options) {
				var total = this._calculateTotalSingleMultipleChoice(rawData);
				var values = [];

				for (var key in rawData) {
					if (!rawData.hasOwnProperty(key)) continue;

					if (key.indexOf("#") === 0) {
						var splittedKey = key.split("#");
						//label for DK/NA
						if (splittedKey[2] === "NsNr") {
							splittedKey[2] = $rootScope.dknaTranslatedLabel;
						}
						values.push({
							"choiceVal": parseInt(splittedKey[1]),
							"title": splittedKey[2],
							"titleWithBase": splittedKey[2] + " (" + rawData[key] + ")",
							"value": (rawData[key] * 100. / total).toFixed(1)
						});
					}
				}
				var dataProvider = _.sortBy(values, function (obj) {
					return obj.choiceVal;
				});
				return dataProvider;
			},

			preprocessDataCrossWithMatrix: function (crossData, question, chartConfig) {
				if (crossData.crossStats == null) return [];
				return crossData.crossStats.map((crossMatrix, index) => ({
					title1: crossData.labels1[index],
					title2: chartConfig.crossQuestion.question_text,
					labels1: crossData.labels2,
					labels2: crossData.labels3,
					crossMatrix: crossMatrix,
					chartUniqueKey: question.matrixCrossStats[index].chartUniqueKey
				}));
			},

			crossChange: function (question, chartConfig, crossQuestion) {
				chartConfig.crossQuestionType = crossQuestion ? crossQuestion.question_type : null;
				if (!["selects", "ranking"].includes(chartConfig.crossQuestionType) || chartConfig.cross != "other") {
					chartConfig.crossWithMatrix = false;
				}

				if (chartConfig.cross != "vote") {
					chartConfig.crossWithVote = null;
				}

				if (!chartConfig.cross) {
					//RESET CROSS
					chartConfig.crossWithId = null;
					chartConfig.crossWithDivisionId = null;
					chartConfig.crossWithVote = null;

					if (question.type === "selects" || question.type === "ranking") {
						chartConfig.selectsCrossWith = false;
						chartConfig.selectsId = -1;
						chartConfig.type = 'stack';
					}
					else {
						chartConfig.crossWithSelects = false;
						chartConfig.selectsOptions = null;
						chartConfig.type = 'bar';
					}
				}
				else {
					chartConfig.type = 'stack';
					switch (chartConfig.cross) {
						case "age":
							chartConfig.crossWithId = $rootScope.globals.asl.ageID;
							chartConfig.crossWithDivisionId = null;
							chartConfig.crossQuestionType = "combodate";
							break;
						case "sex":
							chartConfig.crossWithId = $rootScope.globals.asl.sexID;
							chartConfig.crossWithDivisionId = null;
							chartConfig.crossQuestionType = "singleChoice";
							break;
						case "vote":
							chartConfig.crossWithVote = true;
							chartConfig.crossQuestionType = "singleChoice";
						case "location":
							chartConfig.crossWithId = $rootScope.globals.asl.locationID;
							chartConfig.crossWithDivisionId = null;
							delete chartConfig.crossWithLocationExt;
							break;
						case "location_ext":
							chartConfig.crossWithId = $rootScope.globals.asl.locationID;
							chartConfig.crossWithLocationExt = true;
							chartConfig.crossWithDivisionId = null;
							break;
						case "regions":
							chartConfig.crossWithId = null;
							chartConfig.crossQuestionType = "cityCounty";
							break;
						case "other":
							if (crossQuestion) {
								if (crossQuestion.question_type === "multipleChoice") chartConfig.type = 'cluster';
								if (["singleChoice", "multipleChoice", "combodate", "cityCounty"].includes(question.type)
									&& ["selects", "ranking"].includes(crossQuestion.question_type)) {
									chartConfig.crossWithMatrix = true;
									chartConfig.crossQuestion = crossQuestion;
								}
							}
							chartConfig.crossWithDivisionId = null;
							break;
						default:
							break;
					}
					if (question.type !== "selects" && question.type !== "ranking") {
						chartConfig.crossWithSelects = false;
					}
					else {
						chartConfig.selectsCrossWith = true;
					}
				}
			},

			determineChartRequestUrl: function (question, chartConfig, questionConfig) {
				if (chartConfig.crossWithDivisionId && !["location", "location_ext"].includes(chartConfig.cross)) {
					// select cross with division
					if (chartConfig.selectsCrossWith)
						return "select/" + chartConfig.selectsId + "/division/" + chartConfig.crossWithDivisionId;
					return question.question_id + "/division/" + chartConfig.crossWithDivisionId;
				}
				//single choice cross with a selects item
				if (chartConfig.crossWithSelects) {
					if (chartConfig.transposeCross) {
						return "select/" + chartConfig.crossWithSelectsId + "/" + question.question_id;
					}
					return question.question_id + "/select/" + chartConfig.crossWithSelectsId;
				}
				if (chartConfig.crossWithMatrix) {
					if(question.type === "cityCounty") {
						return "division/" + chartConfig.divisionId + "/matrix/" + chartConfig.crossQuestion.question_id;
					}
					return question.question_id + "/matrix/" + chartConfig.crossQuestion.question_id;
				}

				if (chartConfig.cross === 'vote') {
					return chartConfig.selectsId && chartConfig.selectsId !== "-1"
						? "select/" + chartConfig.selectsId + "/crossVote"
						: question.question_id + "/crossVote";
				}

				//selects item cross with a single choice
				else if (chartConfig.selectsCrossWith) {
					if(["location", "location_ext"].includes(chartConfig.cross)) {
						return "select/" + chartConfig.selectsId + "/location"
					}
					if (chartConfig.transposeCross) {
						return chartConfig.crossWithId + "/select/" + chartConfig.selectsId;
					}
					return "select/" + chartConfig.selectsId + "/" + chartConfig.crossWithId;
				}
				if(chartConfig.crossWithId && question.type === "cityCounty") {
                    return question.question_id + "/" + chartConfig.crossWithId;
				}
				return chartConfig.crossWithId ? question.question_id + "/" + chartConfig.crossWithId : question.question_id;
			},

			setUpWordsForWordCloud: function (mapWords) {
                var maxCount = 0;
                var maxFontSize = 100;
                var words = [];
                for(var key in mapWords){
                    if(mapWords[key] > maxCount)
                        maxCount = mapWords[key];
                }
                for(var key in mapWords){
                    if(mapWords.hasOwnProperty(key)){
                        var y = (mapWords[key] * 100)/maxCount;
                        var x = (y * maxFontSize)/100;
                        var elem = {
                            text: key,
                            count: mapWords[key],
                            size: Math.round(x),
                            color: '#0c1207',
                            tooltipText: key + " - " + mapWords[key]
                        };
                        words.push(angular.copy(elem));
                    }
                }
                words.sort(function (a, b) {
                	return b.count - a.count;
				});

                return words;
            },

            transposeMatrix: function (matrix) {
                return matrix[0].map(function (col, i) {
                    return matrix.map(function(row){
                        return row[i];
                    });
                })
            },

			sumMatrix: function (matrix) {
				return matrix.reduce(function (flattenedList, row) { return flattenedList.concat(row) })
					.reduce(function (sum, elem) { return sum + elem });
			},

			findColorPalettesByTemplateName : function(templateName,colorTemplates){
				return colorTemplates.find(e => e.templateName === templateName).templatePalettes;
			},

			cleanNumberQuestionStats: function (stats) {
				return {
					count: stats.count,
					min: stats.count === 0 ? "-" : parseFloat(stats.min).toFixed(2),
					max: stats.count === 0 ? "-" : parseFloat(stats.max).toFixed(2),
					mean: stats.count === 0 ? "-" : parseFloat(stats.mean).toFixed(2),
					median: stats.count === 0 ? "-" : parseFloat(stats.median).toFixed(2),
					variance: stats.count === 0 ? "-" : parseFloat(stats.variance).toFixed(2),
					mode: stats.count === 0 || ! stats.mode instanceof Array ? "-"
						: (stats.mode.length > 0 ? stats.mode.join(', ') : "-"),
					standard_deviation: stats.count === 0 ? "-" : parseFloat(stats.standard_deviation).toFixed(2)
				};
			}
		}
	}
})();
