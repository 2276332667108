(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .factory('WidgetsService', WidgetsService);

    WidgetsService.$inject = ['$http', 'Upload'];

    function WidgetsService($http, Upload) {
        var service = {};

        service.GetAllTopics = function (okCallback, koCallback) {
            $http.get("/rest/widgets/retrieveTopics/")
                .then(function successCallback(response) {
                        okCallback(response);
                    }, function errorCallback(response) {
                        koCallback && koCallback(response);
                    }
                );
        };

        service.SaveTopic = function (topic, okCallback, koCallback) {
            $http.post("/rest/widgets/saveTopic/", topic)
                .then(function successCallback(response) {
                        okCallback(response);
                    }, function errorCallback(response) {
                        koCallback && koCallback(response);
                    }
                );
        };

        service.RemoveTopic = function (id, okCallback, koCallback) {
            $http.post("/rest/widgets/removeTopic/", id)
                .then(function successCallback(response) {
                        okCallback(response);
                    }, function errorCallback(response) {
                        koCallback && koCallback(response);
                    }
                );
        };

        service.GetAllChoiceTypes = function (langId, okCallback, koCallback) {
            $http.get("/rest/widgets/choiceTypesList/" + langId)
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.SaveChoiceType = function (choiceType, okCallback, koCallback) {
            $http.post("/rest/widgets/createChoices/", choiceType)
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.DeleteChoiceType = function (choiceType, okCallback, koCallback) {
            $http.delete("/rest/widgets/deleteChoiceType/" + choiceType)
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.getChoiceTypeById = function (choiceTypeId, okCallback, koCallback) {
            $http.get("/rest/widgets/getChoiceType/" + choiceTypeId)
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
	                koCallback && koCallback(response);
                });
        };

        service.DeleteChoice = function (choice, okCallback, koCallback) {
            $http.post("/rest/widgets/deleteChoice/", choice)
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.SaveChoice = function (choice, okCallback, koCallback) {
            $http.post("/rest/widgets/createChoice/", choice)
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.SaveChoicePicture = function (choiceId, blobImg, okCallback, koCallback) {
            var formData = new FormData();
            formData.append('file', blobImg);
            $http.put("/rest/widgets/addImageToChoice/" + choiceId, formData, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            })
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });

        };


        service.GetAllQuestions = function (params, okCallback, koCallback) {
            var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
            return $http.get("/rest/widgets/questionsList?" + queryString)
                .then(function successCallback(response) {
                    return okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.GetAnswersCounts = function (okCallback, koCallback) {
            $http.get("/rest/widgets/answersCounts/")
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.GetTopicsCounts = function (okCallback, koCallback) {
            $http.get("/rest/widgets/count/questions/by/topics")
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.SaveQuestionPicture = function (questionId, blobImg, okCallback, koCallback) {
            var formData = new FormData();
            formData.append('file', blobImg);
            $http.put("/rest/widgets/addImageToQuestion/" + questionId, formData, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            })
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.DeleteQuestionPicture = function (questionId, okCallback, koCallback) {
            $http.post("/rest/widgets/deleteQuestionImage/" + questionId)
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.DeleteQuestion = function (questionId, okCallback, koCallback) {
            $http.post("/rest/widgets/deleteQuestion/" + questionId)
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.UpdateQuestionAcceptStatus = function (questionId, status, okCallback, koCallback) {
            $http.post("/rest/widgets/updateQuestionAcceptStatus/" + questionId + "/" + status)
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.SaveQuestion = function (question, okCallback, koCallback) {
            $http.post("/rest/widgets/saveQuestion/", question)
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.GetQuestionById = function (questionId, okCallback, koCallback) {
            $http.get("/rest/widgets/getQuestion/" + questionId)
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.getQuestionImage = function (questionId ,okCallback, koCallback) {
            $http.get("/rest/widgets/questionImg/" + questionId, {responseType : 'arraybuffer'})
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.GetQuestionsAsExcel = function (domain, okCallback, koCallback) {
            $http.get("/rest/widgets/getAsExcel/" + domain, {responseType: 'arraybuffer'})
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.ImportExcel = function (excelFile, okCallback, koCallback) {
            var formData = new FormData();
            formData.append('file', excelFile);

            var url = "/rest/widgets/import/";
            $http.post(url, formData, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            })
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.GetTopicDeletionInfo = function (topicId, okCallback, koCallback) {
            $http.get("/rest/widgets/topicDeletionInfo/" + topicId)
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.GetChoiceTypeDeletionInfo = function (choiceTypeId, okCallback, koCallback) {
            $http.get("/rest/widgets/choiceTypeDeletionInfo/" + choiceTypeId)
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        return service;
    }
})();
