(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .controller('ReportTemplatesController', ReportTemplatesController);

    ReportTemplatesController.$inject = ['$rootScope', 'DeployService', 'ChartsService'];
    function ReportTemplatesController($rootScope, DeployService, ChartsService) {
        var vm = this;

        vm.getTemplatesList = function () {
            ChartsService.GetColorTemplates(function(success){
                vm.templatesList = success.data;
                vm.templatesLoaded = true;
            },function(error){
                console.log(error);
                vm.templatesLoaded = false;
            });
        };

        vm.uploadPptReportFile = function (file, reportDir) {
            var formData = new FormData();

            if (typeof reportDir === "undefined" && typeof reportDir.reportName !== "undefined") {
                return;
            }

            formData.append('file', file);
            DeployService.uploadPptReportFile(
                formData,
                reportDir.reportName,
                function (success) {
                    vm.pptUploadCheck = {
                        show: true,
                        uploaded: true,
                        message: "Successfully uploaded ppt!"
                    };
                    console.log(success.data)
                },
                function (error) {
                    vm.pptUploadCheck = {
                        show: true,
                        uploaded: false,
                        message: "Failed to upload ppt!"
                    };
                    console.log(error)
                }
            )
        };

        (function initController() {
            vm.activeSubmenu = 'setup';
            vm.breadcrumbs = 'Setup: Report Templates';

            vm.getTemplatesList();
        })();

    }
})();