(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .controller('ForecastUserDetailsController', ForecastUserDetailsController);

    ForecastUserDetailsController.$inject = ['$rootScope', '$stateParams', 'ForecastService', '$filter', '$state', '$window', '$mdDialog', 'userInfoService'];
    function ForecastUserDetailsController($rootScope, $stateParams, ForecastService, $filter, $state, $window, $mdDialog, userInfoService) {
        var vm = this;

        vm.convertDataForUI = function (data) {
            var quests = data.userForecastQuests;
            for(var i in quests) {
                quests[i].endDate = new Date(quests[i].endDate);
                var options = quests[i].forecastOptions;
                for(var j in options) {
                    options[j].optionAnswerDate = new Date(options[j].optionAnswerDate);
                }
            }

            return data;
        };

        vm.reloadUserForecastQuestsList = function () {

            var startDate = moment(moment.utc(vm.startDate)).format('YYYY-MM-DD H:m:s');
            ForecastService.getUserForecastActivity(
                vm.userId,
                startDate,
                function (success) {
                    vm.userForecastActivity = vm.convertDataForUI(success.data);
                },
                function (error) {
                    $mdDialog.show(
                        $mdDialog.alert()
                            .title('Failure')
                            .textContent('Error, could not get user forecast quests list, contact support!')
                            .ok('Close')
                    );
                }
            );
        };

        vm.searchForUserAndLoadInfoIfExists = function () {

            delete vm.startDate;
            delete vm.userForecastActivity;
            delete vm.userForecastAccuracy;
            vm.showUserDetails = false;

            userInfoService.getUserInfo(vm.userId,
                function (success) {
                    if(!success.data){
                        $mdDialog.show(
                            $mdDialog.alert()
                                .title('Failure')
                                .textContent('Error, User ID does not exist in the database!')
                                .ok('Close')
                        );
                    } else {
                        vm.getUserAccuracy();
                    }
                },
                function (error) {
                    $mdDialog.show(
                        $mdDialog.alert()
                            .title('Failure')
                            .textContent('Error, User ID does not exist in the database!')
                            .ok('Close')
                    );
                }
            )
        };

        vm.getUserAccuracy = function () {
            ForecastService.getUserForecastPredictionAccuracy(
                vm.userId,
                function (success) {
                    vm.userForecastAccuracy = success.data;
                    vm.showUserDetails = true;
                },
                function (error) {
                    $mdDialog.show(
                        $mdDialog.alert()
                            .title('Failure')
                            .textContent('Error, could not get user accuracy, contact support!')
                            .ok('Close')
                    );
                }
            );
        };


        (function initController() {
            vm.breadcrumbs = "Predictor: Forecasters > " + $stateParams.uid;
            vm.activeSubmenu = 'predictor';

            vm.userId = $stateParams.uid;
            vm.showUserDetails = false;

            if(vm.userId) {
                vm.showSearchForUserBox = false;
                vm.getUserAccuracy();
            } else {
                vm.showSearchForUserBox = true;
            }
        })();
    }

})();