(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .factory('WidgetValidationService', ['WidgetsService', '$mdDialog', 'SnackbarUtils', WidgetValidationService]);

    function WidgetValidationService(WidgetsService, $mdDialog, SnackbarUtils) {
        return {
            isChoiceTypeValidToSave: isChoiceTypeValidToSave,
            cleanChoiceTypeBeforeSave: cleanChoiceTypeBeforeSave,
            getNoOfFieldsTranslatedByLangIdForChoiceType: getNoOfFieldsTranslatedByLangIdForChoiceType,
            validateIncompleteTranslations: validateIncompleteTranslations,
            validateCustomChoices: validateCustomChoices,
            areAllQuestionTextsTranslated: areAllQuestionTextsTranslated,
            getTranslatedLanguages: getTranslatedLanguages,
            isChoiceTypeTranslatedInLang: isChoiceTypeTranslatedInLang
        };

        function hasDuplicates(array) {
            return (new Set(array).size !== array.length);
        }

        function isChoiceTypeValidToSave(question, choiceType, languages) {
            if(!choiceType.choiceTypeName || choiceType.choiceTypeName === "") {
                SnackbarUtils.displaySnackbar("error", 'Invalid question choice internal name! Cannot be blank!');
                return false;
            }

            for(var i in choiceType.choices) {
                if(!choiceType.choices[i].choiceValue) {
                    SnackbarUtils.displaySnackbar("error", 'Choice value is missing, please give all the choices a value!');
                    return false;
                }
            }

            var choicesValues = choiceType.choices.map(function (element) {return element.choiceValue;});
            if (hasDuplicates(choicesValues)) {
                SnackbarUtils.displaySnackbar("error", 'Please remove the duplicated values for choices!');
                return false;
            }

            return areAllTranslationsValid(question, choiceType, languages);
        }

        function getNoOfFieldsTranslatedByLangIdForChoiceType(question, choiceType, languages) {
            var isScaleDefault = typeof question === "undefined";
            var choices = choiceType.choices;
            var translationCountMap = {};

            for (var i in languages) {
                var langId = languages[i].lang_id;
                var countNoOfTranslatedChoices = 0;
                if (isScaleDefault) {
                    if(choiceType.choiceTypeTranslateMap[langId]) {
                        countNoOfTranslatedChoices++;
                    }
                } else {
                    if(question.questionTextTranslateMap[langId]) {
                        countNoOfTranslatedChoices++;
                    }
                }
                for (var m in choices) {
                    if (choices[m].choiceTranslateMap[langId]) {
                        countNoOfTranslatedChoices++;
                    }
                }
                translationCountMap[langId] = countNoOfTranslatedChoices;
            }

            return translationCountMap;
        }

        function areAllTranslationsValid(question, choiceType, languages) {
            var isScaleDefault = typeof question === "undefined";
            var choices = choiceType.choices;
            var translationCountMap = getNoOfFieldsTranslatedByLangIdForChoiceType(question, choiceType, languages);
            var maxTranslationCount = choices.length + (isScaleDefault ? 1 : 0);

            var isAtLeastOneLanguageIsFullyTranslated = false;
            for(var i in languages) {
                var langId = languages[i].lang_id;
                if(translationCountMap[langId] !== 0 && translationCountMap[langId] !== maxTranslationCount) {
                    SnackbarUtils.displaySnackbar("error", 'Choices are not fully translated for ' + languages[i].lang_ui_name + ' language!');
                    return false;
                }
                if(translationCountMap[langId] === maxTranslationCount) {
                    isAtLeastOneLanguageIsFullyTranslated = true;
                }
            }

            if(!isAtLeastOneLanguageIsFullyTranslated) {
                SnackbarUtils.displaySnackbar("error", 'Choices must be fully translated in at least one language in order to be valid for save!');
                return false;
            }

            for(var i in languages) {
                var langId = languages[i].lang_id;
                if(translationCountMap[langId] === maxTranslationCount) {
                    var translationsArray = choices.map(function (element) {return element.choiceTranslateMap[langId];});
                    if(hasDuplicates(translationsArray)) {
                        SnackbarUtils.displaySnackbar("error", 'Please remove the duplicated labels for choices (language ' + languages[i].lang_ui_name + ')!');
                        return false;
                    }
                }
            }

            return true;
        }

        function cleanChoiceTypeBeforeSave(choiceType, languages) {
            for(var i in languages) {
                var langId = languages[i].lang_id;
                if(typeof choiceType.choiceTypeTranslateMap[langId] !== "undefined" && choiceType.choiceTypeTranslateMap[langId] === ""){
                    delete choiceType.choiceTypeTranslateMap[langId];
                }
            }

            for(var j in choiceType.choices) {
                for(var m in languages) {
                    var langId = languages[m].lang_id;
                    if(typeof choiceType.choices[j].choiceTranslateMap[langId] !== "undefined" && choiceType.choices[j].choiceTranslateMap[langId] === ""){
                        delete choiceType.choices[j].choiceTranslateMap[langId];
                    }
                }
            }

            return choiceType;
        }

        function validateIncompleteTranslations(question, languagesList){
            var translationCountMap = getNoOfFieldsTranslatedByLangIdForChoiceType(question, question.questionChoiceTypePayload, languagesList);
            var langsTranslated = "";
            var langsNotTranslated = "";

            var selectedChoiceType = question.questionChoiceTypePayload;

            for(var i = 0; i < languagesList.length; i++) {
                var langId = languagesList[i].lang_id;

                if((typeof question.questionTextTranslateMap[langId] !== 'undefined' &&
                    question.questionTextTranslateMap[langId] !== "") &&
                    translationCountMap[langId] === 0){

                    SnackbarUtils.displaySnackbar("error", "Please add Custom Choices Translation for: " + languagesList[i].lang_ui_name + " language !");
                    return false;
                }

                if((question.questionTextTranslateMap[langId] === "" || typeof  question.questionTextTranslateMap[langId] === 'undefined') &&
                    translationCountMap[langId] > 0){
                    SnackbarUtils.displaySnackbar("error", "Please add Question Text Translation translation for : " + languagesList[i].lang_ui_name + " language !");
                    return false;
                }

                if(translationCountMap[langId] === selectedChoiceType.choices.length + 1 ) {
                    if(langsTranslated === "") {
                        langsTranslated = languagesList[i].lang_ui_name;
                    } else {
                        langsTranslated = langsTranslated + ", " + languagesList[i].lang_ui_name;
                    }
                } else {
                    if(translationCountMap[langId] < 1) continue;
                    if(langsNotTranslated === "") {
                        langsNotTranslated = languagesList[i].lang_ui_name;
                    } else {
                        langsNotTranslated = langsNotTranslated + ", " + languagesList[i].lang_ui_name;
                    }
                }
            }

            if (langsNotTranslated) {
                var suffix = langsNotTranslated.split(',').length > 1 ? ' languages!' : ' language!';
                SnackbarUtils.displaySnackbar("error", "Please add Custom Choices Translation for: "
                    + langsNotTranslated + suffix);
                return false;
            }

            return true;
        };

        function checkIfDuplicateExists(w){
            return new Set(w).size !== w.length
        }

        function validateMissingQuestionTranslation(question,lang){
            if(typeof question.questionTextTranslateMap[lang.lang_id] === 'undefined'){
                SnackbarUtils.displaySnackbar("error", "Please complete question translation for: " + lang.lang_ui_name);
                return false;
            }
            return true;
        }

        function validateCustomChoices(question, languages){
            var foundDuplicates = false;
            var areChoicesValueDuplicatesFound = false;
            for(var lang of languages) {
                var choiceLabelsDuplicatesByLang = [];
                var choiceValuesDuplicatesByLang = [];
                for (var i = 0; i < question.questionChoiceTypePayload.choices.length; ++i) {
                    var currentQuestionChoiceTypePayload = question.questionChoiceTypePayload;

                    if(Object.keys(currentQuestionChoiceTypePayload.choices[i].choiceTranslateMap).length > 0 &&
                        (typeof currentQuestionChoiceTypePayload.choices[i].choiceTranslateMap[lang.lang_id] !== 'undefined')) {

                        if(!validateMissingQuestionTranslation(question, lang)) {
                            return false;
                        }

                        if(currentQuestionChoiceTypePayload.choices[i].choiceTranslateMap[lang.lang_id]!=="") {
                            choiceLabelsDuplicatesByLang.push(currentQuestionChoiceTypePayload.choices[i].choiceTranslateMap[lang.lang_id]);
                        }
                        if(!areChoicesValueDuplicatesFound) {
                            choiceValuesDuplicatesByLang.push(currentQuestionChoiceTypePayload.choices[i].choiceValue.toString());
                        }
                    }
                }
                if(checkIfDuplicateExists(choiceLabelsDuplicatesByLang)){
                    SnackbarUtils.displaySnackbar("error", "Please remove the duplicated labels for choices for language: " + lang.lang_ui_name);
                    foundDuplicates = true;
                }
                if(checkIfDuplicateExists(choiceValuesDuplicatesByLang)){
                    areChoicesValueDuplicatesFound = true;
                    SnackbarUtils.displaySnackbar("error", "Please remove the duplicated values for choices");
                    foundDuplicates = true;
                }
            }
            return !foundDuplicates;

        };

        function areAllQuestionTextsTranslated(languages, question){

            var untranslatedLanguages = [];
            for(var lang of languages){
                if(typeof question.questionTextTranslateMap[lang.lang_id] === 'undefined' ||
                    question.questionTextTranslateMap[lang.lang_id] === ''){
                    untranslatedLanguages.push(lang);
                }
            }

            if(untranslatedLanguages.length > 0) {
                SnackbarUtils.displaySnackbarWithLanguagesEnum("warning", "This question is not translated into: ",untranslatedLanguages);
            }
        }


        function getTranslatedLanguages(question, languages){
            var translatedLanguages = [];

            for(var language of languages){
                if(typeof question.questionTextTranslateMap[language.lang_id] !== 'undefined'){
                    translatedLanguages.push(language);
                }
            }

            return translatedLanguages;
        }

        function isChoiceTypeTranslatedInLang(currentChoiceTypeId, choiceTypesList, langId){
            var currentChoiceType = choiceTypesList.find(choiceType => choiceType.choice_type_id === currentChoiceTypeId);
            var isChoiceTypeTranslated =  currentChoiceType.available_languages_ids.find(lang => lang === langId);
            if(typeof isChoiceTypeTranslated === 'undefined') {
                return false;
            }
            return true;
        }

    }
})();

