(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.controller('TasksController', TasksController);

	TasksController.$inject = ['$rootScope', '$state', 'TasksService', 'LanguagesService'];
	function TasksController($rootScope, $state, TasksService, LanguagesService) {

		var vm = this;

		vm.getLanguages = function (okCallback) {
			LanguagesService.GetPanelLanguages(
				function (success) {
					vm.availableLanguages = success.data;
					okCallback(success.data);
				},
				function (error) {
					console.log(error)
				}
			);
		};

		vm.isLanguageAlreadySelectedForLeadsTask = function(task, langId) {
			if(typeof task.data.language !== "undefined") {
				return task.data.language.lang_id === langId;
			} else {
				return false;
			}
		};

		vm.getTasksStatuses = function () {
			vm.tasks.forEach(function(task) {
				if(task.type !== "leads") {
					TasksService.getStatus(
						task.type,
						function (response) {
							task.started = true;
							task.data = response.data;
						},
						function() {
							task.started = false;
						}
					);
				}
			});
		};

		vm.getFBLeadsStatuses = function (languages) {
			if(typeof languages[0] !== "undefined") {
				TasksService.getFbLeadsStatusForLanguageId(
					languages[0].lang_id,
					function (response) {
						vm.tasks[0].started = true;
						vm.tasks[0].data = response.data;
					},
					function() {
						vm.tasks[0].started = false;
					}
				)
			} else {
				vm.tasks[0].started = false;
			}
			if(typeof languages[1] !== "undefined") {
				TasksService.getFbLeadsStatusForLanguageId(
					languages[1].lang_id,
					function (response) {
						vm.tasks[1].started = true;
						vm.tasks[1].data = response.data;
					},
					function() {
						vm.tasks[1].started = false;
					}
				)
			} else {
				vm.tasks[1].started = false;
			}
		};

		(function initController() {
			vm.activeSubmenu = 'setup';
			vm.breadcrumbs = 'Setup: Automated Tasks';
			vm.tasks = [
				{type: "leads", data: {}, tag: "leads1"},
				{type: "leads", data: {}, tag: "leads2"},
				{type: "activation", data: {}},
				{type: "profile", data: {}},
				{type: "recommend", data: {}},
				{type: "asl", data: {}},
				{type: "no_proposed_questions", data: {}}
			];

			vm.getLanguages(function (responseData) {
				vm.getFBLeadsStatuses(vm.availableLanguages);
			});

			vm.getTasksStatuses();
		})();

		vm.startStop = function(task) {
			if(!task.started) {
				TasksService.startScheduler(
					task,
					function (response) {
						task.started = true;
						task.data = response.data;
					},
					function() {
						task.started = false;
					}
				);
			}
			else {
				if(task.type === "leads") {
					TasksService.stopFbLeadsScheduler(
						task,
						function (response) {
							task.started = false;
						}
					);
				} else {
					TasksService.stopScheduler(
						task,
						function (response) {
							task.started = false;
						}
					);
				}
			}
		};

		vm.goto = function (state) {
			$state.go(state);
		};


	}
})();
