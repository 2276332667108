(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('ForecastService', ForecastService);

	ForecastService.$inject = ['$http'];
	function ForecastService($http) {
		var service = {};

		service.getAllForecasts = getAllForecasts;
		service.getSingleForecast = getSingleForecast;
		service.getSingleForecastStats = getSingleForecastStats;
		service.getForecastQuestions = getForecastQuestions;
		service.postForecastResult = postForecastResult;
		service.GetUserRanksCount = GetUserRanksCount;
		service.updateForecastScores = updateForecastScores;
		service.getQuestionsAnswers = getQuestionsAnswers;
		service.getWeighedSingleForecastStats = getWeighedSingleForecastStats;
		service.sendNotifications = sendNotifications;
		service.getUsersForecastStats = getUsersForecastStats;
		service.resetScores = resetScores;
		service.getUserForecastActivity = getUserForecastActivity;
		service.getUserForecastPredictionAccuracy = getUserForecastPredictionAccuracy;

		return service;

		function getAllForecasts(params, okCallback, koCallback) {
			$http({
				url: params.url,
				type: 'get'
			}).then(function successCallback(response) {
				okCallback && okCallback(response.data.quests_details);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function getSingleForecast(url, okCallback, koCallback) {
			$http({
				url: url,
				type: 'get'
			}).then(function successCallback(response) {
				okCallback && okCallback(response.data);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function GetUserRanksCount(okCallback, koCallback) {
			$http({
				url: '/rest/users/forecastranks',
				type: 'get'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function getQuestionsAnswers(okCallback, koCallback) {
			$http({
				url: '/rest/quests/all/answersnew?type=forecast',
				type: 'get'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function getSingleForecastStats(forecastId, okCallback, koCallback) {
			$http({
				url: '/rest/quests/averages/' + forecastId,
				type: 'get'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function getWeighedSingleForecastStats(forecastId, weigh, okCallback, koCallback){
			$http({
				url: '/rest/quests/weighedaverages/' + weigh + '/' + forecastId,
				type: 'get'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function getForecastQuestions(url, okCallback, koCallback) {
			$http({
				url: url,
				type: 'get'
			}).then(function successCallback(response) {
				okCallback && okCallback(response.data.questions);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function postForecastResult(data, okCallback, koCallback) {
			$http({
				method: 'POST',
				url: '/rest/quests/forecast/' + data.forecast_id + '/' + data.forecastEndDate,
				data: data.forecast_scores
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function updateForecastScores(okCallback, koCallback) {
			$http({
				method: 'POST',
				url: '/rest/users/updateforecastscores/'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function sendNotifications(questId, toAll, okCallback, koCallback) {
			$http({
				method: 'GET',
				url: '/rest/forecast/notifyPredictorNew/' + questId + "?toAll=" + toAll
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

        function getUsersForecastStats(parameters, okCallback, koCallback) {
            $http.post("/rest/forecast/usersStats", parameters)
                .then(function successCallback(response) {
                    okCallback && okCallback(response);
                }, function errorCallback(response) {
                    koCallback && koCallback(response);
                });
        }

        function resetScores(okCallback, koCallback) {
            $http.post("/rest/forecast/resetScores")
                .then(function successCallback(response) {
                    okCallback && okCallback(response);
                }, function errorCallback(response) {
                    koCallback && koCallback(response);
                });
        }

        function getUserForecastActivity(userId, startDate, okCallback, koCallback) {
            $http.post("/rest/forecast/getUserForecastActivity/" + userId, startDate)
                .then(function successCallback(response) {
                    okCallback && okCallback(response);
                }, function errorCallback(response) {
                    koCallback && koCallback(response);
                });
        }

        function getUserForecastPredictionAccuracy(userId, okCallback, koCallback) {
            $http.get("/rest/forecast/getUserForecastAccuracy/" + userId)
                .then(function successCallback(response) {
                    okCallback && okCallback(response);
                }, function errorCallback(response) {
                    koCallback && koCallback(response);
                });
        }
	}

})();