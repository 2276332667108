(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .controller('WidgetsChoicesController', WidgetsChoicesController);

    WidgetsChoicesController.$inject = ['$scope' ,'$rootScope', 'WidgetsService', '$mdDialog', '$timeout', 'NgTableParams', 'LanguagesService', 'WidgetValidationService', 'SnackbarUtils'];

    function WidgetsChoicesController($scope, $rootScope, WidgetsService, $mdDialog, $timeout, NgTableParams, LanguagesService, WidgetValidationService, SnackbarUtils) {
        var vm = this;

        var defaultChoiceType = {
            choiceTypeId: undefined,
            choiceTypeName: undefined,
            choiceTypeDefault: true,
            choiceTypeTranslateMap: {},
            choices: [
                {
                    choiceId: undefined,
                    choiceMedia: undefined,
                    choiceTranslateMap: {},
                    choiceValue: 1
                },
                {
                    choiceId: undefined,
                    choiceMedia: undefined,
                    choiceTranslateMap: {},
                    choiceValue: 2
                }
            ]
        };

        vm.getLanguages = function (okCallback) {
            LanguagesService.GetPanelLanguages(
                function (success) {
                    vm.panelLanguages = success.data;
                    okCallback(success.data);
                },
                function (error) {
                    console.log(error)
                }
            );
        };

        vm.getAvailableLanguagesAsString = function (availableLanguages) {
            if (!availableLanguages)
                return "";
            var toReturn = "";
            for(var i = 0; i < availableLanguages.length; i++) {
                var found = vm.panelLanguages.find(function (elem) {
                    return elem.lang_id === availableLanguages[i];
                });
                if (!found)
                    continue;
                if(i === 0) {
                    toReturn = toReturn + found.lang_ui_name
                } else {
                    toReturn = toReturn + ", " + found.lang_ui_name;
                }
            }

            return toReturn;
        };

        vm.reloadChoiceTypesList = function (langId) {
            delete vm.choiceTypesList;
            WidgetsService.GetAllChoiceTypes(langId,
                function (success) {
                    vm.choiceTypesList = success.data;
                    vm.tableDataset = [];

                    angular.forEach(vm.choiceTypesList, function (value) {
                        var availableLanguagesString = vm.getAvailableLanguagesAsString(value.available_languages_ids);
                        vm.tableDataset.push({
                            "choice_type_id": value.choice_type_id,
                            "choice_type_name": value.choice_type_name,
                            "choice_type_default": value.choice_type_default,
                            "available_languages": availableLanguagesString
                        });
                    });
                    var initialSettings = {
                        // page size buttons (right set of buttons in demo)
                        counts: [],
                        // determines the pager buttons (left set of buttons in demo)ss
                        paginationMaxBlocks: 20,
                        paginationMinBlocks: 2,
                        dataset: vm.tableDataset
                    };
                    vm.tableParams = new NgTableParams({count: 20}, initialSettings);
                },
                function (error) {
                    if(error.status === 404) {
                        vm.tableParams.settings().dataset = [];
                        vm.tableParams.reload();
                    }
                }
            );
        };

        vm.removeChoiceType = function (choiceTypeId) {
            if(!vm.isChoiceTypeDelitionProcessStarted) {
                vm.isChoiceTypeDelitionProcessStarted = true;
                WidgetsService.GetChoiceTypeDeletionInfo(choiceTypeId, function (success) {
                    $mdDialog.show({
                        template:
                            '<md-dialog md-theme="default">' +
                            '   <md-dialog-content class="md-dialog-content">' +
                            '<div style="display:flex; justify-content: center"><img src="images/warning.PNG" alt=""/></div>' +
                            '<b>If you delete this choice, <span style="color:#EF4000">' + success.data["no_questions_affected"] + '</span> wall questions will be deleted.</b>' +
                            '       <div class="md-dialog-content-body" style="text-align: center;margin-top:3%">' +
                            '           <p>Are you sure you want to delete this choice?</p>' +
                            '       </div>' +
                            '   </md-dialog-content>' +
                            '   <md-dialog-actions style="justify-content: center;margin-top:6%;margin-bottom:5%">' +
                            '       <md-button ng-click="ok()" class="md-raised md-warn md-hue-2">' +
                            '           Delete Choice' +
                            '       </md-button>' +
                            '       <md-button ng-click="cancel()" class="md-background md-hue-1">' +
                            '           Go back' +
                            '       </md-button>' +
                            '   </md-dialog-actions>' +
                            '</md-dialog>',
                        locals: {},
                        controller: function ($scope, $mdDialog) {
                            $scope.cancel = function () {
                                vm.isChoiceTypeDelitionProcessStarted = false;
                                $mdDialog.cancel();
                            };
                            $scope.ok = function () {
                                vm.isChoiceTypeDelitionProcessStarted = false;
                                $mdDialog.hide()
                            }
                        }
                    }).then(
                        function () {
                            WidgetsService.DeleteChoiceType(
                                choiceTypeId,
                                function (success) {
                                    vm.isChoiceTypeDelitionProcessStarted = false;
                                    vm.reloadChoiceTypesList();
                                },
                                function (error) {
                                    vm.isChoiceTypeDelitionProcessStarted = false;
                                    SnackbarUtils.displaySnackbar("error", "Failed to delete question choices!");
                                }
                            );
                        },
                        function () {
                        }
                    );
                }, function (error) {
                    console.log(error);
                });
            }
        };

        vm.viewImageOfChoice = function (choiceIndex) {
            console.log(vm.choicesList[choiceIndex]);
            $mdDialog.show({
                template:
                    '<md-dialog>' +
                    '   <md-dialog-content layout="row" layout-align="center center">' +
                    '       <img src="data:image/png;base64, {{choice.choice_media}}"/>' +
                    '   </md-dialog-content>' +
                    '   <md-dialog-actions>' +
                    '       <md-button ng-click="cancel()" class="md-primary">' +
                    '           Close' +
                    '       </md-button>' +
                    '   </md-dialog-actions>' +
                    '</md-dialog>',
                locals: {
                    choice: vm.choicesList[choiceIndex]
                },
                controller: function ($scope, $mdDialog, choice) {
                    $scope.choice = choice;
                    $scope.cancel = function() {
                        $mdDialog.cancel();
                    };
                }
            }).then(
                function () {
                },
                function () {
                }
            );
        };

        function reloadSelectedChoiceType(id) {
            delete vm.selectedChoiceType;
            WidgetsService.getChoiceTypeById(
                id,
                function (response) {
                    vm.selectedChoiceType = response.data;
                },
                function (response) {
                    SnackbarUtils.displaySnackbar("error", "Failed to open in edit mode selected question choices!");
                }
            );
        }

        vm.editChoiceType = function (id) {
            vm.addingNewChoiceType = false;
            reloadSelectedChoiceType(id);
            vm.scrollToElement("targetScrollOnEditId");
        };

        vm.scrollToElement = function (id) {
            $timeout( function(){
                var elemToScroll = document.getElementById(id);
                elemToScroll && elemToScroll.scrollIntoView({ behavior: "smooth"});
            }, 150);
        };

        vm.initNewChoiceType = function() {
            vm.addingNewChoiceType = true;
            vm.lastEditedChoiceTypeName = undefined;

            // Clear vm.selectedChoiceType variable to force the directive to re-render using the ng-if
            delete vm.selectedChoiceType;
            // Wait a few milliseconds until the directive is removed from view
            setTimeout(function () {
                // The setTimeout is executed out of the angular context, use angular $scope.$apply() to modify and notice
                // angular that the variable has suffered changes and it should apply them.
                $scope.$apply(function () {
                    vm.selectedChoiceType = angular.copy(defaultChoiceType);
                });
            }, 250);
            vm.scrollToElement("targetScrollOnEditId");
        };

        vm.cancelEditChoiceType = function() {
            $mdDialog.show({
                template:
                    '<md-dialog md-theme="default">' +
                    '   <md-dialog-content class="md-dialog-content">' +
                    '       <h2 class="md-title">Warning!</h2>' +
                    '       <div class="md-dialog-content-body">' +
                    '           <p>All your unsaved changes will be lost. Are you sure you want to cancel your updates?</p>' +
                    '       </div>' +
                    '   </md-dialog-content>' +
                    '   <md-dialog-actions>' +
                    '       <md-button ng-click="ok()" class="md-raised md-warn md-hue-2">' +
                    '           Yes' +
                    '       </md-button>' +
                    '       <md-button ng-click="cancel()" class="md-background md-hue-1">' +
                    '           Go back' +
                    '       </md-button>' +
                    '   </md-dialog-actions>' +
                    '</md-dialog>',
                locals: {},
                controller: function ($scope, $mdDialog) {
                    $scope.cancel = function() {
                        $mdDialog.cancel();
                    };
                    $scope.ok = function () {
                        $mdDialog.hide()
                    }
                }
            }).then(
                function () {
                    delete vm.selectedChoiceType;
                },
                function () {}
            );
        };

        vm.showMessagesAfterSuccessfulSave = function() {
            var translationCountMap = WidgetValidationService.getNoOfFieldsTranslatedByLangIdForChoiceType(undefined, vm.selectedChoiceType, vm.panelLanguages);
            var langsTranslated = [];
            var langsNotTranslated = [];

            for(var i in vm.panelLanguages) {
                var langId = vm.panelLanguages[i].lang_id;
                if(translationCountMap[langId] === vm.selectedChoiceType.choices.length + 1) {
                    langsTranslated.push(vm.panelLanguages[i]);
                } else {
                    langsNotTranslated.push(vm.panelLanguages[i])
                }
            }

            SnackbarUtils.displaySnackbarWithLanguagesEnum("success", "Your translation has been saved into:", langsTranslated);
            if (langsNotTranslated.length !== 0) {
                SnackbarUtils.displaySnackbarWithLanguagesEnum("warning", "This choice is not translated into:", langsNotTranslated);
            }
        };

        vm.saveChoiceType = function() {
            if (!vm.selectedChoiceType) {
                return;
            }
            for (var i=0; i<vm.selectedChoiceType.choices.length;i++){
                if(!Number.isInteger(vm.selectedChoiceType.choices[i].choiceValue)){
                    vm.selectedChoiceType.choices[i].choiceValue=parseInt(vm.selectedChoiceType.choices[i].choiceValue);
                }
            }

            for (var i in vm.choiceTypesList) {
                if (vm.selectedChoiceType.choiceTypeId !== vm.choiceTypesList[i].choice_type_id &&
                    vm.selectedChoiceType.choiceTypeName === vm.choiceTypesList[i].choice_type_name) {
                    SnackbarUtils.displaySnackbar("warning", 'Questions choice internal name is already taken, please choose another one!');
                    return;
                }
            }
            if (WidgetValidationService.isChoiceTypeValidToSave(undefined, vm.selectedChoiceType, vm.panelLanguages)) {
                var cleanPayload = WidgetValidationService.cleanChoiceTypeBeforeSave(vm.selectedChoiceType, vm.panelLanguages);

                WidgetsService.SaveChoiceType(
                    cleanPayload,
                    function (success) {
                        vm.showMessagesAfterSuccessfulSave();
                        vm.reloadChoiceTypesList(vm.selectedLanguageId);
                        delete vm.selectedChoiceType;
                    },
                    function (error) {
                        SnackbarUtils.displaySnackbar("error", 'There was a problem while trying to save the question choice!');
                    }
                );

            }
        };

        (function initController() {
            vm.activeSubmenu = 'setup_translate';
            vm.breadcrumbs = 'Setup: Wall Choices';

            vm.addingNewChoiceType = false;
            vm.lastEditedChoiceTypeName = undefined;
            vm.selectedLanguageId = "none";

            vm.isChoiceTypeDelitionProcessStarted = false;

            vm.uploadImgToObject = WidgetsService.SaveChoicePicture;
            vm.getLanguages(function (responseData) {
                vm.reloadChoiceTypesList(vm.selectedLanguageId);
            });
        })();

    }
})();
