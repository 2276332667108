(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .controller('Amcharts4Controller', Amcharts4Controller);

    Amcharts4Controller.$inject = ['ChartsService', 'SurveysService', 'AmCharts4Utils', 'RegionsService','QuestiaUtils', '$window', '$rootScope', '$state', '$queue', '$stateParams', '$element', '$mdDialog'];
    function Amcharts4Controller(ChartsService, SurveysService, AmCharts4Utils, RegionsService,QuestiaUtils, $window, $rootScope, $state, $queue, $stateParams, $element, $mdDialog) {

        var vm = this;

        var originatorEv;
        function initVars(){
            vm.breadcrumbs = "Surveys: Charts";
            vm.activeSubmenu = 'surveys';
            vm.surveyId = $stateParams.qid || 2;
            vm.completed = false;
            vm.filterQId = null;
            vm.date = null;
            vm.filterChoices = [];
            vm.colors =  [ "#B1CA54", "#095478", "#1DA77D", "#F8A587", "#9A1B3C", "#D5DF83", "#318BA8",
                "#6BC399", "#FCC9B2", "#CB2B56", "#E0EABB", "#A4D5E5", "#C4E7D6", "#F8804B",
                "#EDA8BA", "#AA5C93", "#751BB7", "#C260E0", "#D6D6D6", "#B5B5B5", "#ADD6F7",
                "#00AFF0", "#0078D7", "#B0FFAF", "#D1BE96"];
            vm.greyBackground = false;
            vm.ASL_QUESTION_ID = "2009";
            vm.selectedLanguage = null;
        }

        vm.openMenu = function ($mdMenu, ev) {
            originatorEv = ev;
            $mdMenu.open(ev);
        };

        vm.changeTranslationLanguage = function (languageToTranslate) {
            vm.selectedLanguage = languagesMapper()[languageToTranslate];
            if(languageToTranslate !== 'Original'){
                getQuestions(vm.selectedLanguage);
            }else{
                getQuestions(null);
            }
        };

        function setFilters(qid) {
            vm.generalFilters = {
                filters: []
            };
            if (vm.filterQId && vm.filterChoiceValue) {
                vm.generalFilters.filters.push({
                    "type": "other_question_answer",
                    "values": [vm.filterQId, vm.filterChoiceValue]
                });
            }
            if (vm.date) {
                vm.generalFilters.filters.push({
                    "type": "date_answer",
                    "values": [vm.date.toLocaleString()]
                });
            }
            if(qid) {
                //individual question filters
                if (vm.chartConfigs[qid].dknaOption && vm.chartConfigs[qid].dknaOption !== "0") {
                    vm.generalFilters.filters.push({
                        "type": "answers_nsnr",
                        "values": [vm.chartConfigs[qid].dknaOption]
                    });
                }
                if(vm.chartConfigs[qid].dynamicAgeGroups) {
                    vm.chartConfigs[qid].dynamicAgeGroups.replace(" ", "");
                    vm.generalFilters.dynamicAgeGroups = vm.chartConfigs[qid].dynamicAgeGroups.split(",");
                    vm.redoRequest = true;
                }
            }
        }

        function buildChart(qid) {

            var chartType = AmCharts4Utils.getChartType(vm.chartConfigs[qid].type);

            if(chartType == null){
                console.log('There is no chart type selected');
                return;
            }

            vm.charts[qid] = am4core.createFromConfig(
                AmCharts4Utils.buildChartConfig(qid, angular.copy(vm.chartDataProviders[qid]), vm.chartConfigs[qid]),"chart" + qid,chartType, vm.chartConfigs[qid]);
        }

        function cleanSelectDataKeys(selectData){

            for (var key in selectData) {
                if (selectData.hasOwnProperty(key)) {
                    if(key.startsWith("|")){
                        var currentValue = selectData[key];
                        var oldKey = key;
                        key = key.split("|")[2];
                        selectData[key] = currentValue;
                        delete selectData[oldKey];
                    }
                }
            }

            return selectData;
        }

        function cleanStatsRefreshedData(data){
            for(var i = 0; i < data.length ; i++){
                if(data[i]){
                    if(data[i].match(/^\d/) && data[i].length > 1){
                        if(data[i].includes("#")){
                            data[i] = data[i].split("#")[1];
                        }
                    }
                }
            }
            return data;
        }

        function makeQueuedRequest(question) {
            var questionStatisticsConfigs = {
                filtersWithAgeGroup: vm.generalFilters
            };
            if(question.type === "cityCounty"){
                questionStatisticsConfigs.cityCountyType = vm.chartConfigs[question.question_id].cityCountyType;
                if(questionStatisticsConfigs.cityCountyType === 'division'){
                    questionStatisticsConfigs.divisionId = vm.chartConfigs[question.question_id].divisionId;
                }
            }

            ChartsService.GetQuestionStats(vm.surveyId, question.question_id,vm.selectedLanguage, questionStatisticsConfigs, function (response2) {
                    //we need to cache these, as after the chart renders it mangles with data, and if we need to re-render we better have these untouched
                    response2.data = cleanSelectDataKeys(response2.data);

                    var utilData = AmCharts4Utils.buildDataProvider(response2.data, vm.chartConfigs[question.question_id]);
                    if (question.type !== "selects") {
                        vm.chartDataProviders[question.question_id] = utilData.values;
                        question.total = utilData.total;
                    }
                    else {
                        vm.chartDataProviders[question.question_id] = utilData;
                        question.total = utilData.dataProvider.total;
                    }

                    buildChart(question.question_id);
                },
                function (response2) {
                });
        }

        function getQuestions(languageToTranslate) {
            vm.questions = [];
            vm.charts = {};
            vm.numberOfChunks = 1;
            vm.chartConfigs = {};
            vm.chartDataProviders = {};

            setFilters();
            SurveysService.GetSurveyJson(vm.surveyId, true,languageToTranslate,  function (response) {

                if(response.status === 201){
                    QuestiaUtils.showAlert("This survey is not translated in the chosen language" );
                }

                var questions = response.data.questions;
                var queueOptions = {
                    delay: 250, //delay 0.25 seconds between processing items
                    paused: true, //start out paused
                    complete: function () {
                        console.log('complete!');
                        vm.completed = true;
                    }
                };

                // create an instance of a queue
                // note that the first argument - a callback to be used on each item - is required
                var chartsReqQueue = $queue.queue(makeQueuedRequest, queueOptions);

                for (var i = 0; i < questions.length; i++) {
                    if (['quote', 'jumbotron', 'text', 'smileys'].indexOf(questions[i].type) === -1) {
                        vm.questions.push(questions[i]);
                        vm.chartConfigs[questions[i].question_id] = {};
                        //create a default config - shallow copy of the constant
                        angular.extend(vm.chartConfigs[questions[i].question_id], AmCharts4Utils.DEFAULT_CHART_CONFIG);
                        vm.chartConfigs[questions[i].question_id].type = questions[i].type === "selects" ? "stack" : "bar";
                        //legend on by default for stacks
                        if (questions[i].type === "selects") {
                            vm.chartConfigs[questions[i].question_id].showLegend = true;
                            (function (i) {
                                ChartsService.GetSelectsForCross(questions[i].question_id,languageToTranslate,
                                    function (response) {
                                        if (response.data.length > 0) {
                                            vm.chartConfigs[questions[i].question_id].selectsOptions = response.data;
                                        }
                                    },
                                    function (response) {
                                        console.log(response);
                                    });
                            })(i);
                        }
                        if(questions[i].type === "cityCounty") {
                            vm.chartConfigs[questions[i].question_id].cityCountyType = "division";
                            if(typeof vm.divisions !== 'undefined'){
                                vm.chartConfigs[questions[i].question_id].divisionId = vm.divisions[0].division_id;
                            }
                        }
                        vm.chartConfigs[questions[i].question_id].questionType = questions[i].type;
                        if (!vm.greyBackground) {
                            vm.chartConfigs[questions[i].question_id].backgroundColor = "#ffffff";
                        }
                        (function (question) {
                            chartsReqQueue.add(question);
                        })(questions[i]);
                    } else if(questions[i].type === "text"){
                        vm.questions.push(questions[i]);
                        vm.chartConfigs[questions[i].question_id] = {
                            selectedWordId: "selectedWord" + questions[i].question_id,
                            selectedSizeId: "selectedSize" + questions[i].question_id,
                            selectedWord:"",
                            selectedSize:"",
                            height: 700,
                            // height: $window.innerHeight * 0.5,
                            width: 1500,
                            rotate: function(){
                                return ~~(Math.random() * 2) * 90;
                            },
                            random: function(){
                                return 0.4; // a constant value here will ensure the word position is fixed upon each page refresh.
                            },
                            useTooltip: true,
                            useTransition: false,
                            words: [],
                            wordsFont: 'Roboto, "Helvetica Neue", sans-serif',
                            wordClicked: function(word){
                                // alert('text: ' + word.text + ',size: ' + word.size);
                            }
                        };

                        (function (i) {
                            ChartsService.GetQuestionStats(vm.surveyId, questions[i].question_id, vm.selectedLanguage, {},
                                function (response) {
                                    vm.chartConfigs[questions[i].question_id].words =
                                        AmCharts4Utils.setUpWordsForWordCloud(response.data);
                                },
                                function (response) {
                                    console.log(response);
                                });
                        })(i);
                        if (!vm.greyBackground) {
                            vm.chartConfigs[questions[i].question_id].backgroundColor = "#ffffff";
                        }
                    }
                }
                chartsReqQueue.start(); //must call start() if queue starts paused
            });
        }

        vm.refreshCharts = function () {
            if (!vm.surveyId) return;
            getQuestions(null);
        };

        vm.crossChangeReq = function (question) {
            var chartConfig = vm.chartConfigs[question.question_id];
            chartConfig.redoRequest = true;

            AmCharts4Utils.crossChange(question, chartConfig);
        };

        vm.buildUtilDataAndMakeChart = function (crossData, question) {
            var chartConfig = vm.chartConfigs[question.question_id];
            var utilData = AmCharts4Utils.buildDataProvider(crossData, chartConfig);
            if (chartConfig.crossWithId || chartConfig.crossWithDivisionId) {
                vm.chartDataProviders[question.question_id] = utilData;
            }
            else {
                if (question.type === "selects") {
                    vm.chartDataProviders[question.question_id] = utilData;
                }
                else {
                    vm.chartDataProviders[question.question_id] = utilData.values;
                    question.total = utilData.total;
                }

            }
            //modified config, same data provider
            buildChart(question.question_id)
        };

        vm.refreshChart = function (question) {
            if (!question || !vm.charts[question.question_id]) return;
            var chartConfig = vm.chartConfigs[question.question_id];
            var chartDomEl = document.getElementById('chart' + question.question_id);
            chartDomEl.style.height = chartConfig.height + 'px';
            chartDomEl.style.width = chartConfig.width + 'px';


            //data needs refresh?
            if (chartConfig.redoRequest) {
                chartConfig.redoRequest = false;

                //redo req
                setFilters();

                // filters configs
                var questionStatisticsConfigs = {
                    filtersWithAgeGroup: vm.generalFilters
                };

                // CityCounty configs
                if(question.type === "cityCounty"){
                    questionStatisticsConfigs.cityCountyType = vm.chartConfigs[question.question_id].cityCountyType;
                    if(questionStatisticsConfigs.cityCountyType === 'division'){
                        questionStatisticsConfigs.divisionId = vm.chartConfigs[question.question_id].divisionId;
                    }
                }

                var req_id = AmCharts4Utils.determineChartRequestUrl(question, chartConfig, questionStatisticsConfigs);
                var promise = ChartsService.GetQuestionStatsPromise(vm.surveyId, question.question_id,vm.selectedLanguage, questionStatisticsConfigs);
                setFilters(question.question_id);
                questionStatisticsConfigs.filtersWithAgeGroup = vm.generalFilters;

                ChartsService.GetQuestionStats(vm.surveyId, req_id,vm.selectedLanguage, questionStatisticsConfigs,
                    function (response2) {
                        if(response2.data.labels1 && response2.data.labels2){
                            response2.data.labels1 = cleanStatsRefreshedData(response2.data.labels1);
                            if(chartConfig.crossWithId !== chartConfig.questionType !== "selects"){
                                response2.data.labels2 = cleanStatsRefreshedData(response2.data.labels2);
                            }
                            if(chartConfig.crossWithId){
                                var questionType = vm.questions.find(function(x) {
                                   return x.question_id === chartConfig.crossWithId
                                });
                                if(questionType){
                                    questionType = questionType.type;
                                    if(chartConfig.questionType !== "selects" && questionType !== 'combodate'){
                                        response2.data.labels2 = cleanStatsRefreshedData(response2.data.labels2);
                                    }
                                }
                            }
                        }
                        if(response2.data && (response2.data.labels1===undefined && response2.data.labels2===undefined)){
                            response2.data = cleanSelectDataKeys(response2.data);
                        }
                        if (question.type === "multipleChoice") {
                            promise.then(function (response) {
                                chartConfig.multipleQuestionBaseScale = response.data;
                                // chartConfig.type = "cluster";
                                vm.buildUtilDataAndMakeChart(response2.data, question);
                            }).catch(function (e) {
                                console.log(e); // "oh, no!"
                            })
                        }
                        else {
                            // chartConfig.type = "stack";

                            // cityCounty division type data parse
                            if(question.type === "cityCounty" && chartConfig.crossWithId && vm.chartConfigs[question.question_id].cityCountyType === 'division') {
                                var aux_labels = response2.data.labels1;
                                response2.data.labels1 = response2.data.labels2;
                                response2.data.labels2 = aux_labels;
                                response2.data.crossMatrix = AmCharts4Utils.transposeMatrix(response2.data.crossMatrix);
                            }
                            vm.buildUtilDataAndMakeChart(response2.data, question);
                        }
                    },
                    function (response2) {
                    }
                );
            }
            else {
                //modified config, same data provider
                buildChart(question.question_id);
            }
        };

        vm.sendAllChartsDetails = function() {
            vm.completed = false;
            var surveyData = [];
            var numQuestions = vm.questions.length;

            var surveyId = vm.surveyId + "";
            var domain = $window.location.hostname;
            var pptFileName = surveyId + "_" + domain + ".pptx";
            surveyData.push(surveyId);
            surveyData.push(domain);

            var questionsWithExportableCharts = [];
            for (var i = 0; i < numQuestions; i++) {
                var questionId = vm.questions[i].question_id + "";
                if (vm.charts[questionId] && vm.charts[questionId].exporting)
                    questionsWithExportableCharts.push(vm.questions[i]);
            }
            numQuestions = questionsWithExportableCharts.length;

            var pngImagesExported = 0;
            for (var i = 0; i < numQuestions; i++) {
                var currentQuestion = questionsWithExportableCharts[i];
                var currentQuestionId = currentQuestion.question_id+"";
                var currentQuestionDbID = currentQuestion.id;
                var titleAndAnswersCount = currentQuestionDbID + ". " + currentQuestion.question + "->" + currentQuestion.total;
                surveyData.push(titleAndAnswersCount);
                vm.charts[currentQuestionId].exporting.getImage("png").then(function(imgData) {
                    pngImagesExported++;
                    surveyData.push(imgData);
                    if (pngImagesExported === numQuestions) {
                        ChartsService.SendChartsDetails(surveyData,
                            function (response) {
                                vm.completed = true;
                                var pptFile = new Blob([response.data], {
                                    type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
                                });

                                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                    window.navigator.msSaveOrOpenBlob(pptFile, pptFileName);
                                }
                                else {
                                    var downloadLink = document.createElement("a");
                                    document.body.appendChild(downloadLink);
                                    downloadLink.style = "display: none";
                                    var fileURL = URL.createObjectURL(pptFile);
                                    downloadLink.href = fileURL;
                                    downloadLink.download = pptFileName;
                                    downloadLink.click();
                                    window.URL.revokeObjectURL(fileURL);
                                    downloadLink.remove();
                                }
                            },
                            function (response) {
                                vm.completed = true;
                                console.log(response);
                            });
                    }
                });
            }
        };

        vm.markFilterQuestionIfComboDate = function() {
            var item = vm.questions.find(function (question) {
                return question.type === 'combodate' && question.question_id == vm.filterQId;
            });
            vm.filterQuestionIsComboDate = !!item;
        };

        vm.filterChange = function () {
            vm.filterQuestionIsComboDate = false;

            if (!vm.filterQId) {
                vm.filterChoiceValue = null;
                vm.filterChoices = [];
            }
            vm.markFilterQuestionIfComboDate();
            if (!vm.filterQuestionIsComboDate) {
                ChartsService.GetQuestChoices(vm.filterQId, function (response) {
                    vm.filterChoices = [];
                    for (var key in response.data) {
                        if (response.data.hasOwnProperty(key)) {
                            vm.filterChoices.push({value: key, label: response.data[key]});
                        }
                    }
                    vm.filterChoiceValue = vm.filterChoices[0].value;
                });
            }
            else {
                vm.filterChoiceValue = "";
            }

        };

        vm.switchAnswers = function (qid1, qid2) {
            vm.switchLoading = true;
            ChartsService.SwitchAnswers(qid1, qid2,
                function (response) {
                    vm.switchAnswersDone = "SUCCESS. STATUS: " + response.data;
                    delete vm.switchLoading;
                    window.location.href = "/charts?qid=" + qid2;
                },
                function (response) {
                    vm.switchAnswersDone = "ERROR. STATUS: " + response.data;
                    delete vm.switchLoading;
                });
        };

        vm.clearSurveyCache = function() {
            var surveyQuestionIds = '(' + vm.questions.map(function (element) { return "\'chart_" + element.question_id + "\'"; }).toString() + ')';
            ChartsService.ClearSurveyCache(surveyQuestionIds,
                function (response) {
                    $mdDialog.show(
                        $mdDialog.alert()
                            .title('Success')
                            .textContent('The cache was cleared')
                            .ok('Close')
                    );
                },
                function (response) {
                    console.log(response);
                });
        };

        vm.goto = function (page) {
            $state.go(page);
        };

        function languagesMapper() {
            var languages = [];
            languages["English"] = "en";
            return languages;
        }

        vm.goToSpeedsters = function (qid) {
            $state.go("speedsters", {qid: qid});
        };

        (function initController() {
            vm.breadcrumbs = "Simple Dashboard";
            vm.questions = [];
            //fetch data from profile survey
            vm.questTestID = 2;
            vm.generalFilters = {
                filters: []
            };
            vm.quest = {};
            vm.selectedChartType = '';
            vm.isDataFetched = false;
            vm.questionText = '';

            initVars();

            getQuestions(null);
        })();

    }
})();
