(function () {
    'use strict';
    angular
        .module('questiaPlatformApp')
        .factory('ChartsJsonBuilder', ['$location', '$mdDialog', ChartsJsonBuilder]);

    function ChartsJsonBuilder($location, $mdDialog) {
        return {

            selectsChartBody : function(data,colors,xAxes,yAxes){
                return {
                    "data": data.dataP,
                    "colors": {
                        "list": colors
                    },
                    "legend": {
                        "visible" : data.options.showLegend
                    },
                    "xAxes":[xAxes],
                    "yAxes": [yAxes],
                    "series": data.graphsData
                }
            },

            /*STACKED CHART*/
            stackedXAxesHorizontal: function (data) {
                return {
                    "type": "ValueAxis",
                    "paddingRight": 50,
                    "renderer": {
                        "grid": {
                            "template": {
                                "opacity": 0
                            }
                        },
                        "ticks": {
                            "template": {
                                "strokeOpacity": 0.5,
                                "length": 10
                            }
                        },
                        "line": {
                            "strokeOpacity": 0.5
                        },
                        "baseGrid": {
                            "disabled": true
                        },
                        "minGridDistance": 40
                    },
                    "min": 0,
                    "max": data.options.scale || 100,
                    "numberFormatter": {
                        "numberFormat": "#.#'%'"
                    }
                }
            },

            stackedYAxesHorizontal : function(data){
                return {
                    "type": "CategoryAxis",
                    "dataFields": {
                        "category": data.title
                    },
                    "renderer": {
                        "grid": {
                            "template": {
                                "opacity": 0
                            }
                        },
                        "labels": {
                            "wrap": true,
                            "maxWidth": 500
                        }
                    }
                };
            },

            stackedXAxesVertical :   function(data){
                return {
                    "type": "CategoryAxis",
                    "dataFields": {
                        "category": data.title
                    },
                    "renderer": {
                        "grid": {
                            "template": {
                                "opacity": 0
                            }
                        },
                        "labels": {
                            "wrap": true,
                            "maxWidth": 250,
                            "maxHeight": 20
                        }
                    }
                }
            },

            stackedYAxesVertical :   function(data){
                return {
                    "type": "ValueAxis",
                    "renderer": {
                        "grid": {
                            "template": {
                                "opacity": 0
                            }
                        },
                        "ticks": {
                            "template": {
                                "strokeOpacity": 0.5,
                                "length": 10
                            }
                        },
                        "line": {
                            "strokeOpacity": 0.5
                        },
                        "baseGrid": {
                            "disabled": true
                        },
                        "minGridDistance": 20
                    },
                    "min": 0,
                    "max": data.options.scale || 100,
                    "strictMinMax" : true,
                    "numberFormatter": {
                        "numberFormat": "#.#'%'"
                    }
                };
            },

            /*CLUSTERED CHART*/
            clusterXAxesHorizontal : function(data) {
                return {
                    "type": "ValueAxis",
                    "paddingRight": 50,
                    "renderer": {
                    "minGridDistance": 40
                    },
                    "min": 0,
                    "max": data.options.scale || 100,
                    "strictMinMax" : true,
                    "numberFormatter": {
                        "numberFormat": "#.#'%'"
                    }
                }
            },

            clusterYAxesHorizontal : function (data) {
                return {
                    "type": "CategoryAxis",
                    "dataFields": {
                        "category": data.title
                    },
                    "renderer": {
                        "inversed" : true,
                        "cellStartLocation" : 0.1,
                        "cellEndLocation" : 0.9,
                        "grid": {
                            "template": {
                                "location": 0
                            }
                        },
                        "labels": {
                            "wrap": true,
                            "maxWidth": 500
                        }
                    }
                }
            },

            clusterXAxesVertical :   function (data) {

            },

            clusterYAxesVertical :   function (data){

            },

            /*BAR CHART*/

            barChartBody : function(dataProvider,xAxes, yAxes, dateField, toolTipText,color){
                return {
                    "data": dataProvider,
                    "paddingRight" : 50,
                    "xAxes":[xAxes],
                    "yAxes": [yAxes],
                    "series": [{
                        "type": "ColumnSeries",
                        "dataFields": dateField,
                        "tooltipText" : toolTipText,
                        "columns" : {
                            "template" : {
                                "strokeOpacity" : 0,
                                "fillOpacity" : 0.5,
                                "adapter": {
                                    "fill": function(fill, target) {
                                        return color;
                                    }
                                }
                            }
                        },
                        "bullets": [{
                            "type": "LabelBullet",
                            "label":{
                                "text" : "{valueX.value}"
                            },
                            "dx" : 30
                        }]
                    }],
                    "cursor":{
                        "type": "XYCursor"
                    }
                }
            },

            barXAxesVertical : function(title) {
                return {
                    "type": "CategoryAxis",
                    "dataFields": {
                        "category": title
                    },
                    "renderer": {
                        "grid": {
                            "template": {
                                "location": 0
                            }
                        },
                        "labels": {
                            "wrap": true,
                            "maxWidth": 300
                        }
                    }
                }
            },

            barYAxesVertical : function(options){
                return {
                    "type": "ValueAxis",
                    "dataFields": {
                        "category": "value"
                    },
                    "renderer":{
                        "grid":{
                            "template":{
                                "location": 0
                            }
                        },
                        "minGridDistance": 40
                    },
                    "min" : 0,
                    "max" : options.scale || 100,
                    "strictMinMax" : true,
                    "numberFormatter": {
                        "numberFormat": "#.#'%'"
                    }
                }
            },

            barXAxesHorizontal : function(options){
                return {
                    "type": "ValueAxis",
                    "dataFields": {
                        "category": "value"
                    },
                    "renderer":{
                        "grid":{
                            "template":{
                                "location": 0
                            }
                        },
                        "minGridDistance": 40
                    },
                    "min" : 0,
                    "max" : options.scale || 100,
                    "strictMinMax" : true,
                    "numberFormatter": {
                        "numberFormat": "#.#'%'"
                    }
                }
            },

            barYAxesHorizontal : function(title){
                return {
                    "type": "CategoryAxis",
                    "dataFields": {
                        "category": title
                    },
                    "renderer":{
                        "grid":{
                            "template":{
                                "location": 0
                            }
                        },
                        "inversed" : true,
                        "labels": {
                            "wrap": true,
                            "maxWidth": 500
                        }
                    }
                }
            },

            /*RADAR CHART*/
            radarChartBody : function(dataProvider,title,options){
                return {
                    "data": dataProvider,
                    "xAxes": [{
                        "type": "CategoryAxis",
                        "dataFields": {
                            "category": title
                        }
                    }],
                    "yAxes": [{
                        "type": "ValueAxis",
                        "numberFormatter": {
                            "numberFormat": "#.#'%'"
                        },
                        "min" : 0,
                        "max" : options.scale || 100
                    }],
                    "series": [{
                        "type": "RadarSeries",
                        "name": "Sales",
                        "dataFields": {
                            "valueY": "value",
                            "categoryX": title
                        }
                    }]
                }
            }

        };
    }
})();

