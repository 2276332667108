(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('userInfoService', userInfoService);

	userInfoService.$inject = ['$http'];
	function userInfoService($http) {
		var service = {};

		service.getUserInfo = getUserInfo;
		service.getUserQuests = getUserQuests;
		service.getUserTicketHistory = getUserTicketHistory;
		service.getUserIncentives = getUserIncentives;
		return service;

		function getUserInfo(userId, okCallback, koCallback) {
			$http({
				url: "/rest/users/" + userId,
				type: 'get'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function getUserQuests(userId, okCallback, koCallback) {
			$http({
				url: "/rest/quests/answers/user/" + userId,
				type: 'get'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function getUserTicketHistory(userId, okCallback, koCallback) {
            $http({
                url: "/rest/quests/tickethistory/user/" + userId,
                type: 'get'
            }).then(function successCallback(response) {
                okCallback && okCallback(response);
            }, function errorCallback(response) {
                koCallback && koCallback(response);
            });
		}

		function getUserIncentives(userId, okCallback, koCallback) {
			$http({
				url: "/rest/users/incentives/" + userId,
				type: 'get'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}
	}

})();