(function () {
    'use strict';

    angular
        .module('questiaPlatformApp').directive('importQuestionsDirective', function () {
        return {
            restrict: 'E',
            scope: {
                successFunction: '&',
                errorFunction: '&'
            },
            templateUrl: 'js/ng-templates/subviews/directives/importquestionsdirective.view.html',
            controllerAs: 'vm',
            controller: ['$scope','$mdDialog', function ($scope, $mdDialog) {
                var vm = this;
                vm.successFunction = $scope.successFunction;
                vm.errorFunction = $scope.errorFunction;

                vm.addQuestions = function () {
                    $mdDialog.show({
                        templateUrl: 'js/ng-templates/subviews/directives/importquestionsdialog.subview.html',
                        controller: AddQuestionsController
                    }).then(
                        function () {
                            if(vm.successFunction() instanceof Function) vm.successFunction()();
                        },
                        function () {
                            if(vm.errorFunction() instanceof Function) vm.errorFunction()();
                        });
                };

                function AddQuestionsController($scope, $mdDialog, $timeout, WidgetsService) {
                    $scope.importResult = undefined;
                    $scope.excelFile = undefined;

                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };

                    $scope.save = function () {
                        WidgetsService.ImportExcel(
                            $scope.excelFile,
                            function success(response) {
                                $scope.importResult = {
                                    message: "Import successful!"
                                };
                                $timeout( function(){
                                    $mdDialog.cancel();
                                }, 2000);
                            },
                            function error(response) {
                                $scope.importResult = {
                                    message: "Import failed!",
                                    cause: response.data
                                };
                                $timeout( function(){
                                    $mdDialog.cancel();
                                }, 2000);
                            }
                        )
                    }

                    $scope.changeExcelFile = function() {
                        $scope.importResult = undefined;
                    }
                }
            }]
        };
    })

})();