(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .controller('DemographicsController', DemographicsController);

    DemographicsController.$inject = ['$rootScope', '$q', 'DemographicsService', 'RegionsService'];
    function DemographicsController($rootScope, $q, DemographicsService, RegionsService) {
        var vm = this;

        vm.adjustRespondersParameters = function () {
            if (typeof vm.surveyId === 'undefined' || vm.surveyId === null) vm.surveyId = undefined;
            if (typeof vm.respondersXDays === 'undefined' || vm.respondersXDays === null) vm.respondersXDays = 0;
            if (typeof vm.loggedInXDays === 'undefined' || vm.loggedInXDays === null) vm.loggedInXDays = 0;
        }

        vm.getStats = function () {
            vm.genderAndAgeGroupsLoading = true;
            vm.divisionAndGendersLoading = true;
            vm.divisionAndAgeGroupsLoading = true;
            vm.divisionAgeGroupsAndGendersLoading = true;

            vm.isCitySelected = (vm.division_id === vm.cityDivisionId);
            vm.divisionAndGendersHeader = vm.isCitySelected ? vm.citiesAndGendersHeader : vm.regionsAndGendersHeader;
            vm.divisionAndAgeGroupsHeader = vm.isCitySelected ? vm.citiesAndAgeGroupsHeader : vm.regionsAndAgeGroupsHeader;

            vm.adjustRespondersParameters();

            let divisionId = vm.isCitySelected ? undefined : vm.division_id;
            DemographicsService.getDemographicsByGendersAndAgeGroups(divisionId, vm.surveyId, vm.respondersXDays, vm.loggedInXDays,
                function successCallback(response) {
                    vm.genderAndAgeGroupsUIData = response.data;
                    vm.genderAndAgeGroupsLoading = false;

                    vm.getDivisionAndGendersDemographics();
                    vm.getDivisionAndAgeGroupsDemographics();
                    vm.getDivisionAgeGroupsAndGendersDemographics();
                },
                function errorCallback() {
                    vm.genderAndAgeGroupsLoading = false;
                    vm.divisionAndGendersLoading = false;
                    vm.divisionAndAgeGroupsLoading = false;
                    vm.divisionAgeGroupsAndGendersLoading = false;
                }
            );
        };

        vm.getDivisionAndGendersDemographics = function() {
            let successCallback = function(response) {
                if (response.data.logged_null_asl) {
                    vm.loggedInXDaysLabel = vm.loggedInXDays;
                    vm.loggedInXDaysDetail = response.data.logged_null_asl;
                }

                vm.divisionAndGendersUIData = response.data.division_details;
                vm.divisionAndGendersLoading = false;
            };

            let errorCallback = function(response) {
                console.log(response);
                vm.divisionAndGendersLoading = false;
            };

            if (vm.isCitySelected) {
                DemographicsService.getDemographicsByCitiesAndGenders(vm.surveyId, vm.respondersXDays, vm.loggedInXDays,
                    successCallback, errorCallback);
            }
            else {
                DemographicsService.getDemographicsByRegionsAndGenders(vm.division_id, vm.surveyId, vm.respondersXDays, vm.loggedInXDays,
                    successCallback, errorCallback);
            }
        };

        vm.getDivisionAndAgeGroupsDemographics = function() {
            let successCallback = function(response) {
                vm.divisionAndAgeGroupsUIData = response.data;
                vm.divisionAndAgeGroupsLoading = false;
            };

            let errorCallback = function(response) {
                console.log(response);
                vm.divisionAndAgeGroupsLoading = false;
            };

            if (vm.isCitySelected) {
                DemographicsService.getDemographicsByCitiesAndAgeGroups(vm.surveyId, vm.respondersXDays, vm.loggedInXDays,
                    successCallback,
                    errorCallback
                );
            }
            else {
                DemographicsService.getDemographicsByRegionsAndAgeGroups(vm.division_id, vm.surveyId, vm.respondersXDays, vm.loggedInXDays,
                    successCallback,
                    errorCallback
                );
            }
        };

        vm.getDivisionAgeGroupsAndGendersDemographics = function() {
            let successCallback = function(response) {
                vm.divisionAgeGroupsAndGendersUIData = response.data[0];
                vm.divisionDemographicsTotals = response.data[1];
                vm.divisionAgeGroupsAndGendersLoading = false;
            };

            let errorCallback = function(response) {
                console.log(response);
                vm.divisionAgeGroupsAndGendersLoading = false;
            };

            if (vm.isCitySelected) {
                DemographicsService.getDemographicsByCitiesAgeGroupsAndGenders(vm.surveyId, vm.respondersXDays, vm.loggedInXDays,
                    successCallback,
                    errorCallback
                );
            }
            else {
                DemographicsService.getDemographicsByRegionsAgeGroupsAndGenders(vm.division_id, vm.surveyId, vm.respondersXDays, vm.loggedInXDays,
                    successCallback,
                    errorCallback
                )
            }
        };

        vm.getDivisionsList = function (){
            RegionsService.getDivisionsList().then(function (response) {
                vm.divisions = response.data;
                if (vm.divisions) {
                    vm.cityDivisionId = vm.divisions[vm.divisions.length - 1].division_id + 1;
                    vm.division_id = vm.divisions[0].division_id;
                    vm.divisions.push({division_id: vm.cityDivisionId, division_name: "City"});
                    vm.getStats();
                }
            });
        };

        vm.initVariables = function(){
            vm.division_id = "";
            vm.loggedInXDaysLabel = 0;
            vm.respondersXDays = 0;
            vm.loggedInXDays = 0;
            vm.isCitySelected = false;

            vm.regionsAndGendersHeader = ["Region Name", "Females", "%", "Males", "%", "Total"];
            vm.citiesAndGendersHeader = ["City Name", "County Name", "Females", "%", "Males", "%", "Total"];

            DemographicsService.getAgeGroups(
                function successCallback(response) {
                    vm.ageGroups = response.data;
                    vm.genderOffset = vm.ageGroups.length * 2 - 1;
                    vm.ageGroupsFormatted = vm.ageGroups.reduce((acc, ageGroup) => acc.concat(ageGroup, "%"), []);

                    vm.gendersAndAgeGroupsHeader = ["Gender"].concat([...vm.ageGroupsFormatted], "TOTAL");
                    vm.regionsAndAgeGroupsHeader = ["Region Name"].concat([...vm.ageGroupsFormatted], "Total");
                    vm.citiesAndAgeGroupsHeader = ["City Name", "County Name"].concat([...vm.ageGroupsFormatted], "Total");

                    vm.getDivisionsList();
                },
                function errorCallback(response) {
                    console.log(response);
                }
            );
        };

        vm.createGendersAndAgeGroupsCSVData = function(gendersAndAgeGroupsResponseData) {
            return gendersAndAgeGroupsResponseData.map(function (dataEntry) {
                let csvRow = dataEntry.statsItems.reduce((acc, statItem) => acc.concat(statItem.count, statItem.percent), []);
                csvRow.unshift(dataEntry.splitName);
                csvRow.push(dataEntry.usersTotal);
                return csvRow;
            });
        };

        vm.createDivisionAndGendersCSVData = function(divisionAndGendersResponseData) {
            if (vm.isCitySelected)
                return divisionAndGendersResponseData;

            let divisionAndAgeGendersResponseDataCopy = [...divisionAndGendersResponseData];
            divisionAndAgeGendersResponseDataCopy.forEach(dataEntry => {
                delete dataEntry.parentLocationName;
            });

            return divisionAndAgeGendersResponseDataCopy;
        };

        vm.createDivisionAndAgeGroupsCSVData = function(divisionAndAgeGroupsResponseData) {
            return divisionAndAgeGroupsResponseData.map(function (dataEntry) {
                let csvRow = dataEntry.statsItems.reduce((acc, statItem) => acc.concat(statItem.count, statItem.percent), []);
                if (vm.isCitySelected)
                    csvRow.unshift(dataEntry.additionalSplitName);
                csvRow.unshift(dataEntry.splitName);
                csvRow.push(dataEntry.usersTotal);
                return csvRow;
            });
        };

        vm.createDivisionAgeGroupsAndGendersCSVData = function(divisionAgeGroupsAndGenderData, divisionAgeGroupsAndGendersDemographicsTotals) {
            var records = [];
            var tableHeader = {
                males : new Array(vm.isCitySelected ? 3 : 2).concat(['Males']),
                females : new Array(vm.genderOffset).concat(['Females'])
            };
            records.push(tableHeader);

            let tableSubHeader;
            if (vm.isCitySelected) {
                tableSubHeader = {
                    firstCell: 'City',
                    secondCell: 'County Name',
                    thirdCell: 'Rural/Urban',
                    ageGroups : vm.ageGroupsFormatted.concat(vm.ageGroupsFormatted).concat(['Total'])
                }
            }
            else {
                tableSubHeader = {
                    firstCell: 'Region',
                    secondCell: 'Rural/Urban',
                    ageGroups : vm.ageGroupsFormatted.concat(vm.ageGroupsFormatted).concat(['Total'])
                }
            }
            records.push(tableSubHeader);

            let dataRecords = [];
            if (vm.isCitySelected) {
                dataRecords = divisionAgeGroupsAndGenderData.map(cityDataEntry => {
                    let cityMaleValues = cityDataEntry.males.map(item => [item.count, item.percent]);
                    let cityFemaleValues = cityDataEntry.females.map(item => [item.count, item.percent]);
                    let cityValues = cityMaleValues.concat(cityFemaleValues);

                    return {
                        cityName: cityDataEntry.cityName,
                        countyName: cityDataEntry.countyName,
                        ruralUrban: cityDataEntry.ruralUrban,
                        cityValues: cityValues,
                        total: cityDataEntry.usersTotal
                    }
                });
            }
            else {
                divisionAgeGroupsAndGenderData.forEach(regionDataEntry => {
                    let regionUrbanValues = [], regionUrbanTotal = "";
                    if (regionDataEntry.urban !== null) {
                        var regionUrbanMalesValues = regionDataEntry.urban.males.map(item => [item.count, item.percent]);
                        var regionUrbanFemalesValues = regionDataEntry.urban.females.map(item => [item.count, item.percent]);
                        regionUrbanValues = regionUrbanMalesValues.concat(regionUrbanFemalesValues);

                        if (regionDataEntry.urban.total !== null)
                            regionUrbanTotal = regionDataEntry.urban.total;
                    }
                    let recordUrban = {
                        regionName: regionDataEntry.regionName,
                        regionType: 'urban',
                        regionUrbanValues : regionUrbanValues,
                        total: regionUrbanTotal
                    };

                    let regionRuralValues = [], regionRuralTotal = "";
                    if (regionDataEntry.rural !== null) {
                        var regionRuralMalesValues = regionDataEntry.rural.males.map(item => [item.count, item.percent]);
                        var regionRuralFemalesValues = regionDataEntry.rural.females.map(item => [item.count, item.percent]);
                        regionRuralValues = regionRuralMalesValues.concat(regionRuralFemalesValues);

                        if (regionDataEntry.rural.total !== null)
                            regionRuralTotal = regionDataEntry.rural.total;
                    }
                    let recordRural = {
                        regionName: regionDataEntry.regionName,
                        regionType: 'rural',
                        regionRuralValues: regionRuralValues,
                        total: regionRuralTotal
                    };

                    dataRecords.push(recordUrban);
                    dataRecords.push(recordRural);
                });
            }

            records = records.concat(dataRecords);

            let urbanTotalsFormatted = divisionAgeGroupsAndGendersDemographicsTotals.malesUrbanTotals
                .concat(divisionAgeGroupsAndGendersDemographicsTotals.femalesUrbanTotals)
                .concat([divisionAgeGroupsAndGendersDemographicsTotals.totalUrban])
                .reduce((acc, elem) => acc.concat(elem," "), []);

            let ruralTotalsFormatted = divisionAgeGroupsAndGendersDemographicsTotals.malesRuralTotals
                .concat(divisionAgeGroupsAndGendersDemographicsTotals.femalesRuralTotals)
                .concat([divisionAgeGroupsAndGendersDemographicsTotals.totalRural])
                .reduce((acc, elem)=> acc.concat(elem," "),[])

            if (vm.isCitySelected) {
                records.push({
                    totalKey : "Total",
                    totalSpace: "",
                    region : 'urban',
                    totalUrban : urbanTotalsFormatted
                });

                records.push({
                    totalKey : "",
                    totalSpace: "",
                    region : 'rural',
                    totalRural : ruralTotalsFormatted
                });
            }
            else {
                records.push({
                    totalKey : "Total",
                    region : 'urban',
                    totalUrban : urbanTotalsFormatted
                });

                records.push({
                    totalKey : "",
                    region : 'rural',
                    totalRural : ruralTotalsFormatted
                });
            }

            return records;
        };

        vm.getGendersAndAgeGroupsCSV = function() {
            vm.genderAndAgeGroupsLoading = true;
            var deferme = $q.defer();
            deferme.resolve(vm.createGendersAndAgeGroupsCSVData(vm.genderAndAgeGroupsUIData));
            vm.genderAndAgeGroupsLoading = false;
            return deferme.promise;
        };

        vm.getDivisionAndGendersCSV = function() {
            vm.divisionAndGendersLoading = true;
            var deferme = $q.defer();
            deferme.resolve(vm.createDivisionAndGendersCSVData(vm.divisionAndGendersUIData));
            vm.divisionAndGendersLoading = false;
            return deferme.promise;
        };

        vm.getDivisionAndAgeGroupsCSV = function() {
            vm.divisionAndAgeGroupsLoading = true;
            var deferme = $q.defer();
            deferme.resolve(vm.createDivisionAndAgeGroupsCSVData(vm.divisionAndAgeGroupsUIData));
            vm.divisionAndAgeGroupsLoading = false;
            return deferme.promise;
        };

        vm.getDivisionAgeGroupsAndGendersCSV = function() {
            vm.divisionAgeGroupsAndGendersLoading = true;
            var deferme = $q.defer();
            deferme.resolve(vm.createDivisionAgeGroupsAndGendersCSVData(vm.divisionAgeGroupsAndGendersUIData, vm.divisionDemographicsTotals));
            vm.divisionAgeGroupsAndGendersLoading = false;
            return deferme.promise;
        };

        (function initController() {
            vm.breadcrumbs = "Users: Demographics";
            vm.activeSubmenu = 'users';

            vm.initVariables();
        })()
    }

})();