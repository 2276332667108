(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .controller('SpeedstersController', SpeedstersController);

    SpeedstersController.$inject = ['$rootScope', '$stateParams', 'SpeedstersService', '$mdDialog', 'NgTableParams'];

    function SpeedstersController($rootScope, $stateParams, SpeedstersService, $mdDialog, NgTableParams) {
        var vm = this;

        vm.markAllAsSpeedstersOrCompleted = function (userType) {
            vm.tableActionsDisabled = true;

            var dialogConfig = {};

            if (userType === 'speedster') {
                dialogConfig = {
                    title: "Mark All As Speedsters",
                    textContent: "Are you sure that you want to mark all possible speedsters as speedsters?"
                };
            } else if (userType === 'completed') {
                dialogConfig = {
                    title: "Mark All As Completed",
                    textContent: "Are you sure that you want to mark all possible speedsters as completed?"
                };
            }

            var confirm = $mdDialog.confirm()
                .title(dialogConfig.title)
                .textContent(dialogConfig.textContent)
                .cancel("No")
                .ok("Yes");

            $mdDialog.show(confirm).then(function () {
                SpeedstersService.toggleAllSpeedstersForSurvey(
                    vm.surveyId,
                    userType,
                    function success(response) {
                        if (userType === 'speedster') {
                            $mdDialog.show(
                                $mdDialog.alert()
                                    .title('Success')
                                    .textContent('Successfully marked all entries as speedsters, god bless technology and devs, don\'t forget the devs!')
                                    .ok('Close')
                            );
                        } else if (userType === 'completed') {
                            $mdDialog.show(
                                $mdDialog.alert()
                                    .title('Success')
                                    .textContent('Successfully marked all entries as completed, god bless technology and devs, don\'t forget the devs!')
                                    .ok('Close')
                            );
                        }
                        vm.reloadSpeedstersList(vm.surveyId);
                        vm.tableActionsDisabled = false;
                    },
                    function error(response) {
                        $mdDialog.show(
                            $mdDialog.alert()
                                .title('Failure')
                                .textContent('Failed to mark all entries as speedsters!')
                                .ok('Close')
                        );
                        vm.tableActionsDisabled = false;
                    }
                );
            }, function() {
                vm.tableActionsDisabled = false;
            });
        };

        vm.toggleUserTypeForQat = function (qatId) {
            vm.tableActionsDisabled = true;
            SpeedstersService.toggleSingleSpeedster(
                qatId,
                function success(response) {
                    var currentQatIndex = vm.speedstersDataset.findIndex(element => element.time_record_id === response.data.time_record_id);
                    vm.speedstersList[currentQatIndex] = response.data;
                    var updatedDatasetEntry = vm.createDatasetEntryFromQat(response.data);
                    vm.speedstersDataset.splice(currentQatIndex, 1, updatedDatasetEntry);
                    vm.initialSettings.dataset = vm.speedstersDataset;
                    vm.tableParams.reload();
                    vm.tableActionsDisabled = false;
                },
                function error(response) {
                    $mdDialog.show(
                        $mdDialog.alert()
                            .title('Failure')
                            .textContent('Failed to toggle user type (speedster/completed)!')
                            .ok('Close')
                    );
                    vm.tableActionsDisabled = false;
                }
            );
        };

        vm.createDatasetEntryFromQat = function(qat) {
            return {
                time_record_id: qat.time_record_id,
                user: qat.user,
                time_spent: qat.time_spent,
                user_type: qat.user_type
            }
        };

        vm.reloadSpeedstersList = function (surveyId) {
            vm.speedstersList = [];
            SpeedstersService.getAllSpeedstersForSurvey(surveyId,
                function success(response) {
                    vm.speedstersList = response.data;
                    vm.speedstersDataset = [];
                    angular.forEach(vm.speedstersList, function (speedster) {
                        vm.speedstersDataset.push(vm.createDatasetEntryFromQat(speedster))
                    });

                    vm.initialSettings.dataset = vm.speedstersDataset;
                    vm.tableParams = new NgTableParams(vm.initialParams, vm.initialSettings);
                    vm.getMedianTimeForSurvey(surveyId);
                },
                function error(response) {
                    $mdDialog.show(
                        $mdDialog.alert()
                            .title('Failure')
                            .textContent('Could not get speedsters list for survey ' + surveyId + '!')
                            .ok('Close')
                    );
                }
            )
        };

        vm.getMedianTimeForSurvey = function(surveyId){
            SpeedstersService.getMedianTimeForSurvey(
                surveyId,
                function success(response) {
                    vm.surveyMedianTime = Math.round(response.data);
                },
                function error(response) {
                    $mdDialog.show(
                        $mdDialog.alert()
                            .title('Failure')
                            .textContent('Failed to get median time for survey ' + surveyId + '!')
                            .ok('Close')
                    );
                }
            )
        };

        vm.getQuestState = function (surveyId) {
            SpeedstersService.getSurveyState(
                surveyId,
                function success(response) {
                    vm.tableActionsDisabled = response.data;
                    vm.surveyState = response.data;
                },
                function error(response) {
                    $mdDialog.show(
                        $mdDialog.alert()
                            .title('Failure')
                            .textContent('Failed to get quest state for survey ' + surveyId + '!')
                            .ok('Close')
                    );
                }
            );
        };

        (function initController() {
            vm.activeSubmenu = 'speedsters';
            vm.breadcrumbs = 'Speedsters for survey ' + $stateParams.qid;

            vm.surveyId = $stateParams.qid;
            vm.speedstersList = [];

            vm.initialParams = {
                // initial page size
                count: 20
            };
            vm.initialSettings = {
                // page size buttons (right set of buttons in demo)
                counts: [],
                // determines the pager buttons (left set of buttons in demo)ss
                paginationMaxBlocks: 20,
                paginationMinBlocks: 2,
                dataset: []
            };
            vm.tableActionsDisabled = false;
            vm.surveyState = false;

            if (vm.surveyId) {
                vm.reloadSpeedstersList(vm.surveyId);
                vm.getMedianTimeForSurvey(vm.surveyId);
                vm.getQuestState(vm.surveyId)
            } else {
                vm.speedstersList = [];
            }
        })();

    }
})();
