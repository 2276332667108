(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('SurveyQuotaUtils', ['$location', SurveyQuotaUtils]);

	function SurveyQuotaUtils($location) {

		return {
			getLabelsForQuotaType: function (quotas, type) {
				return quotas.filter(function (key) {
					return key.type === type;
				})[0];
			},

			calculatePercentagePerGroup: function (group) {
				var sum = 0;
				if (group.split) {
					angular.forEach(group.split.quotas, function (labelValue, labelKey) {
						angular.forEach(labelValue, function (splitLabelValue, splitLabelKey) {
							sum += splitLabelValue;
						});
					});
				}
				else {
					angular.forEach(group.quotas, function (splitLabelValue, splitLabelKey) {
						sum += splitLabelValue;
					});
				}
				group.totalSum = parseFloat(sum.toFixed(2));
			},

			uiToServerQuotas: function (uiQuotas) {
				if (!uiQuotas || uiQuotas.length === 0) {
					return [];
				}
				var result = [];
				for (var i = 0; i < uiQuotas.length; i++) {
					var obj = uiQuotas[i];
					if (!obj.split) {
						angular.forEach(obj.labels, function (labelValue, labelKey) {
							result.push({
								main_label_id: labelKey,
								split_label_id: null,
								percent: obj.quotas[labelKey],
								percent_deviation:obj.deviation
							});
						});
					}
					else {
						var quotas = obj.split.quotas;
						angular.forEach(obj.labels, function (labelValue, labelKey) {
							angular.forEach(obj.split.labels, function (splitLabelValue, splitLabelKey) {
								result.push({
									main_label_id: labelKey,
									split_label_id: splitLabelKey,
									percent: quotas[labelKey][splitLabelKey],
									percent_deviation:obj.deviation
								});
							});
						});
					}
				}
				return result;
			},

			serverToUiAllQuotas: function (serverQuotas) {
				if (!serverQuotas || serverQuotas.length === 0) {
					return [];
				}
				var quotas = [];
				angular.forEach(serverQuotas, function (value, key) {
					var labelsMap = {};
					for(var each in value){
						labelsMap[value[each].labelId] = value[each].label;
					}
					quotas.push({
						type: key,
						labels: labelsMap
					});
				});
				return quotas;
			},

			newServerToUiQuotas: function (serverQuotas, allQuotas) {
				function getQuotaTypeObj(label_id) {
					return allQuotas.filter(function (obj) {
						return obj.labels.hasOwnProperty(label_id);
					})[0];
				}

				if (!serverQuotas || serverQuotas.length === 0) {
					return {};
				}

				var quotaGroups = {};

				for (var i = 0; i < serverQuotas.length; i++) {
					var quota = serverQuotas[i];
					var quotaTypeObj = getQuotaTypeObj(quota.main_label_id);

					if (!quotaGroups[quotaTypeObj.type]) {
						quotaGroups[quotaTypeObj.type] = angular.copy(quotaTypeObj);
						quotaGroups[quotaTypeObj.type].quotas = {};
					}
					quotaGroups[quotaTypeObj.type].deviation = quota.percent_deviation;
					if (quota.split_label_id) {
						var splitQuotaTypeObj = getQuotaTypeObj(quota.split_label_id);
						delete quotaGroups[quotaTypeObj.type].quotas;

						quotaGroups[quotaTypeObj.type].splitType = splitQuotaTypeObj.type;
						if (!quotaGroups[quotaTypeObj.type].split) {
							quotaGroups[quotaTypeObj.type].split = angular.copy(splitQuotaTypeObj);
							quotaGroups[quotaTypeObj.type].split.quotas = {};
						}
						if (!quotaGroups[quotaTypeObj.type].split.quotas[quota.main_label_id]) {
							quotaGroups[quotaTypeObj.type].split.quotas[quota.main_label_id] = {};
						}
						quotaGroups[quotaTypeObj.type].split.quotas[quota.main_label_id][quota.split_label_id] = quota.percent;
					}
					else {
						quotaGroups[quotaTypeObj.type].quotas[quota.main_label_id] = quota.percent;
					}
				}

				return Object.values(quotaGroups);
			}

		};
	}
})();

