(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('ChartsService', ChartsService);

	ChartsService.$inject = ['$http', '$rootScope'];
	function ChartsService($http, $rootScope) {
		var service = {};

		service.GetQuestions = function (surveyId, auth, okCallback, koCallback) {
			$http.get("https://" + $rootScope.globals.panelHostname + "/rest/quest/" + surveyId + auth)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

		service.GetQuestion = function (questionId,okCallback, koCallback) {
			$http.get("/rest/quests/question/" + questionId)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};


		service.GetQuestionStatsPromise = function (questId, questionId,toLanguage, questionStatisticsConfigs) {

			var url = "/rest/quests/stats/" + questionId + "?questId=" + questId;

            if(toLanguage){
                url += "&toLanguage=" + toLanguage
            }

			return $http.post(url, questionStatisticsConfigs)
		};

		service.GetQuestionStats = function (questId, questionId, toLanguage, withWeighting, questionStatisticsConfigs, okCallback, koCallback) {
			var url = "/rest/quests/stats/" + questionId + "?questId=" + questId;
            if(toLanguage){
                url += "&toLanguage=" + toLanguage;
            }
			if(withWeighting){
				url += "&withWeighting=" + withWeighting;
			}
			$http.post(url, questionStatisticsConfigs)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

		service.getQuestionThreeDotsChoiceWordCloudStats = function(questionId, okCallback, koCallback) {
			var url = "/rest/quests/stats/other/" + questionId;
			$http.post(url)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

		service.GetQuestChoices = function (qId, queryParams, okCallback, koCallback) {
			$http.get("/rest/quests/choices/" + qId, {
				params: queryParams
			})
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

		service.GetSelectsForCross = function (questionId,toLanguage, okCallback, koCallback) {

			var url = "/rest/quests/details/" + questionId;

			if(toLanguage){
				url+="?toLanguage=" + toLanguage;
			}

			$http.get(url)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

		service.SwitchAnswers = function (qid1, qid2, okCallback, koCallback) {
			$http.post("/rest/quests/switchAnswers/" + qid1 + "/" + qid2)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

        service.ExportChartsPpt = function(templateName,chartsPayload, okCallback, koCallback) {
            $http.post("/rest/quests/chartsPpt/" + templateName, chartsPayload, {responseType: 'arraybuffer'})
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        service.ClearSurveyCache = function (surveyQuestionIds, okCallback, koCallback) {
        	$http.post("/rest/quests/clearCache/" , surveyQuestionIds)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

        service.GetColorTemplates = function (okCallback, koCallback){
			$http.get("/rest/config/colorTemplates/")
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

		return service;
	}

})();
