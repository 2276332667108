(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.controller('ForecastController', ForecastController);

	ForecastController.$inject = ['$rootScope', 'ForecastService', 'NgTableParams', '$state', '$window'];
	function ForecastController($rootScope, ForecastService, NgTableParams, $state, $window) {
		var vm = this;

		function getCount(ranks) {
			var total = 0;

			for (var key in ranks){
				total += ranks[key];
			}

			return total;
		}

		function getPercentages(ranks) {
			var result = {};

			for (var key in ranks){
				result[key] = {};
				result[key]["count"] = ranks[key];
				result[key]["percentage"] = (100/getCount(ranks)*ranks[key]).toFixed(1);
			}

			return result;
		}

		vm.getUserRanksCount = function () {
			ForecastService.GetUserRanksCount(function (response) {
				vm.ranks = getPercentages(response.data);

			}, function (response) {
				console.log(response);
			});
		};

		(function initController() {
			vm.breadcrumbs = "Predictor: Events";
			vm.activeSubmenu = 'predictor';

			vm.getUserRanksCount();
		})();

		vm.forecastId = null;
		vm.allForecastParams = {
			url: "/rest/users/" + $rootScope.globals.userId + "/quests/forecast"
		};
		vm.getAllForecasts = function (allforecastparams) {
			ForecastService.getAllForecasts(allforecastparams,
				function (response) {
					vm.forecasts = response;
					vm.tableDataset = [];
					vm.totalPredictions = vm.totalHistoryPredictions = 0;
					angular.forEach(vm.forecasts, function (value, key) {
						vm.tableDataset.unshift({
							"id": key,
							"title": value.description,
							"category": value.title.substring(9),
							"start_date": value.start_date,
							"end_date": value.end_date,
							"active": value.active
						});
					});
					var initialParams = {
						count: 13 // initial page size
					};
					var initialSettings = {
						// page size buttons (right set of buttons in demo)
						counts: [],
						// determines the pager buttons (left set of buttons in demo)
						paginationMaxBlocks: 13,
						paginationMinBlocks: 2,
						dataset: vm.tableDataset
					};
					vm.tableParams = new NgTableParams(initialParams, initialSettings);

					ForecastService.getQuestionsAnswers(
						function (response) {
							angular.forEach(vm.forecasts, function (value, key) {
								if (response.data[key]) {
									vm.totalPredictions += response.data[key][0];
									vm.totalHistoryPredictions += response.data[key][1];
									var forecast = vm.tableDataset.filter(function (forecast) {
										return forecast.id == key;
									})[0];
									forecast["answers"] = response.data[key] ? response.data[key][0] : 0;
									forecast["totalAnswers"] = response.data[key] ? (response.data[key][1]) : 0;
									forecast["updatedPredictions"] = response.data[key] ? (response.data[key][1] - response.data[key][0]) : 0;
								}
							});
						},
						function (response) {

						});
				},
				function (response) {
					console.log(response);
				}
			);
		};
		vm.getAllForecasts(vm.allForecastParams);

		vm.viewForecastHistory = function (forecastId) {
			var url = $state.href("forecastHistory", {qid: forecastId}, {absolute: true});
			window.open(url,'_blank');
		};

		vm.goToStats = function () {
			$state.go("forecastStatistics");
		};

		vm.goToEdit = function (qid) {
			$state.go("survey", {qid: qid});
		};

		vm.goToForecastEventStats = function (qid) {
			$state.go("forecastEventStats", {qid: qid});
		};

		vm.dateOpts = {
			dateFormat: 'Y-m-d H:i:s',
			enableTime: true,
			defaultDate: new Date(),
			// create an extra input solely for display purposes
			altInput: true,
			altFormat: "F j, Y h:i K"
		};
	}

})();