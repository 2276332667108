(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .controller('RafflePrizesController', RafflePrizesController);

    RafflePrizesController.$inject = ['$state', '$rootScope','$scope','IncentivesService', 'LanguagesService', '$mdDialog', 'NgTableParams', 'SnackbarUtils'];
    function RafflePrizesController($state, $rootScope, $scope, IncentivesService, LanguagesService, $mdDialog, NgTableParams, SnackbarUtils) {

        var vm = this;

        vm.initData = function(){
            vm.rafflePrizesList = [];
            vm.initialSettings = {
                // page size buttons (right set of buttons in demo)
                counts: [],
                // determines the pager buttons (left set of buttons in demo)ss
                paginationMaxBlocks: 20,
                paginationMinBlocks: 2,
                getData: function (params) {
                    return vm.getRafflePrizes(params);
                }
            };
            vm.initialParams = {
                // initial page size
                count: 20
            };
        };

        vm.initTable = function() {
            vm.tableParams = new NgTableParams(vm.initialParams, vm.initialSettings);
        };

        vm.getRafflePrizes = function(params) {
            return IncentivesService.getRafflePrizes(
                function (success) {
                    if(success.data.length === 0) {
                        SnackbarUtils.displaySnackbar("warning", 'No prizes found in the database!');
                    }
                    vm.rafflePrizesList = success.data;
                    params.total(vm.rafflePrizesList.length);
                    return vm.rafflePrizesList;
                },
                function (error) {
                    SnackbarUtils.displaySnackbar("error", 'Cannot retrieve the raffle prizes list from the database!');
                }
            )
        };

        vm.switchRankBetweenRafflePrizes = function (prizeId1, prizeId2) {
            if (typeof prizeId1 === "undefined" || typeof prizeId2 === "undefined" || prizeId1 === prizeId2) {
                return;
            }
            vm.disableActions = true;
            IncentivesService.switchRafflePrizesRank(prizeId1, prizeId2,
                function (success) {
                    vm.disableActions = false;
                    vm.tableParams.reload();
                },
                function (error) {
                    vm.disableActions = false;
                    vm.tableParams.reload();
                    SnackbarUtils.displaySnackbar("error", 'Unable to switch ranks between the 2 raffle prizes!');
                }
            );
        };

        vm.publishPrize = function (prizeId) {
            $mdDialog.show({
                template:
                    '<md-dialog flex="50" md-theme="default">' +
                    '   <md-dialog-content class="md-dialog-content">' +
                    '       <h2 class="md-title">Publish raffle prize</h2>' +
                    '       <div class="md-dialog-content-body">' +
                    '           <p>Are you sure you want to publish this raffle prize? It will be added to the automatic emails, in the Prizes page from the panel and Winners section for future winners that you’re going to publish.</p>' +
                    '       </div>' +
                    '   </md-dialog-content>' +
                    '   <md-dialog-actions>' +
                    '       <md-button ng-click="ok()" class="md-raised md-primary" style="background-color: #5666BE;">' +
                    '           Publish prize' +
                    '       </md-button>' +
                    '       <md-button ng-click="cancel()" class="md-background md-hue-1">' +
                    '           Cancel' +
                    '       </md-button>' +
                    '   </md-dialog-actions>' +
                    '</md-dialog>',
                locals: {},
                controller: function ($scope, $mdDialog) {
                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };
                    $scope.ok = function () {
                        $mdDialog.hide()
                    }
                }
            }).then(
                function yes() {
                    vm.togglePublishStateForRafflePrize(prizeId)
                },
                function no() {
                }
            );
        };

        vm.unPublishPrize = function (prizeId) {
            $mdDialog.show({
                template:
                    '<md-dialog flex="50" md-theme="default">' +
                    '   <md-dialog-content class="md-dialog-content">' +
                    '       <h2 class="md-title">Disable raffle prize</h2>' +
                    '       <div class="md-dialog-content-body">' +
                    '           <p>Are you sure you want to disable this raffle prize? It won’t be displayed on the panel Prizes page, nor in the automatic emails. Don’t worry, the prize name will still be displayed in the Winners section for previous winners.</p>' +
                    '       </div>' +
                    '   </md-dialog-content>' +
                    '   <md-dialog-actions>' +
                    '       <md-button ng-click="ok()" class="md-raised md-warn" style="background-color: #EF4000;">' +
                    '           Disable prize' +
                    '       </md-button>' +
                    '       <md-button ng-click="cancel()" class="md-background md-hue-1">' +
                    '           Cancel' +
                    '       </md-button>' +
                    '   </md-dialog-actions>' +
                    '</md-dialog>',
                locals: {},
                controller: function ($scope, $mdDialog) {
                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };
                    $scope.ok = function () {
                        $mdDialog.hide()
                    }
                }
            }).then(
                function yes() {
                    vm.togglePublishStateForRafflePrize(prizeId)
                },
                function no() {
                }
            );
        };

        vm.togglePublishStateForRafflePrize = function(prizeId) {
            vm.disableActions = true;
            IncentivesService.togglePublishForRafflePrize(prizeId,
                function (success) {
                    vm.disableActions = false;
                    var prize = vm.rafflePrizesList.find(function(prize){
                        return prize.prizeId === prizeId;
                    });
                    prize.published = !prize.published;
                    if(prize.published) {
                        SnackbarUtils.displaySnackbar("success", "Raffle prize was published!");
                    } else {
                        SnackbarUtils.displaySnackbar("success", "Raffle prize was unpublished!");
                    }
                },
                function (error) {
                    vm.disableActions = false;
                    var prize = vm.rafflePrizesList.find(function(prize){
                        return prize.prizeId === prizeId;
                    });
                    if(!prize.published) {
                        SnackbarUtils.displaySnackbar("error", "Error! Raffle prize was not published!");
                    } else {
                        SnackbarUtils.displaySnackbar("error", "Error! Raffle prize was not unpublished!");
                    }
                }
            );
        };

        vm.getPanelLanguages = function(){
            LanguagesService.GetPanelLanguages(
                function (success) {
                    vm.languagesList = success.data;
                },
                function (error) {
                    SnackbarUtils.displaySnackbar("error", "Error! Unable to retrieve the panel languages list from the database!");
                }
            );
        };

        vm.initNewPrize = function () {
            if(typeof vm.selectedPrize !== "undefined"){
                delete vm.selectedPrize;
            }
            vm.selectedPrize = {
                prizeId: null,
                rank: null,
                translations: {}
            }
        };

        vm.editPrize = function (prizeId) {
            vm.disableActions = true;
            IncentivesService.getRafflePrizeById(prizeId,
                function (success) {
                    vm.disableActions = false;
                    if(typeof vm.selectedPrize !== "undefined") {
                        delete vm.selectedPrize;
                    }
                    vm.selectedPrize = success.data;
                },
                function (error) {
                    vm.disableActions = false;
                    SnackbarUtils.displaySnackbar("error", "Error! Unable to retrieve raffle prize from the database!");
                }
            )
        };

        vm.cancelEditPrize = function () {
            if (typeof vm.selectedPrize !== "undefined") {
                delete vm.selectedPrize;
            }
        };

        vm.removePrize = function (prizeId) {
            $mdDialog.show({
                template:
                    '<md-dialog md-theme="default">' +
                    '   <md-dialog-content class="md-dialog-content">' +
                    '       <h2 class="md-title">Delete raffle prize</h2>' +
                    '       <div class="md-dialog-content-body">' +
                    '           <p>Are you sure you want to delete this raffle prize?</p>' +
                    '       </div>' +
                    '   </md-dialog-content>' +
                    '   <md-dialog-actions>' +
                    '       <md-button ng-click="ok()" class="md-raised md-warn" style="background-color: #EF4000;">' +
                    '           Delete prize' +
                    '       </md-button>' +
                    '       <md-button ng-click="cancel()" class="md-background md-hue-1">' +
                    '           Cancel' +
                    '       </md-button>' +
                    '   </md-dialog-actions>' +
                    '</md-dialog>',
                locals: {},
                controller: function ($scope, $mdDialog) {
                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };
                    $scope.ok = function () {
                        $mdDialog.hide()
                    }
                }
            }).then(
                function yes() {
                    vm.disableActions = true;
                    IncentivesService.deleteAPrize(prizeId,
                        function (success) {
                            vm.disableActions = false;
                            vm.tableParams.reload();
                            SnackbarUtils.displaySnackbar("success", "Raffle prize was deleted!");
                        },
                        function (error) {
                            vm.disableActions = false;
                            SnackbarUtils.displaySnackbar("error", "Error! Raffle prize was not deleted!");
                        }
                    )
                },
                function no() {
                }
            );
        };

        vm.savePrize = function () {
            if(typeof vm.selectedPrize === "undefined") return;

            vm.isPrizeValid = vm.isPrizeValidToSave(vm.selectedPrize, vm.languagesList);
            if(!vm.isPrizeValid) return;

            vm.disableActions = true;
            IncentivesService.saveRafflePrize(vm.selectedPrize,
                function (success) {
                    vm.disableActions = false;
                    vm.cancelEditPrize();
                    vm.tableParams.reload();
                    SnackbarUtils.displaySnackbar("success", "Raffle prize was saved!");
                },
                function (error) {
                    vm.disableActions = false;
                    SnackbarUtils.displaySnackbar("error", "Error! Raffle prize was not saved!");
                }
            )

        };

        vm.isPrizeValidToSave = function (rafflePrize, languages) {
            for(var i in languages) {
                var langId = languages[i].lang_id;
                if(typeof rafflePrize.translations[langId] === "undefined" || rafflePrize.translations[langId] === "") {
                    SnackbarUtils.displaySnackbar("error", "Please insert the prize name translation into all panel languages.");
                    return false;
                }
            }
            return true;
        };

        (function initController() {
            vm.breadcrumbs = "Rewards: Raffle Prizes";
            vm.activeSubmenu = 'setup_rewards';
            vm.isPrizeValid = true;

            vm.initData();
            vm.initTable();
            vm.getPanelLanguages();
        })();
    }
})();
