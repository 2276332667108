(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .factory('ConfigurationsService', ConfigurationsService);

    ConfigurationsService.$inject = ['$http'];

    function ConfigurationsService($http) {
        var service = {};

        service.getRewardsConfigurations = function (okCallback, koCallback) {
            $http.get("/rest/config/getRewardsConfigurations/")
                .then(function successCallback(response) {
                    okCallback && okCallback(response);
                }, function errorCallback(response) {
                    koCallback && koCallback(response);
                });
        };

        service.saveRewardsConfiguration = function (payload, okCallback, koCallback) {
            $http.post("/rest/config/saveRewardsConfiguration/", payload)
                .then(function successCallback(response) {
                    okCallback && okCallback(response);
                }, function errorCallback(response) {
                    koCallback && koCallback(response);
                });
        };

        service.GetConfigurationByLangId = function (keyName, langId, okCallback, koCallback){
            $http.get("/rest/config/configTranslation/" + keyName + "/" + langId)
                .then(function successCallback(response) {
                    okCallback(response);
                }, function errorCallback(response) {
                    koCallback(response);
                });
        };

        return service;
    }
})();
