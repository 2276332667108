(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .factory('DeployService', DeployService);

    DeployService.$inject = ['$http'];
    function DeployService($http) {
        return {
            uploadPdfFile: function (formData, language, okCallback, koCallback) {
                $http.post("/rest/deploy/pdf/" + language, formData, {
                    transformRequest: angular.identity,
                    headers: {'Content-Type': undefined}
                })
                    .then(function successCallback(response) {
                        okCallback(response);
                    }, function errorCallback(response) {
                        koCallback(response);
                    });
            },
            uploadPptReportFile: function (formData, pptDir, okCallback, koCallback) {
                $http.post("/rest/deploy/ppt/report/" + pptDir, formData, {
                    transformRequest: angular.identity,
                    headers: {'Content-Type': undefined}
                })
                    .then(function successCallback(response) {
                        okCallback(response);
                    }, function errorCallback(response) {
                        koCallback(response);
                    });
            }
        }
    }
})();
