(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.controller('UserInfoController', UserInfoController);

	UserInfoController.$inject = ['$window', '$rootScope', '$location', '$mdDialog', 'userInfoService', 'UsersService', 'EmailsService'];
	function UserInfoController($window, $rootScope, $location, $mdDialog, userInfoService, UsersService, EmailsService) {
		var vm = this;

		vm.calculateAge = function (birthdate) {
			return new Date().getFullYear() - new Date(birthdate).getFullYear();
		};

		vm.getUserSource = function (user_recommended_by) {
			if (user_recommended_by === null || user_recommended_by >= 50)
				return "Organic";
			else if (user_recommended_by < 9) {
				return "Unknown";
			}
			else if (user_recommended_by === 9) {
				return "Fingerprint / Cint";
			}
			else if (user_recommended_by >= 10 && user_recommended_by <= 18) {
				return "Facebook earned";
			}
			else if (user_recommended_by === 19) {
				return "Facebook Leads";
			}
			else if (user_recommended_by > 40 && user_recommended_by < 50) {
				return "MVF";
			}
			else {
				return "Unknown";
			}
		};

		vm.calculateActiveExpiredTickets = function (tickets) {
			var activeTickets = 0;
			var expiredTickets = 0;
			for (var i = 0, len = tickets.length; i < len; i++) {
				var isValidDate = (new Date(tickets[i].ticket_ballot_date)).getMonth() === (new Date().getMonth()) &&
					(new Date(tickets[i].ticket_ballot_date)).getFullYear() === (new Date().getFullYear());
				if (tickets[i].ticket_ballot_date == null || isValidDate) {
					activeTickets ++;
				}
				else {
					expiredTickets ++;
				}
			}
			return activeTickets + " (" + (activeTickets + expiredTickets) + ")";
		};

		vm.formatMillisecondsDate = function (milliseconds) {
			var date = new Date(milliseconds);
			var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			return date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
		};

		vm.formatIncentiveType = function (incentiveType) {
			var formattedType;

			switch (incentiveType) {
				case "raffle_draw_win":
					formattedType = "Raffle draw win";
					break;
				case "points_to_tickets_conversion":
					formattedType = "Conversion (points to tickets)";
					break;
				case "points_to_prize_conversion":
					formattedType = "Conversion (points to prize)";
					break;
				default:
					break;
			}

			return formattedType;
		}

		vm.refreshData = function (userId) {
			vm.avatarSrc = "https://" + $rootScope.globals.panelHostname + "/rest/getupload/" + userId;
			userInfoService.getUserInfo(userId,
				function (response) {
					if (response.data) {
						vm.userData = response.data;

						vm.registerDate = vm.formatMillisecondsDate(vm.userData.user_registration_datetime);
						vm.lastLoginDate = vm.formatMillisecondsDate(vm.userData.user_last_login * 1000);
						vm.userSource = vm.getUserSource(vm.userData.user_recommended_by);
						vm.userAge = vm.calculateAge(vm.userData.user_birthdate);
						vm.ticketsString = vm.calculateActiveExpiredTickets(vm.userData.tickets);

						userInfoService.getUserQuests(userId,
							function (response) {
								vm.questData = response.data;
								vm.userSpeedsterStreak = 0;
								vm.userCompletedStreak = 0;
								vm.userShortSurveysCompleted = 0;
								for (var i in vm.questData) {
									if (vm.questData[i].quest_user_type === 'speedster') {
										vm.userSpeedsterStreak ++;
									}
									if (vm.questData[i].quest_user_type === 'completed') {
										vm.userCompletedStreak ++;
										if (vm.questData[i].quest_type === 'short') {
											vm.userShortSurveysCompleted ++;
										}
									}
								}
							},
							function (response) {
								console.log(response);
							});

						userInfoService.getUserTicketHistory(userId,
                            function (response) {
                                vm.ticketHistory = response.data;
                            },
                            function (response) {
                                console.log(response);
                            });

						userInfoService.getUserIncentives(userId,
							function (response) {
								vm.userIncentives = response.data;
							},
							function (response) {
								console.log(response);
							}
						);
					}
				},
				function (response) {
					console.log(response);
				});
		};

		vm.suspendUser = function() {
			var userId = vm.userData.user_id;
			UsersService.SuspendUser(userId,
				function (response) {
					vm.refreshData(userId);
				},
				function (response) {
					console.log(response);
				}
			);
		};

		vm.unsuspendUser = function() {
			var userId = vm.userData.user_id;
			UsersService.UnsuspendUser(userId,
				function (response) {
					vm.unsuspendNotification = true;
					vm.refreshData(userId);
					EmailsService.sendUnsuspendUserEmail(userId,
						function success(response) {
						},
						function error(response) {
						}
					);
					setTimeout(function() {
						vm.unsuspendNotification = undefined;
					}, 2000);
				},
				function (response) {
					console.log(response);
				}
			);
		};

		vm.resendActivation = function() {
			var userId = vm.userData.user_id;
			var userEmail = vm.userData.user_email;
			EmailsService.resendActivation(userId,
				function success(response) {
					$mdDialog.show(
						$mdDialog.alert()
							.title()
							.htmlContent("<h3><strong><center>The account activation reminder has been successfully<br/> sent to " + userEmail + "!</center></strong></h3>")
							.ok('OK')
					);
				},
				function error(response) {
					$mdDialog.show(
						$mdDialog.alert()
							.title()
							.htmlContent("<h3><strong><center>The account activation reminder could NOT be<br/> sent to " + userEmail + "!</center></strong></h3>")
							.ok('OK')
					);
				}
			);
		};

		(function initController() {
			vm.breadcrumbs = "Users: User details";
			vm.activeSubmenu = 'users';
			vm.unsuspendNotification = undefined;
			vm.ticketsEnabled = $rootScope.globals.ticketsEnabled;
			var locationPath = $location.path();
			var userId = locationPath.substring(locationPath.lastIndexOf('/') + 1);

			if (userId) {
				vm.refreshData(userId);
			}
		})();
	}
})();