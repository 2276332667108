'use strict';

angular
	.module('questiaPlatformApp').filter('sortBy', sortBy);

function sortBy() {
	return function (obj) {
		if (obj) {
			//regular stack chart
			if (obj.values) {
				return Object.keys(obj.values[0]).filter(function (key) {
					if (key !== "title" && key !== "titleWithBase" && key !== "$$hashKey") return key;
				});
			}

			//cross chart
			else {
				return Object.keys(obj[0]).filter(function (key) {
					if (key !== "title" && key !== "titleWithBase" && key !== "$$hashKey") return key;
				});
			}

		}
		else return 0;
	};
}
