(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.controller('IncentivesController', IncentivesController);

	IncentivesController.$inject = ['$rootScope', 'IncentivesService', '$mdDialog'];
	function IncentivesController($rootScope, IncentivesService,$mdDialog) {
		var vm = this;

		vm.ticketsData = [{
			item_count : 0,
			price : 0,
			category : 'tickets'
		}];

		(function initController() {
			vm.breadcrumbs = "Rewards: Conversion to Tickets";
			vm.activeSubmenu = 'setup_rewards';
			IncentivesService.getTickets(
				function success(response) {

					if(response.data.length === 0) {
						console.log('No tickets found into database');
						return;
					}

					vm.ticketsData = response.data.filter(function(elem){
						return elem.category === 'tickets'
					});
				},
				function err(response) {
					console.log(response);
				}
			);
			IncentivesService.getExtraPointsMap(
				function success(response) {
					vm.bonusPointsMap = response.data;
					console.log(vm.bonusPointsMap);
				},
				function err(response) {
					console.log(response);
				}
			);
		})();

		function convertToString(obj) {
			for(var key in obj) {
				if(obj.hasOwnProperty(key)) {
					obj[key] += "";
				}
			}
			return obj;
		}

		vm.setPrices = function (ticketsData) {
			vm.showSpinner = true;
			delete vm.updateStatus;
			IncentivesService.setConfigurationsMap(ticketsData,
				function ok(response) {

				    for(var i = 0 ; i < vm.ticketsData.length ; i++){
				    	if(vm.ticketsData[i].id === undefined){
				    		var correspondedFetchedTicket = response.data.find(function(elem){
				    			return elem.price === vm.ticketsData[i].price;
							});
							vm.ticketsData[i].id = correspondedFetchedTicket.id;
						}
					}

					vm.updateStatus = {
						code: 1,
						msg: "Ticket prices updated successfully."
					};
					vm.showSpinner = false;
				},
				function err(response) {
					if(response.status === 500) {
						vm.updateStatus = {
							code: -1,
							msg: response.data
						};
					}
					else {
						vm.updateStatus = {
							code: -2,
							msg: "Something went wrong."
						};
					}
					vm.showSpinner = false;
				});
		};

		vm.setExtraPoints = function (extraPoints) {
			vm.showSpinnerBonusPoints = true;
			delete vm.updateStatusBonusPoints;
			var stringExtraPoints = angular.copy(extraPoints);
			stringExtraPoints = convertToString(stringExtraPoints);
			IncentivesService.setConfigurationsMap(stringExtraPoints,
				function ok(response) {
					vm.updateStatusBonusPoints = {
						code: 1,
						msg: "Bonus points for mobile app users updated successfully."
					};
					vm.showSpinnerBonusPoints = false;
				},
				function err(response) {
					if(response.status === 500) {
						vm.updateStatusBonusPoints = {
							code: -1,
							msg: response.data
						};
					}
					else {
						vm.updateStatusBonusPoints = {
							code: -2,
							msg: "Something went wrong."
						};
					}
					vm.showSpinnerBonusPoints = false;
				});
		};

		vm.ticketPricesSplice = function (index) {
			var ticketConfig = {
				item_count : 0,
				price : 0,
				category : 'tickets'
			};
			if(vm.ticketsData.length <= index+1) {
				vm.ticketsData.splice(index + 1, 0, ticketConfig);
			}
		};

		vm.deleteTicket = function(index){
			var toDeleteTicket = vm.ticketsData[index];

			if(toDeleteTicket.id === undefined) {
				vm.ticketsData.splice(index, 1);
				return;
			}

			var titleString = "Delete a ticket record";
			var confirm = $mdDialog.confirm({
				onComplete: function afterShowAnimation() {
					var $dialog = angular.element(document.querySelector('md-dialog'));
					var $actionsSection = $dialog.find('md-dialog-actions');
					var $cancelButton = $actionsSection.children()[0];
					var $confirmButton = $actionsSection.children()[1];
					angular.element($confirmButton).addClass('md-raised');
					angular.element($cancelButton).addClass('md-raised md-warn');
				}
			})
				.title(titleString)
				.textContent("Are you sure that you want to delete a ticket record from the database?")
				.ok("YES")
				.cancel("NO");

			$mdDialog.show(confirm).then(function () {
				IncentivesService.deleteTicket(toDeleteTicket,
					function success(response) {
						vm.ticketsData.splice(index, 1);
						$mdDialog.show($mdDialog.alert()
							.parent(angular.element(document.querySelector('md-dialog')))
							.clickOutsideToClose(true)
							.title('The ticket was succesfully deleted from the database')
							.ok('OK'))
					},
					function err(response) {
						console.log(response);
					}
				);
			}, function () {
				vm.progress = false;
			});
		};

	}

})();