(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('FilterUtils', ['EmailsService', FilterUtils]);

	function FilterUtils(EmailsService) {
		return {
			buildFiltersForNewForecast: function (toAll) {
				var result = [];
				if (!toAll) {
					result.push({type: "quest_type_responders", values: ["forecast", "false"]});
					result.push({type: "user_level", values: ["4"]});
				}
				return result;
			},

			buildFilters: function (filters) {
				var result = [];
				if(!filters) return result;
				if (filters.simpleList) {
					result.push({type: "emails_list", values: [filters.simpleListEmails]});
				}
				else {
					if(filters.survey) {
						if (filters.survey.respondersOfType) {
							result.push({type: "quest_type_responders", values: [filters.survey.respondersOfType, "" + filters.survey.excludeRespondersOfType]});
						}
						if (filters.survey.nonRespondersOfId) {
							result.push({type: "quest_non_responders", values: [filters.survey.nonRespondersOfId]});
						}
						if (filters.survey.respondersOfId) {
							result.push({type: "quest_responders", values: [filters.survey.respondersOfId]});
						}
					}
					if(filters.user) {
						var onlyNullASLCondition = filters.user.user_only_null_asl === false || typeof filters.user.user_only_null_asl ==='undefined';
						if (filters.user.minAge && onlyNullASLCondition) {
							var minAgeValue = filters.user.minAge;
                            if (filters.user.user_null_asl === 'true'){
                            	minAgeValue = "n" + minAgeValue;
                                filters.user.ageIncludeNull = true;
							}
							result.push({type: "user_min_age", values: [minAgeValue, filters.user.ageIncludeNull]});
						}
						if (filters.user.maxAge && onlyNullASLCondition) {
							var maxAgeValue = filters.user.maxAge;
                            if (filters.user.user_null_asl === 'true'){
                            	maxAgeValue = "n" + maxAgeValue;
                                filters.user.ageIncludeNull = true;
                            }
							result.push({type: "user_max_age", values: [maxAgeValue, filters.user.ageIncludeNull]});
						}
                        if (filters.user.minPredictionScore) {
                            result.push({type: "user_min_prediction_score", values: [filters.user.minPredictionScore]});
                        }
                        if (filters.user.maxPredictionScore) {
                            result.push({type: "user_max_prediction_score", values: [filters.user.maxPredictionScore]});
                        }
                        if (filters.user.minPoints) {
                            result.push({type: "user_min_points", values: [filters.user.minPoints]});
                        }
                        if (filters.user.maxPoints) {
                            result.push({type: "user_max_points", values: [filters.user.maxPoints]});
                        }
                        if (filters.user.minPanelAge) {
                        	result.push({type: "user_min_panel_age", values: [filters.user.minPanelAge]})
						}
						if (filters.user.sex && onlyNullASLCondition) {
							var sexValue = filters.user.sex;
                            if (filters.user.user_null_asl === 'true'){
                            	sexValue = "n" + sexValue;
							}
							result.push({type: "user_sex", values: [sexValue]});
						}
						if (filters.user.panelSegment) {
							result.push({type: "user_panel_segment", values: [filters.user.panelSegment]});
						}
						if (filters.user.minRegDate || filters.user.maxRegDate) {
							var minDateFormatted = null;
							if(filters.user.minRegDate && filters.user.minRegDate.format) {
								minDateFormatted = filters.user.minRegDate.format("YYYY-MM-DD");
							}
							var maxDateFormatted = null;
							if(filters.user.maxRegDate && filters.user.maxRegDate.format) {
								maxDateFormatted = filters.user.maxRegDate.format("YYYY-MM-DD");
							}
							if(!(minDateFormatted==null && maxDateFormatted==null)) {
                                result.push({
                                    type: "user_registration_date",
                                    values: [minDateFormatted, maxDateFormatted]
                                });
                            }
						}
						if (filters.user.neverLoggedIn) {
							result.push({type: "user_never_logged_in", values:[]});
						}

						var areASLFieldCompleted = !((filters.user.sex === undefined || filters.user.sex === '') &&
							(filters.user.minAge === undefined || filters.user.minAge === null) &&
							(filters.user.maxAge === undefined || filters.user.maxAge === null) &&
							(filters.location!==undefined && (filters.location.user_region === undefined || filters.location.user_region === '')) &&
							(filters.location!==undefined && (filters.location.user_county === undefined || filters.location.user_county === '')) &&
							(filters.location!==undefined && (filters.location.rural_urban === undefined || filters.location.rural_urban === '')));

                        if (filters.user.user_null_asl === 'true' && onlyNullASLCondition && areASLFieldCompleted) {
                            result.push({type: "user_null_asl", values:[]});
                        }
                        if(filters.user.user_only_null_asl === 'true'){
                            result.push({type: "user_only_null_asl", values:[]});
						}
						if (filters.user.recommendedBy) {
							result.push({type: "user_recommended_by", values:[filters.user.recommendedBy]});
						}
						if(filters.user.selectedQuestionId && filters.user.selectedChoiceId!==-1 ){
							var filterValue = "";

							var questionId = filters.user.selectedQuestionId;
							if(filters.user.excludeRespondents === true){
								questionId = "n" + questionId;
							}
							filterValue+=questionId;

							if(filters.user.selectedChoiceId && filters.user.selectedChoiceId !== "-1"){
								filterValue+=";" + filters.user.selectedChoiceId;
							}

							result.push({type:"user_question_answer_filter", values:[filterValue]})
						}
					}
					if(filters.location) {
						var onlyNullASLCondition = true;
						if(typeof filters.user !== 'undefined'){
							if(!angular.equals({}, filters.user)){
								if(typeof filters.user.user_only_null_asl !== 'undefined'){
									onlyNullASLCondition =  (filters.user.user_only_null_asl === 'false' || filters.user.user_only_null_asl === false);
								}
							}
						}
						if(filters.location.user_region && onlyNullASLCondition) {
                            var locationFilterValue = filters.location.user_region;
                            if ( filters.user && filters.user.user_null_asl === 'true') {
                                locationFilterValue = "n" + locationFilterValue;
                            }
                            result.push({type: "user_region", values: [locationFilterValue]});
						}
						if(filters.location.user_county && onlyNullASLCondition) {
                            var locationFilterValue = filters.location.user_county;
                            if ( filters.user && filters.user.user_null_asl === 'true') {
                                locationFilterValue = "n" + locationFilterValue;
                            }
							result.push({type: "user_county", values: [locationFilterValue]});
						}
						if(filters.location.rural_urban && onlyNullASLCondition) {
							var locationFilterValue = filters.location.rural_urban;
                            if (filters.user && filters.user.user_null_asl === 'true') {
                                locationFilterValue = "n" + locationFilterValue;
                            }
							result.push({type: "user_rural_urban", values: [locationFilterValue]});
						}
                        if(filters.location.user_county_districts && filters.location.user_county && onlyNullASLCondition){
                        	if(filters.location.user_county_districts.length > 0) {
                        		var citiesIDs = filters.location.user_county_districts.toString();
                                if (filters.user && filters.user.user_null_asl === 'true') {
                                    citiesIDs = "n" + citiesIDs;
                                }
                                result.push({
                                    type: "user_county_districts",
                                    values: [citiesIDs]
                                });
                            }
                        }
					}
				}

				return result;
			},

			buildFiltersForSaveSurvey: function (filters) {
				var result = {};
				if(filters.survey) {
					if (filters.survey.nonRespondersOfId) {
						result['quest_non_responders'] = filters.survey.nonRespondersOfId;
					}
					if (filters.survey.respondersOfId) {
						result['quest_responders'] = filters.survey.respondersOfId;
					}
				}
				if(filters.user) {
					var onlyNullASLCondition = typeof filters.user.user_only_null_asl === 'undefined' || filters.user.user_only_null_asl === 'false' || filters.user.user_only_null_asl === false;
					if (filters.user.minAge && onlyNullASLCondition) {
						var notNullFlag = "";
                        if(filters.user.user_null_asl === 'true'){
                            notNullFlag += "n";
                        }
						result['user_min_age'] = notNullFlag + filters.user.minAge;
					}
					if (filters.user.maxAge && onlyNullASLCondition) {
						var notNullFlag = "";
                        if(filters.user.user_null_asl === 'true'){
                            notNullFlag += "n";
                        }
						result['user_max_age'] = notNullFlag +  filters.user.maxAge ;
					}
					if (filters.user.minPoints) {
						result['user_min_points'] = filters.user.minPoints;
					}
					if (filters.user.maxPoints) {
						result['user_max_points'] = filters.user.maxPoints;
					}
					if (filters.user.minPanelAge) {
						result['user_min_panel_age'] = filters.user.minPanelAge;
					}
					if (filters.user.sex && onlyNullASLCondition) {
						var sexValue = filters.user.sex;
						var userNullASLFilter = filters.user.user_null_asl;
                        if (userNullASLFilter && userNullASLFilter === 'true'){
                            sexValue = "n" + sexValue;
                        }
						result['user_sex'] = sexValue;
					}
					if (filters.user.panelSegment) {
						result['user_panel_segment'] = filters.user.panelSegment;
					}
					var areASLFieldCompleted = !((filters.user.sex === undefined || filters.user.sex === '') &&
						(filters.user.minAge === undefined || filters.user.minAge === null) &&
						(filters.user.maxAge === undefined || filters.user.maxAge === null) &&
						(filters.location!==undefined && (filters.location.user_region === undefined || filters.location.user_region === '')) &&
						(filters.location!==undefined && (filters.location.user_county === undefined || filters.location.user_county === '')) &&
						(filters.location!==undefined && (filters.location.rural_urban === undefined || filters.location.rural_urban === '')));

					if(filters.user.user_null_asl === 'true' && onlyNullASLCondition && areASLFieldCompleted){
                        result['user_null_asl'] = filters.user.user_null_asl;
					}
                    if(filters.user.user_only_null_asl === 'true'){
                        result['user_only_null_asl'] = filters.user.user_only_null_asl;
                    }
					if (filters.user.recommendedBy) {
						result['user_recommended_by'] = filters.user.recommendedBy;
					}
					if(filters.user.selectedQuestionId && (filters.user.selectedChoiceId !== -1)){
						var filterValue = "";

						var questionId = filters.user.selectedQuestionId;
						if(filters.user.excludeRespondents === true){
							questionId = "n" + questionId;
						}
						filterValue+=questionId;

						if(filters.user.selectedChoiceId){
							filterValue+=";" + filters.user.selectedChoiceId;
						}

						result["user_question_answer_filter"] = filterValue;
					}
				}
				if(filters.location) {
					var onlyNullASLCondition = true;
                    if(typeof filters.user !== 'undefined'){
                    	if(!angular.equals({}, filters.user)){
                    		if(typeof filters.user.user_only_null_asl !== 'undefined'){
								onlyNullASLCondition =  (filters.user.user_only_null_asl === 'false' || filters.user.user_only_null_asl === false);
							}
						}
					}
					if(filters.location.user_region && onlyNullASLCondition) {
                        var userNullASLFilter = filters.user;
                        notNullFlag= "";
                        if(userNullASLFilter && userNullASLFilter.user_null_asl === 'true'){
                            notNullFlag += "n";
                        }
						result['user_region'] = notNullFlag + filters.location.user_region;
					}
					if(filters.location.user_county && onlyNullASLCondition) {
                        var userNullASLFilter = filters.user;
                        notNullFlag= "";
                        if(userNullASLFilter && userNullASLFilter.user_null_asl === 'true'){
                            notNullFlag += "n";
                        }
						result['user_county'] = notNullFlag + filters.location.user_county;
					}
					if(filters.location.rural_urban && onlyNullASLCondition) {
                        var userNullASLFilter = filters.user;
                        notNullFlag= "";
                        if(userNullASLFilter && userNullASLFilter.user_null_asl === 'true'){
                            notNullFlag += "n";
                        }
						result['user_rural_urban'] = notNullFlag + filters.location.rural_urban;
					}
                    if(filters.location.user_county_districts && filters.location.user_county && onlyNullASLCondition){
						var districtFilterString = filters.location.user_county_districts.toString();
						var userNullASLFilter = filters.user;
						if(districtFilterString!==""){
							var notNullFlag = "";
							if(userNullASLFilter && userNullASLFilter.user_null_asl === 'true'){
                            	notNullFlag += "n";
							}
                            result['user_county_districts'] =  notNullFlag + districtFilterString;
						}
                    }
				}
				return result;
			},

			transformFiltersToUiFormat: function (filters) {
				var uiFilters = {
					survey: {},
					user: {},
					location: {}
				};
				if(filters['quest_non_responders']) {
					uiFilters.survey.nonRespondersOfId = parseInt(filters['quest_non_responders']);
				}
				if(filters['quest_responders']) {
					uiFilters.survey.respondersOfId = parseInt(filters['quest_responders']);
				}
				if(filters['user_min_age']) {
                    if(filters['user_null_asl']){
                        //remove the 'n' character if it is the case (survey is saved with null asl option)
                        filters['user_min_age'] = filters['user_min_age'].slice(1);
                    }
					uiFilters.user.minAge = parseInt(filters['user_min_age']);
				}
				if(filters['user_max_age']) {
                    if(filters['user_null_asl']){
                        //remove the 'n' character if it is the case (survey is saved with null asl option)
                        filters['user_max_age'] = filters['user_max_age'].slice(1);
                    }
					uiFilters.user.maxAge = parseInt(filters['user_max_age']);
				}
				if(filters['user_min_points']) {
					uiFilters.user.minPoints = parseInt(filters['user_min_points']);
				}
				if(filters['user_max_points']) {
					uiFilters.user.maxPoints = parseInt(filters['user_max_points']);
				}
				if(filters['user_min_panel_age']) {
					uiFilters.user.minPanelAge = parseInt(filters['user_min_panel_age']);
				}
				if(filters['user_sex']) {
                    if(filters['user_null_asl']){
                    	//remove the 'n' character if it is the case (survey is saved with null asl option)
                        filters['user_sex'] = filters['user_sex'].slice(1);
					}
					uiFilters.user.sex = filters['user_sex'];
				}
				if(filters['user_panel_segment']) {
					uiFilters.user.panelSegment = filters['user_panel_segment'];
				}
                if(filters['user_null_asl']) {
                    uiFilters.user.user_null_asl = filters['user_null_asl'];
                }
                if(filters['user_only_null_asl']) {
                    uiFilters.user.user_only_null_asl = filters['user_only_null_asl'];
                }
				if(filters['user_region']) {
                    if(filters['user_null_asl']){
                        filters['user_region'] = filters['user_region'].slice(1);
                    }
					uiFilters.location.user_region = filters['user_region'];
				}
				if(filters['user_county']) {
                    if(filters['user_null_asl']){
                        filters['user_county'] = filters['user_county'].slice(1);
                    }
					uiFilters.location.user_county = filters['user_county'];
				}
				if(filters['user_rural_urban']) {
                    if(filters['user_null_asl']){
                        filters['user_rural_urban'] = filters['user_rural_urban'].slice(1);
					}
					uiFilters.location.rural_urban = filters['user_rural_urban'];
				}
                if(filters['user_county_districts']){
                    if(filters['user_null_asl']){
                        //remove the 'n' character if it is the case (survey is saved with null asl option)
                        filters['user_county_districts'] = filters['user_county_districts'].slice(1);
					}
					var receivedFiltersString = filters['user_county_districts'].split(",");
					var array = [];
					if(receivedFiltersString){
						for(var i = 0; i < receivedFiltersString.length; i++){
							array.push(parseInt(receivedFiltersString[i]));
						}
					}
                    uiFilters.location.user_county_districts = array;
                }

				if (filters['user_recommended_by']) {
					uiFilters.user.recommendedBy = parseInt(filters['user_recommended_by']);
				}

				if(filters['user_question_answer_filter']){
					var filterValue = filters['user_question_answer_filter'];
					var splittedFilterValue = filterValue.split(";");

					if(splittedFilterValue[0].startsWith("n")){
						uiFilters.user.excludeRespondents = true;
						uiFilters.user.selectedQuestionId = parseInt(splittedFilterValue[0].substring(1));
					}else{
						uiFilters.user.selectedQuestionId = parseInt(splittedFilterValue[0]);
					}

                    if(splittedFilterValue.length === 2){
						uiFilters.user.selectedChoiceId = parseInt(splittedFilterValue[1]);
					}
				}

				return uiFilters;
			},

			getFiltersCount: function (questType, notificationsToAll, filters, okCallback) {
				var mailData = {
				    email: {
						withUnsubscribe: true
					}
				};
				if (questType === 'forecast') {
					mailData.email.filters = this.buildFiltersForNewForecast(notificationsToAll);
				}
				else {
					mailData.email.filters = this.buildFilters(filters);
				}

				EmailsService.GetUserCountForMail(mailData,
					function (response) {
						okCallback(response.data);
					},
					function (response) {
						alert(response.data);
					});
			}
		}
	}
})();

