(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.controller('ForecastStatisticsController', ForecastStatisticsController);

	ForecastStatisticsController.$inject = ['$rootScope', '$location', 'ForecastStatisticsService'];
	function ForecastStatisticsController($rootScope, $location, ForecastStatisticsService) {
		var vm = this;

		vm.refreshResults = function (url, title, idChart, base) {
			ForecastStatisticsService.GetStats(url,
				function (response) {
					var chartData = [];
					for (var i = response.data.length - 1; i >= 0; i--) {
						chartData.push(response.data[i]);
					}
					function getBalloon(date) {
						return AmCharts.formatDate(date, "DD/MM/YYYY");
					}

					var graphs = [];
					graphs.push({
						"id": 0,
						"bullet": "round",
						"bulletBorderAlpha": 1,
						"bulletColor": "#FFFFFF",
						"bulletSize": 5,
						"hideBulletsCount": 50,
						"lineThickness": 2,
						"useLineColorForBulletBorder": true,
						"valueField": "percent",
						"balloonText": "<span style='font-size:18px;'>[[value]] - [[base]] " + base + "</span>"
					});

					var chart = AmCharts.makeChart(idChart + "Chart", {
						"type": "serial",
						"theme": "light",
						"titles": [
							{
								"text": title,
								"size": 15
							}
						],
						"marginRight": 80,
						"dataProvider": chartData,
						"path": "/js/lib/amcharts",
						"dataDateFormat": "YYYY-MM-DD JJ:NN:SS",
						"valueAxes": [{
							"position": "left",
							"title": "Percent"
						}],
						"graphs": graphs,
						"chartScrollbar": {
							"graph": graphs[0].id,
							"scrollbarHeight": 50,
							"backgroundAlpha": 0,
							"selectedBackgroundAlpha": 0.1,
							"selectedBackgroundColor": "#888888",
							"graphFillAlpha": 0.5,
							"graphLineAlpha": 0.5,
							"selectedGraphFillAlpha": 0,
							"selectedGraphLineAlpha": 1,
							"autoGridCount": true,
							"color": "#AAAAAA"
						},
						"valueScrollbar": {
							"backgroundAlpha": 0,
							"selectedBackgroundColor": "#888888",
							"autoGridCount": true,
							"color": "#AAAAAA"
						},
						"chartCursor": {
							"cursorPosition": "mouse",
							"categoryBalloonFunction": getBalloon
						},
						"categoryField": "date",
						"categoryAxis": {
							"minPeriod": "mm",
							"parseDates": true
						},
						"export": {
							"enabled": true,
							"dateFormat": "YYYY-MM-DD HH:NN:SS"
						}
					});

					chart.addListener("rendered", zoomChart);
					zoomChart();
					function zoomChart() {
						chart.zoomToIndexes(chartData.length - 800, chartData.length - 1);
					}

					if(idChart === "forecast1") {
						vm.forecast1Loaded = true;
					}
					if(idChart === "forecast2") {
						vm.forecast2Loaded = true;
					}

				},
				function (response) {
					console.log("error getting stats");
				});
		};

		(function initController() {
			vm.breadcrumbs = "Predictor > Statistics";
			vm.forecast1Loaded = false;
			vm.refreshResults('/rest/forecast/percent/answers/40', 'Average updates per user', 'forecast1','answers' );
			vm.forecast2Loaded = false;
			vm.refreshResults('/rest/forecast/percent/questions/40', 'Percent average question answered per user', 'forecast2', 'users');
		})();
	}
})();
