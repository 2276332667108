(function () {
    'use strict';
    /*
        Description: a directive that shows a pop-up for picking a CSV file and importing its data
        according to an upload function

        Usage:
            HTML:
                <import-from-csv-directive upload-function="uploadFunction">
                </import-from-csv-directive>

            Directive parameters:
                1. uploadFunction (mandatory): a function for importing the content of a CSV
                        - objectId (optional): the ID of the object (e.g) to update with the content
                        - csvFile: the CSV content
                        - success callback function
                        - error callback function

                2. toolbarButton: an indicator for determining the class used for the directive
                (true for toolbar button, false for independent button)

                3. importType: the type of information to be imported (e.g. "Cities and Counties")

                4. objectId: the ID of the object to upload the information to (e.g. a survey ID)

                5. successFunction: the function to be called when the modal closes with promise resolve

                6. errorFunction: the function to be called when the modal closes with promise reject

                !!! if 5 and 6 are left undefined they will not be called
     */

    angular
        .module('questiaPlatformApp').directive('importFromCsvDirective', function () {
        return {
            restrict: 'E',
            scope: {
                toolbarButton: '=',
                importType: '@',
                objectId: '=',
                importFunction: '&',
                successFunction: '&',
                errorFunction: '&'
            },
            templateUrl: 'js/ng-templates/subviews/directives/importfromcsvdirective.view.html',
            controllerAs: 'vm',
            controller: ['$scope','$mdDialog', function ($scope, $mdDialog) {
                var vm = this;
                vm.toolbarButton = $scope.toolbarButton;
                vm.importType = $scope.importType;
                vm.objectId = $scope.objectId;
                vm.importFunction = $scope.importFunction;
                vm.successFunction = $scope.successFunction;
                vm.errorFunction = $scope.errorFunction;

                vm.addCsv = function (objectId, importFunction) {
                    $mdDialog.show({
                        templateUrl: 'js/ng-templates/subviews/directives/importfromcsvdialog.subview.html',
                        locals: {
                            objectId: objectId,
                            importFunction: importFunction
                        },
                        controller: AddCsvController
                    }).then(
                        function () {
                            if(vm.successFunction() instanceof Function) vm.successFunction()();
                        },
                        function () {
                            if(vm.errorFunction() instanceof Function) vm.errorFunction()();
                        });
                };

                function AddCsvController($scope, $mdDialog, $timeout, objectId, importFunction) {
                    $scope.importResult = undefined;
                    $scope.csvFile = undefined;
                    $scope.objectId = objectId;
                    $scope.importFunction = importFunction;

                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };

                    $scope.save = function () {
                        if ($scope.objectId) {
                            $scope.importFunction()(
                                $scope.objectId,
                                $scope.csvFile,
                                function success(response) {
                                    $scope.importResult = {
                                        message: "Import successful!"
                                    };
                                    $timeout( function(){
                                        $mdDialog.cancel();
                                    }, 2000);
                                },
                                function error(response) {
                                    $scope.importResult = {
                                        message: "Import failed!",
                                        cause: response.data
                                    };
                                    $timeout( function(){
                                        $mdDialog.cancel();
                                    }, 2000);
                                }
                            )
                        }
                        else {
                            $scope.importFunction()(
                                $scope.csvFile,
                                function success(response) {
                                    $scope.importResult = {
                                        message: "Import successful!"
                                    };
                                    $timeout( function(){
                                        $mdDialog.cancel();
                                    }, 2000);
                                },
                                function error(response) {
                                    $scope.importResult = {
                                        message: "Import failed!",
                                        cause: response.data
                                    };
                                    $timeout( function(){
                                        $mdDialog.cancel();
                                    }, 2000);
                                }
                            )
                        }
                    };

                    $scope.changeCsvFile = function() {
                        $scope.importResult = undefined;
                    };
                }
            }]
        };
    })

})();