(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .controller('ForecastOperationsController', ForecastOperationsController);

    ForecastOperationsController.$inject = ['$rootScope', 'ForecastService', '$filter', '$state', '$window', '$mdDialog', 'NgTableParams', '$q'];
    function ForecastOperationsController($rootScope, ForecastService, $filter, $state, $window, $mdDialog, NgTableParams, $q) {
        var vm = this;

        vm.createDatasetEntry = function (userStats) {
            return {
                userId: userStats.userId,
                numberOfForecastedEvents: userStats.numberOfForecastedEvents,
                userForecastingAccuracy: userStats.userForecastingAccuracy
            }
        };

        vm.areParametersValid = function (params) {
            if(typeof params === "undefined" ||
                typeof params.startDate === "undefined" ||
                typeof params.endDate === "undefined" ||
                typeof params.minAccuracy === "undefined" ||
                typeof params.minNumberOfForecastsPerMonth === "undefined") {
                return false;
            } else {
                if(params.minAccuracy < 0 || params.minAccuracy > 100){
                    return false;
                }
                if(params.minNumberOfForecastsPerMonth < 0) {
                    return false
                }
            }

            return true;
        };

        vm.reloadForecastUsersStatsList = function () {
            if(!vm.areParametersValid(vm.inputParams)) {
                $mdDialog.show(
                    $mdDialog.alert()
                        .title('Error')
                        .textContent('One or more parameters are invalid!')
                        .ok('Close')
                );

                return;
            }

            var parameters = {
                "startDate": moment(moment.utc(vm.inputParams.startDate)).format('YYYY-MM-DD H:m:s'),
                "endDate": moment(moment.utc(vm.inputParams.endDate)).format('YYYY-MM-DD H:m:s'),
                "minAccuracy": vm.inputParams.minAccuracy,
                "minNumberOfForecastsPerMonth": vm.inputParams.minNumberOfForecastsPerMonth
            };

            vm.forecastUsersStatsDataset = [];
            ForecastService.getUsersForecastStats(parameters,
                function (success) {
                    vm.forecastUsersStatsList = success.data;
                    vm.forecastUsersStatsDataset = [];
                    angular.forEach(vm.forecastUsersStatsList, function (userStats) {
                        vm.forecastUsersStatsDataset.push(vm.createDatasetEntry(userStats));
                    });

                    vm.initialSettings.dataset = vm.forecastUsersStatsDataset;
                    vm.tableParams = new NgTableParams(vm.initialParams, vm.initialSettings);
                },
                function (error) {
                    $mdDialog.show(
                        $mdDialog.alert()
                            .title('Failure')
                            .textContent('Could not get forecasters users stats list!')
                            .ok('Close')
                    );
                }
            );
        };

        vm.resetPredictorScores = function () {
            var confirm = $mdDialog.confirm()
                .title('Are you sure you want to reset all predictor accuracy scores?')
                .textContent('If deleted, predictor accuracy scores will be only recalculated when a forecast event is closed. Also predictor accuracy scores are calculated for the period the event is closed in!')
                .ok('Yes')
                .cancel('No');
            $mdDialog.show(confirm).then(function() {
                ForecastService.resetScores(
                    function (success) {
                        $mdDialog.show(
                            $mdDialog.alert()
                                .title('Success')
                                .textContent('Successfully reset all prediction scores!')
                                .ok('Close')
                        );
                    },
                    function (error) {
                        $mdDialog.show(
                            $mdDialog.alert()
                                .title('Failure')
                                .textContent('Failed to reset prediction scores!')
                                .ok('Close')
                        );
                    }
                );
            }, function(){});
        };

        vm.createForecastUsersStatsCSVRecords = function () {
            var records = [];
            for (var i = 0; i < NUM_EXAMPLE_COUNTIES; i++) {
                var county_id = vm.counties[i].county_id;
                var county_name = vm.counties[i].county_name;
                var cities = vm.cities.filter(function (city) {return city.county.county_id === county_id;}).slice(0, NUM_EXAMPLE_CITIES_PER_COUNTY);
                var currentRecords = cities.map(function (city) {
                    return {
                        county_name: county_name,
                        city_name: city.city_name,
                        city_population: city.city_population,
                        city_rural_urban: city.city_rural_urban
                    }
                });
                records = records.concat(currentRecords);
            }
            return records;
        };

        vm.getCSVForForecastUsersStats = function () {
            vm.forecastUsersStatsCSVHeader = ["User ID", "Number of Forecasted Events", "User Forecasting Accuracy"];
            var deferme = $q.defer();
            deferme.resolve(angular.copy(vm.forecastUsersStatsDataset));
            return deferme.promise;

        };

        (function initController() {
            vm.breadcrumbs = "Predictor: Operations";
            vm.activeSubmenu = 'predictor';
            vm.forecastUsersStatsList = [];

            vm.initialParams = {
                // initial page size
                count: 20
            };
            vm.initialSettings = {
                // page size buttons (right set of buttons in demo)
                counts: [],
                // determines the pager buttons (left set of buttons in demo)ss
                paginationMaxBlocks: 20,
                paginationMinBlocks: 2,
                dataset: []
            };

            vm.inputParams = {
                selectedDate: undefined,
                monthsPeriod: undefined,
                minAccuracy: undefined,
                minNumberOfForecastsPerMonth: undefined
            };
        })();
    }

})();