(function () {
    'use strict';
    angular
        .module('questiaPlatformApp')
        .factory('SurveyDistributionsUtils', ['$rootScope', SurveyDistributionsUtils]);

    function SurveyDistributionsUtils($rootScope) {
        return {
            validateDistributions: validateDistributions,
            areDistributionsIdentical: areDistributionsIdentical
        };

        function validateDistributions(distributionsData) {
            return {
                age: validateAgeDistribution(distributionsData.ageDistributions),
                sex: validateSexDistribution(distributionsData.sexDistributions),
                location: validateLocationDistribution(distributionsData.locationDistributions)
            }
        }

        function validateAgeDistribution(ageDistributions) {
            if (ageDistributions.distTypeActive) {
                for (var idx in ageDistributions.distributionsList) {
                    if (!ageDistributions.distributionsList[idx].percentage) {
                        return "One of the percentages is incomplete!";
                    }

                    if(typeof ageDistributions.distributionsList[idx].maxAge === "undefined" || ageDistributions.distributionsList[idx].maxAge > 99) {
                        return "Incomplete or invalid max age, max age value can be 99 or lower!";
                    }
                }

                if (!distributionPercentsAddTo100(ageDistributions.distributionsList)) {
                    return "Sum of percentages should be 100%";
                }
            }

            return "";
        }

        function validateSexDistribution(sexDistributions) {
            if (sexDistributions.distTypeActive) {
                for (var idx in sexDistributions.distributionsList) {
                    if (!sexDistributions.distributionsList[idx].percentage) {
                        return "One of the percentages is incomplete!";
                    }
                }

                if (!distributionPercentsAddTo100(sexDistributions.distributionsList)) {
                    return "Sum of percentages should be 100%";
                }
            }

            return "";
        }

        function validateLocationDistribution(locationDistributions) {
            if (locationDistributions.distTypeActive) {
                if(!locationDistributions.divisionId
                    || !locationDistributions.distributionsList
                    || locationDistributions.distributionsList.length === 0){
                    return "No division selected for location distribution!"
                }
                for (var idx in locationDistributions.distributionsList) {
                    if (!locationDistributions.distributionsList[idx].percentage) {
                        return "One of the percentages is incomplete!";
                    }
                }

                if (!distributionPercentsAddTo100(locationDistributions.distributionsList)) {
                    return "Sum of percentages should be 100%";
                }
            }

            return "";
        }

        function distributionPercentsAddTo100(distributionList) {
            const totalPercent = distributionList.reduce((acc, distribution) => acc + distribution.percentage, 0)
                , error = 0.001;

            return Math.abs(totalPercent - 100) < error;
        }

        function areDistributionsIdentical(distributionsData1, distributionsData2) {
            return areAgeDistributionsIdentical(distributionsData1.ageDistributions, distributionsData2.ageDistributions) &&
                areSexDistributionsIdentical(distributionsData1.sexDistributions, distributionsData2.sexDistributions) &&
                areLocationDistributionsIdentical(distributionsData1.locationDistributions, distributionsData2.locationDistributions)
        }

        function areAgeDistributionsIdentical(ageDistributions1, ageDistributions2) {
            if (ageDistributions1.distTypeActive !== ageDistributions2.distTypeActive)
                return false;

            if (ageDistributions1.distributionsList && ageDistributions2.distributionsList) {
                if (ageDistributions1.distributionsList.length !== ageDistributions2.distributionsList.length)
                    return false;

                return ageDistributions1.distributionsList.every((ageDistribution =>
                    ageDistributions2.distributionsList.findIndex(distribution => distribution.minAge === ageDistribution.minAge
                        && distribution.maxAge === ageDistribution.maxAge && distribution.percentage === ageDistribution.percentage) !== -1
                ));
            }

            return !ageDistributions1.distributionsList && !ageDistributions2.distributionsList;
        }

        function areSexDistributionsIdentical(sexDistributions1, sexDistributions2) {
            if (sexDistributions1.distTypeActive !== sexDistributions2.distTypeActive)
                return false;

            if (sexDistributions1.distributionsList && sexDistributions2.distributionsList) {
                if (sexDistributions1.distributionsList.length !== sexDistributions2.distributionsList.length)
                    return false;

                return sexDistributions1.distributionsList.every((sexDistribution =>
                        sexDistributions2.distributionsList.findIndex(distribution => distribution.choiceId === sexDistribution.choiceId
                            && distribution.labelId === sexDistribution.labelId && distribution.percentage === sexDistribution.percentage) !== -1
                ));
            }

            return !sexDistributions1.distributionsList && !sexDistributions2.distributionsList;
        }

        function areLocationDistributionsIdentical(locationDistributions1, locationDistributions2) {
            if (locationDistributions1.distTypeActive !== locationDistributions2.distTypeActive)
                return false;

            if (locationDistributions1.divisionId !== locationDistributions2.divisionId)
                return false;

            if (locationDistributions1.distributionsList && locationDistributions2.distributionsList) {
                if (locationDistributions1.distributionsList.length !== locationDistributions2.distributionsList.length)
                    return false;

                return locationDistributions1.distributionsList.every((locationDistribution =>
                        locationDistributions2.distributionsList.findIndex(distribution => distribution.labelId === locationDistribution.labelId
                            && distribution.regionId === locationDistribution.regionId && distribution.percentage === locationDistribution.percentage) !== -1
                ));
            }

            return !locationDistributions1.distributionsList && !locationDistributions2.distributionsList;
        }
    }
})();

