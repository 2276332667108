(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .factory('TranslateService', TranslateService);

    TranslateService.$inject = ['$http'];
    function TranslateService($http) {
        return {
            getTexts : function (languageDirectory, okCallback, koCallback) {
                $http.get("/rest/deploy/json/" + languageDirectory)
                    .then(function successCallback(response) {
                            okCallback(response);
                        }, function errorCallback(response) {
                            koCallback && koCallback(response);
                        }
                    );
            },
            saveJson : function (json, languageDirectory, okCallback, koCallback) {
                $http.post("/rest/deploy/json/" + languageDirectory, json,{
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                        'Accept': 'application/json'
                    }
                })
                    .then(function successCallback(response) {
                            okCallback(response);
                        }, function errorCallback(response) {
                            koCallback && koCallback(response);
                        }
                    );
            },
            executeGulpWithFunction : function (gulpFunction, okCallback, koCallback) {
                $http.post("/rest/deploy/gulp/" +
                    "" + gulpFunction)
                    .then(function successCallback(response) {
                            okCallback(response);
                        }, function errorCallback(response) {
                            koCallback && koCallback(response);
                        }
                    );
            }
        }
    }

})();
