(function () {
	'use strict';

	angular
		.module('questiaPlatformApp').directive( 'mailPreview', function () {
		return {
			restrict: 'AE',
			scope: {
				directiveData: "=",
				lang: "="
			},
			template: '<ng-include src="getTemplateUrl()"/>',
			controllerAs: 'vm',
			controller: ['$scope', function ($scope) {
				$scope.getTemplateUrl = function() {
					return "specific/admin-emails/" + $scope.lang + "/emptyemail.subview.html";
				}
			}]
		};
	});
})();