(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.controller('SurveysController', SurveysController);

	SurveysController.$inject = ['$scope', '$window', 'SurveysService', '$state', '$q', '$filter', 'NgTableParams', 'ngTableDefaultGetData','PermissionStrategies', 'SurveyTranslationUtils', 'SurveyTranslateService', '$mdDialog', '$rootScope', '$cookies'];
	function SurveysController($scope, $window, SurveysService, $state, $q, $filter, NgTableParams, ngTableDefaultGetData, PermissionStrategies, SurveyTranslationUtils, SurveyTranslateService, $mdDialog, $rootScope, $cookies) {

		var vm = this;
		vm.enableElement = PermissionStrategies.enableElement;
		vm.disableElement = PermissionStrategies.disableElement;
		var originatorEv;

		vm.surveyTypes = ['Profile', 'Test', 'Short', 'Recurrent', 'RCS'];
		vm.surveyTypesJs = [{id:'test', title:'test'},{id:'short', title:'short'},{id:'profile', title:'profile'},{id:'recurrent', title:'recurrent'},{id:'rcs', title:'rcs'}];
		if($rootScope.globals.permissions.includes("predictor")){
			vm.surveyTypes.push('Forecast');
			vm.surveyTypesJs.push({id:'forecast', title: 'forecast'});
		}

		vm.getTable = function () {
			SurveysService.GetSurveys(function successCallback(response) {
					vm.quests = response.data.filter(function(obj){
						return obj.quest_type !== 'archived';
					});

					vm.quests.map(function (quest) {
						quest.answers_count_first_24h = quest.answers_count_first_24h !== null
							? quest.answers_count_first_24h
							: -1;
					});

					var initialParamsFromCookie = $cookies.getObject('surveys_list_params');
					var initialParams;
					if (initialParamsFromCookie) {
						initialParams = initialParamsFromCookie;
					} else {
						initialParams = {
							page: 1,
							count: 10, // initial page size
							sorting: {'quest_id': "desc"}
						};
					}
					var initialSettings = {
						// page size buttons (right set of buttons in demo)
						counts: [],
                        getData: function (params) {
							var now = new Date();
							//expire date after 60 minutes
							var exp = new Date(now.getTime() + 60*60*1000);
							$cookies.putObject('surveys_list_params', params.url(), {expires: exp});
							return ngTableDefaultGetData(vm.quests, params);
                        }
					};
					vm.tableParams = new NgTableParams(initialParams, initialSettings);
					vm.getQuestsAnswers();
					vm.getTranslatedSurveyIds();
				},
				function fail(response) {
					console.log(response);
				});
		};
		(function initController() {
			vm.activeSubmenu = "surveys";
			vm.breadcrumbs = "Panel: Surveys";
			vm.getTable();
		})();

		vm.getQuestsAnswers = function () {
			SurveysService.GetQuestsAnswers(
				function successCallback(response) {
					var totals = response.data;

					vm.quests.forEach(function (quest) {
						quest.totals = totals.hasOwnProperty(quest.quest_id)
							? totals[quest.quest_id][0]
							: 0;
					});
				},
				function failCallback(response) {

				});
		};

		vm.getTranslatedSurveyIds = function () {
			SurveysService.GetTranslatedSurveyIds(
				function successCallback(response) {
					vm.translatedSurveyIds = response.data.allLanguagesTranslatedQuestsIds;
					vm.panelLanguagesTranslatedSurveyIds = response.data.panelLanguagesTranslatedQuestsIds;
					vm.isTranslationMandatory = response.data.translationMandatory;
				},
				function failCallback(response) {
					vm.translatedSurveyIds = [];
					vm.panelLanguagesTranslatedSurveyIds = [];
				}
			);
		};

		vm.goToChart = function (id) {
			$state.go("charts", {qid: id});
		};

		vm.expandThreadStatus = function (id, index) {
			if (!vm.quests[index].expandThread) {
				SurveysService.GetThreadStatus(id, function successCallback(response) {
						vm.quests[index].thread = response.data;
					},
					function errorCallback(response) {

					});
			}

			vm.quests[index].expandThread = !vm.quests[index].expandThread;
			vm.quests[index].expandRespondentsType = false;
		};

		vm.expandRespondentsStatus = function (id, index) {
			if (!vm.quests[index].expandRespondentsType) {
				SurveysService.GetRespondentsByType(id, function successCallback(response) {
						vm.quests[index].respondentsStatus = response.data;
					},
					function errorCallback(response) {

					});
			}
			vm.quests[index].expandRespondentsType = !vm.quests[index].expandRespondentsType;
			vm.quests[index].expandThread = false;
		};

		vm.getJSON = function (id, index) {
			vm.quests[index].JSON = true;
			SurveysService.GetSurveyJson(id, false,null, function successCallback(response) {
					var link = document.createElement("a");
					link.download = "quest" + id + ".json";
					var data = "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(response.data, null, 2));
					link.href = "data:" + data;
					link.click();
					vm.quests[index].JSON = false;
				},
				function fail(response) {
					vm.quests[index].JSON = false;
					console.log(response);
				});
		};

		vm.toggleAutoMail = function (qid, index) {
			SurveysService.ToggleAutoMail(qid, function successCallback(response) {
					vm.getTable();
				},
				function fail(response) {
					console.log(response);
					alert("error state not changed");
				});
		};

		vm.toggleActiveState = function (qid) {
			SurveysService.CloseQuest(qid, function successCallback(response) {
					vm.getTable();
				},
				function fail(response) {
					console.log(response);
					alert("error state not changed");
				});
		};

		vm.goToEdit = function (qid) {
			$state.go("survey", {qid: qid});
		};

		vm.goToNewSurvey = function () {
			$state.go("survey");
		};

		vm.goToSpeedsters = function (qid) {
			$state.go("speedsters", {qid: qid});
		};

		vm.goToCint = function (qid) {
			window.location = '/surveys/external/' + qid;
		};

		vm.goToRcs = function (questId) {
			window.location = '/surveys/rcs/' + questId;
		};

		vm.openMenu = function ($mdMenu, ev) {
			originatorEv = ev;
			$mdMenu.open(ev);
		};

		vm.translateSurvey = function (qid) {
			$state.go("surveytranslate", {qid:qid});
		};

		vm.changeSurveyType = function (qid, type) {
			function changeType(){
				SurveysService.ChangeSurveyType(qid, type.toLowerCase(),
					function successCallback(response) {
						vm.getTable();
					},
					function fail(response) {
						$mdDialog.show({
							template:
								'<md-dialog md-theme="default">' +
								'   <md-dialog-content class="md-dialog-content">' +
								'		<div style="display:flex; justify-content: center">' +
								'			<img src="images/warning.PNG" alt=""/>' +
								'		</div>' +
								'       <div class="md-dialog-content-body" style="text-align: center;margin-top:3%">' +
								'           <p>' + response.data + '</p>' +
								'       </div>' +
								'   </md-dialog-content>' +
								'   <md-dialog-actions style="justify-content: center;margin-top:6%;margin-bottom:5%">' +
								'       <md-button ng-click="cancel()" class="md-background md-hue-1">' +
								'           Close' +
								'       </md-button>' +
								'   </md-dialog-actions>' +
								'</md-dialog>',
							locals: {},
							controller: function ($scope, $mdDialog) {
								$scope.cancel = function () {
									$mdDialog.cancel();
								};
							}
						}).then(
							function () {
							},
							function () {
							}
						);
					}
				);
			}
			/* When changing to short, check if the survey
			 * is translated in all non-default languages */
			if ((type === 'Short' || type === 'Profile' || type === 'RCS')) {
				var errorMessage = "";
				if (vm.panelLanguagesTranslatedSurveyIds.indexOf(qid) === -1)
					errorMessage = "Please translate the survey to all panel languages before changing its type to " + type + "!";
				else if (vm.translatedSurveyIds.indexOf(qid) === -1 && vm.isTranslationMandatory)
					errorMessage = "Please translate the survey to all languages before changing its type to " + type + "!";

				if (errorMessage) {
					$mdDialog.show(
						$mdDialog.alert()
							.title('Survey type change error')
							.textContent(errorMessage)
							.ok('Yes')
					);
					return ;
				}
			}
			changeType();
			// }
		};
	}
})();
