(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .controller('RaffleController', RaffleController);

    RaffleController.$inject = ['$rootScope', 'RaffleService', 'IncentivesService', 'DeployService', 'LanguagesService'];
    function RaffleController($rootScope, RaffleService, IncentivesService, DeployService, LanguagesService) {
        var vm = this;

        vm.drawWinners = function (winnersConfig) {
            delete vm.winnersSuccess;
            var months = [ "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December" ];
            vm.raffleMonth = months[new Date(winnersConfig.initialDate).getMonth()];
            winnersConfig.initialDate = moment(winnersConfig.initialDate).format("YYYY-MM-DD");
            winnersConfig.finalDate = moment(winnersConfig.finalDate).format("YYYY-MM-DD");
            RaffleService.getWinners(winnersConfig,
                function (response) {
                    if (response.status === 202) {
                        if (response.data.error === 'ticket_no_exceeded') console.log("Tickets number are above maximum limit");
                        if (response.data.error === 'draw_limit_exceeded') vm.remainingDrawCounts = 0;
                        return;
                    }

                    vm.winnersCollection = [];
                    if(response.data.length != 0) {
                        for (var i = 0; i < response.data.length; i++) {
                            vm.winnersCollection.push({
                                ticketId: response.data[i].ticket_id,
                                ticketType: response.data[i].ticket_type,
                                userId: response.data[i].user_id,
                                userName: response.data[i].user_lastname + " " + response.data[i].user_surname,
                                userEmail: response.data[i].user_email,
                                userLastLogin: response.data[i].user_last_login ? response.data[i].user_last_login : "Never logged in",
                                // userLastLogin: response.data[i].user_last_login ?
                                //     moment(new Date(response.data[i].user_last_login * 1000)).format("YYYY-MM-DD") : "Never logged in",
                                userPhone : response.data[i].user_phone ? response.data[i].user_phone : "Not completed",
                                userAge: response.data[i].user_age !== null ? response.data[i].user_age : "Not completed",
                                userSex: response.data[i].user_sex === null ? "Not completed" :
                                    (response.data[i].user_sex ? "Female" : "Male"),
                                userCity: response.data[i].user_city_name !== "" ? response.data[i].user_city_name : "Not completed",
                                userCounty: response.data[i].user_county_name !== "" ? response.data[i].user_county_name : "Not completed",
                                userLastWinDate: response.data[i].user_last_win_date !== null ?
                                    moment(new Date(response.data[i].user_last_win_date)).format("YYYY-MM-DD") : "Not available"
                            });
                        }
                    }
                },
                function (response) {
                    console.log(response);
                }
            );
        };

        vm.makeWinners = function () {
            var winnersArr = [];
            for(var i = 0; i < vm.winnersCollection.length; i++) {
                winnersArr.push(vm.winnersCollection[i].userId);
            }
            RaffleService.makeWinners(winnersArr,
                function (response) {
                    vm.winnersSuccess = true;
                },
                function (response) {
                    console.log(response);
                    vm.winnersSuccess = false;
                }
            );
        };

        vm.currentRaffleTime = function(){
            return moment().toString();
        };

        vm.getUserCount  = function(winnersConfig){

            var months = [ "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December" ];

            if ( typeof (winnersConfig) !== 'undefined') {


                if (typeof (winnersConfig.initialDate) !== 'undefined') {
                    vm.raffleMonth = months[new Date(winnersConfig.initialDate).getMonth()];
                    if (winnersConfig.finalDate)
                        vm.raffleMonth = months[new Date(winnersConfig.finalDate).getMonth()];
                }

                if (winnersConfig.initialDate)
                    winnersConfig.initialDate = moment(winnersConfig.initialDate).format("YYYY-MM-DD");
                if (winnersConfig.finalDate)
                    winnersConfig.finalDate = moment(winnersConfig.finalDate).format("YYYY-MM-DD");

                RaffleService.countWinners(winnersConfig,
                    function (response) {
                        vm.noFiltredUsers = response.data.noFiltredUsers;
                        vm.noFiltredTickets = response.data.noFiltredTickets;
                    },
                    function (response) {
                        console.log(response);
                    }
                );
            }
        };

        vm.resetTickets = function (ticketDates) {
            vm.showResetSpinner = true;
            delete vm.resetStatus;
            var stringTicketDates = angular.copy(ticketDates);

            stringTicketDates['TICKET_START_DATE'] = moment(stringTicketDates['TICKET_START_DATE']).format("YYYY-MM-DD 00:00:00");
            stringTicketDates['TICKET_END_DATE'] = moment(stringTicketDates['TICKET_END_DATE']).format("YYYY-MM-DD 23:59:59");

            IncentivesService.resetTickets(stringTicketDates,
                function ok(response) {
                    vm.resetStatus = {
                        code: 1,
                        msg: "Tickets reset successfully."
                    };
                    vm.showResetSpinner = false;
                },
                function err(response) {
                    if (response.status === 500) {
                        vm.resetStatus = {
                            code: -1,
                            msg: response.data
                        };
                    } else {
                        vm.resetStatus = {
                            code: -2,
                            msg: "Something went wrong."
                        };
                    }
                    vm.showResetSpinner = false;
                });
        };

        vm.getLanguages = function () {
            LanguagesService.GetPanelLanguages(
                function (success) {
                    vm.availableLanguages = success.data;
                    vm.languagesLoaded = true;
                },
                function (error) {
                    vm.languagesLoaded = false;
                    console.log(error)
                }
            );
        };

        vm.uploadPdfFile = function (file, language) {
            var formData = new FormData();
            var lang;

            if(typeof language === "undefined") {
                lang = "NO_LANGUAGE";
            } else {
                lang = language.lang_panel_directory_name;
            }

            formData.append('file', file);
            DeployService.uploadPdfFile(
                formData,
                lang,
                function (success) {
                    vm.pdfUploadCheck = {
                        show: true,
                        uploaded: true,
                        message: "Successfully uploaded pdf!"
                    };
                    console.log(success.data)
                },
                function (error) {
                    vm.pdfUploadCheck = {
                        show: true,
                        uploaded: false,
                        message: "Failed to upload pdf!"
                    };
                    console.log(error)
                }
            )
        };

        vm.getDrawCounts = function () {
            RaffleService.getDrawCounts(
                function (success) {
                    vm.remainingDrawCounts = success.data.remaining_draw_counts;
                    vm.raffleTicketsLimit = success.data.raffle_tickets_limit;
                },
                function (error) {
                    console.log(error)
                }
            );
        };

        (function initController() {
            vm.breadcrumbs = "Rewards: Raffle Draw";
            vm.activeSubmenu = 'panel_rewards';
            vm.remainingDrawCounts = 0;

            IncentivesService.getDates(
                function success(response) {
                    vm.ticketDates = response.data;
                },
                function err(response) {
                    console.log(response);
                }
            );
            vm.getLanguages();
            vm.getDrawCounts();
        })();

    }
})();