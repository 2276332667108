(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.controller('SurveyTranslateController', SurveyTranslateController);

	SurveyTranslateController.$inject = ['$location', '$stateParams', '$mdDialog', 'LanguagesService', 'SurveysService',  'SurveyTranslationUtils', 'SurveyTranslateService', 'QuestiaUtils'];
	function SurveyTranslateController($location, $stateParams, $mdDialog, LanguagesService, SurveysService, SurveyTranslationUtils, SurveyTranslateService, QuestiaUtils) {
		var vm = this;

		vm.changeLanguage = function(language) {
			function changeLanguage() {
				vm.translationSaved = (vm.originalSurveyTitle === vm.surveyTitle) &&
					(JSON.stringify(vm.originalTranslation) === JSON.stringify(vm.translation)) &&
					(JSON.stringify(vm.originalMatrixOptions) === JSON.stringify(vm.matrixOptions) );

				if (vm.translationSaved) {
					vm.previousLanguage = language;
					vm.translateSurvey(language);
				}

				if (vm.translation && !vm.translationSaved) {
					var confirm = $mdDialog.confirm()
						.title("Change translation language to " + language.lang_ui_name)
						.textContent("Warning! Make sure you saved any changes made to the current language. If you already did, click YES to change the language!")
						.cancel("Cancel")
						.ok("YES");

					$mdDialog.show(confirm).then(function() {
						vm.translateSurvey(language);
					}, function() {
						vm.toLanguage = vm.previousLanguage;
					});
				}
			}
			changeLanguage();
		};

		vm.translateSurvey = function(language) {
			if (!language) return ;

			SurveyTranslateService.GetTranslation(vm.qid, language.lang_id,
				function(response) {
					vm.originalSurveyTitle = response.data.surveyTitle;
					vm.surveyTitle = angular.copy(vm.originalSurveyTitle);
					vm.originalTranslation = response.data.questions;
					vm.translation = angular.copy(vm.originalTranslation);
					vm.originalMatrixOptions = vm.createMatrixOptionsMapFromTranslation(vm.originalTranslation);
					vm.matrixOptions = angular.copy(vm.originalMatrixOptions);
					vm.translationSaved = true;
				},
				function(response) {
					vm.originalSurveyTitle = "";
					vm.surveyTitle = "";
					vm.originalTranslation = {};
					vm.translation = {};
					vm.originalMatrixOptions = {};
					vm.matrixOptions = {};
					vm.translationSaved = false;
				})
		};

		vm.createMatrixOptionsMapFromTranslation = function(translation) {
			var matrixOptionsMap = {};
			for (var qid in translation) {
				if (!QuestiaUtils.isEmptyObject(translation[qid].selects)) {
					var question = vm.quest.questions.find(function (question) { return question.id === qid});
					if (question.type !== "selects")
						continue ;
					matrixOptionsMap[qid] = {options: vm.createMatrixOptionsForQuestion(question, vm.translation)};
				}
			}
			return matrixOptionsMap;
		};

		vm.createMatrixOptionsForQuestion = function (question, translation) {
			var questionId = question.id;
			var questionOptions = question.selects[0].options;
			var questionMatrixOptions = {};
			for (var selectId in translation[questionId].selects) {
				if (!QuestiaUtils.isEmptyObject(translation[questionId].selects[selectId]) && !QuestiaUtils.isEmptyObject(translation[questionId].selects[selectId].options)) {
					questionMatrixOptions = angular.copy(translation[questionId].selects[selectId].options);
					break;
				}
			}

			return questionOptions.reduce(function(acc, option) {
				var optionValue = option.value;
				acc[optionValue] = acc[optionValue] || {};
				acc[optionValue] = questionMatrixOptions[optionValue] || "";
				return acc;
			}, {});
		};

		vm.cloneSelects = function() {
			for (var qid in vm.matrixOptions) {
				if (QuestiaUtils.isEmptyObject(vm.translation[qid]))
					vm.translation[qid] = {selects: {}};
				else if (QuestiaUtils.isEmptyObject(vm.translation[qid].selects))
					vm.translation[qid].selects = {};
				var options = angular.copy(vm.matrixOptions[qid].options);
				var question = vm.quest.questions.find(function(question) {return question.id === qid});
				var selects = question.selects;
				selects.map(function (select) {
					if (QuestiaUtils.isEmptyObject(vm.translation[qid].selects[select.id]))
						vm.translation[qid].selects[select.id] = {options: options};
					else
						vm.translation[qid].selects[select.id].options = options;
				});
			}
		};

		vm.saveTranslation = function() {
			vm.cloneSelects();
			var translationValidation = SurveyTranslationUtils.validateTranslation(vm.surveyTitle, vm.quest, vm.translation);
			var isValidTranslation = (translationValidation == "");
			if (!isValidTranslation) {
				var confirm = $mdDialog.confirm()
					.title("Save translation")
					.textContent("Some fields are not translated. Are you sure you want to save the intermediary translation?")
					.cancel("Cancel")
					.ok("YES");
				$mdDialog.show(confirm).then(function() {
					vm.save(isValidTranslation);
				}, function() {
				});
			}
			else
				vm.save(isValidTranslation);
		};

		vm.save = function(isValidTranslation) {
			var toLanguagePayload = {
				lang_id: vm.toLanguage.lang_id
			};
			SurveyTranslateService.SaveTranslation(vm.qid, {
					language: toLanguagePayload,
					questions: vm.translation,
					surveyTitle : vm.surveyTitle
				},
				function(response) {
					vm.translationSaved = true;
					vm.originalSurveyTitle = vm.surveyTitle;
					vm.originalTranslation = vm.translation;
					vm.originalMatrixOptions = vm.matrixOptions;
					vm.toLanguageUIName = vm.toLanguage.lang_ui_name;
					vm.languagesMap.set(vm.toLanguage, vm.toLanguageUIName + (isValidTranslation ? " - translated" : " - not translated"));
					$mdDialog.show(
						$mdDialog.alert()
							.title('Translation success')
							.textContent("Your translation had been saved!")
							.ok('OK')
					);
				},
				function(response) {
					$mdDialog.show(
						$mdDialog.alert()
							.title('Translation error')
							.textContent("Your translation could not be saved!")
							.ok('OK')
					);
				}
			);
		};

		vm.getSurvey = function() {
			new Promise(LanguagesService.GetAllLanguages).then(
				function(value) {
					vm.availableLanguages = value.data.languagesList;
					vm.defaultLanguageId = value.data.defaultLanguageId;
					let defaultLangIndex = vm.availableLanguages.findIndex(lang => lang.lang_id === vm.defaultLanguageId);
					if (defaultLangIndex !== -1)
						vm.availableLanguages.splice(defaultLangIndex, 1);

					SurveysService.GetSurveyJson(vm.qid,false,null,
						function(response) {
							vm.quest = response.data;
							vm.getLanguagesUsedForSurvey();
						});
				}
			);
		};

		vm.getLanguagesUsedForSurvey = function() {
			SurveysService.GetUsedLanguagesForQuestTranslation(vm.qid,
				function(response) {
					vm.usedLanguages = response.data;
					vm.languagesLoaded = true;
					vm.availableLanguages.forEach(function(language) {
						var index = vm.usedLanguages.findIndex(function (elem) {return elem.lang_id == language.lang_id});
						var translated = language.lang_ui_name + (index != -1 ? " - translated" : " - not translated");
						vm.languagesMap.set(language, translated);
					});
				},
				function(response) {
				})
		};

		(function initController() {
			delete vm.quest;
			delete vm.translation;
			delete vm.matrixOptions;

			vm.breadcrumbs = "Surveys: Translate Survey";
			vm.translationSaved = false;
			vm.languagesLoaded = false;

			vm.qid = $location.search().qid;
			vm.languagesMap = new Map();

			vm.previousLanguage = null;

			vm.getSurvey();
		})();
	}
})();
