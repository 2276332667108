(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.controller('RcsController', RcsController);

	RcsController.$inject = ['$scope', '$rootScope', 'SurveysService', 'RcsUtils', '$stateParams', '$interval','$window'];
	function RcsController($scope, $rootScope, SurveysService, RcsUtils, $stateParams, $interval, $window) {
		const vm = this;

		const AUTO_REFRESH = 600000;

		function getZoomAndSave(e) {
            var questionId = e.chart.questionData.questionId;
            vm.questionsData[questionId].startIndex = e.startIndex;
            vm.questionsData[questionId].endIndex = e.endIndex;
        }

		vm.createChoicesArray = function (stats) {
			var choices = [];
			for (var key in stats.labels) {
				choices.push({
					"value": key,
					"label": stats.labels[key]
				});
			}
			return choices;
		}

		vm.refreshChartData = function (questionId) {
			var stats = angular.copy(vm.chartData[questionId].stats);
			stats = vm.removeValuesFromStats(stats, vm.hiddenChoices[questionId]);
			var dataProvider = [];
			var volumes = {};
			for (var i = stats.data.length - 1; i >= 0; i--) {
				var perc = RcsUtils.getPercentages(stats.data[i], i);
				dataProvider.push(perc);
				volumes[stats.data[i].date.substring(0, 16)] = perc.total;
			}
			vm.chartData[questionId].dataProvider = dataProvider;
			vm.chartData[questionId].volumes = volumes;

			vm.questionsData[questionId].chart.dataProvider = vm.chartData[questionId].dataProvider;
			vm.questionsData[questionId].chart.validateData();
		}

		vm.removeValuesFromStats = function (stats, values) {
			for(var i in values) {
				var currentValue = values[i];
				for(var j in stats.data) {
					delete stats.data[j][currentValue];
				}
				delete stats.labels[currentValue];
			}
			return stats;
		}

		vm.getStats = function (questionId, autoRefresh) {
			vm.chartData[questionId] = {
				stats: undefined,
				dataProvider: [],
				volumes: {}
			};

			function startAutoRefresh() {
				vm.questionsData[questionId].intervalID = $interval(function () {
					SurveysService.GetRcs(questionId, 1, vm.withWeighting,
                        function successCallback(response) {
							var stats = response.data;
							vm.chartData[questionId].stats.push(angular.copy(stats.data[0]));
							stats = vm.removeValuesFromStats(stats, vm.hiddenChoices[questionId]);

							const prc = RcsUtils.getPercentages(stats.data[0], vm.chartData[questionId].dataProvider.length);

							vm.chartData[questionId].dataProvider.push(prc);
                            vm.chartData[questionId].volumes[stats.data[0].date.substring(0, 16)] = prc.total;

                            vm.questionsData[questionId].chart.questionId = questionId;
                            vm.questionsData[questionId].chart.dataProvider = vm.chartData[questionId].dataProvider;
                            vm.questionsData[questionId].chart.validateData();

                            vm.questionsData[questionId].endIndex = vm.chartData[questionId].dataProvider.length - 1;
                            vm.questionsData[questionId].chart.zoomToIndexes(vm.questionsData[questionId].startIndex, vm.questionsData[questionId].endIndex);
                        },
						function errorCallback(response) {
							console.log("error");
						});
				}, AUTO_REFRESH);
			}

			SurveysService.GetRcs(questionId, 0, vm.withWeighting,
				function successCallback(response) {
					const stats = response.data;
					vm.chartData[questionId].stats = angular.copy(response.data);
					vm.hiddenChoices[questionId] = [];
					vm.choicesArray[questionId] = vm.createChoicesArray(vm.chartData[questionId].stats);

					if(vm.withWeighting) {
						stats.question.question_text += " (weighted)";
					}

					for (var i = stats.data.length - 1; i >= 0; i--) {
						var perc = RcsUtils.getPercentages(stats.data[i], i);
						vm.chartData[questionId].dataProvider.push(perc);
						vm.chartData[questionId].volumes[stats.data[i].date.substring(0, 16)] = perc.total;
					}

					if (vm.questionsData[questionId] === null || typeof vm.questionsData[questionId] !== 'object') {
						vm.questionsData[questionId] = {};
					}

					vm.questionsData[questionId].startIndex = 0;
					vm.questionsData[questionId].endIndex =  vm.chartData[questionId].dataProvider.length - 1;

                    vm.questionsData[questionId].chart = RcsUtils.buildRcsChart(questionId, stats, vm.chartData[questionId]);

                    vm.questionsData[questionId].chart.addListener("zoomed", getZoomAndSave.bind(this));
                    vm.questionsData[questionId].chart.questionData = {
                        questionId: questionId
                    };

					if (autoRefresh) {
						startAutoRefresh();
					}
				},
				function errorCallback(response) {
					console.log("error");
				});
		};

		function fetchRcsQuestionsStats(questId) {
			SurveysService.GetSurveyJson(questId, false, false,
				(response) => {
					const quest = response.data;
					if (quest.type !== 'rcs') {
						console.log("The quest " + questId + " is not of RCS type");
						return;
					}

					const autoRefresh = new Date().getTime() < quest.end_date;

					vm.rcsQuestions = quest.questions.filter((question) => question.type === 'singleChoice');
					vm.rcsQuestions.forEach((question) => {
						vm.getStats(question.question_id, autoRefresh);
					});
				},
				(err) => {
					console.log("Error fetching RCS survey: " +  questId);
				}
			);
		}

		(function initController() {
			vm.breadcrumbs = "Surveys: RCS";
			vm.activeSubmenu = 'surveys';

			vm.hiddenChoices = {};
			vm.choicesArray = {};
			vm.chartData = {};

			vm.rcsQuestions = [];
			vm.questionsData = {};
			vm.withWeighting = false;

			if ($stateParams.withWeighting) {
				vm.withWeighting = $stateParams.withWeighting === "true";
			}

			if ($stateParams.questId) {
				const questId = $stateParams.questId;
				fetchRcsQuestionsStats(questId);
			} else {
				SurveysService.GetRcsCurrentQuestId(
					(response) => {
						const questId = response.data;

						if (questId === -1) {
							console.log("No current RCS");
							return;
						}

						fetchRcsQuestionsStats(questId);
					},
					(err) => {
						console.log("Error fetching current RCS");
					}
				);
			}

			$scope.$on('$destroy',function(){
				for(var key in vm.questionsData){
				    var intervalID = vm.questionsData[key].intervalID;
				    if(intervalID) $interval.cancel(intervalID);
                }
			});
		})();
	}
})();
