(function () {
		'use strict';

		angular
			.module('questiaPlatformApp')
			.controller('ResendController', ResendController);

		ResendController.$inject = ['$location', '$rootScope', '$state', 'EmailsService', 'RegionsService', 'QuestiaUtils', '$mdDialog', 'FilterUtils', 'NgTableParams', 'ngTableDefaultGetData'];
		function ResendController($location, $rootScope, $state, EmailsService, RegionsService, QuestiaUtils, $mdDialog, FilterUtils, NgTableParams, ngTableDefaultGetData) {
			var vm = this;
			vm.foundMails = undefined;

			vm.getCustomMailHistoryGroups = function () {
				EmailsService.getCustomMailHistoryGroups(function(response) {

					// Show message for non existent response.data
					vm.foundMails = response.data;
					if (vm.foundMails.length === 0) {
						QuestiaUtils.showAlert(
							'There are no emails in the history list'
						);
						return;
					}

					let initialSettings = {
						// page size buttons (right set of buttons in demo)
						counts: [],
						paginationMaxBlocks: 20,
						paginationMinBlocks: 2,
						getData: function (params) {
							vm.initialParams = params.url();
							return ngTableDefaultGetData(vm.foundMails, params);
						}
					};

					vm.tableParams = new NgTableParams(vm.initialParams, initialSettings);
				}, function(response) {
					QuestiaUtils.showAlert(
						'Something went wrong, send the following info to support@questiagroup.com :',
						JSON.stringify(response)
					);
				});
			};

			vm.editEmail = function (mail_history_group_id) {
				if(mail_history_group_id) {
					window.location = "/engagement/email/edit/" + mail_history_group_id;
				} else {
					window.location = "/engagement/email/edit";
				}
			};

			vm.cancelEmail = function (email) {
				let emailDate = moment(email.date).format("YYYY-MM-DD HH:mm:ss");

				var confirm = $mdDialog.confirm()
					.title("Cancel email")
					.textContent(`Are you sure you want to cancel ${email.subject} from being scheduled for ${emailDate}?`)
					.cancel("NO")
					.ok("YES");

				$mdDialog.show(confirm).then(function() {
					EmailsService.cancelEmail(email.mailHistoryGroupId,
						function (response) {
							vm.getCustomMailHistoryGroups();
						},
						function (error) {
							$mdDialog.show(
								$mdDialog.alert()
									.title('Cancel email')
									.textContent('Failed to cancel email!')
									.ok('Close')
							);
						}
					);
					},function () {}
				);
			};

			vm.removeEmail = function (email) {
				var confirm = $mdDialog.confirm()
					.title("Delete email")
					.textContent(`Are you sure you want to delete ${email.subject} ?`)
					.cancel("NO")
					.ok("YES");

				$mdDialog.show(confirm).then(function() {
					EmailsService.removeEmail(email.mailHistoryGroupId,
						function (response) {
							vm.getCustomMailHistoryGroups();
						},
						function (error) {
							$mdDialog.show(
								$mdDialog.alert()
									.title('Delete email')
									.textContent('Failed to delete email!')
									.ok('Close')
							);
						}
					);
					},function () {}
				);
			};

			(function initController() {
				vm.progress = false;
				vm.breadcrumbs = "Emails";
				vm.activeSubmenu = 'panel';

				vm.initialParams = {
					count: 20
				};

				vm.getCustomMailHistoryGroups();
			})();

		}
	}
)();