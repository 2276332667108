(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('DashboardService', DashboardService);

	DashboardService.$inject = ['$http'];
	function DashboardService($http) {
		var service = {};

		service.getStatistics = getStatistics;
		service.getStatisticsForPeriod = getStatisticsForPeriod;
		service.getUsersFromSource = getUsersFromSource;
		service.getStatsByQuestType = getStatsByQuestType;
		service.getEvents = getEvents;
		service.postChartEvent = postChartEvent;
		return service;

		function getStatistics(okCallback, koCallback) {
			$http({
				url: "/rest/users/statistics",
				type: 'get'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function getEvents(okCallback, koCallback) {
			$http({
				url: "/rest/chart/events",
				type: 'get'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function getStatisticsForPeriod(type, period, okCallback, koCallback) {
			var url;
			switch (type) {
				case "users":
				case "fb":
				case "total":
				case "google":
					url = "/rest/users/source/" + type + "/" + period;
					break;
				default:
					url = "/rest/users/statistics/" + type + "/" + period;
			}
			$http({
				url: url,
				type: 'get'
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function getStatsByQuestType(okCallback, koCallback) {
			$http({
				url: "/rest/users/questsstatistics",
				type: "get"
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function getUsersFromSource(source, okCallback, koCallback) {
			$http({
				url: "/rest/users/source/" + source + "/1",
				type: "get"
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}

		function postChartEvent(data, okCallback, koCallback) {
			$http({
				method: 'POST',
				url: '/rest/chart/events',
				data: JSON.stringify(data),
				contentType: "application/json; charset=utf-8"
			}).then(function successCallback(response) {
				okCallback && okCallback(response);
			}, function errorCallback(response) {
				koCallback && koCallback(response);
			});
		}
	}

})();