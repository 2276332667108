(function () {
    'use strict';

    angular
        .module('questiaPlatformApp')
        .controller('VoterProfileController', VoterProfileController);

    VoterProfileController.$inject = ['$rootScope','MlService'];
    function VoterProfileController($rootScope,MlService) {
        var vm = this;

        vm.filterParties = function(parties)
        {
            var formattedParties = [];
            for(var i = 0 ; i < parties.length; i++){
                if(parties[i].value <= 3 || parties[i].value === "997"){
                    if(parties[i].value === "997"){
                        parties[i].name = "Undecided";
                    }
                    formattedParties.push(parties[i]);
                }
            }
            return formattedParties;
        };

        vm.fetchParties = function () {
            MlService.getParties(
                function (response) {
                    var parties = Object.keys(response).map(function (e) {
                        var party = {};
                        party.value = e;
                        if(response[e].length > 35) {
                        	party.name = response[e].substr(0, 25) + "...";
						} else {
							party.name = response[e];
						}
                        return party;
                    });
                    vm.parties = vm.filterParties(parties);
                },
                function (error) {
                    console.log(error);
                }
            );
        };

		vm.createBubbleFromResponse = function(srvBubble){
			var bubble={};
			bubble.width=srvBubble.radius;
			bubble.text=(Math.abs(srvBubble.value)/1000.).toFixed(1)+'k';
			bubble.percent=parseFloat(srvBubble.value/115000.).toFixed(1);
			bubble.detailsPosition='top';
			bubble.details=[];
			srvBubble.tooltip.split('\r\n').forEach((element, index)=>{
				var labelValues = element.split(':');
				if(labelValues.length<2)
					bubble.details.push(element);
				else
					bubble.details.push(labelValues[0].toUpperCase()+': <b>'+labelValues[1]+'</b>');
			});
			bubble.details.push('');
			bubble.position={};
			bubble.position.left=srvBubble.x+'px';
			bubble.position.top=srvBubble.y+'px';
			return bubble;
		};
		
        vm.runAnalysis = function () {
            if (!vm.selectedParty) return;
			vm.showSpinner = true;
			document.getElementById("bubbles_container").innerHTML = '';
            MlService.getProfilingData(
                vm.selectedParty.value,
                function (response) {
                    vm.isDataFetched = true;
					var bubbles=[];
					var maxY = response.reduce((a,b)=>a.y>b.y?a:b).y; 
					var minY = response.reduce((a,b)=>a.y<b.y?a:b).y;
					var maxX = response.reduce((a,b)=>a.x>b.x?a:b).x; 
					var minX = response.reduce((a,b)=>a.x<b.x?a:b).x;
					var maxV = response.reduce((a,b)=>a.value>b.value?a:b).value; 
					var minV = response.reduce((a,b)=>a.value<b.value?a:b).value;
					var ctX=(800-100)/(maxX-minX);
					var ctY=(400-100)/(maxY-minY);
					var ctV=(80-25)/(maxV-minV);
                    response.forEach((el, index) => {
						el.x=800-(maxX-el.x)*ctX;
						el.y=400-(maxY-el.y)*ctY;
						el.radius=80-(maxV-el.value)*ctV;
						el.radius = el.radius * 2;
					});
					for (let i = 0; i < 1000; i++) {
						let b1 = vm.getRandomInt(response.length);
						let b2 = vm.getRandomInt(response.length);
						if (b1===b2) continue;
						let res = vm.checkMoveBubbleIntersection(response[b1],response[b2]);
					}
					response.forEach((el, index) => {
						bubbles.push(vm.createBubbleFromResponse(el));
					});
					vm.bubbleCreator(bubbles);
					vm.showSpinner=false;
                },
                function (error) {
                    console.log(error);
					vm.showSpinner=false;
                }
            );
        };
		vm.checkMoveBubbleIntersection = function(b1, b2){
			const c1x=b1.x+b1.radius/2;
			const c1y=b1.y+b1.radius/2;
			const c2x=b2.x+b2.radius/2;
			const c2y=b2.y+b2.radius/2;
			const d = Math.sqrt((c1x-c2x)*(c1x-c2x)+(c1y-c2y)*(c1y-c2y));
			const gap = d - b1.radius/2 - b2.radius/2;
			const delta=3;
			if(gap<-5){
				let way = Math.sign(b1.x-b2.x);
				b1.x-=way*gap/delta;
				b2.x+=way*gap/delta;
				way = Math.sign(b1.y-b2.y);
				b1.y-=way*gap/delta;
				b2.y+=way*gap/delta;
				return true;
			}
			return false;
		};
		vm.getRandomInt = function (max) {
			return Math.floor(Math.random() * max);
		};
		vm.bubbleCreator = function (bubles) {
			const bubleContainer = document.getElementsByClassName('bubles__container')[0];
			bubles.forEach((element, index) => {
				bubleContainer.insertAdjacentHTML('beforeend', `<div class="bubles__item active" style="
					top: ${element.position.top || 'initial'}; 
					right: ${element.position.right || 'initial'};
					bottom: ${element.position.bottom || 'initial'};
					left: ${element.position.left || 'initial'};
				"></div>`);

				const bubles = bubleContainer.getElementsByClassName('bubles__item');
				const lastBuble = bubles[bubles.length-1];
				const size = element.width;

				const bubleContent = `<div class="bubles__item__circle bubles__item__circle--${index}" style="background-color: ${vm.colors[index]}; width: ${size}px; height: ${size}px;">
					<span class="bubles__item__circle__title">${element.text}</span>
					<span class="bubles__item__circle__subtitle">${element.percent}%</span>
				</div>`;

				lastBuble.insertAdjacentHTML('beforeend', bubleContent);
				lastBuble.insertAdjacentHTML('beforeend', `<div class="bubles__item__details bubles__item__details--${element.detailsPosition}"></div>`);

				const detailsContainer = lastBuble.getElementsByClassName('bubles__item__details')[0];
				detailsContainer.insertAdjacentHTML('beforeend', '<span class="bubles__item__details__rows"></span>');

				const rowsSelector = detailsContainer.getElementsByClassName('bubles__item__details__rows')[0];
				element.details.forEach(row => rowsSelector.insertAdjacentHTML('beforeend', `<span>${row}</span>`));

				//detailsContainer.insertAdjacentHTML('beforeend', '<span class="bubles__item__details__buble"></span>');
				//detailsContainer.insertAdjacentHTML('beforeend', '<span class="bubles__item__details__line"></span>');

				detailsContainer.style.display = 'flex';
				const height = detailsContainer.offsetHeight;
				detailsContainer.style.display = '';
				let left = -parseInt(element.position.left)-200+650-Math.sin(-Math.PI/2+Math.PI/20+(Math.PI*index)/10)*650;// (200 - size) / 2;
				let top = -parseInt(element.position.top)-200+600-Math.cos(-Math.PI/2+Math.PI/20+(Math.PI*index)/10)*600;//height + 50;

				detailsContainer.style.top = `${top}px`;
				detailsContainer.style.left = `${left}px`;
				detailsContainer.style.backgroundColor = `${vm.colors[index]}`;

			});
			$('.bubles__item').on('click', '.bubles__item__circle', function(e) {
				$(this).parent().find('.bubles__item__details').toggleClass('active');
			});
		};
        (function initController() {
            vm.breadcrumbs = "Voter Profile";
            vm.activeSubmenu = 'voterProfile';
            vm.isDataFetched = false;
            vm.fetchParties();
			vm.colors = [
				'#D2B7F9',
				'#9DE0D8',
				'#FFD166',
				'#FBAC79',
				'#A5D6FA',
				'#9AA5F5',
				'#F2768E',
				'#BCE09D',
				'#B487B4',
				'#6277CC'
			];
        })();
    }

})();