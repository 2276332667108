(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.factory('SurveysService', SurveysService);

	SurveysService.$inject = ['$http', '$rootScope','$window'];
	function SurveysService($http, $rootScope, $window) {
		var service = {};

		service.AddQuest = function (questId, data, okCallback, koCallback) {
			var splitAuth = $rootScope.globals.authorization.split(":");
			$http.post("/rest/quests/" + questId, data)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

		service.GetSurveys = function (okCallback, koCallback) {
			$http.get("/rest/quests/all")
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

		service.GetTranslatedSurveyIds = function (okCallback, koCallback) {
			$http.get("/rest/quests/translatedQuestIds")
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

		service.GetUsedLanguagesForQuestTranslation = function(questId, okCallback, koCallback) {
			$http.get('/rest/quests/usedLanguages/' + questId)
				.then(function successCallback(response){
					okCallback && okCallback(response);
				}, function errorCallback(response){
					koCallback && koCallback(response);
				})
		};

		service.GetSurveyJson = function (qid, withASL,toLanguage, okCallback, koCallback) {
			var url;
			if(withASL) {
				url = "/rest/quests/"+qid+"?withASL=true";
			}
			else {
				url = "/rest/quests/"+qid;
			}

            if(toLanguage){
				if(qid.toString().includes("questId")){
                    url += "?toLanguage=" + toLanguage
				}else{
                    url += "&toLanguage=" + toLanguage
				}
            }

			$http.get(url)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

		service.ToggleAutoMail = function (qid, okCallback, koCallback) {
			$http.post("/rest/quests/toggleautomail/" + qid)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

		service.GetQuestsAnswers = function (okCallback, koCallback) {
			$http.get("/rest/quests/all/answersnew")
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

		service.GetSurveyCsv = function (qid, ageGroups, okCallback, koCallback) {
			$http.post("/rest/quests/answers/" + qid, ageGroups)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

		service.getRespondentsUserNamesForQuestAndDomain = function (questId, emailDomain, okCallback, koCallback) {
			$http.get("/rest/quests/respondents/user_names/" + questId + "/" + emailDomain)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

		service.CloseQuest = function (qid, okCallback, koCallback) {
			$http.post("/rest/quests/close/" + qid)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

		service.ChangeSurveyType = function (qid, type, okCallback, koCallback) {
			var url = "/rest/quests/" + qid + "/type/" + type;
			$http.post(url)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

		service.GetThreadStatus = function (qid, okCallback, koCallback) {
			$http.get("/rest/schedulers/quest/" + qid)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

		service.GetRespondentsByType = function (qid, okCallback, koCallback) {
			$http.get("/rest/quests/respondentsbytype/" + qid)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

		service.GetPredefinedQuestions = function (okCallback, koCallback) {
			$http.get("/rest/quests/predefinedQuestions")
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

		service.GetPredefinedQuestionsBatchForQuestion = function (questionId, okCallback, koCallback) {
			$http.get("/rest/quests/predefinedQuestions/" + questionId)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

		service.GetAvailableQuotas = function (okCallback, koCallback) {
			$http.get("/rest/quota")
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

		service.CloneQuest = function (qid, okCallback, koCallback) {
			$http.get("/rest/quests/clone/" + qid)
				.then(function (response) {
					okCallback(response);
				}, function (response) {
					koCallback(response);
				});
		};

		service.GetRcsCurrentQuestId = function (okCallback, koCallback) {
			$http.get('/rest/quests/rcs/currentQuestId')
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

		service.GetRcs = function (questionId, count, withWeighting, okCallback, koCallback) {
			var extraQuery = withWeighting ? "?withWeighting=" + withWeighting : "";
			$http.get('/rest/quests/rcs/question/' + questionId + '/3/10/' + count + extraQuery)
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

        service.GetQuestQuotas = function (qid, okCallback, koCallback) {
            $http.get("/rest/quota/quest/" + qid)
                .then(function (response) {
                    okCallback(response);
                }, function (response) {
                    koCallback(response);
                });
        };

        service.PostEditedQuotas = function(quotas, okCallback, koCallback){
        	$http.post("/rest/quota/editQuotas", quotas)
				.then(function (response) {
					okCallback(response);
                }, function (response) {
					koCallback(response);
                });
		};

        service.doesQuestionContainAnswers = function (questionId, okCallback, koCallback) {
            $http.get("/rest/quests/questionsHasAnswers/" + questionId)
                .then(function (response) {
                    okCallback(response);
                }, function (response) {
                    koCallback(response);
                });
        };

		service.importSurveyResultsFromCsv = function (questId, csvFile, okCallback, koCallback) {
			var formData = new FormData();
			formData.append('file', csvFile);
			var url = "/rest/quests/answers/import/" + questId;
			$http.post(url, formData, {
				transformRequest: angular.identity,
				headers: {'Content-Type': undefined}
			})
				.then(function successCallback(response) {
					okCallback(response);
				}, function errorCallback(response) {
					koCallback(response);
				});
		};

		service.getChoicesForQuestion = function(questionId, okCallback, koCallback) {
			$http.get("/rest/quests/getChoicesForQuestion/" + questionId)
				.then(function (response) {
					okCallback(response);
				}, function (response) {
					koCallback(response);
				});
		};


		return service;
	}

})();
