(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.controller('HeaderController', HeaderController);

	HeaderController.$inject = ['$window', '$state', '$rootScope', '$location', '$mdSidenav', '$mdMedia'];
	function HeaderController($window, $state, $rootScope, $location, $mdSidenav, $mdMedia) {
		var vm = this;

		(function initController() {
			$rootScope.lockMenu = true;
			$rootScope.isBurgerOpen = false;
			vm.hostname = $window.location.hostname;
		})();

		vm.goto = function (state) {
			$state.go(state);
		};

		vm.controlMenu = function() {
			if($mdMedia('gt-md')) {
				$mdSidenav('left').close();
				$rootScope.lockMenu = !$rootScope.lockMenu;
			}
			else {
				$mdSidenav('left').toggle();
			}
		};

		vm.openVoterSimBurger = function() {
			$rootScope.isBurgerOpen = !$rootScope.isBurgerOpen;
		};
	}
})();
