(function () {
	'use strict';

	angular
		.module('questiaPlatformApp')
		.controller('SurveyEditorController', SurveyEditorController);

	SurveyEditorController.$inject = ['$rootScope','$scope', 'SurveysService', '$stateParams', 'RegionsService', 'QuestiaUtils',
									  'SurveyQuotaUtils', 'FilterUtils', '$mdSidenav', '$state',
					                  '$timeout', 'ForecastService', 'SurveyLogicUtils', 'CorrelationLogicService',
									  'SkipLogicService', 'SurveyValidationService', 'SurveyEditsService', 'CloneService', '$window', '$mdDialog', '$interval'];
	function SurveyEditorController($rootScope,$scope, SurveysService, $stateParams, RegionsService, QuestiaUtils,
	                                SurveyQuotaUtils, FilterUtils, $mdSidenav, $state, $timeout,
	                                ForecastService, SurveyLogicUtils, CorrelationLogicService, SkipLogicService,
	                                SurveyValidationService, SurveyEditsService, CloneService, $window, $mdDialog, $interval) {

		var vm = this;
		var originatorEv;
		var DKNA_LABEL = 900;
		var QUEST_EDIT_PERIOD = 10000;
		vm.QUESTION_SECTION_ID_PREFIX = "question_section_id_";

		vm.fillScreenOutExamplesMap = function() {
			vm.screenOutExamplesMap.set('in','value_1,value_2,..,value_n');
			vm.screenOutExamplesMap.set('at_least_one_in','value_1,value_2,..,value_n');
			vm.screenOutExamplesMap.set('not_in','some_value');
			vm.screenOutExamplesMap.set('not_range','min_value-max_value');
		};
		vm.fillScreenOutToolTipsMap = function() {
			vm.screenOutTooltipsMap.set('eq','Screen-out if an answer equals the specified value');
			vm.screenOutTooltipsMap.set('not','Screen-out if an answer does not equal the specified value');
			vm.screenOutTooltipsMap.set('in','Screen-out if an answer contains all of the specified values');
			vm.screenOutTooltipsMap.set('at_least_one_in','Screen-out if an answer contains at least one of the specified values');
			vm.screenOutTooltipsMap.set('not_in','Screen-out if an answer does not contain the specified value');
			vm.screenOutTooltipsMap.set('not_range','Screen-out if an answer is not within the specified range');
			vm.screenOutTooltipsMap.set('not_intersect','Screen-out if an answer does not intersect the specified values');
			vm.screenOutTooltipsMap.set('eq_multi','Screen-out if an answer equals one of the specified values');
			vm.screenOutTooltipsMap.set('not_multi','Screen-out if an answer equals none of the specified values');
			vm.screenOutTooltipsMap.set('one_of_regions','Screen-out if a user\'s location is within any of the specified regions');
			vm.screenOutTooltipsMap.set('not_one_of_regions','Screen-out if a user\'s location is not within any of the specified regions');
		};
		vm.getTooltipForCondition = function(condition) {
			return !condition ? 'No screen-out condition selected' : vm.screenOutTooltipsMap.get(condition);
		};
		vm.getPlaceholderForCondition = function(condition) {
			return !condition ? 'some_value' : 	vm.screenOutExamplesMap.get(condition);
		};
        vm.stripFormat = function (html) {
            var tempDiv = document.createElement("DIV");
            tempDiv.innerHTML = html;
            return tempDiv.innerText;
        };
		vm.resetFilters = function() {
			vm.filters = {survey: {},user:{},other:{}};
			vm.getFiltersCount();
		};
		vm.getFiltersCount = function () {
			FilterUtils.getFiltersCount(vm.quest.type, vm.notificationsToAll, vm.filters,
				function success(data) {
					vm.filteredUsersCount = data;
				});
		};

		/// ---- quotas related stuff ---- ///
		function getQuotasAndTransformToUiFormat() {
			SurveysService.GetAvailableQuotas(
				function (response) {
					vm.ageQuotasLabels = response.data.age;
					vm.sexQuotasLabels = response.data.sex;
					vm.locationQuotasLabels = response.data.location;
					vm.createProfileQuotasIfNotExists();
					vm.allQuotas = SurveyQuotaUtils.serverToUiAllQuotas({"SEX": response.data.sex, "AGE": response.data.age});

					if (vm.quest.quotas) {
						vm.quest.quotaGroups = SurveyQuotaUtils.newServerToUiQuotas(vm.quest.quotas, vm.allQuotas);
					}
					else {
						vm.quest.quotaGroups = [];
					}
				},
				function (response) {
				}
			);
		}

		vm.addPredefinedQuestionToIndex = function(index, predefinedQuestion, withRelation, withCorrelationsOrLogic) {
			var predefinedQuestionCopy = angular.copy(predefinedQuestion);
			predefinedQuestionCopy.predefinedQuestion = false;
			if (withRelation) {
				predefinedQuestionCopy.parentQuestionId = predefinedQuestionCopy.question_id;
			} else {
				var availableQId = SurveyLogicUtils.findNextAvailableQid(vm.quest.questions);
				predefinedQuestionCopy.id = availableQId;
			}
			if (!withCorrelationsOrLogic) {
				delete predefinedQuestionCopy.skip;
				delete predefinedQuestionCopy.skipString;
				delete predefinedQuestionCopy.skipGroup;
				delete predefinedQuestionCopy.correlationArray;
				delete predefinedQuestionCopy.correlationArrayJson;
			}
			delete predefinedQuestionCopy.question_id;
			if (predefinedQuestionCopy.type === 'selects' && typeof predefinedQuestionCopy.selectsScale === 'undefined') {
				predefinedQuestionCopy.selectsScale = predefinedQuestionCopy.selects[0].options.map(function (option) {
					return {
						value: option.value,
						label: option.label
					}
				});
			}
			vm.quest.questions.splice(index + 1, 0, predefinedQuestionCopy);
			vm.quest = SurveyLogicUtils.initServerToUi(vm.quest, index + 1);
		};

		function AddPredefinedQuestionWithLogicOrCorrelationsController($scope, $mdDialog, index,
																		questionId, dbQuestionId, batchQuestions) {
			$scope.questionId = questionId;
			$scope.dbQuestionId = dbQuestionId;

			$scope.cancel = function () {
				$mdDialog.cancel();
			}

			$scope.addQuestionWithLogicOrCorrelations = function (withLogic) {
				$mdDialog.hide();
				if (withLogic) {
					for (var i = 0; i < batchQuestions.length; i ++) {
						vm.addPredefinedQuestionToIndex(index + i, batchQuestions[i], true, true);
					}
				}
				else {
					//in the batchQuestion array select the element with a specific questionId and add it to the survey
					vm.addPredefinedQuestionToIndex(index, batchQuestions.find(function (obj) {
						return obj.question_id === dbQuestionId;
					}), true, false);
				}
			}
		}

		function AddPredefinedQuestionDialogController($scope, $mdDialog, index, predefinedQuestion) {
			$scope.cancel = function () {
				$mdDialog.cancel();
			}

			$scope.addQuestionWithRelation = function (withRelation) {
				$mdDialog.hide();

				if (withRelation) {
					SurveysService.GetPredefinedQuestionsBatchForQuestion(predefinedQuestion.question_id,
						function (response) {
							var questions = response.data;
							if (questions.length > 1) {
								var confirm = $mdDialog.confirm({
									templateUrl: 'js/ng-templates/subviews/directives/addPredefinedQuestionWithLogicOrCorrelationsDialog.subview.html',
									locals: {
										index: index,
										questionId: predefinedQuestion.id,
										dbQuestionId: predefinedQuestion.question_id,
										batchQuestions: questions
									},
									controller: AddPredefinedQuestionWithLogicOrCorrelationsController
								});
								$mdDialog.show(confirm).then(function () {});
							}
							else {
								vm.addPredefinedQuestionToIndex(index, predefinedQuestion, withRelation, true);
							}
						},
						function (error) {
							$mdDialog.show(
								$mdDialog.alert()
									.title('Predefined Question Alert')
									.textContent('Failed to check correlated questions!')
									.ok('Close')
							);
						}
					);
				}
				else {
					vm.addPredefinedQuestionToIndex(index, predefinedQuestion, withRelation, true);
				}
			}
		}

		vm.changeSplitType = function (index, type) {
			delete vm.quest.quotaGroups[index].totalSum;
			if (type) {
				vm.quest.quotaGroups[index].split = angular.copy(SurveyQuotaUtils.getLabelsForQuotaType(vm.allQuotas, type));
			}
			else {
				delete vm.quest.quotaGroups[index].split;
			}
		};
		vm.changeQuota = function (index, type) {
			delete vm.quest.quotaGroups[index].totalSum;
			vm.quest.quotaGroups[index] = angular.copy(SurveyQuotaUtils.getLabelsForQuotaType(vm.allQuotas, type));
		};
		vm.isQuotaTypeChosen = function (type, $index) {
			return vm.quest.quotaGroups.filter(function (obj, index) {
				return index !== $index && (obj.type === type || obj.splitType === type);
			}).length > 0;
		};
		vm.deleteCloseQuotas = function () {
			$mdSidenav('quotas').close();
			vm.quest.quotaGroups = [];
			vm.quest.quotas = [];
		};
		vm.calculateTotalPercentageForGroup = function (index) {
			var group = vm.quest.quotaGroups[index];
			SurveyQuotaUtils.calculatePercentagePerGroup(group);

			//TODO fi disable button when having more than one group!
			vm.disableQuotaSaveButton = group.totalSum !== 100;
		};
        function parseQuotasForUI(questions){
            for(var i = 0; i <questions.length ; i++){
                if(questions[i].quotas){
                    if(questions[i].type==='combodate') {
                        var quotasArray = [];
                        for (var key in questions[i].quotas) {
                            if (questions[i].quotas.hasOwnProperty(key)) {
                                var quotaKey = key;
                                var quotaValue = questions[i].quotas[quotaKey];
                                var lowRange = quotaKey.split('-')[0];
                                var upRange = quotaKey.split('-')[1];
                                quotaValue.lowerRange = parseInt(lowRange);
                                quotaValue.upperRange = parseInt(upRange);
                                questions[i].options.quotasDeviation = quotaValue.percent_deviation;
                            }
                            quotasArray.push(quotaValue);
                        }
                        questions[i].quotas = quotasArray;
                    }
                    if(questions[i].type==='cityCounty'){
                        if(questions[i].options.isSimpleRuralUrbal === 'true'
						  && (!questions[i].options.hasRegionQuota || questions[i].options.hasRegionQuota === 'false')){
                            parseQuotasForUiSimpleRuralUrban(questions[i]);
                        }else{
                            parseQuotasForUIRegions(questions[i]);
                        }
                    }
                    if(questions[i].type==='singleChoice'){
                        for (var key in questions[i].quotas) {
                            if (questions[i].quotas.hasOwnProperty(key)) {
                                var quotaValue = questions[i].quotas[key];
                                questions[i].options.quotasDeviation = quotaValue.percent_deviation;
                            }
                        }
					}
                    vm.quotasUI.push({
                        id : questions[i].question_id,
                        quotas : questions[i].quotas,
                        options : _.clone(questions[i].options)
                    });
                }
            }
        }

        function parseQuotasForUiSimpleRuralUrban(questions){
            var quotasArray = [];
            for(var key in questions.quotas){
                if(questions.quotas.hasOwnProperty(key)){
                    var quotaKey = key;
                    var quotaValue = questions.quotas[quotaKey];
                    quotaValue.categoryName = key;
                    quotasArray.push(quotaValue);
                    questions.options.quotasDeviation = quotaValue.percent_deviation;
                }
            }
            questions.quotas = quotasArray;
        }

        function parseQuotasForUIRegions(question){
            RegionsService.getRegionsListForDivision(question.options.quotaDivision).then(function(response) {
                var regions_divisions = response.data;
                var quotasArray = [];
                for(var key in question.quotas){
                    if(question.quotas.hasOwnProperty(key)){
                        var quotaKey = key;
                        quotaKey = parseInt(quotaKey);
                        var region_division = regions_divisions.find(x => x.region.region_id === quotaKey);
                        var quotaValue = question.quotas[key];
                        quotaValue.region_data = region_division.region;
                        quotasArray.push(quotaValue);
                        question.options.quotasDeviation = quotaValue.percent_deviation;
                    }
                }
                question.quotas = quotasArray;
                vm.cityCountyQuotasUI.push({id:question.id,division_id:question.options.quotaDivision,quotas:question.quotas});
            });
        }

        vm.createProfileQuotasIfNotExists = function() {
        	if (!vm.quest.ageQuotas || vm.quest.ageQuotas.length == 0) {
        		vm.hasAgeQuotas = false;
        		vm.quest.ageQuotas = [];
        		if (!vm.ageQuotasLabels) {
        			console.error("No age quota labels");
				}
        		else {
					Object.keys(vm.ageQuotasLabels).forEach(function (each) {
						vm.quest.ageQuotas.push({
							quota_id: null,
							main_type_name: null,
							main_label_id: vm.ageQuotasLabels[each].labelId,
							main_label: vm.ageQuotasLabels[each].label,
							percent: 0.0,
							percent_filled: 0.0,
							percent_deviation: 0.0
						});
					});
				}
			}
        	else {
        		vm.hasAgeQuotas = true;
			}
        	if (!vm.quest.sexQuotas || vm.quest.sexQuotas.length == 0) {
        		vm.hasSexQuotas = false;
        		vm.quest.sexQuotas = [];
        		if (!vm.sexQuotasLabels) {
					console.error("No sex quota labels");
				}
        		else {
					Object.keys(vm.sexQuotasLabels).forEach(function (each) {
						vm.quest.sexQuotas.push({
							quota_id: null,
							main_type_name: null,
							main_label_id:  vm.sexQuotasLabels[each].labelId,
							main_label: vm.sexQuotasLabels[each].label,
							percent: 0.0,
							percent_filled: 0.0,
							percent_deviation: 0.0
						});
					});
				}
        	}
        	else {
        		vm.hasSexQuotas = true;
			}
            if (!vm.quest.selectedDivisionIdForQuotas || !vm.quest.locationQuotas || vm.quest.locationQuotas.length == 0) {
                vm.hasLocationQuotas = false;
                vm.quest.locationQuotas = [];
                if (!vm.locationQuotasLabels) {
                    console.error("No location quota labels");
                }
            }
            else {
                vm.hasLocationQuotas = true;
            }
		};

        vm.buildEmptyLocationQuotaLabels = function (divisionId) {
			vm.quest.locationQuotas = [];
			if (!vm.locationQuotasLabels || !vm.locationQuotasLabels[divisionId]) {
				console.error("No location quota labels found for division with ID " + divisionId + "!");
				return;
			} else {
				Object.keys(vm.locationQuotasLabels[divisionId]).forEach(function (each) {
					vm.quest.locationQuotas.push({
						quota_id: null,
						main_type_name: null,
						main_label_id: vm.locationQuotasLabels[divisionId][each].labelId,
						main_label: vm.locationQuotasLabels[divisionId][each].label,
						percent: 0.0,
						percent_filled: 0.0,
						percent_deviation: 0.0
					});
				});
			}
        };

        vm.updateAgeQuotasDeviation = function(ageQuotasDeviation) {
        	for (var i = 0; i < vm.quest.ageQuotas.length; i ++)
        		vm.quest.ageQuotas[i].percent_deviation = ageQuotasDeviation;
		};

		vm.updateSexQuotasDeviation = function(sexQuotasDeviation) {
			for (var i = 0; i < vm.quest.sexQuotas.length; i ++)
				vm.quest.sexQuotas[i].percent_deviation = sexQuotasDeviation;
		};

		vm.updateLocationQuotasDeviation = function(locationQuotasDeviation) {
			for (var i = 0; i < vm.quest.locationQuotas.length; i ++)
				vm.quest.locationQuotas[i].percent_deviation = locationQuotasDeviation;
		};

        vm.createQuotasIfNotExists = function(question_id,toggle_value){
            var questionQuotas = vm.quest.questions.find(x=> x.id === question_id);
            var quotasUIFound = vm.quotasUI.find(x=>x.id === question_id);

            if(!questionQuotas.quotas || questionQuotas.quotas===null || question_id===undefined){
                var quotasArray = [];
                if(questionQuotas.type === 'combodate'){
                    var quota = {
                        lowerRange: 0,
                        upperRange: 0,
                        percent: 0,
                        percent_deviation : 0,
                        percent_filled : 0
                    };
                    quotasArray.push(quota);
                    questionQuotas.quotas = quotasArray;
                }
                if(questionQuotas.type === 'cityCounty' && toggle_value == 'true' && !quotasUIFound){
                	if(questionQuotas.options && questionQuotas.options.quotaDivision){
                      delete questionQuotas.options.quotaDivision;
					}
                    vm.checkSimpleRuralUrban(question_id,toggle_value);
				}else if(questionQuotas.type === 'cityCounty' && toggle_value == 'true' && quotasUIFound){
                      vm.checkSimpleRuralUrban(question_id,toggle_value);
				}
            }
            if(questionQuotas.type==='cityCounty' && toggle_value==='true' &&
                (!questionQuotas.options.quotaDivision)){
                 delete questionQuotas.options.hasRegionQuota;
            }
        };

        vm.changeDivision = function(division_id,question_id){
            var questionQuotas = vm.quest.questions.find(x=> x.id === question_id);
            var quotasUIFound = vm.quotasUI.find(x=>x.id === question_id);

            vm.loadedRegions = false;
            RegionsService.getRegionsListForDivision(division_id).then(function(response) {
                if(response.data){
                    vm.loadedRegions = true;
                    var regions_divisions = response.data;
                    if(regions_divisions.length === 0){
                        console.log('no regions found');
                    }
                    else{
                        var quotasArray = [];
                        for(var i = 0 ; i < regions_divisions.length; i++){
                            var region = regions_divisions[i].region;
                            var quota = {
                                region_data : region,
                                percent: 0,
                                percent_deviation : 0,
                                percent_filled : 0
                            };
                            quotasArray.push(quota);
                        }
                        questionQuotas.quotas = quotasArray;
                    }
                    if(vm.cityCountyQuotasUI.length>0){
                        var oldDivisionId = vm.cityCountyQuotasUI.find(x=>x.id===questionQuotas.id);
                        if(oldDivisionId && oldDivisionId.division_id===questionQuotas.options.quotaDivision){
                            questionQuotas.quotas = vm.cityCountyQuotasUI.find(x=>x.id===questionQuotas.id).quotas;
                        }
                    }

                }
            });
        };

        vm.treatBirthDayChange = function(question_id,checkBoxValue){
            var questionQuotas = vm.quest.questions.find(x=> x.id === question_id);
            if(checkBoxValue === 'false'){
            	var condition = questionQuotas.options && questionQuotas.options.hasQuota
					&& questionQuotas.options.hasQuota === 'true';
            	if(condition){
                    questionQuotas.options.hasQuota = 'false';
				}
			}
		};

        vm.checkSimpleRuralUrban = function(question_id,value){

            var simpleRuralUrban = ["Rural Simple","Urban Simple"];
            var questionQuotas = vm.quest.questions.find(x=> x.id === question_id);
            var quotasUIFound = vm.quotasUI.find(x=>x.id === question_id);

            if(questionQuotas && questionQuotas.quotas){
                delete questionQuotas.quotas;
			}

            if(questionQuotas.options && questionQuotas.options.quotaDivision){
                delete questionQuotas.options.quotaDivision;
            }

            if(!questionQuotas.quotas || Object.keys(questionQuotas.quotas).length===0 ||
                (quotasUIFound && quotasUIFound.options.quotaDivision)
                || questionQuotas.options.quotaDivision){
                var quotasArray = [];
                for(var i = 0; i < simpleRuralUrban.length; i++){
                    var quota = {
                        categoryName : simpleRuralUrban[i],
                        percent: 0,
                        percent_deviation : 0,
                        percent_filled : 0
                    };
                    quotasArray.push(quota);
                }
                questionQuotas.quotas = quotasArray;
            }
        };

        vm.checkRegion = function(question_id,toggleValue){
            var questionQuotas = vm.quest.questions.find(x=> x.id === question_id);
            var quotasUIFound = vm.quotasUI.find(x=>x.id === question_id);

            if(questionQuotas && questionQuotas.quotas){
                delete questionQuotas.quotas;
			}

            if(quotasUIFound && quotasUIFound.options.isSimpleRuralUrbal==='false' ){
                questionQuotas.quotas = quotasUIFound.quotas;
            }

            if(toggleValue === 'true'){
                vm.checkSimpleRuralUrban(question_id,toggleValue);
			}

        };

        vm.setBackUpUIQuotas = function(){
            vm.cityCountyQuotasUI = [];
            vm.quotasUI = [];
        };

        vm.deleteChoice = function(qid,index,choice_value){
			var question = vm.quest.questions.find(x=> x.id === qid);
			if(question){
                question.choices.splice(index, 1);
                delete question.quotas[choice_value];
            }
        };

        vm.addNewDateQuota = function (index,question_id) {
            var quota = {
                lowerRange: 0,
                upperRange: 0,
                percent: 0,
                percent_deviation : 0,
                percent_filled : 0
            };
            if(vm.quest.questions.find(x=> x.id === question_id).quotas.length <= index+1){
                vm.quest.questions.find(x=> x.id === question_id).quotas.splice(index + 1, 0, quota);
            }

        };
		/// ----end quotas related stuff ---- ///

		/// ---- skip logic related stuff ---- ///
		vm.addEmptySkip = function (parentIndex, index) {
			vm.currentQuestion = SkipLogicService.addEmptySkip(vm.currentQuestion, parentIndex, index);
		};
		vm.resetSkipGroup = function (parentIndex, index) {
			vm.currentQuestion = SkipLogicService.resetSkipGroup(vm.currentQuestion, parentIndex, index);
		};
		vm.changeOperatorWithinGroup = function (parentIndex, index) {
			vm.currentQuestion = SkipLogicService.changeOperatorWithinSkipGroup(vm.currentQuestion, parentIndex, index);
		};
		vm.changeOperatorBetweenGroups = function (parentIndex) {
			vm.currentQuestion = SkipLogicService.changeOperatorBetweenSkipGroup(vm.currentQuestion, parentIndex);
		};
		vm.deleteCloseLogic = function () {
			vm.currentQuestion = SkipLogicService.initializeCloseSkip(vm.currentQuestion);
		};
		vm.findSkipQuestionChoices = function (parentIndex, index) {
			vm.currentQuestion = SkipLogicService.findSkipQuestionChoices(vm.currentQuestion, vm.previousQuestions, parentIndex, index);
		};
		vm.getScaleChoicesForSelect = function (parentIndex, index) {
			vm.currentQuestion = SkipLogicService.getScaleChoicesForSkipSelect(vm.currentQuestion, vm.previousQuestions, parentIndex, index);
		};
		vm.addSkipGroup = function (parentIndex) {
			vm.currentQuestion = SkipLogicService.addSkipGroup(vm.currentQuestion, parentIndex);
		};
		vm.saveSkipString = function (question) {
			SkipLogicService.saveSkipJson(question);
		};
		vm.cloneSkips = function (fromQuestion) {
			var question = vm.previousQuestions.filter(function (q) {
				return q.id === fromQuestion;
			});
			vm.currentQuestion.skipGroup = angular.copy(question[0].skipGroup)
		};

		vm.deleteSkipFromSkipGroup = function (index) {
			vm.currentQuestion.skipGroup.splice(index,1);
		}

		/// ---- end skip logic stuff ---- ///

		/// ----- correlation logic related stuff ---- ///
		vm.addEmptyCorrelation = function (choiceGroupIndex, correlationGroupIndex, index) {
			vm.currentQuestion = CorrelationLogicService.addEmptyCorrelation(vm.currentQuestion, choiceGroupIndex, correlationGroupIndex, index);
		};
		vm.addEmptyCorrelationGroup = function (choiceGroupIndex, correlationGroupIndex) {
			vm.currentQuestion = CorrelationLogicService.addEmptyCorrelationGroup(vm.currentQuestion, choiceGroupIndex, correlationGroupIndex);
		};
		vm.changeOperatorWithinCorrelationGroup = function (choiceGroupIndex, correlationGroupIndex, index) {
			vm.currentQuestion = CorrelationLogicService.changeOperatorWithinCorrelationGroup(vm.currentQuestion, choiceGroupIndex, correlationGroupIndex, index);
		};
		vm.changeOperatorBetweenCorrelationGroups = function (choiceGroupIndex, correlationGroupIndex) {
			vm.currentQuestion = CorrelationLogicService.changeOperatorBetweenCorrelationGroups(vm.currentQuestion, choiceGroupIndex, correlationGroupIndex);
		};
		vm.initializeCloseCorrelation = function() {
			vm.currentQuestion = CorrelationLogicService.initializeCloseCorrelation(vm.currentQuestion);
		};
		vm.findCorrelationQuestionChoices = function (choiceGroupIndex, correlationGroupIndex, index) {
			vm.currentQuestion = CorrelationLogicService.findCorrelationQuestionChoices(vm.currentQuestion, vm.previousQuestions, choiceGroupIndex, correlationGroupIndex, index);
		};
		vm.getScaleChoicesForCorrelationSelect = function (choiceGroupIndex, correlationGroupIndex, index) {
			vm.currentQuestion = CorrelationLogicService.getScaleChoicesForCorrelationSelect(vm.currentQuestion, vm.previousQuestions, choiceGroupIndex, correlationGroupIndex, index);
		};
		vm.addCorrelationGroup = function (parentIndex) {
			vm.currentQuestion = CorrelationLogicService.addCorrelationGroup(vm.currentQuestion, parentIndex);
		};
		vm.addCorrelationChoiceGroup = function (parentIndex) {
			vm.currentQuestion = CorrelationLogicService.addCorrelationChoiceGroup(vm.currentQuestion, parentIndex);
		};
		vm.saveCorrelationJson = function (question) {
			CorrelationLogicService.saveCorrelationJson(question);
		};
		/// ----end correlation related stuff ---- ///


		/// ---- clone related stuff ///
		vm.cloneQuest = function () {
			CloneService.cloneQuest(vm.quest.id, function (response) {vm.reload({qid: response.data})});
		};
		vm.cloneChoicesAsScale = function (question, qidToCloneChoices) {
			CloneService.cloneChoicesAsScale(question, vm.quest.questions, qidToCloneChoices);
		};
		vm.cloneQuestion = function(index) {
			vm.quest.questions = CloneService.cloneQuestion(vm.quest.questions, index);
		};
		vm.cloneChoices = function (question, qidToCloneChoices) {
			CloneService.cloneChoices(question, vm.quest.questions, qidToCloneChoices);
		};
		/// ----end clone related stuff ///

		/// --- toggle/open menu stuff ///
		vm.openInheritAnswerSyntaxMenu = function ($mdMenu, ev, index, questionTypes) {
			originatorEv = ev;
			vm.getPreviousQuestions(index,questionTypes);
			$mdMenu.open(ev);
		};
		vm.openMultipleOptionMenu = function ($mdMenu, ev) {
			originatorEv = ev;
			$mdMenu.open(ev);
		};
		vm.addInheritAnswerSyntaxInText = function (index, qid) {
			var question = vm.quest.questions.filter(function (obj) {
				return obj.id === qid;
			})[0];
			if (question && question.type == "multipleChoice") {
				var regexp = new RegExp('#.+#');
				var timesMultiplied = 0;
				for (var i = 0; i < question.choices.length; i++) {
					if (!regexp.test(question.choices[i].value) && parseInt(question.choices[i].value) < DKNA_LABEL) {
						timesMultiplied++;
					}
				}
				vm.quest.questions[index].timesMultiplied = timesMultiplied;
			}
			if (vm.quest.questions[index].question) {
				vm.quest.questions[index].question += " ##" + qid + "## ";
			}
			else {
				vm.quest.questions[index].question = " ##" + qid + "## ";
			}
			if(!vm.quest.questions[index].options) {
				vm.quest.questions[index].options = {};
			}
			vm.quest.questions[index].options.insertPreviousAnswerAsText = "true";
		};
		vm.toggleLogic = function (index) {
			if ($mdSidenav('right').isOpen()) {
				$mdSidenav('right').close();
				vm.saveSkipString(vm.currentQuestion);
				vm.saveCorrelationJson(vm.currentQuestion);
				delete vm.currentQuestion.idx;
			}
			else {
				delete vm.currentQuestion;
				vm.currentQuestion = vm.quest.questions[index];
				SurveyLogicUtils.populateSkipGroupsWithUIData(vm.currentQuestion, vm.quest);
				vm.currentQuestion.idx = index;
				if (!vm.currentQuestion.skipGroup) {
					vm.currentQuestion = SkipLogicService.initializeCloseSkip(vm.currentQuestion);
				}
				if (!vm.currentQuestion.correlationArray) {
					vm.currentQuestion = CorrelationLogicService.initializeCloseCorrelation(vm.currentQuestion);
				}
				vm.getPreviousQuestions(index);
				$mdSidenav('right').open();
			}
		};
		vm.toggleQuotas = function () {
			var panel = $mdSidenav('quotas');
			if (panel.isOpen()) {
				panel.close();
				vm.quest.quotas = SurveyQuotaUtils.uiToServerQuotas(vm.quest.quotaGroups);
			}
			else {
				panel.open();
			}
		};
		vm.toggleQuestionEditMode = function(question) {
			if (question.editMode && vm.quest.id) {
				SurveyEditsService.CheckQuestEdits(vm.quest.id, true,
					function (checkResponse) {
						vm.questEditsCount = parseInt(checkResponse.data);
						if (vm.questEditsCount > 0) {
							var confirm = $mdDialog.confirm()
								.title()
								.htmlContent("<h3><strong>The survey editor is opened by " + vm.questEditsCount + " other users.<br/>" +
									"Please close all other instances of the survey editor and refresh the page before editing.</strong></h3>")
								.cancel("DISCARD CHANGES")
								.ok("ACCEPT CHANGES");

							$mdDialog.show(confirm).then(
								function() {
									question.editMode = !question.editMode;
								},
								function() {
									vm.reload({qid: vm.quest.id});
								}
							);
						}
						else {
							question.editMode = !question.editMode;
						}
					},
					function (checkError) {
						question.editMode = !question.editMode;
					}
				)
			}
			else {
				question.editMode = !question.editMode;
			}
		}
		/// --- toggle/open menu stuff ///


		/// --- question manipulation functions ///
		//creates new scale
		vm.qselectsScaleSplice = function qselectsScaleSplice(index, parentIndex) {
		    const selectsScale = vm.quest.questions[parentIndex].selectsScale;

			selectsScale.splice(index, 0, {value: index});
			for (var i = index; i < selectsScale.length; i++) {
				selectsScale[i].value = i + 1;
			}
		};

		vm.toggleHideChoice = function (choice) {
			choice.choice_is_hidden = !choice.choice_is_hidden;
		};

		vm.showDeleteButtonForQuestion = function (question, quest) {
			if (question.predefinedQuestion && question.hasChildren) {
				return false;
			}

			return (vm.quest.type === 'test'|| vm.quest.type === 'forecast') && vm.quest.questions.length > 1;
		}

		vm.isPredefinedQuestion = function (question) {
			return question.predefinedQuestion;
		};

		//creates new choice
		vm.qchoicesSplice = function qchoicesSplice(index, parentIndex) {
			const question = vm.quest.questions[parentIndex];
			const newChoice = {value: index, choice_is_hidden: false};

			if (Number.isInteger(question.question_id) && question.predefinedQuestion) {
				newChoice.value = vm.findFirstAvailableChoiceId(question);
				question.choices.splice(index, 0, newChoice);
				return;
			}

			question.choices.splice(index, 0, newChoice);
			for (var i = index; i < question.choices.length; i++) {
				question.choices[i].value = i + 1;
			}
		};

		vm.findFirstAvailableChoiceId = function (question) {
			const choices_ids = question.choices.map(c => Number.parseInt(c.value));
			for (let newId = 1; newId <= Math.max(...choices_ids) + 1; newId++) {
				if (!choices_ids.includes(newId)) {
					return newId;
				}
			}
		};

		vm.setInheritFlag = function (q, cloneId, matrix) {
			if (!q.options) q.options = {};
			var varToSet;
			if (matrix) {
				q.selects = [{
					id: q.id + "_",
					"options": [{}]
				}];
				varToSet = "inheritItemsFrom";
			}
			else {
				varToSet = "inheritFrom";
			}
			if (cloneId) {
				q.options[varToSet] = cloneId;
			}
			else {
				delete q.options[varToSet];
			}

		};
		vm.addEmptyQuestion = function (index) {
			vm.quest.questions[index].editMode = false;
			vm.quest.questions.splice(index + 1, 0, {choices: [{}], editMode: true});
		};

		vm.addPredefinedQuestion = function (index, predefinedQuestion) {
			var confirm = $mdDialog.confirm({
				templateUrl: 'js/ng-templates/subviews/directives/addPredefinedQuestionDialog.subview.html',
				locals: {
					index: index,
					predefinedQuestion: predefinedQuestion
				},
				controller: AddPredefinedQuestionDialogController
			});
			$mdDialog.show(confirm).then(function () {});
		};

		vm.addEmptyChoices = function (index, type, oldType) {
			delete vm.quest.questions[index].correlationArray;
			if (type == "selects" || type == "multipleOpen" || type === "ranking") {
				delete vm.quest.questions[index].choices;
				vm.quest.questions[index].options = {};
				vm.quest.questions[index].selectsScale = [{}];
				vm.quest.questions[index].selects = [{
					id: vm.quest.questions[index].id + "_1",
					options: [{}]
				}];
			}
			else {
				delete vm.quest.questions[index].selects;
				if ((oldType === "singleChoice" && type === "multipleChoice") || (oldType === "multipleChoice" && type === "singleChoice")) {
                    if(vm.quest.questions[index].quotas){
                        delete vm.quest.questions[index].quotas;
					}
					return;
				}
				vm.quest.questions[index].options = {};
				vm.quest.questions[index].choices = [{choice_is_hidden: false}];
			}
			if(vm.quest.questions[index].quotas){
			vm.quest.questions[index].quotas = null;
			}
		};
		vm.changeSelects = function (index, type) {
			if (type == "selects" || type == "multipleOpen") {
				for (var i = 0; i<vm.quest.questions[index].selects.length; i++)
					vm.quest.questions[index].selects[i].id =  vm.quest.questions[index].id + "_" + (i+1);
			}
		};
		vm.getPreviousQuestions = function (index, questionTypes) {
			vm.previousQuestions = vm.quest.questions.slice(0, index);
			if(questionTypes && questionTypes.length > 0){
				vm.previousQuestions = vm.previousQuestions.filter(function (q) {
					return questionTypes.includes(q.type);
				});
			}
		};
		vm.insertFirstName = function (q) {
			SurveyLogicUtils.insertFirstName(q);
		};
		vm.createMultipleChoiceLimits = function () {
			vm.currentQuestion = SurveyLogicUtils.createMultipleChoiceLimits(vm.currentQuestion);
		};
		vm.clearSpecialState = function (q, choice) {
			if(choice.specialState === 'headline') {
				SurveyLogicUtils.makeHeadline(choice);
			}
			if(choice.specialState === 'disabled') {
				SurveyLogicUtils.makeDisabled(q, choice);
			}
			if(choice.specialState === 'hidden') {
				SurveyLogicUtils.makeHidden(q, choice);
			}
		};

		vm.makeDisabled = function (q, choice) {
			SurveyLogicUtils.makeDisabled(q, choice);
		};

		vm.makeHidden = function (q, choice) {
			SurveyLogicUtils.makeHidden(q, choice);
		};

		vm.makeHeadline = function (choice) {
			SurveyLogicUtils.makeHeadline(choice);
		};
		vm.addMultipleOptionSyntax = function (type, qid) {
			vm.currentQuestion = SurveyLogicUtils.addMultipleOptionSyntax(vm.currentQuestion, type, qid);
		};
		/// --- question manipulation functions ///

        vm.populateCitiesDropDown = function (county_id) {

        	if(vm.filters.location.user_county_districts){
        		delete vm.filters.location.user_county_districts;
			}

            vm.loadedCities = false;
            RegionsService.getCitiesByCounty(county_id).then(function(response) {
               vm.loadedCities = true;
               vm.citiesForCounty = response.data;
            });

        };

		vm.addRecurrent = function (index, type) {
			vm.quest.questions = SurveyLogicUtils.addRecurrent(vm.quest.questions, index, type);
		};

		vm.reload = function (params) {
			$state.transitionTo($state.current, params, {reload: true, inherit: false, notify: true});
		};

		vm.showSendEmailsDialog = function(event) {
			var confirm = $mdDialog.confirm()
				.title('Are you sure you want to send the email notification to ' + vm.filteredUsersCount + ' forecasters?')
				.targetEvent(event)
				.ok('Yes')
				.cancel('No');

			$mdDialog.show(confirm).then(function () {
				vm.sendNotifications();
			}, function () {
			});
		};

		vm.sendNotifications = function() {
			vm.disableSendMail = true;
			ForecastService.sendNotifications(vm.quest.id, vm.notificationsToAll,
				function(){
					QuestiaUtils.showAlert(
						'SUCCES!',
						'Emails have been successfully sent.',
						function() {
							delete vm.disableSendMail;
						}
					);
				},
				function() {
					QuestiaUtils.showAlert(
						'ERROR!',
						'Something went wrong with sending the emails.',
						function() {
							delete vm.disableSendMail;
						}
					);
				}
			)
		};

		function performBeforeSaveManipulation() {
			return SurveyValidationService.performBeforeSaveManipulation(vm.quest, vm.filters);
		}

		function removeUnusedProfileQuotas() {
			if (!vm.hasAgeQuotas){
				vm.quest.ageQuotas = [];
			}
			if (!vm.hasSexQuotas){
				vm.quest.sexQuotas = [];
			}
			if (!vm.hasLocationQuotas) {
				vm.quest.locationQuotas = [];
				vm.quest.selectedDivisionIdForQuotas = undefined;
			}
		}

		vm.save = function () {
			if (vm.quest.id) {
				SurveyEditsService.CheckQuestEdits(vm.quest.id, true,
					function (editsResponse) {
						vm.questEditsCount = parseInt(editsResponse.data);
						if (vm.questEditsCount > 0) {
							var confirm = $mdDialog.confirm()
								.title()
								.htmlContent("<h3><strong>The survey editor is opened by " + vm.questEditsCount + " other users.<br/>" +
									"Please close all other instances of the survey editor and refresh the page before editing.</strong></h3>")
								.cancel("DISCARD CHANGES")
								.ok("SAVE CHANGES");

							$mdDialog.show(confirm).then(
								function() {
									vm.saveSurvey();
								},
								function() {
									vm.saving = false;
									vm.reload({qid: vm.quest.id});
								}
							);
						}
						else {
							vm.saveSurvey();
						}
					}
				);
			}
			else {
				vm.saveSurvey();
			}
		};

		vm.saveSurvey = function () {
			vm.saving = true;
			removeUnusedProfileQuotas();

			var validationResult = SurveyValidationService.validateSurvey(vm);
			if (validationResult !== "") {
				vm.saving = false;
				vm.saveOk = false;
				vm.errorValidating = validationResult;
				document.getElementById("saveErrors").scrollIntoView();
				return;
			}
			vm.errorValidating = false;

			var saveQuestId = typeof vm.quest.id === "undefined" ? "new_quest" : vm.quest.id;

			SurveysService.AddQuest(saveQuestId, performBeforeSaveManipulation(),
				function (response) {
					vm.saving = false;
					vm.errorSaving = false;
					vm.saveOk = true;

					vm.createProfileQuotasIfNotExists();

					$timeout(function () {
						vm.saveOk = false;
					}, 1250);
					if (!vm.quest.id) {
						vm.reload({qid: response.data});
					} else {
						$timeout(function () {
							vm.reload({qid: vm.quest.id});
						}, 1250);
					}
				},
				function (response) {
					vm.saving = false;
					vm.saveOk = false;
					vm.errorSaving = response.data;
				});
		};

		vm.initializeFiltersForUI = function (filters){
           if(filters.location.user_county){
               vm.loadedCities = false;
               RegionsService.getCitiesByCounty(filters.location.user_county).then(function (response) {
				   vm.loadedCities = true;
				   vm.citiesForCounty = response.data;
				   vm.resetZonesDropdown(filters.location.user_county);
			   });
		    }
		};

		vm.disableParentEditForMatrixWithChoicesInheritance = function (questions) {
			for(var i = 0; i < questions.length; i++) {
				if(!questions[i].options)
					continue;
				if(questions[i].options.inheritItemsFrom && questions[i].options.matrix === "true") {
					var question = questions.find(function (elem) {
						return elem.id === questions[i].options.inheritItemsFrom;
                    });
					(function (questionQId) {
                        SurveysService.doesQuestionContainAnswers(question.question_id,
                            function (success) {
                                if (success.data) {
                                    vm.disableMatrixParentQuestions[questionQId] = true;
                                }
                            },
                            function (error) {
                                console.log(error);
                            });
                    })(questions[i].options.inheritItemsFrom);
                }
            }
        };

		vm.checkIfQuestionHasLimitedEdit = function (qId) {
			return typeof vm.disableMatrixParentQuestions[qId] !== 'undefined';
        };

		vm.checkIfQuestionIsEditable = function (question) {
			return !question.parentQuestionId;
		};

		(function initController() {
			vm.breadcrumbs = "Surveys : ";
			vm.activeSubmenu = 'surveys';

			$scope.$on('$destroy', function() {
				if (vm.questEditIntervalID) {
					$interval.cancel(vm.questEditIntervalID);
				}
			});

            vm.setBackUpUIQuotas();
            vm.loadedRegions = true;
			vm.notificationsToAll = false;
            vm.citiesForCounty = undefined;
            vm.hostname = $window.location.hostname;
            vm.ticketsEnabled = $rootScope.globals.ticketsEnabled;

            vm.selectedZones = [];
            vm.previousSelectedZones = [];

			vm.screenOutExamplesMap = new Map();
			vm.fillScreenOutExamplesMap();

			vm.screenOutTooltipsMap = new Map();
			vm.fillScreenOutToolTipsMap();
            vm.disableMatrixParentQuestions = {};

            vm.selectedChoiceId = undefined;
            vm.choices = undefined;
			vm.getChoicesForQuestionMessage = "";

			vm.questEditsCount = 0;

			if ($stateParams.qid) {
				vm.showSpinner = true;
				SurveyEditsService.CheckQuestEdits($stateParams.qid, false,
					function(checkSuccess) {
						vm.questEditsCount = parseInt(checkSuccess.data);
						if (vm.questEditsCount > 0) {
							$mdDialog.show(
								$mdDialog.alert()
									.title()
									.htmlContent("<h3><strong>The survey editor is opened by " + vm.questEditsCount + " other users.<br/>" +
										"Please close all other instances of the survey editor and refresh the page before editing.</strong></h3>")
									.ok("Open anyway")
							).then(
								function() {
									vm.getQuest($stateParams.qid);
								},
								function() {}
							);
						}
						else {
							vm.getQuest($stateParams.qid);
						}
					},
					function(checkError) {
						var confirm = $mdDialog.confirm()
							.title("Quest edit check error")
							.textContent("Could not check survey for edits. Proceed?")
							.cancel("No")
							.ok("Yes");

						$mdDialog.show(confirm).then(function() {
							vm.getQuest($stateParams.qid);
						});
					}
				);
			}
			else {
				getQuotasAndTransformToUiFormat();
				vm.breadcrumbs = "Surveys : Create new survey";
				vm.quest = {
					type: "test",
					questions: [{
						choices: [{}],
						type: "text",
						editMode: true
					}],
					quotaGroups: [],
					visibleInPanel: true
				};
				vm.getFiltersCount();
			}

			RegionsService.getAllCounties().then(function (response) {
				vm.counties = response.data;
			}, function (error) {});

			RegionsService.getDivisionsList().then(function(response) {
				vm.availableDivisions = response.data;
			});
			RegionsService.getRegionsList().then(function(response) {
				vm.regions = response.data;
			});

			RegionsService.getDivisionsToRegionsMap().then(function(response) {
				vm.divisionsToRegionsMap = response.data;
			});

			SurveysService.GetPredefinedQuestions(
				function (response) {
					vm.predefinedQuestions = response.data;
				},
				function (response) {
				}
			);
		})();

		vm.getQuest = function (questId) {
			SurveysService.GetSurveyJson(questId, false,null,
				function (response) {
					vm.quest = response.data;
					getQuotasAndTransformToUiFormat();
					parseQuotasForUI(vm.quest.questions);

					if($stateParams.focusOnQuestionWithId) {
						setTimeout(function() {
							var element = document.getElementById(vm.QUESTION_SECTION_ID_PREFIX + $stateParams.focusOnQuestionWithId);
							if(element) {
								element.scrollIntoView({behavior: "smooth"});
							}
						}, 3000);
					}

					vm.disableParentEditForMatrixWithChoicesInheritance(response.data.questions);

					if(vm.quest.type === 'forecast') {
						vm.forecastPageUrl = "https://" + $rootScope.globals.panelHostname + "/singleforecast/" + vm.quest.id;
					}
					vm.newUrl = "https://" + $rootScope.globals.panelHostname + '/polls/' + vm.quest.id;
					vm.publicHashUrl = vm.newUrl + "?hash=" + QuestiaUtils.sha1(vm.quest.id + vm.quest.description);

					vm.breadcrumbs = "Surveys : Edit survey " + vm.quest.id;
					vm.quest = SurveyLogicUtils.initServerToUi(vm.quest);
					if(vm.quest.filters && !angular.equals({}, vm.quest.filters)) {
						vm.filters = FilterUtils.transformFiltersToUiFormat(vm.quest.filters);
						vm.initializeFiltersForUI(vm.filters);
						vm.getFiltersCount();
					}
					if(typeof vm.filters !== 'undefined') {
						if (vm.filters.user && vm.filters.user.selectedQuestionId) {
							vm.getChoicesForQuestion();
						}
					}
					else {
						vm.getFiltersCount();
					}
					delete vm.showSpinner;

					SurveyEditsService.CheckQuestEdits(questId, true,
						function(updateResponse) {
							vm.questEditsCount = parseInt(updateResponse.data);
							vm.startQuestEditPingInterval(questId);
						},
						function(updateResponse) {}
					);
				},
				function (response) {

				}
			);
		};

		vm.startQuestEditPingInterval = function (questId) {
			if (!vm.questEditIntervalID) {
				vm.questEditIntervalID = $interval(function () {
					SurveyEditsService.CheckQuestEdits(questId, true,
						function(updateResponse) {
							vm.questEditsCount = parseInt(updateResponse.data);
						},
						function(updateResponse) {}
					);
				}, QUEST_EDIT_PERIOD);
			}
		};

		vm.resetZonesDropdown = function (countyId) {
			vm.loadingZonesDropdown = true;
			RegionsService.getAllZonesForCounty(
				countyId,
				function success(response) {
					vm.countyZones = response.data;
					vm.selectZonesBasedOnSelectedCities();
					vm.loadingZonesDropdown = false;
				},
				function error(error) {
					console.log("Error retrieving zones for county " + countyId + " !");
					vm.loadingZonesDropdown = false;
				}
			);
		};



		vm.selectZonesBasedOnSelectedCities = function () {
			if (vm.selectedZones && vm.selectedZones.length > 0) {
				delete vm.selectedZones;
				vm.selectedZones = [];
			}
			for (var i in vm.countyZones) {
				if (vm.isZoneSelected(vm.countyZones[i])) {
					vm.selectedZones.push(vm.countyZones[i]);
				}
			}
			vm.previousSelectedZones = angular.copy(vm.selectedZones);
		};

		vm.isZoneSelected = function (zone) {
			if (typeof vm.filters.location.user_county_districts === "undefined" || vm.filters.location.user_county_districts.length === 0) {
				return false;
			}
			if (typeof zone.citiesList === "undefined" || zone.citiesList.length === 0) {
				return false;
			}
			var citiesContained = zone.citiesList.filter(function (elem, index) {
				return vm.filters.location.user_county_districts.includes(elem.city_id);
			});

			return citiesContained.length === zone.citiesList.length;
		};

		vm.getFirstDistinctElementBetweenZonesArrays = function (zones1, zones2) {
			for (var i in zones1) {
				var foundElem = zones2.filter(function (elem, index) {
					return elem.zoneId === zones1[i].zoneId;
				});
				if (foundElem.length === 0) return zones1[i];
			}
		};

		vm.selectCitiesBasedOnZonesSelection = function (selectedZones) {
			if (typeof vm.filters.location.user_county_districts === "undefined") {
				vm.filters.location.user_county_districts = [];
			}
			if (selectedZones.length === vm.previousSelectedZones.length) {
				vm.previousSelectedZones = angular.copy(selectedZones);
			} else {
				if (selectedZones.length > vm.previousSelectedZones.length) {
					var checkedZone = vm.getFirstDistinctElementBetweenZonesArrays(selectedZones, vm.previousSelectedZones);
					vm.selectCitiesAfterZoneSelection(checkedZone);
				}
				if (selectedZones.length < vm.previousSelectedZones.length) {
					var uncheckedZone = vm.getFirstDistinctElementBetweenZonesArrays(vm.previousSelectedZones, selectedZones);
					vm.deselectCitiesAfterZoneDeselection(uncheckedZone);
				}
				vm.previousSelectedZones = angular.copy(selectedZones);
			}
		};

		vm.selectCitiesAfterZoneSelection = function (zone) {
			for (var j in zone.citiesList) {
				if (!vm.filters.location.user_county_districts.includes(zone.citiesList[j].city_id)) {
					vm.filters.location.user_county_districts.push(zone.citiesList[j].city_id);
				}
			}
		};

		vm.deselectCitiesAfterZoneDeselection = function (zone) {
			for (var j in zone.citiesList) {
				if (vm.filters.location.user_county_districts.includes(zone.citiesList[j].city_id)) {
					var indexOfElement = vm.filters.location.user_county_districts.indexOf(zone.citiesList[j].city_id);
					vm.filters.location.user_county_districts.splice(indexOfElement, 1);
				}
			}
		};

		vm.checkSurveyId = function(id){

			if(id.toString().length > 10){
				vm.getChoicesForQuestionMessage = "The input is too large";
				return;
			}

			if(typeof id !== 'undefined' && id != null){
				vm.choices = undefined;
				vm.getChoicesForQuestionMessage = "";

				vm.filters.user.selectedChoiceId = undefined;
				SurveysService.getChoicesForQuestion(id,
					function (success) {
                       vm.choices = success.data;
                       vm.choices[-1] = 'NULL';
                       vm.getChoicesForQuestionMessage = "";
                       vm.getFiltersCount();
					},
					function(error){
					   vm.getChoicesForQuestionMessage = error.data.message;
					   vm.filters.user.selectedChoiceId = -1;
					})
			}
		};

		vm.onSelectChange = function(){
           if(vm.filters.user.selectedChoiceId === "-1"){
			   vm.filters.user.selectedChoiceId = undefined;
		   }
		};

		vm.getChoicesForQuestion = function(){
			SurveysService.getChoicesForQuestion(vm.filters.user.selectedQuestionId,
				function (success) {
					vm.choices = success.data;
					vm.choices[-1] = 'NULL';
					vm.getChoicesForQuestionMessage = "";
				},
				function(error){
					vm.getChoicesForQuestionMessage = error.data.message;
				})
		};

		vm.onDivisionChangeForQuestionSkip = function(skip) {
			delete skip.value;
		};

		vm.onDivisionChangeForQuestionScreenout = function(question) {
			delete question.options.pointsChoiceVal;
		};
	}
})();
